import React, { Component } from 'react';
import { Loader} from '../../utils/common';
import DataGrid from "g6reactdatagrid";
import { Row, Col, Input, Nav, NavItem, NavLink , Modal, Button, ModalHeader, ModalBody, ModalFooter} from "reactstrap";
import { AppSwitch } from '@coreui/react'
import moment from 'moment';
import AddEditContent from './AddEditContent';
import CategoryList from './CategoryList';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import Session from '../../utils/session';


import Constants from '../../config/constants';
import * as _ from 'underscore';

class Content extends Component {


  constructor(props) {
    super(props);
    // this.apiUrl = "http://ninjaapi.gate6.com:3002"
    this.session = Session.getSessionData('config');
    this.apiUrl = this.session && this.session.UTILS && this.session.UTILS.BUCKET_URL ? this.session.UTILS.BUCKET_URL : process.env.REACT_APP_CLOUDFRONT_URL;
    this.state = {
      totalRecords: 0,
      contentList : [],
      ageGroups: [],
      authors:[],
      dropdownOpen: {},
      page:0,
      limit : 10,
      offset : 0,
      sortColumn: "",
      sortValue: "",
      openAddEditContentModal : false,
      contentObj: {},
      searchKey: '',
      categoryId: '',
      status: '',
      accessType: '',
      authorId: '',
      signedQueryString: "",
      formData: {
        _id: 0,
        title : "",
        priority: 1,
        priorityText: 'High',
        status : "",
        pillarId: "",
        categoryId : "",
        contentType : "",
        titleColor : "",
        thumbTitleColor : "",
        dialColor : "",
        description : "",
        fullImage : "",
        thumb : "",
        media : "",
        mediaUrl : "",
        order : 1,
        titlePosition : Constants.MIDDLE,
        contentBy: '',
        narratedBy: '',
        writtenBy: '',
        writerId: ''
      },
      systemLanguages : Session.getSessionData('languages')['languages']
    }
    this.intervalInstance = '';

    this.columns = [
      { "columnName": "title", "displayName": "Title", "sort": true},
      { "columnName": "contentType", "displayName": "Content Type", "sort": true},
      { "columnName": "status", "displayName": "Status", "sort": true},
      { "columnName": "priority", "displayName": "Priority" },
      { "columnName": "titleColor", "displayName": "Title Color" },
      { "columnName": "thumbTitleColor", "displayName": "Thumb Title Color" },
      { "columnName": "imageThumb", "displayName": "Image" },
      { "columnName": "createdDate", "displayName": "Created On", "sort": true}
    ];
  }

  componentDidMount() {
    this.getContentList();
    this.getAgeGroups();
    this.getAuthors();
  }

  getContentList(){
    let {categoryId, searchKey, sortColumn, sortValue, limit, offset, authorId, status, accessType} = this.state;
    let params = {
      categoryId: categoryId,
      search: searchKey,
      sortColumn: sortColumn,
      sortValue: sortValue,
      limit : limit,
      offset : offset,
      authorId,
      status,
      accessType
    }
    this.props._getContentList(params);
  }

  componentWillReceiveProps(nextProps) {
    let self = this;
    let contentList = [];
    Loader(nextProps.requestProcess);


    if (nextProps.statusChange && nextProps.statusChange.statusCode === 200) {
        this.getContentList();
    }



    if (nextProps.data && nextProps.data.statusCode === 200) {
      let listData = (nextProps.data.result.length) ? nextProps.data.result   : [];
      let signedQueryString= (nextProps.data.signedQueryString) ? nextProps.data.signedQueryString : '?';
      listData.map(function (element, key) {
        let categories = [];
        let images = [];
        let categoryImages = "";
        
        element['apiTitleColor'] = element.titleColor
        element['apiThumbTitleColor'] = element.thumbTitleColor
        element['apiDialColor'] = element.dialColor

        // placing ,iltiple images thumb according to selected categories
        element['categoriesMap'].map(function( subElem, subKey) {
          if (images.indexOf(subElem.imageThumb) === -1) {
              images.push(subElem.imageThumb)
              categoryImages += '<img src="'+self.apiUrl+subElem.imageThumb+ signedQueryString+ '" alt="'+element.title+'" />'
            
          }
          return subElem;
        })
        categories = [];
        element.imageThumb = '<div class="title-img" >'+categoryImages+'</div> '

        element.titleColor = '<div class="title-bg-color" style="background:'+element.titleColor+';"></div>'
        element.thumbTitleColor = '<div class="title-bg-color" style="background:'+element.thumbTitleColor+';"></div>'
        element['priority'] = 0;
        if(element && element.order){
          element['priority'] = element.order === 1  ? Constants.HIGH  : (element.order === 2? Constants.MEDIUM : (element.order === 3 ? Constants.LOW : Constants.LOW) )
        }

        let contributorTitle = '';
        if(element.contentBy == Constants.NARRATED_BY && (element.narratedBy || element.writtenBy)){

          if(element.narratedBy && element.writtenBy){
            contributorTitle = '<span style="font-size:12px;display: block;">(<i>Narrator</i>: <b>'+element.narratedBy+'</b><br/> <i>Writer</i>: <b>'+element.writtenBy+'</b>)</span>';
          }else if(element.narratedBy && !element.writtenBy){
            contributorTitle = '<span style="font-size:12px;display: block;">(<i>Narrator</i>: <b>'+element.narratedBy+'</b>)</span>';
          }else if(!element.narratedBy && element.writtenBy){
            contributorTitle = '<span style="font-size:12px;display: block;">(<i>Writer</i>: <b>'+element.writtenBy+'</b>)</span>';
          }
        }else if(element.contentBy == Constants.NARRATED_WRITTEN_BY && element.narratedBy){
          contributorTitle = '<span style="font-size:12px;display: block;">(<i>Narrator and Writer</i>: <b>'+element.narratedBy+'</b>)</span>';
        }else if(element.contentBy == Constants.COMPOSED_BY && element.narratedBy){
          contributorTitle = '<span style="font-size:12px;display: block;">(<i>Composer</i>: <b>'+element.narratedBy+'</b>)</span>';
        }

        let title = element.title;
        if(element.contentUsers.length > 0 || element.narratedBy){          
          title = element.contentUsers && element.contentUsers.length > 0 && element.contentUsers[0].name ? title+'<span style="font-size:12px;display: block;">(<i>Author</i>: <b>'+element.contentUsers[0].name+'</b>)</span>': title;
          title = contributorTitle ? title + contributorTitle: title;
          element['title'] = title
        }else{
          element['title'] = contributorTitle ? title + contributorTitle: title;
        }
        element.createdDate = moment(element.createdDate).format("DD-MMM-YYYY")
        contentList.push(element);
        return element;
      });
      self.setState({contentList: contentList, openAddEditContentModal: false, contentObj: {}, totalRecords  :  (nextProps.data && nextProps.data['totalRecords']) ?  nextProps.data['totalRecords'] : 0});
    }
  }

  toggle(id) {
    let self  = this;
    self.state.dropdownOpen[id] = !self.state.dropdownOpen[id]
    this.setState({});
  }

  appUpdatePillarCategory(rowData){
    let self = this
    _.each(this.state.systemLanguages, function(value, index){
      let contentObj = _.findWhere(rowData['contentLang'], {languageSlug : value['slug']});
      self.state.systemLanguages[index]['contentObj'] = (!_.isEmpty(contentObj)) ? contentObj : {title : "", languageSlug :  value['slug']};
    })
    this.setState({openAddEditContentModal: true, contentObj: rowData, systemLanguages : self.state.systemLanguages });



  }


  onCloseModal = (shouldRefresh) => {
    this.setState({openAddEditContentModal: false, contentObj: {} });
    if(shouldRefresh){
        this.getContentList();
    }
  };

  changeStatus(rowData){
    rowData['contentId'] = rowData['_id'];
    let adminId = (rowData.contentUsers && rowData.contentUsers.length > 0) ? rowData.contentUsers[0]._adminId : '';
    let params = {
      status: rowData['status'] === "PUBLISHED" ? "UNPUBLISH" : "PUBLISHED",
      contentId: rowData['_id'],
      adminId: adminId,
      title:rowData.title
    }
    console.log(params)
    this.props._changeContentStatus(params);
  }

  updateAuthorContentStatus(data, status){
    let adminId = (data.contentUsers && data.contentUsers.length > 0) ? data.contentUsers[0]._adminId : '';
    let params = {
      status: status,
      contentId: data['_id'],
      adminId: adminId,
      title:data.title
    }
    this.props._changeContentStatus(params);
  }

  handleChange(event){
    let value = event.target.value;
    this.setState({pillarId : value});
  }
  categoryChange(event){
    let value = event.target.value;
    this.setState({categoryId : value, offset: 0, page: 0}, ()=>{this.getContentList()});    
  }
  statusChange(event){
    let value = event.target.value;
    this.setState({status : value, offset: 0, page: 0}, ()=>{this.getContentList()});
  }

  accessTypeChange(event){
    let value = event.target.value;
    this.setState({accessType : value, offset: 0, page: 0}, ()=>{this.getContentList()});
  }
  
  authorChange(event){
    let value = event.target.value;
    this.setState({authorId : value, offset: 0, page: 0}, ()=>{this.getContentList()});
  }

  searchContent(){
    let self = this
    clearTimeout(self.intervalInstance)
    self.intervalInstance = setTimeout(() => {
      self.setState({
        limit : 10,
        offset : 0,
        page : 0
      }, function(){
        self.getContentList();
      })
    }, 300)
  }

  updateContent(rowData) {
    let self = this
    _.each(this.state.systemLanguages, function(value, index){
      let contentObj = _.findWhere(rowData['contentLang'], {languageSlug : value['slug']});
      self.state.systemLanguages[index]['contentObj'] = (!_.isEmpty(contentObj)) ? contentObj : {title : "", description : "", media : "", languageSlug :  value['slug']};
    })

    let contentObj = {
      categoryId : rowData.categoryId,
      contentType : rowData.contentType,
      description : rowData.description,
      dialColor : rowData.apiDialColor,
      fullImage : rowData.fullImage,
      image: rowData.image,
      thumb : rowData.thumb,
      status : rowData.status,
      thumbTitleColor : rowData.apiThumbTitleColor,
      title : rowData.title,
      titleColor : rowData.apiTitleColor,
      _id : rowData._id,
      mediaUrl : rowData.mediaUrl,
      order : rowData.order,
      titlePosition : rowData.titlePosition,
      contentLang : rowData.contentLang,
      contentUsers : rowData.contentUsers,
      ContentAgeMap : rowData.ContentAgeMap,
      contentTags: rowData.contentTags,
      contentBy: rowData.contentBy,
      narratedBy: rowData.narratedBy,
      writtenBy: rowData.writtenBy,
      writerId: rowData.writerId
    }

    this.setState({
      contentObj : contentObj,
      openAddEditContentModal : true,
      systemLanguages : self.state.systemLanguages
    })
  }

  deleteContent(rowObj){
    this.setState({ deleteRowData: rowObj, visible: true })
  }
  closeModal(){
    this.setState({ deleteRowData: {}, visible: false })
  }

  confirmDelete(){
    let { deleteRowData } = this.state;
    if(deleteRowData && Object.keys(deleteRowData).length > 0){
      this.props._deleteContent({ contentId: deleteRowData._id });
      setTimeout(()=>{ this.closeModal(); }, 1000);
    }
  }

  createAuthorOption(){
    let options =  this.state.authors
    return options.map(function(obj){return (<option key={obj._id} value={obj._id} >{obj.name}</option>);})
  }

  getAgeGroups(){
    this.props._getAgeGroups().then( response => {
      if(response && response.statusCode === 200){
        this.setState({ageGroups: response.result});
      }
    }, error => {
      console.log('error', error)
    })
  }
  getAuthors(){
    this.props._getAuthors().then( response => {
      if(response && response.statusCode === 200){
        this.setState({authors: response.result});
      }
    }, error => {
      console.log('error', error)
    })
  }
  render() {
    let self = this;
    let { contentList, totalRecords, page, openAddEditContentModal, contentObj, visible, limit, systemLanguages, ageGroups, authors, authorId, status, accessType } = this.state;
    let totalRecordsLabel = '##TOTAL_RECORDS## '+(totalRecords > 1 ? 'Contents' : 'Content')
    let options = {
      totalRecords: totalRecords,
      totalRecordsLabel: totalRecordsLabel,
      rowsPerPage: limit,
      page: page,
      loadMore: false,
      customColumn: {
        isActive: true,
        columnTitle: '',
        columnContent: (rowData) => {
          return (
          <div >
            <Nav className="justify-content-right">    
              {(rowData.status==='PUBLISHED' || rowData.status==='APPROVED' || rowData.status==='UNPUBLISH' || rowData.status==='UNPUBLISHED') &&
              <NavItem>
                <AppSwitch className={'mx-1 mt-2'} variant={'pill'} color={'primary'} label checked={rowData.status==='PUBLISHED'} onChange={() => { this.changeStatus(rowData); }} />
              </NavItem>
              }
              <NavItem>
                <NavLink href="/" onClick={(e) => { this.updateContent(rowData); e.preventDefault(); }}><FontAwesomeIcon icon={faEdit} size='lg' /> </NavLink>
              </NavItem>
              <NavItem>
                <NavLink href="/" onClick={(e) => { this.deleteContent(rowData); e.preventDefault();}}><FontAwesomeIcon icon={faTrashAlt}  size='lg' /></NavLink>
              </NavItem>
            </Nav>
          </div>
          );
        }
      },

      onPageChange(pageNumber) {
        self.setState({page : pageNumber, offset:  parseInt(pageNumber) * parseInt(self.state.limit)});
        setTimeout(() => {
          self.getContentList();
        }, 200)


      },
      onColumnSortChange: (column, direction) => {
        direction = (direction === "ASC") ? -1 : 1;
        self.setState({sortColumn: column, sortValue: direction}, ()=>{this.getContentList()})        
      }
    };

    
    return (
      <div className="content">
        <Row className="pb-4">
          <Col lg={12} sm={12}>
            <h3>Content List</h3>
          </Col>
        </Row>
        <Row className="pb-4">
        <Col lg={3}><Input type="text" id="searchKey" placeholder="Search String" name="searchKey" value={this.state.searchKey} onChange={($event)=>{this.setState({searchKey: $event.target.value}); this.searchContent()}} /></Col>
        <Col lg={3}><CategoryList selectedPillarId={this.state.pillarId} selectedCategoryId={this.state.categoryId} categoryChange={(event)=>{this.categoryChange(event)}}  /></Col>
        <Col lg={2}>
          <Input type="select" name="authorId" id="authorId" value={ authorId } onChange={($event)=>{this.authorChange($event)}}>
            <option value="">Select Author</option>
            {this.createAuthorOption()}
          </Input>
        </Col>
        <Col lg={2}>
          <Input type="select" name="status" id="status" value={ status } onChange={($event)=>{this.statusChange($event)}}>
            <option value="">Select Status</option>
            <option value="PUBLISHED">Published</option>
            <option value="UNPUBLISH">Unpublished</option>
            <option value="PENDING">Pending</option>
            <option value="APPROVED">Approved</option>
          </Input>
        </Col>
        {/* <Col lg={2}>
          <Input type="select" name="accessType" id="accessType" value={ accessType } onChange={($event)=>{this.accessTypeChange($event)}}>
            <option value="">Select Access Type</option>
            <option value="FREE">Free</option>
            <option value="PAID">Paid</option>
            <option value="GUEST">Guest</option>
          </Input>
        </Col> */}
        <Col lg={2}><button onClick={()=>{this.appUpdatePillarCategory(this.state.formData)}} className="btn btn-primary btn-block">Add New</button></Col>
        </Row>

        <Row>
          <Col lg={12} sm={12}>
            {openAddEditContentModal && systemLanguages && 
            <AddEditContent visible={openAddEditContentModal}  closeModal={this.onCloseModal} formData={ contentObj } handleChange={(event)=>{this.handleChange(event)}} categoryChange={(event)=>{this.categoryChange(event)}} getContent={(event)=>{this.getContentList(null)}} systemLanguages={systemLanguages} updateAuthorContentStatus={(rowData, status)=>{this.updateAuthorContentStatus(rowData, status)}} ageGroups={ageGroups} authors={authors} />}
            <div className="dataTable-wrapper pillarList_grid">
              <DataGrid
                columns={this.columns}
                options={options}
                rows={contentList}
              />
            </div>
          </Col>
        </Row>
        <Modal isOpen={visible}>
            <ModalHeader toggle={()=>{this.closeModal(false)}}>Delete Content</ModalHeader>
            <ModalBody>
              Are you sure, want to delete content?
            </ModalBody>
            <ModalFooter className="justify-content-left">
              <Button type="button" size="sm" color="primary" onClick={()=>{this.confirmDelete()}}>Delete</Button>{' '}
              <Button type="reset" size="sm" color="danger"  onClick={() => { this.closeModal() }}>Cancel</Button>
            </ModalFooter>
        </Modal>
      </div>
    );
  }
}

export default Content;
