import {createAction} from 'redux-act';
import httpClient from '../../services/http';


export const requestGetPillars = createAction();
export const responseGetPillars = createAction();

export const requestGetPillar = createAction();
export const responseGetPillar = createAction();

export const requestUpdatePillar = createAction();
export const responseUpdatePillar = createAction();


export const requestPillerChangeStatus = createAction();
export const responsePillerChangeStatus = createAction();



export function getPillars(params){
  return (dispatch) => {
      dispatch(requestGetPillars([]));
      httpClient.call('PILLAR','/get_pillars', params, {method: 'GET', headers: {}}).then(function(response){
          dispatch(responseGetPillars(response));
      }, function(error){
        console.log('error', error);
          dispatch(responseGetPillars(error));
      });
  }
}


export function changePillerStatus(params){
    return (dispatch) => {
        dispatch(requestPillerChangeStatus([]));
        httpClient.call("PILLAR","/update_pillar/" , params, {method: 'PUT'}).then(function(response){
            dispatch(responsePillerChangeStatus(response));
        }, function(error){
            dispatch(responsePillerChangeStatus(error));
        });
    }
}

export function getPillar(params){
    return (dispatch) => {
      dispatch(requestGetPillar([]));
      httpClient.call('PILLAR','/get_pillars', {}, {method: 'GET', headers: {}}).then(function(response){
          dispatch(responseGetPillar(response));
      }, function(error){
          dispatch(responseGetPillar(error));
      });
  }
}


export function updatePillar(params){
    return (dispatch) => {
      dispatch(requestUpdatePillar([]));
      httpClient.call('PILLAR','/update_pillar', params, {method: 'PUT', headers: {}}).then(function(response){
          dispatch(responseUpdatePillar(response));
      }, function(error){
          dispatch(responseUpdatePillar(error));
      });
  }
}
