import React, { Component } from 'react';
import { Loader } from '../../utils/common';
import Session from '../../utils/session';
import DataGrid from "g6reactdatagrid";
import { Row, Col, Nav, NavItem, NavLink } from "reactstrap";
import { AppSwitch } from '@coreui/react'
import Constants from '../../config/constants';  
import NotificationForm from './NotificationForm';  
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit } from '@fortawesome/free-solid-svg-icons'


import  * as _ from 'underscore'



class Notifications extends Component {

  constructor(props) {
    super(props);
    this.state = {
      notificationList : [],
      contentData: [],
      totalRows: 0,
      rowsPerPage: 10,
      page:0,
      limit : 10,
      offset : 0,      
      openAddNotificationModal: false,
      openDeleteNotificationModal: false,
      selectedObj: null,
      systemLanguages : Session.getSessionData('languages')['languages']
    }
    this.notificationColumns = [
      { "columnName": "gridTitle", "displayName": "Title", sort : false},
      { "columnName": "ageGroup", "displayName": "Age Group", sort : false},
      { "columnName": "gridDesc", "displayName": "Description", sort : false},
      { "columnName": "contentTitle", "displayName": "Track", sort : false},
      { "columnName": "alertTime", "displayName": "Time", sort : false},
      { "columnName": "days", "displayName": "Days", sort : false},
      { "columnName": "repeat", "displayName": "Repeat", sort : false},
    ];
  }

  componentDidMount() {
    this.getAllNotifications();
    this.getContent('');
  }

  getContent(searchKey){
    this.props._getContentList({search: searchKey, limit: 1000, offset: 0});
  }

  componentWillReceiveProps(nextProps) {
    let self = this;
    let notificationList = [];
    Loader(nextProps.requestProcess);
    if(nextProps.contentList && nextProps.contentList.statusCode === 200){
      this.setState({contentData: nextProps.contentList.result});
    }
    if ((nextProps.notificationData && nextProps.notificationData.statusCode === 200)) {
      let couponList = [];
      let totalRows = 0;
      if (nextProps.notificationData && nextProps.notificationData.result) {
        nextProps.notificationData.result.list.map((element, index)=>{
          nextProps.notificationData.result.list[index]['gridTitle'] = (element.title.length > Constants.MAX_CHARACTER_LIMIT) ?  element.title.substring(0, Constants.MAX_CHARACTER_LIMIT)+"..." : element.title;


          nextProps.notificationData.result.list[index]['gridDesc'] = (element.description.length > Constants.MAX_CHARACTER_LIMIT) ?  element.description.substring(0, Constants.MAX_CHARACTER_LIMIT)+"..." : element.description;

          nextProps.notificationData.result.list[index]['repeat'] = element.repeatStatus ? 'Yes' : 'No'
          nextProps.notificationData.result.list[index]['ageGroup'] = element.minAge + ' - '+element.maxAge 
        })
        couponList = nextProps.notificationData.result.list;
        totalRows = nextProps.notificationData.result.totalRecords;        
      }
      self.setState({notificationList : couponList, totalRows: totalRows});  
    }

    if (nextProps.addUpdateData && nextProps.addUpdateData.statusCode === 200) {
      self.closeModal();
      self.getAllNotifications();
    }

    if(nextProps.addData) {
      if(nextProps.addData.statusCode === 200) {
        self.closeModal();
        self.getAllNotifications()
      }else {
        self.setState({addNotificationPopupError : (nextProps.addData && nextProps.addData.data && nextProps.addData.data.statusDescription) ? nextProps.addData.data.statusDescription : ""});
      }
    }
  }



  getAllNotifications() {
    let {limit, offset} = this.state;
    let params = {
      limit : limit,
      offset : offset
    }
    this.props._getAllNotifications(params);
  }

  addNotificationModal(event, rowData) {
    let self = this;
    self.state.systemLanguages.map(function(obj, index){
      let langObj = {};
      langObj = {
        _id : (rowData && rowData.reminderLanguages && _.findWhere(rowData.reminderLanguages, {languageSlug : obj.slug})) ? _.findWhere(rowData.reminderLanguages, {languageSlug : obj.slug})._id : null,
        title : (rowData && rowData.reminderLanguages && _.findWhere(rowData.reminderLanguages, {languageSlug : obj.slug})) ? _.findWhere(rowData.reminderLanguages, {languageSlug : obj.slug}).title : (rowData && rowData.title)  ? rowData.title : "",
        description : (rowData && rowData.reminderLanguages && _.findWhere(rowData.reminderLanguages, {languageSlug : obj.slug})) ? _.findWhere(rowData.reminderLanguages, {languageSlug : obj.slug}).description : (rowData && rowData.description) ? rowData.description : "",
        languageSlug : obj.slug,
      }
      self.state.systemLanguages[index]['lang'] = langObj
    })

    this.setState({
      systemLanguages: self.state.systemLanguages,
      openAddNotificationModal : true,
      selectedObj: rowData,
    })
  }

  closeModal(){
    this.setState({openDeleteNotificationModal: false, openAddNotificationModal: false, selectedObj : {}});
  }


  

  handleChange(event) {
    let target = event.target;
    let name = target.name;
    let value;


    if (target.type === 'checkbox') {
      value = (this.state[name]) ? false : true
    }else {
      value = target.value;
    }
    this.setState({[name]: value});
  }

  changeSubscription(event, rowData) {
    let subsObj = {
      featureId : rowData['_id'],
      subscription : (event.target.checked) ? Constants.PAID : Constants.FREE,
    }
    // calling api for change subscription
    this.props._updateFeature(subsObj);
  }

  addUpdateNotification(notificationObj) {
    this.props._addUpdateNotification(notificationObj);
    //this.setState({addDiscountPopupError : ""});
    //event.preventDefault();
  }

  changeStatus(rowData){
    let languages = [];
    let self = this;
    self.state.systemLanguages.map(function(obj, index){
      let langObj = {};
      langObj = {
        _id : (rowData && rowData.reminderLanguages && _.findWhere(rowData.reminderLanguages, {languageSlug : obj.slug})) ? _.findWhere(rowData.reminderLanguages, {languageSlug : obj.slug})._id : null,
        title : (rowData && rowData.reminderLanguages && _.findWhere(rowData.reminderLanguages, {languageSlug : obj.slug})) ? _.findWhere(rowData.reminderLanguages, {languageSlug : obj.slug}).title : (rowData && rowData.title)  ? rowData.title : "",
        description : (rowData && rowData.reminderLanguages && _.findWhere(rowData.reminderLanguages, {languageSlug : obj.slug})) ? _.findWhere(rowData.reminderLanguages, {languageSlug : obj.slug}).description : (rowData && rowData.description) ? rowData.description : "",
        languageSlug : obj.slug,
      }
      languages.push(langObj);
    })
    rowData['languages'] = languages;
    rowData['status'] = rowData['status'] ? false : true;
    rowData['reminderId'] = rowData['_id'];
    this.props._addUpdateNotification(rowData);
  }



  render() {
    let self = this;
    let { notificationList, page, rowsPerPage, contentData, addUpdateData, totalRows, selectedObj, openAddNotificationModal, addNitificationPopupError, systemLanguages } = this.state;
    let totalRecordsLabel = '##TOTAL_RECORDS## '+(totalRows && totalRows.length > 1) ? 'Notifications' : 'Notification';

    let notificationsOptions = {
      totalRecords: totalRows,
      totalRecordsLabel: totalRecordsLabel,
      rowsPerPage: rowsPerPage,
      page: page,
      loadMore: false,
      customColumn: {
        isActive: true,
        columnTitle: 'Action',
        columnContent: (rowData) => {
          return (
            <div>            
              <Nav>
                <NavItem>
                  <NavLink href="Javascript:void(0);" onClick={(event) => { this.addNotificationModal(event, rowData); }}><FontAwesomeIcon icon={faEdit} size='lg' /> </NavLink>
                </NavItem>

                <NavItem>
                  <AppSwitch className={'mx-1'} variant={'pill'} color={'primary'} label checked={rowData.status} onChange={() => { this.changeStatus(rowData); }} />
                </NavItem>
              </Nav>
            </div>
          );
        },        
      },
      onPageChange(pageNumber) {
        self.setState({page : pageNumber, offset:  parseInt(pageNumber) * parseInt(self.state.limit)});
        setTimeout(() => {
          self.getAllNotifications();
        }, 200)  

      },
    };




    return (
      <div className="content">
        <Row className="pb-4">
          <Col lg={12} sm={12}>
            <Row className="pb-4">
              <Col lg={12} sm={12}>
                <h3>Notifications</h3>                 
              </Col> 
              <Col lg={12} sm={12} className="text-right">
                {
                   <button className="btn btn-primary" color="link" onClick={() => this.addNotificationModal()}>Add New Notification</button>
                }
              </Col> 
            </Row>
              {openAddNotificationModal && 
                  <NotificationForm 
                    contentData={contentData} 
                    systemLanguages={systemLanguages} 
                    addUpdateData={addUpdateData} 
                    getContent={(searchKey)=>{this.getContent(searchKey)}}
                    {...this.props} 
                    openAddNotificationModal={openAddNotificationModal} 
                    formData={selectedObj} 
                    closeModal={()=>{this.closeModal()}} 
                    addUpdateNotification={(notificatiopnsObj) => {this.addUpdateNotification(notificatiopnsObj)}}  
                    addNitificationPopupError={addNitificationPopupError}/>
              }             
              

              <div className="dataTable-wrapper pillarList_grid">
                {<DataGrid
                  columns={this.notificationColumns}
                  options={notificationsOptions}
                  rows={ notificationList }
                />}
              </div> 
          </Col>
        </Row>
        
      </div>

      
    );
  }
}

export default Notifications;
