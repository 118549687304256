import { connect } from 'react-redux';

import WhatsAppCampaign from './whatsAppCampaign';
import { getUsers, uploadCSV, sendSingleWhatsAppMessage, sendMessageToAll, getCampaignList } from './action';


const mapStateToProps = (state) => {
		
		var stateData = {
			requestProcess: state.whatsAppCampaign.requestProcess
		};

		if(state.whatsAppCampaign.dataImported){
			stateData['dataImported'] = state.whatsAppCampaign.dataImported;
		}
		if(state.whatsAppCampaign.users){
			stateData['users'] = state.whatsAppCampaign.users;
		}


		if(state.whatsAppCampaign.singleMessageSend){
			stateData['singleMessageSend'] = state.whatsAppCampaign.singleMessageSend;
		}
		if(state.whatsAppCampaign.sentAllMessage){
			stateData['sentAllMessage'] = state.whatsAppCampaign.sentAllMessage;
		}


		if(state.whatsAppCampaign.campaignList){
			stateData['campaignList'] = state.whatsAppCampaign.campaignList;
		}

		return stateData;
};

const mapDispatchToProps = (dispatch) => {
	return {
		_getUsers: () => {
			dispatch(getUsers());
		},

		_uploadCSV: (data, processCallBack) => {
			dispatch(uploadCSV(data, processCallBack));
		},


		_sendSingleWhatsAppMessage: (data) => {
			dispatch(sendSingleWhatsAppMessage(data));
		},

		_sendMessageToAll: (params)=>{
			dispatch(sendMessageToAll(params));
		},

		_getCampaignList: (params)=>{
			dispatch(getCampaignList(params));
		}	


	};
};


export default connect(
	mapStateToProps,
	mapDispatchToProps
)(WhatsAppCampaign);
