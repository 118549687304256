import {createReducer} from 'redux-act';
import { requestGetEmailCampaignList, responseGetEmailCampaignList, responseGetTemplateList} from './action';


const defaultState = {
	requestProcess: false,
};

const reducer = createReducer({

	
	[requestGetEmailCampaignList]: (state) => {
		return {
			requestProcess: true,
		};
	},

	[responseGetEmailCampaignList]: (state, params) => {
		return {
			requestProcess: false,
			emailCampaignList: params,
		};
	},

	[responseGetTemplateList]: (state, params) => {
		return {
			requestProcess: false,
			templateList: params,
		};
	},


}, defaultState);



export default reducer