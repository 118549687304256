import React, { Component } from 'react';
import { Loader, createCouponCode } from '../../../utils/common';
import { Alert, Row, Col, Card, CardBody, CardHeader, CardFooter, Table, Button, Modal, ModalHeader, ModalBody, ModalFooter, Pagination, PaginationItem, PaginationLink, FormGroup, Label, Input, Tooltip } from "reactstrap";
import Constants from '../../../config/constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faPlus, faSearch, faUnlink, faSync, faCopy, faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import SimpleReactValidator from 'simple-react-validator';
import config from '../../../config';

class ShortLinks extends Component {
  constructor(props) {
    super(props);
    this.validator = new SimpleReactValidator();
    this.state = {
      openShortLinkModal: false,
      total: 0,
      shortLinks: [],
      inviteCode: createCouponCode(),
      firstName: '',
      lastName: '',
      email: '',
      description: '',
      blogLink: '',
      startOn: new Date(moment().format("YYYY-MM-DD")),
      expiredOn: new Date(moment().add(1, 'year').format("YYYY-MM-DD")),
      isErrorMessaeg: '',
      copyText: "Copy to clipboard",
      page: 0,
      limit: 10,
      searchString: '',
      tooltipIndex: -1
    }
    this.getSocialAccountLinks();
  }

  componentWillReceiveProps(nextProps) {
    Loader(nextProps.requestProcess);
    if (nextProps.addUpdateUser && nextProps.addUpdateUser.statusCode === 200) {
      this.getSocialAccountLinks();
      this.toggleInviteCodeModal(null);
    } else if (nextProps.addUpdateUser && nextProps.addUpdateUser.statusCode !== 200) {
      this.setState({ isErrorMessaeg: nextProps.addUpdateUser.data.statusDescription })
    }

  }

  getSocialAccountLinks() {
    let { page, limit, searchString } = this.state;
    page = parseInt(page * limit);
    this.props._getSocialShortLinks({ page, limit, searchString }).then((response) => {
      if (response.statusCode == 200) {
        this.setState({ shortLinks: response.result.list, total: response.result.total });
      }
    })
  }

  toggleShortLinkModal() {

    this.setState({ openShortLinkModal: !this.state.openShortLinkModal }, () => {
      if (this.state.openShortLinkModal) {
        this.getSocialAccountLinks();
      }
    });
  }
  copyLink(linkCode, bitlyShortUrl) {
    if (linkCode) {
      const link = bitlyShortUrl ? bitlyShortUrl : config.NINJA_WEB_LINK + '/install/' + linkCode;
      this.setState({ copyText: 'Copied!' }, () => {

        if (window.clipboardData && window.clipboardData.setData) {
          // IE specific code path to prevent textarea being shown while dialog is visible.
          return window.clipboardData.setData("Text", link); 
    
        } else if (document.queryCommandSupported && document.queryCommandSupported("copy")) {
          var textarea = document.createElement("textarea");
          textarea.textContent = link;
          textarea.style.position = "fixed";  // Prevent scrolling to bottom of page in MS Edge.
          document.body.appendChild(textarea);
          textarea.select();
          try {
            return document.execCommand("copy");  // Security exception may be thrown by some browsers.
          } catch (ex) {
            console.warn("Copy to clipboard failed.", ex);
            return false;
          } finally {
            document.body.removeChild(textarea);
          }
        }
        // navigator.clipboard.writeText(link).then(function () {
        //   console.log('Async: Copying to clipboard was successful!');
        // }, function (err) {
        //   console.error('Async: Could not copy text: ', err);
        // });


      })
    }
  }




  toggleInviteCodeModal(socialPlatformId) {
    this.setState({
      openInviteCodeModal: !this.state.openInviteCodeModal,
      socialPlatformId,
      inviteCode: createCouponCode(),
      firstName: '',
      lastName: '',
      email: '',
      description: '',
      blogLink: '',
      isErrorMessaeg: null
    });
    this.validator.hideMessages();
  }



  resetShortUrl(socialPlatformId) {
    this.toggleInviteCodeModal(socialPlatformId);
  }


  handleChange(event) {
    let target = event.target;
    let name = target.name;
    let value = target.value;
    this.setState({ [name]: value });
  }

  setCopyTextToInitial() {
    let self = this
    setTimeout(() => {
      self.setState({ copyText: "Copy to clipboard" })
    }, 100)
  }

  generateNewInviteUrl(e) {
    if (this.validator.allValid()) {
      let discountsObj = {
        inviteCode: this.state.inviteCode,
        discount: "0",
        type: Constants.PERCENTAGE,
        description: this.state.description,
        email: this.state.email,
        firstName: this.state.firstName,
        lastName: this.state.lastName,
        discountType: Constants.SOCIAL_INVITE,
        startOn: this.state.startOn,
        blogLink: this.state.blogLink,
        expiredOn: moment(this.state.expiredOn).add(1, 'day').format("YYYY-MM-DD"),
      }

      this.setState({ isErrorMessaeg: null }, () => {

        this.props._resetSocialSortUrl(discountsObj);
      })
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
    e.preventDefault();
  }

  socialInsightsByCode(shortLinkId) {
    if (typeof this.props.socialInsightsByCode == 'function') {
      this.props.socialInsightsByCode(Constants.FACEBOOK.toLowerCase(), shortLinkId);
    }
  }

  setPaginationLinks() {
    let { page, total, limit } = this.state;
    let linksCount = (total > 0 && limit > 0) ? Math.ceil(total / limit) : 0;
    let links = [];
    for (let i = 0; i < linksCount; i++) {
      links.push(<PaginationItem key={i} active={page == i} >
        <PaginationLink disabled={page == i} href="javascript:void)(0)" onClick={(event) => { this.setState({ page: i }, () => { this.getSocialAccountLinks() }) }} >
          {i + 1}
        </PaginationLink>
      </PaginationItem>);
    }

    return (<Pagination aria-label="Page navigation example" className="float-right"> {links} </Pagination>)
  }

  toggleToolTip(index) {
    let { tooltipIndex } = this.state;
    this.setState({ tooltipIndex: tooltipIndex == -1 ? index : -1, copyText: 'Copy to clipboard' })
  }

  render() {
    const { openShortLinkModal, total, shortLinks, openInviteCodeModal, inviteCode, isErrorMessaeg, searchString, copyText, tooltipIndex } = this.state;

    return (
      <Row>
        <Card>
          <CardBody>
            <CardHeader>
              <Row>
                <Col lg={8} sm={12}>
                  <h3 className="mt-5">Short Links</h3>
                </Col>
                <Col lg={4} sm={12}>
                  <button className="social-media connect-button m-4 p-3" onClick={(e) => { this.resetShortUrl(); e.preventDefault(); }} >
                    <FontAwesomeIcon icon={faPlus} /> Generate New Link
                    </button>
                </Col>
              </Row>
              <Row>
                <Col lg={6} sm={12} className="searchwrap">
                  <Input type="text"
                    placeholder={'Search by Invite Code, First Name, Last Name or Email'}
                    onChange={(e) => { this.setState({ searchString: e.target.value }) }}
                    onKeyDown={(e) => { if (e.key == 'Enter') { this.getSocialAccountLinks() } }} />
                  <FontAwesomeIcon icon={faSearch} className="search-icon" onClick={() => { this.getSocialAccountLinks() }} />
                </Col>
                <Col lg={6} sm={12}>

                </Col>
              </Row>
            </CardHeader>
            <Table responsive hover>
              <thead>
                <tr>
                  <th scope="col">Name</th>
                  <th scope="col">Invite Code</th>
                  <th scope="col">Install</th>
                  <th scope="col">Signup</th>
                  <th scope="col">Blog Click</th>
                  <th scope="col">Click</th>
                  <th scope="col">Create Date</th>
                  <th scope="col">Action</th>
                </tr>
              </thead>
              <tbody>
                {shortLinks &&
                  shortLinks.map((element, index) => {
                    let tooltipIndexDom = 'tooltip-' + index;
                    return (
                      <tr key={index}>
                        <td>{element.lastName ? element.lastName : ''} {element.firstName ? element.firstName : ''}</td>
                        {/*<th scope="row">
                                  {element.shortUrl}
                                  <span id={tooltipIndexDom} className="copy-icon">
                                    <FontAwesomeIcon 
                                    onClick={(e) => { this.copyLink(element.shortCode); e.preventDefault();}} 
                                    icon={faCopy} />                                   
                                  </span>
                                  <Tooltip placement="right" 
                                    isOpen={tooltipIndex == index ? true : false} 
                                    target={tooltipIndexDom} 
                                    toggle={()=>{this.toggleToolTip(index)}}>{copyText}</Tooltip>
                                </th>*/}
                        <td>{element.inviteCode}</td>
                        <td>{element.install ? element.install : 0} </td>
                        <td> {element.signup ? element.signup : 0} </td>
                        <td> {element.blogClick ? element.blogClick : 0}</td>
                        <td> {element.click ? element.click : 0}</td>
                        <td>{moment(element.createdDate).format('MM/DD/YYYY')}</td>
                        <td>
                          <button className="btn btn-outline-primary tooltip-custom ml-2 p-1"
                            onClick={(e) => { this.copyLink(element.shortCode, element.bitlyShortUrl); e.preventDefault(); }}
                            onMouseOut={() => this.setCopyTextToInitial()}
                          >
                            <FontAwesomeIcon icon={faCopy} /> Copy Link to clipboard <span className="tooltiptext" id="myTooltip">{copyText}</span>
                          </button>
                          <button className="btn btn-outline-primary tooltip-custom ml-2 p-1"
                            onClick={(e) => { this.toggleShortLinkModal(); this.socialInsightsByCode(element._id); e.preventDefault(); }} >
                            <FontAwesomeIcon icon={faEye} /> View Insights
                                  </button>
                        </td>
                      </tr>
                    )
                  })
                }

              </tbody>
            </Table>
          </CardBody>
          <CardFooter>
            <span>Total Short Links : {total}</span>
            {this.setPaginationLinks()}
          </CardFooter>
        </Card>
        <Modal fade={false} isOpen={openInviteCodeModal} toggle={() => { this.toggleInviteCodeModal() }} >
          <form>
            <ModalHeader toggle={() => { this.toggleInviteCodeModal() }}>Generate Invite Code</ModalHeader>
            <ModalBody className="m-3">
              <Row>
                <Col xs="12">
                  <FormGroup>
                    <Label htmlFor="email">Invite Code</Label>
                    <Input type="text" id="inviteCode" disabled name="inviteCode" value={this.state.inviteCode} />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col xs="12">
                  <FormGroup>
                    <Label htmlFor="email">First Name</Label>
                    <Input type="text" id="firstName" placeholder="First Name" name="firstName" value={this.state.firstName} onChange={(event) => { this.handleChange(event) }} />
                    <div className="validation-error">{this.validator.message('First Name', this.state.firstName, 'required')}</div>
                  </FormGroup>
                </Col>
              </Row>

              <Row>
                <Col xs="12">
                  <FormGroup>
                    <Label htmlFor="email">Last Name</Label>
                    <Input type="text" id="lastName" placeholder="Last Name" name="lastName" value={this.state.lastName} onChange={(event) => { this.handleChange(event) }} />
                    <div className="validation-error">{this.validator.message('Last Name', this.state.lastName, 'required')}</div>
                  </FormGroup>
                </Col>
              </Row>

              <Row>
                <Col xs="12">
                  <FormGroup>
                    <Label htmlFor="email">Email</Label>
                    <Input type="text" id="email" placeholder="Email" name="email" value={this.state.email} onChange={(event) => { this.handleChange(event) }} />
                    <div className="validation-error">{this.validator.message('Email', this.state.email, 'email')}</div>
                  </FormGroup>
                </Col>
              </Row>


              <Row>
                <Col xs="12">
                  <FormGroup>
                    <Label htmlFor="description">Description</Label>
                    <Input type="textarea" id="description" maxLength="500" placeholder="Description" name="description" value={this.state.description} onChange={(event) => { this.handleChange(event) }} />

                  </FormGroup>
                </Col>
              </Row>

              <Row>
                <Col xs="12">
                  <FormGroup>
                    <Label htmlFor="email">Blog Link</Label>
                    <Input type="text" id="blogLink" placeholder="Blog Link" name="blogLink" value={this.state.blogLink} onChange={(event) => { this.handleChange(event) }} />
                    <div className="validation-error">{this.validator.message('Blog Link', this.state.blogLink, 'url')}</div>
                  </FormGroup>
                </Col>
              </Row>

              {isErrorMessaeg && <Alert fade={false} color="danger">{isErrorMessaeg}</Alert>}

            </ModalBody>
            <ModalFooter>
              <Button color="primary" onClick={(e) => { this.generateNewInviteUrl(e) }} >Generate</Button>
              <Button color="secondary" onClick={() => { this.toggleInviteCodeModal() }} >Close</Button>
            </ModalFooter>
          </form>
        </Modal>
      </Row>
    );
  }
}

export default ShortLinks;
