import React, { Component } from 'react';
import { Loader } from '../../utils/common';
import { Row, Col, Modal, Button, ModalHeader, ModalBody, ModalFooter, FormGroup, Label, Input, Form, Alert } from "reactstrap";
import { AppSwitch } from '@coreui/react'
import SimpleReactValidator from 'simple-react-validator';



class AdminUsersForm extends Component {

  constructor(props) {
    super(props);
    this.validator = new SimpleReactValidator({
      messages: {
        regex: 'Password must contain at least 8 characters, 1 uppercase, 1 lowercase, 1 number, 1 special character (!@#$%^&*-_).'
      },
    });

    this.state = {
      _id: props.formData && props.formData._id ? props.formData._id : null,
      roles: props.formData && props.formData.roles ? props.formData.roles[0] : 'SUB_ADMIN',
      fullName: props.formData && props.formData.name ? props.formData.name : '',
      authorName: props.formData && props.formData.authorName ? props.formData.authorName : '',
      email: props.formData && props.formData.email ? props.formData.email : '',
      password: '',
      newPassword: '',
      confirmPassword: '',
      confirmNewPassword: '',
      status: props.formData && props.formData.status ? props.formData.status : false,
      isDeleted: props.formData && props.formData.isDeleted ? props.formData.isDeleted : '0',
      touched: props.formData && props.formData.authorName ? true : false,
      confirmPasswordError: '',
      passwordRegex: new RegExp("^(?!.* )(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*_-])(?=.{8,})")
    }
  }

  componentDidMount() {
  }

  componentWillReceiveProps(nextProps) {
    Loader(nextProps.requestProcess);
  }


  handleChange(event) {
    let target = event.target;
    let name = target.name;
    let value = target.value;

    if (target.name === 'authorName') {
      this.setState({ touched: true });
    }
    if (target.type === 'checkbox' && target.name === 'status') {
      value = (target.value === 'Active') ? false : true;
    }
    this.setState({ [name]: value });

  }
  handleOnBlur(event) {
    let target = event.target;
    let name = target.name;
    let value = target.value;
    if (target.name === 'fullName' && this.state.authorName === '' && this.state.touched === false) {
      //this.setState({authorName: value});  
    }
    this.setState({ [name]: value });
  }
  handleSubmit(e) {
    let self = this;
    let cpass = this.confirmPasswordMessage(this.state._id ? this.state.confirmNewPassword : this.state.confirmPassword)
    if (this.validator.allValid() && cpass === true) {
      let adminUserObj = {
        _id: this.state._id,
        roles: [this.state.roles],
        name: this.state.fullName,
        authorName: this.state.authorName,
        email: this.state.email,
        password: this.state.password,
        newPassword: this.state.newPassword,
        confirmPassword: this.state.confirmPassword,
        confirmNewPassword: this.state.confirmNewPassword,
        status: this.state.status,
        isDeleted: this.state.isDeleted
      }
      if (this.state.roles !== 'AUTHOR') {
        adminUserObj.authorName = '';
      }
      self.props._addUpdateAdminUser(adminUserObj);
    } else {
      self.validator.showMessages();
      self.forceUpdate();
    }
    e.preventDefault();
  }
  confirmPasswordMessage(value) {
    let tempPassword = this.state._id ? this.state.newPassword : this.state.password;

    if (value !== tempPassword) {
      this.setState({ confirmPasswordError: "Confirm password must match." });
      return false;
    } else {
      this.setState({ confirmPasswordError: "" });
      return true;
    }

  }

  onSelect(item) {
    this.setState({ contentId: item._id, contentTitle: item.fullName, typedContent: item.fullName })

  }


  setTextAttrs(event, index, languageSlug) {
    this.state.systemLanguages[index]['lang'][event.target.name] = event.target.value;
    this.state.systemLanguages[index]['lang']['languageSlug'] = languageSlug;
    this.setState({ systemLanguages: this.state.systemLanguages })

  }

  render() {
    let { openAddAdminUserModal, closeModal, addAdminUserPopupError } = this.props;
    let { _id } = this.state;
    return (
      <Modal isOpen={openAddAdminUserModal}>
        <ModalHeader toggle={() => { closeModal(false) }}>{_id ? 'Update' : 'Add'} User</ModalHeader>
        <Form onSubmit={(event) => { this.handleSubmit(event) }} autoComplete="off">
          <ModalBody>
            <Row>
              <Col xs="12">
                <FormGroup>
                  <Label htmlFor="roles">Role</Label>

                  <Input type="select" id="roles" placeholder="Select Role" name="roles" value={this.state.roles} disabled={_id ? true : false} onChange={(event) => { this.handleChange(event) }}>
                    <option value="SUB_ADMIN">Sub Admin</option>
                    <option value="ANALYTICS">Analytics</option>
                    <option value="AUTHOR">Author</option>
                    <option value="MARKETING">Marketing</option>
                  </Input>
                  <div className="validation-error">{this.validator.message('Role', this.state.roles, 'required')}</div>
                </FormGroup>
              </Col>
              <Col xs="12">

                <FormGroup>
                  <Label htmlFor="fullName">Full Name</Label>
                  <Input type="text" maxLength="100" id="fullName" placeholder="Full Name" name="fullName" value={this.state.fullName} onChange={(event) => { this.handleChange(event) }} />
                  <div className="validation-error">{this.validator.message('Full Name', this.state.fullName, 'required|min:3|alpha_space')}</div>
                </FormGroup>


              </Col>
              {this.state.roles === 'AUTHOR' &&
                <Col xs="12">
                  <FormGroup>
                    <Label htmlFor="authorName">Author Name</Label>
                    <Input type="text" maxLength="100" id="authorName" placeholder="Author Name" name="authorName" autoComplete="off" value={this.state.authorName} onChange={(event) => { this.handleChange(event) }} />

                  </FormGroup>


                </Col>

              }

              <Col xs="12">
                <FormGroup>
                  <Label htmlFor="email">Email</Label>
                  <Input type="text" id="email" disabled={_id ? true : false} placeholder="Email" name="email" autoComplete="off" value={this.state.email} onChange={(event) => { this.handleChange(event) }} />
                  <div className="validation-error">{this.validator.message('Email', this.state.email, 'required|email')}</div>
                </FormGroup>
              </Col>

              <Col xs="12">
                <FormGroup>
                  <Label htmlFor={_id ? 'newPassword' : 'password'}>{_id ? 'Change' : ''} Password</Label>
                  <Input type="password" id={_id ? 'newPassword' : 'password'} placeholder="Password" name={_id ? 'newPassword' : 'password'} value={_id ? this.state.newPassword : this.state.password} onChange={(event) => { this.handleChange(event) }} min="8" max="120" autoComplete="new-password" />
                  {!_id ?
                    <div className="validation-error">{this.validator.message('Password', this.state.password, ['required', { regex: this.state.passwordRegex }])}</div>
                    :
                    <div className="validation-error">{this.validator.message('Password', this.state.newPassword, [{ regex: this.state.passwordRegex }])}</div>
                  }
                </FormGroup>
              </Col>
              <Col xs="12">
                <FormGroup>
                  <Label htmlFor={_id ? 'confirmNewPassword' : 'confirmPassword'}>{_id ? 'Confirm Change' : 'Confirm'} Password</Label>
                  <Input type="password" id={_id ? 'confirmNewPassword' : 'confirmPassword'} placeholder="Confirm Password" name={_id ? 'confirmNewPassword' : 'confirmPassword'} value={_id ? this.state.confirmNewPassword : this.state.confirmPassword} onChange={(event) => { this.handleChange(event) }} max="120" onBlur={(event) => { this.confirmPasswordMessage(this.state._id ? this.state.confirmNewPassword : this.state.confirmPassword) }} autoComplete="new-password" />
                  {(this.state.newPassword || this.state.password || this.state.confirmNewPassword || this.state.confirmPassword) &&
                    <div className="validation-error">{this.state.confirmPasswordError}</div>
                  }



                </FormGroup>
              </Col>


              <Col xs="12">
                <FormGroup>
                  <Label htmlFor="status">Status</Label>
                  <div>
                    <AppSwitch className={'mx-1'} variant={'pill'} color={'primary'} id="status" name="status"
                      onChange={(event) => { this.handleChange(event) }}
                      checked={this.state.status}
                      value={this.state.status ? 'Active' : 'Inactive'} />
                  </div>
                </FormGroup>
              </Col>
            </Row>


            {(addAdminUserPopupError) ? <Alert color="danger">{addAdminUserPopupError}</Alert> : null}
          </ModalBody>
          <ModalFooter className="justify-content-left">
            <Button type="submit" size="sm" color="primary">
              {_id ? 'Update' : 'Add'}
            </Button>{' '}
            <Button type="reset" size="sm" color="danger" onClick={() => { closeModal() }}>Cancel</Button>
          </ModalFooter>
        </Form>
      </Modal>

    );
  }
}

export default AdminUsersForm;
