import {createAction} from 'redux-act';
import httpClient from '../../services/http';


export const requestSocialInsights = createAction();
export const responseSocialInsights = createAction();

export const requestInsightsGraphData = createAction();
export const responseInsightsGraphData = createAction();



export const requestGetSingleSocialConnectData = createAction();
export const responseGetSingleSocialConnectData = createAction();




export function getSocialProfileInsights(params){
	return (dispatch) => {
		dispatch(requestSocialInsights([]));
		httpClient.call('MARKETING','/get_social_insights', params  , {method: 'GET', headers: {}}).then(function(response){
			dispatch(responseSocialInsights(response));
		}, function(error){
			dispatch(responseSocialInsights(error));
		});
	}
}

export function getInsightsGraphData(params){
	return (dispatch) => {
		dispatch(requestInsightsGraphData([]));
		httpClient.call('MARKETING','/get_social_insights_graph_data', params, {method: 'GET', headers: {}}).then(function(response){
			dispatch(responseInsightsGraphData(response));
		}, function(error){
			dispatch(responseInsightsGraphData(error));
		});
	}
}



export function getCurrentSocialConnectData(params){
	return (dispatch) => {
		dispatch(requestGetSingleSocialConnectData([]));
		httpClient.call('MARKETING','/get_single_social_connect_data', params, {method: 'GET', headers: {}}).then(function(response){
			dispatch(responseGetSingleSocialConnectData(response));
		}, function(error){
			dispatch(responseGetSingleSocialConnectData(error));
		});
	}
}

