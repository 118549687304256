import React, { Component, Suspense } from 'react';
import { connect } from 'react-redux';
import { ErrorToast, SuccessToast, Loader, setHttpClientConfig } from '../utils/common';

import { Router, Route, browserHistory, IndexRedirect } from 'react-router';
import { Container } from 'reactstrap';

import { AppAside, AppBreadcrumb, AppFooter, AppHeader, AppSidebar, AppSidebarFooter, AppSidebarForm, AppSidebarHeader, AppSidebarMinimizer, AppSidebarNav } from '@coreui/react';



import DefaultHeader from './DefaultHeader';
import DefaultFooter from './DefaultFooter';
import DefaultSidebar from './DefaultSidebar';


import Session from '../utils/session';
import { roleBasedSideBar } from '../utils/common';
class DefaultLayout extends Component {

  loading = () => <div className="animated fadeIn pt-1 text-center">Loading...</div>

  constructor(props) {
    super(props);
    this.state = {};
  }
  componentWillMount() {
    let sessionData = Session.getSessionData();
    let path = '';
    let rolePath = [];

    let pathName = browserHistory.getCurrentLocation()
    pathName = pathName.pathname.replace('/', '')
    if (sessionData && sessionData.roles) {
      if (sessionData.roles && sessionData.roles.length > 0 && sessionData.roles[0] == 'MARKETING') {
        window.location.href = process.env.REACT_APP_ANALYTICS_URL + '/marketing-social';
      } else if (sessionData.roles && sessionData.roles.length > 0 && sessionData.roles[0] === 'ANALYTICS') {
        window.location.href = process.env.REACT_APP_ANALYTICS_URL;
      } else {

        rolePath = roleBasedSideBar(sessionData.roles);
        if (rolePath.indexOf(pathName) > -1) {
          return true;
        } else {
          path = rolePath[0];
          this.navigate(path);
        }

      }


    }

  }



  navigate(path) {
    browserHistory.push(path);
  }

  signOut() {
    let self = this
    Session.deleteSessionData(function () {
      self.navigate("login")
    });
  }


  render() {
    return (
      <div className="app">
        <AppHeader fixed>
          <Suspense fallback={this.loading()}>
            <DefaultHeader {...this.props} onLogout={e => this.signOut(e)} />
          </Suspense>
        </AppHeader>
        <div className="app-body">
          <AppSidebar fixed display="lg">
            <AppSidebarHeader />
            <AppSidebarForm />
            <Suspense>
              <DefaultSidebar {...this.props} onLogout={e => this.signOut(e)} />
            </Suspense>
            <AppSidebarFooter />
            {/*<AppSidebarMinimizer />*/}
          </AppSidebar>
          <main className="main p-4">
            <Container fluid className="box-content p-4">
              <Suspense fallback={this.loading()}>
                {this.props.children}
              </Suspense>
            </Container>
          </main>

        </div>
        <AppFooter className="gate6-footer">
          <Suspense fallback={this.loading()}>
            <DefaultFooter />
          </Suspense>
        </AppFooter>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {

  }
};

const mapDispatchToProps = (dispatch) => {
  return {

  };
};


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DefaultLayout);
