import httpClient from '../../services/http';


export function addNewPlaylist(params) {
  return new Promise((resolve, reject) => {
    httpClient.call('PILLAR', '/create_playlist', params, { method: 'POST', headers: {} }).then(function (response) {
      resolve(response);
    }, function (error) {
      reject(error);
    });
  })
}


export function getPlaylist(params) {
  return new Promise((resolve, reject) => {
    httpClient.call('PILLAR', '/get_playlist_for_admin', params, { method: 'GET', headers: {} }).then(function (response) {
      resolve(response);
    }, function (error) {
      reject(error);
    });
  })
}


export function getAuthorList() {

  let params = {
    roleType: 'AUTHOR',
    statusType: true,
    limit: 1000,
    offset: 0
}

  return new Promise((resolve, reject) => {
    httpClient.call('USER', '/get_admin_user_list', params, { method: 'GET', headers: {} }).then(function (response) {
      resolve(response);
    }, function (error) {
      reject(error);
    });
  })
}



export function updatePlaylist(params) {
  return new Promise((resolve, reject) => {
    httpClient.call('PILLAR', '/update_playlist', params, { method: 'PUT', headers: {} }).then(function (response) {
      resolve(response);
    }, function (error) {
      reject(error);
    });
  })
}


export function deletePlaylist(params) {
  return new Promise((resolve, reject) => {
    httpClient.call('PILLAR', '/delete_playlist', params, { method: 'POST', headers: {} }).then(function (response) {
      resolve(response);
    }, function (error) {
      reject(error);
    });
  })
}


export function getPlaylistContent(playlistId) {
  return new Promise((resolve, reject) => {
    httpClient.call('PILLAR', `/get_playlist_content/${playlistId}`, {}, { method: 'GET', headers: {} }).then(function (response) {
      resolve(response);
    }, function (error) {
      reject(error);
    });
  })
}


export function getContentList(params) {
  return new Promise((resolve, reject) => {
    httpClient.call('PILLAR', '/get_content_admin', params, { method: 'GET', headers: {} }).then(function (response) {
      resolve(response);
    }, function (error) {
      reject(error);
    });
  })
}


export function addContentInPlaylist(params) {
  return new Promise((resolve, reject) => {
    httpClient.call('PILLAR', '/add_track_in_playlist', params, { method: 'POST', headers: {} }).then(function (response) {
      resolve(response);
    }, function (error) {
      reject(error);
    });
  })
}


export function removeTrackFromPlayList(params) {
  return new Promise((resolve, reject) => {
    httpClient.call('PILLAR', '/remove_track_from_playlist', params, { method: 'PUT', headers: {} }).then(function (response) {
      resolve(response);
    }, function (error) {
      reject(error);
    });
  })
}
