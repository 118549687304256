import React, { Component } from 'react';
import { Loader } from '../../../utils/common';
import Constants from '../../../config/constants';
import { Button, Card, CardHeader, Nav, NavItem, NavLink, CardBody, Modal, ModalBody, ModalFooter, ModalHeader, Table  } from "reactstrap";
import { AppSwitch } from '@coreui/react'
import SimpleReactValidator from 'simple-react-validator';
import httpClient from '../../../services/http';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import moment from 'moment';
import AddSystemLanguage from './AddSystemLanguage';
import UpdateSystemLanguage from './UpdateSystemLanguage';
import { faEdit } from '@fortawesome/free-solid-svg-icons'

import * as _ from 'underscore';

class SystemLanguage extends Component {
  constructor(props) {
    super(props);
    this.validator = new SimpleReactValidator();
    this.state = {
      getData : false,
      systemLanguages : [],
      openAddLanguageModal : false,
      openUpdateLanguageModal : false,
      openAllowLanguageModal : false,
      popupError : "",
      selectedRow : {},
    }

    this.columns = [
      { "columnName": "title", "displayName": "Title", "sort": false},
      { "columnName": "createdDate", "displayName": "Created On", "sort": false}
    ];
  }

  componentDidMount() {
    this.getSystemlanguages();
    // httpClient.getAppLanguages();
  }

  getSystemlanguages() {
    this.props._getSystemLanguages({});
  }


  componentWillReceiveProps(nextProps) {
    let self = this;
    Loader(nextProps.requestProcess);

    if (nextProps.systemLanguageList) {
      if (nextProps.systemLanguageList.statusCode === 200 && nextProps.systemLanguageList.result && nextProps.systemLanguageList.result.languages && nextProps.systemLanguageList.result.languages.length) {
        this.state.systemLanguages = [];
        _.each(nextProps.systemLanguageList.result.languages, function(value, index){
          value['createdDate'] = moment(value.createdDate).format("DD-MMM-YYYY");
          self.state.systemLanguages.push(value)
        })
        this.setState({systemLanguages : self.state.systemLanguages});
      }
    }

    if (nextProps.languageUpdated) {
      if (nextProps.languageUpdated.statusCode === 200) {
        self.getSystemlanguages();
        self.closeModal();
        httpClient.getAppLanguages();
      }
    }


    if (nextProps.languageAdded) {
      if (nextProps.languageAdded.statusCode === 200) {
        self.getSystemlanguages();
        self.closeModal();
        httpClient.getAppLanguages();
      }else{
        this.setState({popupError : (nextProps.languageAdded['data'] && nextProps.languageAdded['data']['statusDescription']) ? nextProps.languageAdded['data']['statusDescription'] : Constants.SOMETHING_WRONG});
      }
    }
  }


  changeStatus(rowData) {
    let params = {
      languageId : rowData._id,
      title : rowData.title,
      status : !rowData.status
    }

    if (!params.status) {
      params.allowForUsers = false
    }

    this.props._updateLanguage(params)
  }


  addNewlanguageModal() {
    this.setState({
      selectedRow : {},
      popupError : "",
      openAddLanguageModal : true,
    })
  }


  updateSystemLanguageModal(rowData) {
    this.setState({
      selectedRow : rowData,
      popupError : "",
      openUpdateLanguageModal : true,
    })
  }

  closeModal() {
    this.setState({
      selectedRow : {},
      popupError : "",
      openAddLanguageModal : false,
      openUpdateLanguageModal : false,
      openAllowLanguageModal : false,
    }) 
  }

  addNewlanguage(event, languageObj) {
    this.props._addLanguage(languageObj)
  }


  updateSystemlanguage(event, languageObj) {
    this.props._updateLanguage(languageObj)
  }


  changeLanguageRestrictionModal(rowData) {
    this.setState({
      selectedRow : rowData,
      openAllowLanguageModal : true,
    })
  }

  confirmAllowDisallowLanguage() {
    let params = {
      languageId : this.state.selectedRow._id,
      title : this.state.selectedRow.title,
      allowForUsers : !this.state.selectedRow.allowForUsers
    }
    this.props._updateLanguage(params)
  }



  LanguageList(languages){
    let self = this
    let languagesList = []
    _.each(languages, function(languageObj, index){
      languagesList.push(
        <tr key={index}>
          <td>{languageObj.title}</td>
          <td>{moment(languageObj.createdDate).format("DD-MMM-YYYY")}</td>
          <td>
            <Nav>
             <NavItem>
               <NavLink title="Update system language" disabled={languageObj.slug === Constants.ENGLISH} href="#" onClick={() => { self.updateSystemLanguageModal(languageObj); }}><FontAwesomeIcon icon={faEdit}  size='1x' /></NavLink>
             </NavItem>              
            </Nav>
          </td>


          <td>
            <Nav>
             <NavItem>
               <AppSwitch title="Change language status" className={'mx-1'} variant={'pill'} disabled={languageObj.slug === Constants.ENGLISH}  color={'primary'} label checked={languageObj.status} onChange={() => { self.changeStatus(languageObj); }} />
             </NavItem>                
            </Nav>
          </td>


          <td>
            <Button color="danger" size="sm"  disabled={languageObj.slug === Constants.ENGLISH || !languageObj.status} onClick={() => { self.changeLanguageRestrictionModal(languageObj); } }>{languageObj.allowForUsers ? 'Unpublish on App' : 'Publish on App'}</Button>
          </td>
        </tr>
      )
    })
    return languagesList;
  }

  



  render() {
     
   let { systemLanguages, openAddLanguageModal, openUpdateLanguageModal, popupError, selectedRow, openAllowLanguageModal } = this.state;
   //let totalRecordsLabel = '##TOTAL_RECORDS## '+(systemLanguages.length > 1 ? 'Records' : 'Record')
     
    return (
      <div className="content">
        <Card>
          <CardHeader>System Languages  <Button className="add-settings" color="primary" size="sm" onClick={() => this.addNewlanguageModal()}>Add New Language</Button></CardHeader>
          <CardBody>

          <Table hover responsive >
            <thead>
              <tr>
                <th>Title</th>
                <th>Created On</th>
                <th>Update</th>
                <th>Change Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {this.LanguageList(systemLanguages)}
            </tbody>
          </Table>
          </CardBody>
        </Card>

       <AddSystemLanguage openAddLanguageModal={openAddLanguageModal} popupError={popupError} closeModal={(event) => this.closeModal(event)} handleSubmit={(event, params) => this.addNewlanguage(event, params)} />
       

       {openUpdateLanguageModal && <UpdateSystemLanguage selectedRow={selectedRow} openUpdateLanguageModal={openUpdateLanguageModal} popupError={popupError} closeModal={(event) => this.closeModal(event)} handleSubmit={(event, params) => this.updateSystemlanguage(event, params)}  />}

        


        <Modal isOpen={openAllowLanguageModal}>
          <ModalHeader toggle={()=>{this.closeModal()}}>Change User Subscription</ModalHeader>
            <ModalBody>
              Are you sure you want to {(selectedRow.allowForUsers) ? 'disallow' : 'allow'} {(selectedRow && selectedRow.title) ? selectedRow.title : "this"} language for all users ? 
            </ModalBody>
            <ModalFooter className="justify-content-left">
              <Button type="button" size="sm" color="primary" onClick={()=>{this.confirmAllowDisallowLanguage()}}>Yes</Button>{' '}
              <Button type="reset" size="sm" color="danger"  onClick={() => { this.closeModal() }}>Cancel</Button>
            </ModalFooter>
        </Modal>   

       </div>
    );
  }
}

export default SystemLanguage;
