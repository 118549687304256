import React, { Component } from "react";
import DatePicker from 'react-date-picker';
import { Loader, FormatTitlePosLabel } from "../../utils/common";
// import DataGrid from "g6reactdatagrid";
import {
  Row,
  Col,
  Table,
  Modal,
  Button,
  ModalHeader,
  ModalBody,
  Nav,
  NavItem,
  NavLink,
  Input,
  ModalFooter,
  Alert,
} from "reactstrap";
import moment from "moment";
import Constants from "../../config/constants";
import { AppSwitch } from "@coreui/react";
import { StickyTable, Cell, Row as StickyRow } from "react-sticky-table";
import {
  faBaby,
  faHandHoldingUsd,
  faUserCheck,
  faPaperPlane,
  faArrowDown,
  faArrowUp,
  faSort,
  faTrash
} from "@fortawesome/free-solid-svg-icons";
import * as _ from "underscore";
import ExportParent from "./ExportParent";
import "./parent.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactPaginate from "react-paginate";

class Parent extends Component {
  constructor(props) {
    super(props);
    this.apiUrl = process.env.REACT_APP_CLOUDFRONT_URL;
    this.getParents = this.getParents.bind(this);
    this.toggle = this.toggle.bind(this);
    this.seeAllChilds = this.seeAllChilds.bind(this);
    var start = new Date(moment().subtract(7, 'days').format("YYYY-MM-DD"));
    start.setUTCHours(0,0,0,0);

    var end = new Date(moment().format("YYYY-MM-DD"));
    end.setUTCHours(23,59,59,999);
    this.state = {
      refreshList: false,
      totalRecord: 0,
      rowsPerPage: 10,
      sortColumn: "",
      sortValue: "",
      search: "",
      page: 0,
      offset: 0,
      status: "",
      searchVal: "",
      searchKey: "",
      parentList: [],
      openmodalbox: false,
      childRows: [],
      dropdownOpen: [],
      selectedRow: {},
      verifyUserModal: false,
      verificationLinkModal: false,
      changeSubscriptionModal: false,
      screenErrMsg: "",
      subscriptionType: "",
      isError: false,
      fromDate : null,
      toDate : null,
      currentDate : new Date(moment().format("YYYY-MM-DD")),
      minDate : new Date(moment().subtract(4,"years").format("YYYY-MM-DD")),
    };
    this.intervalInstance = "";

    this.columns = [
      { columnName: "email", displayName: "Email", sort: true },
      // { "columnName": "passcode", "displayName": "Passcode", "sort": true },
      //{ "columnName": "socialIcon", "displayName": "Social Communities", "sort": false },
      { columnName: "childCount", displayName: "Child Count", sort: true },
      {
        columnName: "subscription",
        displayName: "User Subscription",
        sort: true,
      },
      { columnName: "isVerified", displayName: "Email Verified", sort: true },
      { columnName: "status", displayName: "Status", sort: true },
      { columnName: "createdDate", displayName: "Created On", sort: true },
    ];

    // this.getParents(
    //   this.state.offset,
    //   this.state.rowsPerPage,
    //   this.state.sortColumn,
    //   this.state.sortValue,
    //   this.state.search,
    //   this.state.fromDate,
    //   this.state.toDate
    // );
  }

  toggle(id) {
    let self = this;
    self.state.dropdownOpen[id] = !self.state.dropdownOpen[id];
    this.setState({});
  }

  componentDidMount() {
    this.getParents(
      this.state.offset,
      this.state.rowsPerPage,
      this.state.sortColumn,
      this.state.sortValue,
      this.state.search,
      this.state.fromDate,
      this.state.toDate
    );
  }

  seeAllChilds(rowData) {
    this.props._getChildren(rowData);
  }

  ChildSection(props) {
    return (
      <Modal isOpen={props.visible}>
        <ModalHeader toggle={props.closeModal}>Children Details</ModalHeader>
        <ModalBody>
          <div className="container-fluid addbox-y">
            <Table responsive striped bordered hover>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Age</th>
                  <th>Created</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>{props.childData}</tbody>
            </Table>
          </div>
        </ModalBody>
      </Modal>
    );
  }

  onCloseModal = () => {
    this.setState({
      openmodalbox: false,
      verifyUserModal: false,
      verificationLinkModal: false,
      changeSubscriptionModal: false,
      selectedRow: {},
    });
  };

  componentWillReceiveProps(nextProps) {
    let self = this;
    let newData = [];
    Loader(nextProps.requestProcess);
    if (nextProps.changeStatus && nextProps.changeStatus.statusCode === 200) {
      //let message = nextProps.changeStatus.statusDescription;
      self.getParents(
        self.state.offset,
        self.state.rowsPerPage,
        self.state.sortColumn,
        self.state.sortValue,
        self.state.search,
        self.state.fromDate,
        self.state.toDate
      );
    }

    if (nextProps.data && nextProps.data.statusCode === 200) {
      let listData = nextProps.data.result.length ? nextProps.data.result : [];
      let total = nextProps["data"]["totalRecords"];
      self.setState((prevState) => {
        listData.map(function (element, key) {
          element["socialIcon"] = "";
          element["planType"] = FormatTitlePosLabel(Constants.FREE);

          if (element.socialCommunities && element.socialCommunities.length) {
            if (_.contains(element.socialCommunities, Constants.FACEBOOK)) {
              // element.email += " <img class='inline-social-icons' src="+fbImage+" >";
              element["socialIcon"] +=
                "<img class='inline-social-icons' src=" +
                self.apiUrl +
                "admin/img/fb.jpg >";
            }

            if (_.contains(element.socialCommunities, Constants.GOOGLE)) {
              // element.email += " <img class='inline-social-icons' src="+googleImage+" >";
              element["socialIcon"] +=
                "<img class='inline-social-icons' src=" +
                self.apiUrl +
                "admin/img/google.png >";
            }
          }

          if (element["plan"] && !_.isEmpty(element["plan"])) {
            element["planType"] = FormatTitlePosLabel(element["plan"]["slug"]);
          }

          element.isVerified =
            element.isVerified === true
              ? Constants.VERIFIED
              : Constants.PENDING;
          element.status = element.status === true ? "Active" : "Inactive";
          element.socialType =
            element["socialProfile"] && element["socialProfile"]["socialType"]
              ? element["socialProfile"]["socialType"]
              : "";
          element.createdDate = moment(element.createdDate).format(
            "DD-MMM-YYYY"
          );
          // element.subscription = element.subscription
          element.passcode = element.passcode ? element.passcode : "Not-Set";
          element.isTest = element.isTest ? element.isTest : false;

          newData.push(element);
        });
        let shouldRefreshList = self.state.refreshList || self.state.page === 0;

        let page = self.state.page;
        let limit = self.state.rowsPerPage;
        //let totalRequestedRecord = parseInt(page + 1) * parseInt(limit);

        return {
          parentList: shouldRefreshList ? newData : newData,
          totalRecord: total,
          refreshList: false,
        };
      });
    }

    if (
      nextProps.childData &&
      nextProps.childData &&
      nextProps.childData.statusCode === 200
    ) {
      self.setState({
        openmodalbox: true,
        childRows: nextProps.childData.result,
      });
    }

    if (
      nextProps.userVerified &&
      nextProps.userVerified &&
      nextProps.userVerified.statusCode === 200
    ) {
      self.getParents(
        self.state.offset,
        self.state.rowsPerPage,
        self.state.sortColumn,
        self.state.sortValue,
        self.state.search,
        self.state.fromDate,
        self.state.toDate
      );
      self.onCloseModal();
    }

    if (nextProps.subscriptionChanged && nextProps.verificationLinkSend) {
      if (nextProps.verificationLinkSend.statusCode === 200) {
        self.getParents(
          self.state.offset,
          self.state.rowsPerPage,
          self.state.sortColumn,
          self.state.sortValue,
          self.state.search,
          self.state.fromDate,
          self.state.toDate
        );
        self.onCloseModal();
      } else {
        self.getParents(
          self.state.offset,
          self.state.rowsPerPage,
          self.state.sortColumn,
          self.state.sortValue,
          self.state.search,
          self.state.fromDate,
          self.state.toDate
        );
        self.onCloseModal();
      }
    }

    if (nextProps.verificationLinkSend && nextProps.verificationLinkSend) {
      if (nextProps.verificationLinkSend.statusCode === 200) {
        self.getParents(
          self.state.offset,
          self.state.rowsPerPage,
          self.state.sortColumn,
          self.state.sortValue,
          self.state.search,
          self.state.fromDate,
          self.state.toDate
        );
        self.onCloseModal();
      } else {
        self.getParents(
          self.state.offset,
          self.state.rowsPerPage,
          self.state.sortColumn,
          self.state.sortValue,
          self.state.search,
          self.state.fromDate,
          self.state.toDate
        );
        self.onCloseModal();
      }
    }

    if (nextProps.subscriptionChanged && nextProps.subscriptionChanged) {
      if (nextProps.subscriptionChanged.statusCode === 200) {
        self.getParents(
          self.state.offset,
          self.state.rowsPerPage,
          self.state.sortColumn,
          self.state.sortValue,
          self.state.search,
          self.state.fromDate,
          self.state.toDate
        );
        self.onCloseModal();
      } else {
        self.setState({
          isError: true,
          screenErrMsg:
            nextProps.subscriptionChanged["data"] &&
            nextProps.subscriptionChanged["data"]["statusDescription"]
              ? nextProps.subscriptionChanged["data"]["statusDescription"]
              : Constants.SOMETHING_WRONG,
        });

        setTimeout(() => {
          self.onErrDismiss();
        }, 3000);

        self.getParents(
          self.state.offset,
          self.state.rowsPerPage,
          self.state.sortColumn,
          self.state.sortValue,
          self.state.search,
          self.state.fromDate,
          self.state.toDate
        );
        self.onCloseModal();
      }
    }

    if(nextProps.parentDeletedResponse){
      if (nextProps.parentDeletedResponse.statusCode === 200) {


        self.setState({
          isError: true,
          screenErrMsg:'Parent deleted successfully!!!'
        });

        setTimeout(() => {
          self.onErrDismiss();
        }, 3000);

        self.getParents(
          self.state.offset,
          self.state.rowsPerPage,
          self.state.sortColumn,
          self.state.sortValue,
          self.state.search,
          self.state.fromDate,
          self.state.toDate
        );
        self.onCloseModal();
      }
    }

  }

  getParents(offset, limit, sortColumn, sortValue, search, fromDate, toDate) {
    const { subscriptionType } = this.state;
    var start, end;

    if (fromDate !== null && toDate !== null) {
      start = new Date(moment(fromDate, "YYYY-MM-DD").add(1, "days"));
      start.setUTCHours(0, 0, 0, 0);
    } else {
      start = end = null;
    }
    if (toDate !== null ) {
      end = new Date(moment(toDate, "YYYY-MM-DD").add(1, "days"));
      end.setUTCHours(23, 59, 59, 999);
    } else {
      end = null;
    }

    if (fromDate !== null && toDate !== null && start > end) {
        alert("From date must be less than or equal to To date.");
        return;
    }

    let params = {
      limit: limit,
      offset: offset,
      search: search,
      subscriptionType: subscriptionType,
      sortField: sortColumn,
      sortValue: sortValue,
      fromDate: start,
      toDate: end
    };
    this.props._getParents(params);
    this.setState({
      dropdownOpen: [],
    });
  }

  deleteParent(rowData) {

    if(rowData && rowData._id){
      let params = {_id:rowData._id};
      this.props._deleteParent(params)
    }
  }

  changeStatus(rowData) {
    let params = {
      userId: rowData._id,
      status: rowData.status === "Active" ? false : true,
    };
    this.props._changeUserStatus(params);
  }

  changeRecordingStatus(rowData) {
    let params = {
      userId: rowData._id,
      enableRecording: rowData.enableRecording ? false : true,
    };
    this.props._changeUserRecordingStatus(params);
  }

  searchParents() {
    let self = this;
    if (self.intervalInstance) {
      clearInterval(self.intervalInstance);
    }

    self.intervalInstance = setInterval(() => {
      self.setState({ page: 0, offset: 0 });
      self.getParents(
        self.state.offset,
        self.state.rowsPerPage,
        self.state.sortColumn,
        self.state.sortValue,
        self.state.search,
        self.state.fromDate,
        self.state.toDate
      );
      if (self.intervalInstance) {
        clearInterval(self.intervalInstance);
      }
    }, 1000);
  }

  goToTransactions(rowData) {
    this.props.router.push({
      pathname: "/payment-history",
      query: { email: rowData.email },
    });
  }

  verifyUser(rowData) {
    this.setState({
      selectedRow: rowData,
      verifyUserModal: true,
    });
  }

  sendVerificationLink(rowData) {
    this.setState({
      selectedRow: rowData,
      verificationLinkModal: true,
    });
  }

  confirmVerification() {
    let params = {
      parentId: this.state.selectedRow._id,
    };
    this.props._verifyUser(params);
  }

  confirmSendVerificationLink() {
    let params = {
      email: this.state.selectedRow.email,
    };
    this.props._sendVerifivationLink(params);
  }

  changeUserSubscription(rowData) {
    this.setState({
      selectedRow: rowData,
      changeSubscriptionModal: true,
    });
  }

  getParentOptions(rowData) {
    return (
      <div>
        <Nav>
          <NavItem>
            <NavLink
              title="View Children"
              href="/"
              alt="View Childrens"
              onClick={(e) => {
                this.seeAllChilds(rowData);
                e.preventDefault();
              }}
            >
              <FontAwesomeIcon icon={faBaby} size="lg" />
            </NavLink>
          </NavItem>

          <NavItem>
            <NavLink
              title="View Transaction"
              disabled={!rowData.plan}
              href="/"
              onClick={(e) => {
                this.goToTransactions(rowData);
                e.preventDefault();
              }}
            >
              <FontAwesomeIcon icon={faHandHoldingUsd} size="lg" />
            </NavLink>
          </NavItem>

          <NavItem>
            <AppSwitch
              className={"mx-1"}
              variant={"pill"}
              color={"primary"}
              label
              checked={rowData.status === "Active"}
              onChange={() => {
                this.changeStatus(rowData);
              }}
            />
          </NavItem>

          <NavItem>
            <NavLink
              title="Verify User"
              disabled={
                rowData.isVerified === Constants.VERIFIED || !rowData.email
              }
              href="/"
              onClick={(e) => {
                this.verifyUser(rowData);
                e.preventDefault();
              }}
            >
              <FontAwesomeIcon icon={faUserCheck} size="lg" />
            </NavLink>
          </NavItem>

          <NavItem>
            <NavLink
              title="Send Verification Email"
              disabled={
                rowData.isVerified === Constants.VERIFIED || !rowData.email
              }
              href="/"
              onClick={(e) => {
                this.sendVerificationLink(rowData);
                e.preventDefault();
              }}
            >
              <FontAwesomeIcon icon={faPaperPlane} size="lg" />
            </NavLink>
          </NavItem>

          {/*<NavItem>
                              <NavLink title="Change User Subscription"  href="Javascript:void(0);" onClick={() => { this.changeUserSubscription(rowData); }}>{(rowData.subscription == Constants.FREE) ? "Set User Paid" : "Set User Free"}</NavLink>
                            </NavItem>*/}
          <NavItem>
            <AppSwitch
              dataOn={"Recording On"}
              dataOff={"Recording Off"}
              className={"mx-1 long-switch"}
              variant={"pill"}
              color={"primary"}
              label
              checked={rowData.enableRecording}
              onChange={() => {
                this.changeRecordingStatus(rowData);
              }}
            />
          </NavItem>

          {(rowData.isTest) && <NavItem>
            <NavLink
              title="Delete User"
              href="javascript:void(0)"
              onClick={(e) => {
                this.deleteParent(rowData);
                e.preventDefault();
              }}
            >
              <FontAwesomeIcon icon={faTrash} size="lg" />
            </NavLink>
          </NavItem>}

        </Nav>
      </div>
    );
  }
  confirmChangeSubscription() {
    let params = {
      parentId: this.state.selectedRow._id,
    };
    this.props._changeUserSubscription(params);
  }

  onErrDismiss() {
    this.setState({ isError: false, screenErrMsg: "" });
  }

  setDate(name, value) {
    if (value !== null) {
      this.setState({ [name]: new Date(value) });
    } else {
      this.setState({ [name]: null });
    }
  }

  render() {
    const { openmodalbox } = this.state;
    let self = this;
    let {
      parentList,
      showTrackForm,
      formElement,
      status,
      page,
      rowsPerPage,
      totalRecord,
      childRows,
      sortColumn,
      sortValue,
      verifyUserModal,
      selectedRow,
      verificationLinkModal,
      changeUserSubscription,
      changeSubscriptionModal,
      isError,
      screenErrMsg,
    } = this.state;
    let totalRecordsLabel =
      "##TOTAL_RECORDS## " + (totalRecord > 1 ? "Parents" : "Parent");
    let linksCount =
      totalRecord > 0 && rowsPerPage > 0
        ? Math.ceil(totalRecord / rowsPerPage)
        : 0;
    let options = {
      totalRecords: totalRecord,
      totalRecordsLabel: totalRecordsLabel,
      rowsPerPage: rowsPerPage,
      page: page,
      loadMore: false,
      customColumn: {
        isActive: true,
        columnTitle: "",
        columnContent: (rowData) => {
          return (
            <div>
              <Nav>
                <NavItem>
                  <NavLink
                    title="View Children"
                    href="/"
                    alt="View Childrens"
                    onClick={(e) => {
                      this.seeAllChilds(rowData);
                      e.preventDefault();
                    }}
                  >
                    <FontAwesomeIcon icon={faBaby} size="lg" />
                  </NavLink>
                </NavItem>

                <NavItem>
                  <NavLink
                    title="View Transaction"
                    disabled={!rowData.plan}
                    href="/"
                    onClick={(e) => {
                      this.goToTransactions(rowData);
                      e.preventDefault();
                    }}
                  >
                    <FontAwesomeIcon icon={faHandHoldingUsd} size="lg" />
                  </NavLink>
                </NavItem>

                <NavItem>
                  <AppSwitch
                    className={"mx-1"}
                    variant={"pill"}
                    color={"primary"}
                    label
                    checked={rowData.status === "Active"}
                    onChange={() => {
                      this.changeStatus(rowData);
                    }}
                  />
                </NavItem>

                <NavItem>
                  <NavLink
                    title="Verify User"
                    disabled={
                      rowData.isVerified === Constants.VERIFIED ||
                      !rowData.email
                    }
                    href="/"
                    onClick={(e) => {
                      this.verifyUser(rowData);
                      e.preventDefault();
                    }}
                  >
                    <FontAwesomeIcon icon={faUserCheck} size="lg" />
                  </NavLink>
                </NavItem>

                <NavItem>
                  <NavLink
                    title="Send Verification Email"
                    disabled={
                      rowData.isVerified === Constants.VERIFIED ||
                      !rowData.email
                    }
                    href="/"
                    onClick={(e) => {
                      this.sendVerificationLink(rowData);
                      e.preventDefault();
                    }}
                  >
                    <FontAwesomeIcon icon={faPaperPlane} size="lg" />
                  </NavLink>
                </NavItem>

                {/*<NavItem>
                              <NavLink title="Change User Subscription"  href="Javascript:void(0);" onClick={() => { this.changeUserSubscription(rowData); }}>{(rowData.subscription == Constants.FREE) ? "Set User Paid" : "Set User Free"}</NavLink>
                            </NavItem>*/}
                <NavItem>
                  <AppSwitch
                    dataOn={"Recording On"}
                    dataOff={"Recording Off"}
                    className={"mx-1 long-switch"}
                    variant={"pill"}
                    color={"primary"}
                    label
                    checked={rowData.enableRecording}
                    onChange={() => {
                      this.changeRecordingStatus(rowData);
                    }}
                  />
                </NavItem>
              </Nav>
            </div>
          );
        },
      },

      onPageChange(pageNumber) {
        self.state.page = pageNumber;
        self.state.offset =
          parseInt(pageNumber) * parseInt(self.state.rowsPerPage);
        self.getParents(
          self.state.offset,
          self.state.rowsPerPage,
          self.state.sortColumn,
          self.state.sortValue,
          self.state.search,
          self.state.fromDate,
          self.state.toDate
        );
      },

      onColumnSortChange: (column, direction) => {
        self.state.sortColumn = column;
        self.state.sortValue = direction === "ASC" ? 1 : -1;
        self.getParents(
          self.state.offset,
          self.state.rowsPerPage,
          self.state.sortColumn,
          self.state.sortValue,
          self.state.search,
          self.state.fromDate,
          self.state.toDate
        );
      },
    };

    const onRowActionClick = function (rowData) {
      // console.log(rowData)
    };

    var ChildSection = this.ChildSection;
    var childData = childRows.map(function (obj) {
      return (
        <tr key={obj["_id"]}>
          <td>{obj["name"]}</td>
          <td>{obj["age"]}</td>
          <td>{moment(obj["createdDate"]).format("DD-MMM-YYYY")} </td>
          <td>{obj["status"] ? "Active" : "Inactive"}</td>
        </tr>
      );
    });

    return (
      <div className="content">
        <Row className="pb-4">
          <Col lg={12} sm={12}>
            <h3>Parent List</h3>
          </Col>
        </Row>

        <Alert
          color="danger"
          isOpen={isError}
          toggle={() => {
            this.onErrDismiss();
          }}
        >
          {screenErrMsg}
        </Alert>

        <Row className="pb-4">
          <Col lg={2} sm={12}>
            <Input
              type="text"
              id="search"
              placeholder="Search String"
              name="search"
              value={this.state.search}
              onChange={($event) => {
                this.setState({ search: $event.target.value });
                this.searchParents();
              }}
            />
          </Col>
          <Col lg={3} sm={12}>
            <Input
              type="select"
              name="subscriptionType"
              id="subscriptionType"
              value={this.state.subscriptionType}
              onChange={(event) => {
                this.setState({ subscriptionType: event.target.value });
                this.searchParents();
              }}
            >
              <option value="">Select Subscription Type</option>
              <option value={Constants.FREE}>{Constants.FREE}</option>
              <option value={Constants.PAID}>{Constants.PAID}</option>
              <option value={Constants.FREE_TRIAL}>FREE TRIAL</option>
              <option value={Constants.IS_TEST}>TEST USERS</option>
            </Input>
          </Col>

          <Col lg={3} sm={12}>
          From: <DatePicker readonly={true} name="fromDate" id="fromDate" value={this.state.fromDate} onChange={(event)=>{this.setDate('fromDate',event);this.searchParents();}} minDate={this.state.minDate} maxDate={this.state.currentDate}/>
          </Col>
          <Col lg={2} sm={12}>
          To:<DatePicker readonly={true} name="toDate" id="toDate" value={this.state.toDate} onChange={(event)=>{this.setDate('toDate',event);this.searchParents();}} minDate={this.state.minDate} maxDate={this.state.currentDate}/>
          </Col>
          <Col lg={2} sm={12} className="text-right">
            <ExportParent
              search={this.state.search}
              subscriptionType={this.state.subscriptionType}
              fromDate={this.state.fromDate}
              toDate={this.state.toDate}
              {...this.props}
            />
          </Col>
        </Row>

        <Row>
          <Col>
            <ChildSection
              columns={this.columns}
              options={options}
              rows={parentList}
              visible={openmodalbox}
              childData={childData}
              closeModal={this.onCloseModal}
            />

            {/* <div className="dataTable-wrapper parentList_grid">
                <DataGrid
                  columns={this.columns}
                  options={options}
                  rows={parentList}
                />
              </div> */}
            <div style={{ width: "100%", height: "62vh" }}>
              <StickyTable>
                <StickyRow>
                  <Cell>#</Cell>
                  <Cell>
                    <div
                      className="pointer"
                      onClick={() => {
                        this.setState({
                          sortColumn: "email",
                          sortValue: sortValue === 1 ? -1 : 1,
                        });
                        this.getParents(
                          self.state.offset,
                          self.state.rowsPerPage,
                          self.state.sortColumn,
                          self.state.sortValue,
                          self.state.search,
                          self.state.fromDate,
                          self.state.toDate
                        );
                      }}
                    >
                      <strong>Parent Email</strong>
                      {sortColumn === "email" && sortValue !== null ? (
                        sortValue === 1 ? (
                          <FontAwesomeIcon icon={faArrowDown} />
                        ) : (
                          <FontAwesomeIcon icon={faArrowUp} />
                        )
                      ) : (
                        <FontAwesomeIcon icon={faSort} />
                      )}
                    </div>
                  </Cell>
                  <Cell className="text-center">
                    <div
                      className="pointer"
                      onClick={() => {
                        this.setState({
                          sortColumn: "childCount",
                          sortValue: sortValue === 1 ? -1 : 1,
                        });
                        this.getParents(
                          self.state.offset,
                          self.state.rowsPerPage,
                          self.state.sortColumn,
                          self.state.sortValue,
                          self.state.search,
                          self.state.fromDate,
                          self.state.toDate
                        );
                      }}
                    >
                      <strong>Child Count</strong>
                      {sortColumn === "childCount" && sortValue !== null ? (
                        sortValue === 1 ? (
                          <FontAwesomeIcon icon={faArrowDown} />
                        ) : (
                          <FontAwesomeIcon icon={faArrowUp} />
                        )
                      ) : (
                        <FontAwesomeIcon icon={faSort} />
                      )}
                    </div>
                  </Cell>
                  <Cell className="text-center">
                    <div
                      className="pointer"
                      onClick={() => {
                        this.setState({
                          sortColumn: "subscription",
                          sortValue: sortValue === 1 ? -1 : 1,
                        });
                        this.getParents(
                          self.state.offset,
                          self.state.rowsPerPage,
                          self.state.sortColumn,
                          self.state.sortValue,
                          self.state.search,
                          self.state.fromDate,
                          self.state.toDate

                        );
                      }}
                    >
                      <strong>User Subscription</strong>
                      {sortColumn === "subscription" && sortValue !== null ? (
                        sortValue === 1 ? (
                          <FontAwesomeIcon icon={faArrowDown} />
                        ) : (
                          <FontAwesomeIcon icon={faArrowUp} />
                        )
                      ) : (
                        <FontAwesomeIcon icon={faSort} />
                      )}
                    </div>
                  </Cell>
                  <Cell className="text-center">
                    <div
                      className="pointer"
                      onClick={() => {
                        this.setState({
                          sortColumn: "isVerified",
                          sortValue: sortValue === 1 ? -1 : 1,
                        });
                        this.getParents(
                          self.state.offset,
                          self.state.rowsPerPage,
                          self.state.sortColumn,
                          self.state.sortValue,
                          self.state.search,
                          self.state.fromDate,
                          self.state.toDate
                        );
                      }}
                    >
                      <strong>Email Verified</strong>
                      {sortColumn === "isVerified" && sortValue !== null ? (
                        sortValue === 1 ? (
                          <FontAwesomeIcon icon={faArrowDown} />
                        ) : (
                          <FontAwesomeIcon icon={faArrowUp} />
                        )
                      ) : (
                        <FontAwesomeIcon icon={faSort} />
                      )}
                    </div>
                  </Cell>
                  <Cell className="text-center">
                    <div
                      className="pointer"
                      onClick={() => {
                        this.setState({
                          sortColumn: "status",
                          sortValue: sortValue === 1 ? -1 : 1,
                        });
                        this.getParents(
                          self.state.offset,
                          self.state.rowsPerPage,
                          self.state.sortColumn,
                          self.state.sortValue,
                          self.state.search,
                          self.state.fromDate,
                          self.state.toDate
                        );
                      }}
                    >
                      <strong>Status</strong>
                      {sortColumn === "status" && sortValue !== null ? (
                        sortValue === 1 ? (
                          <FontAwesomeIcon icon={faArrowDown} />
                        ) : (
                          <FontAwesomeIcon icon={faArrowUp} />
                        )
                      ) : (
                        <FontAwesomeIcon icon={faSort} />
                      )}
                    </div>
                  </Cell>
                  <Cell className="text-center">
                    <div
                      className="pointer"
                      onClick={() => {
                        this.setState({
                          sortColumn: "createdDate",
                          sortValue: sortValue === 1 ? -1 : 1,
                        });
                        this.getParents(
                          self.state.offset,
                          self.state.rowsPerPage,
                          self.state.sortColumn,
                          self.state.sortValue,
                          self.state.search,
                          self.state.fromDate,
                          self.state.toDate
                        );
                      }}
                    >
                      <strong>Created On</strong>
                      {sortColumn === "createdDate" && sortValue !== null ? (
                        sortValue === 1 ? (
                          <FontAwesomeIcon icon={faArrowDown} />
                        ) : (
                          <FontAwesomeIcon icon={faArrowUp} />
                        )
                      ) : (
                        <FontAwesomeIcon icon={faSort} />
                      )}
                    </div>
                  </Cell>
                  <Cell className="text-center"></Cell>
                </StickyRow>
                {parentList && parentList.length > 0 ? (
                  parentList.map((element, index) => {
                    return (
                      <StickyRow key={index}>
                        <Cell scope="row">
                          {self.state.offset * rowsPerPage + index + 1}
                        </Cell>
                        <Cell>{element.email}</Cell>
                        <Cell className="text-center">
                          {element.childCount ? element.childCount : 0}
                        </Cell>
                        <Cell className="text-center">
                          {element.subscription ? element.subscription : "--"}
                        </Cell>
                        <Cell className="text-center">
                          {element.isVerified ? element.isVerified : "--"}
                        </Cell>
                        <Cell className="text-center">
                          {element.status ? element.status : "--"}
                        </Cell>
                        <Cell className="text-center">
                          {element.createdDate ? element.createdDate : "--"}
                        </Cell>
                        <Cell className="text-center">
                          {this.getParentOptions(element)}
                        </Cell>
                      </StickyRow>
                    );
                  })
                ) : (
                  <StickyRow>
                    <Cell>No parent found</Cell>
                  </StickyRow>
                )}
              </StickyTable>
            </div>
          </Col>
        </Row>
        <Row className="pt-4">
          <Col sm="5" lg="6">
            {totalRecord && <>{totalRecord} Parent{totalRecord===1 ? '' : 's'}</>}
            {(!totalRecord) && <>0 Parent</>}
          </Col>
          <Col sm="6" lg="6">
            {parentList && parentList.length > 0 && (
              <ReactPaginate
                breakLabel={"..."}
                pageCount={linksCount}
                marginPagesDisplayed={2}
                pageRangeDisplayed={2}
                onPageChange={(e) => {
                  this.setState(
                    {
                      page: e.selected + 1,
                      offset: parseInt(e.selected),
                    },
                    () => {
                      this.getParents(
                        self.state.offset,
                        self.state.rowsPerPage,
                        self.state.sortColumn,
                        self.state.sortValue,
                        self.state.search,
                        self.state.fromDate,
                        self.state.toDate
                      );
                    }
                  );
                }}
                containerClassName={"pagination"}
                pageClassName={"page-item"}
                pageLinkClassName={"page-link"}
                activeClassName={"active"}
                breakClassName={"page-link"}
              />
            )}
          </Col>
        </Row>
        <Modal isOpen={verifyUserModal}>
          <ModalHeader
            toggle={() => {
              this.onCloseModal(false);
            }}
          >
            Verify User
          </ModalHeader>
          <ModalBody>
            {selectedRow && selectedRow.email ? selectedRow.email : "This user"}{" "}
            can access the app after verification. Are you sure?
          </ModalBody>
          <ModalFooter className="justify-content-left">
            <Button
              type="button"
              size="sm"
              color="primary"
              onClick={() => {
                this.confirmVerification();
              }}
            >
              Verify
            </Button>{" "}
            <Button
              type="reset"
              size="sm"
              color="danger"
              onClick={() => {
                this.onCloseModal();
              }}
            >
              Cancel
            </Button>
          </ModalFooter>
        </Modal>

        <Modal isOpen={verificationLinkModal}>
          <ModalHeader
            toggle={() => {
              this.onCloseModal(false);
            }}
          >
            Send Verification Link
          </ModalHeader>
          <ModalBody>
            Are you sure you want to send verification link again to{" "}
            {selectedRow && selectedRow.email
              ? selectedRow.email
              : " this user"}
          </ModalBody>
          <ModalFooter className="justify-content-left">
            <Button
              type="button"
              size="sm"
              color="primary"
              onClick={() => {
                this.confirmSendVerificationLink();
              }}
            >
              Send
            </Button>{" "}
            <Button
              type="reset"
              size="sm"
              color="danger"
              onClick={() => {
                this.onCloseModal();
              }}
            >
              Cancel
            </Button>
          </ModalFooter>
        </Modal>

        <Modal isOpen={changeSubscriptionModal}>
          <ModalHeader
            toggle={() => {
              this.onCloseModal(false);
            }}
          >
            Change User Subscription
          </ModalHeader>
          <ModalBody>
            Are you sure you want to change{" "}
            {selectedRow && selectedRow.email
              ? selectedRow.email + "'s"
              : " current user's "}{" "}
            subsciption to{" "}
            {selectedRow.subscription === Constants.FREE ? "Paid" : "Free"}
          </ModalBody>
          <ModalFooter className="justify-content-left">
            <Button
              type="button"
              size="sm"
              color="primary"
              onClick={() => {
                this.confirmChangeSubscription();
              }}
            >
              Send
            </Button>{" "}
            <Button
              type="reset"
              size="sm"
              color="danger"
              onClick={() => {
                this.onCloseModal();
              }}
            >
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

export default Parent;
