import { createReducer } from 'redux-act';
import {requestGetPrintable, responseGetPrintable,  requestAddPrintable, responseAddPrintable} from './action';



const defaultState = {
	requestProcess: false,
};

const reducer = createReducer({

	[requestGetPrintable]: (state) => {
		return {
			requestProcess: true,
			data: [],
		};
	},

	[responseGetPrintable]: (state, params) => {
		return {
			requestProcess: false,
			data: params,
		};
	},

	[requestAddPrintable]: (state) => {
		return {
			requestProcess: true,
			addUpdateData: [],
		};
	},

	[responseAddPrintable]: (state, params) => {
		return {
			requestProcess: false,
			addUpdateData: params,
		};
	}


}, defaultState);



export default reducer
