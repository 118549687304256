import { connect } from 'react-redux';

import Settings from './Settings';
import { getAppFeatures, updateFeature, getAllSettings, updateSettings, addSettings, getAllServices, addService, updateService, getAllUtilities, addAppUtility, updateAppUtility } from './action';


const mapStateToProps = (state) => {
	var stateData = {
		requestProcess: state.settings.requestProcess
	};
	if(state.settings.data){
		stateData['getAppFeatures'] = state.settings.data;
	}


	if(state.settings.data){
		stateData['updateFeature'] = state.settings.updateFeature;
	}


	if(state.settings.settings){
		stateData['settings'] = state.settings.settings;
	}


	if(state.settings.updatedSetting){
		stateData['updatedSetting'] = state.settings.updatedSetting;
	}

	if(state.settings.addedSetting){
		stateData['addedSetting'] = state.settings.addedSetting;
	}


	if(state.settings.serviceData){
		stateData['serviceData'] = state.settings.serviceData;
	}

	if(state.settings.addService){
		stateData['addService'] = state.settings.addService;
	}

	if(state.settings.updateService){
		stateData['updateService'] = state.settings.updateService;
	}


	if(state.settings.utilityData){
		stateData['utilityData'] = state.settings.utilityData;
	}


	if(state.settings.addUtility){
		stateData['addUtility'] = state.settings.addUtility;
	}


	if(state.settings.updateUtility){
		stateData['updateUtility'] = state.settings.updateUtility;
	}
	
	return stateData;
};

const mapDispatchToProps = (dispatch) => {
	return {
		_getAppFeatures: (params) => {
			dispatch(getAppFeatures(params));
		},

		_updateFeature: (params) => {
			dispatch(updateFeature(params));
		},


		_getAllSettings: (params) => {
			dispatch(getAllSettings(params));
		},

		_updateSetting: (params) => {
			dispatch(updateSettings(params));
		},

		_addSetting: (params) => {
			dispatch(addSettings(params));
		},

		_getAllServices: (params) => {
			dispatch(getAllServices(params));
		},

		_addService: (params) => {
			dispatch(addService(params));
		},

		_updateService: (params) => {
			dispatch(updateService(params));
		},


		_getAllUtilities: (params) => {
			dispatch(getAllUtilities(params));
		},

		_addAppUtility: (params) => {
			dispatch(addAppUtility(params));
		},

		_updateUtility: (params) => {
			dispatch(updateAppUtility(params));
		},
	};
};


export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Settings);
