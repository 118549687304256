
import Vimeo from 'vimeo';

const CLIENT_ID = process.env.REACT_APP_CLIENT_ID;
const CLIENT_SECRET = process.env.REACT_APP_CLIENT_SECRET;
const ACCESS_TOKEN = process.env.REACT_APP_ACCESS_TOKEN;
const VIMEO_USER_ID = process.env.REACT_APP_VIMEO_USER_ID;
const VIMEO_FOLDER_ID = process.env.REACT_APP_VIMEO_FOLDER_ID;

const VimeoRef = Vimeo.Vimeo;
var vimeoClient = new VimeoRef(CLIENT_ID, CLIENT_SECRET, ACCESS_TOKEN);

const VimeoService = {


    uploadVideo(requestObj, updateProgress){
        const {file, name, description} = requestObj;
        return new  Promise( (resolve, reject) => {
            console.log('vimeoClient', vimeoClient)
            const params = {
                name: name,
                description: description, 
                privacy: {view: 'nobody'}
            }
            vimeoClient.upload(file, params,
                function (uri) {
                    console.log('File upload completed. Your Vimeo URI is:', uri)
                    VimeoService.moveFileUnderFolder(uri)
                    let videoId = uri.replace('/videos/', '');
                    resolve(videoId)
                    
                },
                function (bytesUploaded, bytesTotal) {
                    let percentage = (bytesUploaded / bytesTotal * 100).toFixed(2)
                    updateProgress(percentage)
                },
                function (error) {
                    console.log('Failed because: ' + error)
                    reject(error)
                }
            )
        })
    },

    moveFileUnderFolder(videoId){
        vimeoClient.request({
            method: 'PUT',
            path: '/users/'+VIMEO_USER_ID+'/projects/'+VIMEO_FOLDER_ID+videoId,                
          }, /*callback*/function (error, body, status_code, headers) {
            if (error) {
              console.log('error');
              console.log(error);
            } else {
              console.log('body');
              console.log(body);
            }
          
            console.log('status code');
            console.log(status_code);
            console.log('headers');
            console.log(headers);
          });
    }

}

export default VimeoService;