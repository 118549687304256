import { connect } from 'react-redux';

import DeepLink from './DeepLink';
import { getDeepLinks, getScreenSlug, getPillars, getCategories, getContentList, generateDeepLink, deleteDeepLink, getMediaSeries } from './action';


const mapStateToProps = (state) => {
	return {}
};

const mapDispatchToProps = (dispatch) => {
	return {
		_getDeepLinks: (params) => {return getDeepLinks(params)},
		_getScreenSlug: (params) => {return getScreenSlug(params)},
		_generateDeepLink: (params) => { return generateDeepLink(params)},
		_getPillars: (params) => {return getPillars(params)},
		_getCategories: (pillarId) => {return getCategories(pillarId)},
		_getContentList: (params) => {return getContentList(params)},
		_deleteDeepLink: (params) => {return deleteDeepLink(params)},
		_getMediaSeries: (params) => {return getMediaSeries()}

	};
};


export default connect(
	mapStateToProps,
	mapDispatchToProps
)(DeepLink);
