import { createReducer } from 'redux-act';
import {requestGetNotification, responseGetNotification, requestContentList, responseContentList, requestAddUpdateNotification, responseAddUpdateNotification} from './action';



const defaultState = {
	requestProcess: false,
};

const reducer = createReducer({

	[requestGetNotification]: (state) => {
		return {
			requestProcess: true,
			data: [],
		};
	},

	[responseGetNotification]: (state, params) => {
		return {
			requestProcess: false,
			data: params,
		};
	},

	[requestContentList]: (state) => {
		return {
			requestProcess: true,
			content: [],
		};
	},

	[responseContentList]: (state, params) => {
		return {
			requestProcess: false,
			content: params,
		};
	},

	[requestAddUpdateNotification]: (state) => {
		return {
			requestProcess: true,
			addUpdateData: [],
		};
	},

	[responseAddUpdateNotification]: (state, params) => {
		return {
			requestProcess: false,
			addUpdateData: params,
		};
	}


}, defaultState);



export default reducer
