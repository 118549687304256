import { connect } from 'react-redux';

import Versions from './Versions';
import { getAppVersion, getAllservices, updateBuildVersion } from './action';


const mapStateToProps = (state) => {
	var stateData = {
		requestProcess: state.versions.requestProcess
	};

	if(state.versions.data){
		stateData['getAppVersion'] = state.versions.data;
	}


	if(state.versions.getAllservices){
		stateData['getAllservices'] = state.versions.getAllservices;
	}


	if(state.versions.updateBuildVersion){
		stateData['updateBuildVersion'] = state.versions.updateBuildVersion;
	}
	
	return stateData;
};

const mapDispatchToProps = (dispatch) => {
	return {
		_getAppVersions: (params) => {
			dispatch(getAppVersion(params));
		},

		_getAllservices: (params) => {
			dispatch(getAllservices(params));
		},


		_updateBuildVersion: (params) => {
			dispatch(updateBuildVersion(params));
		},
	};
};


export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Versions);
