import {createReducer} from 'redux-act';
import { requestGetContentList, responseGetContentList, requestAddUpdatePillarCategory, responseAddUpdatePillarCategory, requestCreateContent, responseCreateContent, requestUpdateContent, responseUpdateContent, requestGetContentDetail, responseGetContentDetail} from './action';


const defaultState = {
	requestProcess: false,
};

const reducer = createReducer({

	[requestGetContentList]: (state) => {
		return {
			requestProcess: true,
			data: [],
		};
	},

	[responseGetContentList]: (state, params) => {
		
		return {
			
			requestProcess: false,
			data: params,
		};
	},

	// [requestChangeStatusPillarCategory]: (state) => {
	// 	return {
	// 		requestProcess: true
	// 	};
	// },

	// [responseChangeStatusPillarCategory]: (state, params) => {
	// 	return {
	// 		requestProcess: false,
	// 		addUpdateCategory: params,
	// 	};
	// },
	[requestAddUpdatePillarCategory]: (state) => {
		return {
			requestProcess: true
		};
	},

	[responseAddUpdatePillarCategory]: (state, params) => {
		return {
			requestProcess: false,
			addUpdateCategory: params,
		};
	},



	[requestCreateContent]: (state) => {
		return {
			requestProcess: true
		};
	},

	[responseCreateContent]: (state, params) => {
		return {
			requestProcess: false,
			createContent: params,
		};
	},



	[requestUpdateContent]: (state) => {
		return {
			requestProcess: true
		};
	},

	[responseUpdateContent]: (state, params) => {
		return {
			requestProcess: false,
			updateContent: params,
		};
	},

	[requestGetContentDetail]: (state) => {
		return {
			requestProcess: true
		};
	},

	[responseGetContentDetail]: (state, params) => {
		return {
			requestProcess: false,
			contentDetails: params,
		};
	},



}, defaultState);



export default reducer
