import {createAction} from 'redux-act';
import httpClient from '../../services/http';


export const requestGetContentList = createAction();
export const responseGetContentList = createAction();


export const requestCreateContent = createAction();
export const responseCreateContent = createAction();


export const requestUpdateContent = createAction();
export const responseUpdateContent = createAction();

export const requestGetContentDetail = createAction();
export const responseGetContentDetail = createAction();

export const requestAddUpdatePillarCategory = createAction();
export const responseAddUpdatePillarCategory = createAction();





export function getContentList(params){
  return (dispatch) => {
      dispatch(requestGetContentList([]));
      let categoryId = ''

      let endPoint = "/get_author_content";
      endPoint = categoryId ? endPoint+"/"+categoryId : endPoint;      
      httpClient.call('PILLAR',endPoint, params, {method: 'GET', headers: {}}).then(function(response){
          dispatch(responseGetContentList(response));       
      }, function(error){
          dispatch(responseGetContentList(error)); 
      });
  }
}



export function createContent(params, progressCallBack){
    return (dispatch) => {
      dispatch(requestCreateContent([]));
      let endPoint  = '/create_author_content'
      let method = 'POST';
      httpClient.call('PILLAR', endPoint, params, {method: method, headers: {}}, progressCallBack).then(function(response){
          dispatch(responseCreateContent(response));
      }, function(error){
          dispatch(responseCreateContent(error));
      });
  }
}



export function updateContent(params, progressCallBack){
    return (dispatch) => {
      dispatch(requestUpdateContent([]));
      let endPoint = '/edit_author_content';
      let method = 'PUT';
      httpClient.call('PILLAR', endPoint, params, {method: method, headers: {}}, progressCallBack).then(function(response){
          dispatch(responseUpdateContent(response));
      }, function(error){
          dispatch(responseUpdateContent(error));
      });
  }
}

export function changeContentStatus(params){
    return (dispatch) => {
      dispatch(requestUpdateContent([]));
      let endPoint = '/change_author_content_status';
      let method = 'PUT';
      httpClient.call('PILLAR', endPoint, params, {method: method, headers: {}}).then(function(response){
          dispatch(responseUpdateContent(response));
      }, function(error){
          dispatch(responseUpdateContent(error));
      });
  }
}

export function deleteContent(params){
    return (dispatch) => {
        dispatch(requestUpdateContent([]));
        let endPoint = '/delete_author_content';
        let method = 'DELETE';
        httpClient.call('PILLAR', endPoint, params, {method: method, headers: {}}).then(function(response){
            dispatch(responseUpdateContent(response));
        }, function(error){
            dispatch(responseUpdateContent(error));
        });
    }
}


export function getContentDetails(params){
    return (dispatch) => {
        dispatch(requestGetContentDetail([]));
        let endPoint = '/get_author_content_details';
        let method = 'GET';
        httpClient.call('PILLAR', endPoint, params, {method: method, headers: {}}).then(function(response){
            dispatch(responseGetContentDetail(response));
        }, function(error){
            dispatch(responseGetContentDetail(error));
        });
    }
}
export function getAgeGroups(){
      return httpClient.call('PILLAR', '/get_age_groups', {}, {method: 'GET'})
}