import React, { Component } from 'react';
import {  Table, Modal, ModalHeader, ModalBody } from "reactstrap";

import moment from 'moment';


import * as _ from 'underscore';



class ShowAllPromoUsers extends Component {

  constructor(props) {
    super(props);
    let userList = (props.userList && props.userList.list)  ? props.userList.list : [];
    if(userList && userList.length > 0){
      userList.sort(function(a, b) {
          return new Date(b.createdDate).getTime() - new Date(a.createdDate).getTime();
      });
    }
    this.state = {
      selectedRow : props.selectedRow,
      userList : userList,
      totalRecords : (props.userList && props.userList.totalRecords) ? props.userList.totalRecords : 0,
      activeTab : props.activeTab
    }


    this.userListHeadings = [
      { "displayName": "Email", sort : false},
      { "displayName": "Subscribed On", sort : false}
    ];



  }

  componentDidMount() {
  }


  setGridHeaders(headingData) {
    let gridHeadings = [];
    gridHeadings.push(<th key={headingData[0]['displayName']}>#</th>)
    _.each(headingData, function(value, index){
      {
        gridHeadings.push(<th key={index}>{value['displayName']}</th>)
      }

    })

    return (<tr className="table-head">{gridHeadings}</tr>)
  }


  setGridData(data) {
    let gridData = [];
    _.each(data, function(value, index){
      {
        gridData.push(
          <tr key={value['_id']}>
            <th scope="row">{index + 1}</th>
            <td>{value['user']['email']}</td>
            <td>{moment(value['createdDate']).format("DD-MMM-YYYY")}</td>
          </tr>
       )
      }
    })
    return gridData; 
  }
  



  render() {
    let {visible, closeModal} = this.props;
    let { totalRecords, userList, selectedRow } = this.state;
    return (
    
      <div>
        <Modal isOpen={visible} toggle={() => closeModal()} size="lg">
          <ModalHeader toggle={() => closeModal()}>User list for coupon {selectedRow.couponCode} </ModalHeader>
          <ModalBody>
            <Table hover>
              <thead>
                 {this.setGridHeaders(this.userListHeadings)}
              </thead>
              <tbody>
                {
                  this.setGridData(userList)
                }
              </tbody>
            </Table>
            
            
            <p className="clearfix">Total Records : {totalRecords}
              {/*this.setPagination(discounts.length, discounts.length)*/}
            </p>
          </ModalBody>
        </Modal>
      </div>
      
    );
  }
}

export default ShowAllPromoUsers;
