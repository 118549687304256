import { connect } from 'react-redux';

import DailyQuest from './DailyQuest';
import { getAllQuest, updateQuest, addQuest, getContentList } from './action';
// import { getContentList } from '../content, /action';


const mapStateToProps = (state) => {
		var stateData = {
			requestProcess: state.dailyQuest.requestProcess
		};

		if(state.dailyQuest.questData){
			stateData['questData'] = state.dailyQuest.questData;
		}

		if(state.dailyQuest.questUpdated){
			stateData['questUpdated'] = state.dailyQuest.questUpdated;
		}

		if(state.dailyQuest.contentList){
			stateData['contentList'] = state.dailyQuest.contentList;
		}

		if(state.dailyQuest.questAdded){
			stateData['questAdded'] = state.dailyQuest.questAdded;
		}


		return stateData;
};

const mapDispatchToProps = (dispatch) => {
	return {
		_getAllQuest: (params) => {
			dispatch(getAllQuest(params));
		},


		_updateQuest: (params) => {
			dispatch(updateQuest(params));
		},


		_addQuest: (params) => {
			dispatch(addQuest(params));
		},

		_getContentList: (params) => {
			dispatch(getContentList(params));
		}
	}
};


export default connect(
	mapStateToProps,
	mapDispatchToProps
)(DailyQuest);
