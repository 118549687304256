import React, { Component } from 'react';
import { Loader } from '../../utils/common';
import Session from '../../utils/session';
import DataGrid from "g6reactdatagrid";
import { Row, Col, Input } from "reactstrap";
import moment from 'moment';
import Constants from '../../config/constants';

import DatePicker from 'react-date-picker';
import * as _ from 'underscore'

import {
  faPlus
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import UserPayments from "./UserPayments";



class PaymentHistory extends Component {

  constructor(props) {
    super(props);
    this.state = {
      paymentList: [],
      totalRecords: 0,
      selectedRow: {},
      limit: 10,
      offset: 0,
      page: 0,
      sortColumn: "createdDate",
      sortValue: -1,
      email: "",
      type: "",
      subscriptionType: '',
      grandTotalAmount: 0,
      userService: null,
      openUserPaymentsModal: false,
      parentId:null
    }


    this.paymentColumn = [
      { "columnName": "email", "displayName": "Email", sort: false },
      // { "columnName": "_id", "displayName": "_id", sort: false },
      // { "columnName": "type", "displayName": "Device Type", sort : false},
      { "columnName": "startsOn", "displayName": "Subscription start", sort: true },
      { "columnName": "endOn", "displayName": "Subscription end", sort: true },
      { "columnName": "isRecurring", "displayName": "Is Recurring", sort: false },
      { "columnName": "currentPlanTitle", "displayName": "Plan", sort: false },
      { "columnName": "planTitle", "displayName": "Re-new Plan", sort: false },
      { "columnName": "planPrice", "displayName": "Price", sort: false },
      { "columnName": "discount", "displayName": "Discount Given", sort: false },
      { "columnName": "amount", "displayName": "Amount paid", sort: true },
    ];
  }

  componentDidMount() {
    let configSession = Session.getSessionData('config');

    let setObj = {
      email: (this.props.location && this.props.location.query && this.props.location.query['email']) ? this.props.location.query['email'] : ""
    }
    if(configSession && configSession.SERVICES){
      let userService = _.where(configSession.SERVICES, {SERVICE: "USER"});
      userService = userService && userService.length > 0 ? userService[0] : null;
      setObj.userService = userService
  }

    this.setState(setObj, function () {
      this.getPaymentHistory();
    })
  }

  calc(v, d) {
    return (Math.floor(v * Math.pow(10, d)) / Math.pow(10, d)).toFixed(d);
  }

  componentWillReceiveProps(nextProps) {
    let self = this;
    let paymentList = [];
    let totalRecords = 0;
    let grandTotalAmount = 0;
    Loader(nextProps.requestProcess);
    if (nextProps.paymentList) {

      if (nextProps.paymentList.statusCode === 200 && nextProps['paymentList']['result'] && nextProps['paymentList']['result']['list'] && nextProps['paymentList']['result']['list']['length']) {

        nextProps['paymentList']['result']['list'].map(payment => {

          let discount = payment['subscription']['discount'] && payment['subscription']['discount']['discount'] ? payment['subscription']['discount']['discount'] : '0';

          let discountValue = payment['subscription']['discount'] && payment['subscription']['discount']['type'] === Constants.PERCENTAGE ? discount + '%' : (payment['subscription']['discount']) ? '$' + discount : 0;

          let paidAmount = payment['subscription'] && payment['subscription']['notifyType'] === 'FREE_TRIAL' ? 0 : this.calc(payment['amount'], 2);

          paymentList.push({
            'email': (payment['email']) ? payment['email'] : "N/A",
            'startsOn': moment(payment['subscription']['startsOn']).format("DD-MMM-YYYY"),
            'endOn': moment(payment['subscription']['endOn']).format("DD-MMM-YYYY"),
            'transactionId': payment['transactionId'],
            'isRecurring': (payment['subscription']['isRecurring'] === '1') ? Constants.ACTIVE : Constants.INACTIVE,
            'planTitle': (payment['subscription']['plan'] && payment['subscription']['plan']['title']) ? payment['subscription']['plan']['title'] : "",
            'currentPlanTitle': (payment['subscription']['currentPlan'] && payment['subscription']['currentPlan']['title']) ? payment['subscription']['currentPlan']['title'] : "",
            'planPrice': (payment['subscription']['currentPlan'] && payment['subscription']['currentPlan']['price']) ? payment['subscription']['currentPlan']['price'] : "",
            'discount': discountValue,
            'amount': paidAmount,
            'parentId': payment['parentId'],
          })
        })

        totalRecords = nextProps['paymentList']['result']['total']
        if (nextProps['paymentList']['result']['totalAmount']) {
          grandTotalAmount = nextProps['paymentList']['result']['totalAmount']
        }
      }
      self.setState({ paymentList: paymentList, totalRecords: totalRecords, grandTotalAmount: grandTotalAmount })
    }
  }

  getPaymentHistory() {
    let getTrophyObj = {
      limit: this.state.limit,
      offset: this.state.offset,
      sortField: this.state.sortColumn,
      sortValue: this.state.sortValue,
      type: this.state.type,
      subscriptionType: this.state.subscriptionType,
      email: (this.state.email) ? this.state.email.trim() : "",
      fromDate: (this.state.fromDate) ? this.state.fromDate : null,
      toDate: (this.state.toDate) ? this.state.toDate : null,
      range: (this.state.range && this.state.range != 'none') ? this.state.range : null
    }
    this.props._getPaymentHistory(getTrophyObj);
  }

  handleChange(event) {
    let target = event.target;
    let name = target.name;
    let value;
    if (target.type === 'checkbox') {
      value = (this.state[name]) ? false : true
    } else {
      value = target.value;
    }
    this.setState({ [name]: value });
  }

  formatTitlePosLabel(s) {
    if (typeof s !== 'string') return ''
    s = s.replace("_", " ");
    s = s.charAt(0).toUpperCase() + s.slice(1).toLowerCase();
    return s;
  }

  searchBy(event) {
    const self = this;
    let timeout;
    let name = event.target.name;
    let value = event.target.value;
    self.setState({ [name]: value, offset: 0, page: 0 });
    clearTimeout(timeout);
    timeout = setTimeout(function () {
      self.getPaymentHistory();
    }, 1000)
  }

  setRange(event) {
    if (event?.target?.value) {
      this.setState({ range: event?.target?.value }, () => {
        this.getPaymentHistory()
      });
    }
  }

  setDate(name, value) {
    if (value !== null) {
      this.setState({ [name]: new Date(value) }, () => {
        this.getPaymentHistory()
      });
    } else {
      this.setState({ [name]: null }, () => {
        this.getPaymentHistory()
      });
    }
  }

  downloadPaymentHistory() {
    let {userService} = this.state;
    this.props._getTokenToExportPaymentHistory({
      filter: {
        type: this.state.type,
        subscriptionType: this.state.subscriptionType,
        email: (this.state.email) ? this.state.email.trim() : "",
        fromDate: (this.state.fromDate) ? this.state.fromDate : null,
        toDate: (this.state.toDate) ? this.state.toDate : null,
        range: (this.state.range && this.state.range != 'none') ? this.state.range : null
      }
    }).then((response) => {
      if (response && response.statusCode === 200) {
        const host = userService['HOST'];
        // const host = 'http://localhost:3001/user';
        const version = process.env.REACT_APP_CURRENT_API_VERSION;
        const currentDateTime = new Date().getTime();
        let url = host + '/' + version + '/export_payment_history?token=' + response.result + '&date=' + currentDateTime;
        Loader(false);
        try {
          this.setState({ columns: [] }, () => {
            window.open(url, '_blank');
          })
        } catch (error) {
          this.setState({ isError: true, screenErrMsg: error });
        }
      } else {
        this.setState({ isError: true, screenErrMsg: response.statusDescription });
      }
    }, error => {
      Loader(false);
      this.setState({ isError: true, screenErrMsg: JSON.stringify(error) });
    })
  }

  openModal(rowData){
    console.log('rowData',rowData);

    if(rowData && rowData.parentId){
      this.setState({openUserPaymentsModal:true, parentId: rowData.parentId })
    }
  }

  closeModal() {
    this.setState({
      openUserPaymentsModal: false
    })
  }

  render() {
    let self = this;
    let { paymentList, selectedRow, totalRecords, limit, offset, page, sortColumn, sortValue, grandTotalAmount } = this.state;
    let totalRecordsLabel = '##TOTAL_RECORDS## ' + ('Payments')


    let paymentGridOptions = {
      totalRecords: totalRecords,
      totalRecordsLabel: totalRecordsLabel,
      rowsPerPage: limit,
      page: page,
      loadMore: false,
      customColumn: {
        isActive: true,
        columnTitle: 'View All',
        columnContent: (rowData) => {
          return (
            <div>
              <button onClick={() => { self.openModal(rowData); }} type="button" class="btn"><FontAwesomeIcon icon={faPlus} size="sm" /></button>
            </div>
          );
        }
      },

      onPageChange(pageNumber) {
        self.state.page = pageNumber;
        self.state.offset = parseInt(pageNumber) * parseInt(self.state.limit)
        self.getPaymentHistory();

      },

      onColumnSortChange: (column, direction) => {
        self.state.sortColumn = column;
        self.state.sortValue = (direction === "ASC") ? 1 : -1;
        self.getPaymentHistory();

      }
    };

    return (
      <div className="content">
        <Row className="pb-4">
          <Col lg={12} sm={12}>
            <Row className="pb-4">
              <Col lg={8} sm={8}>
                <h3>Payment History</h3>
              </Col>
              <Col lg={4} sm={4} className="text-right">
                <h2><b className="">${grandTotalAmount}</b></h2>
              </Col>
            </Row>


            <Row className="pb-4">

              <Col lg={3} sm={3} className="text-right">
                {
                  <Input type="text" name="email" placeholder="Search by Email" id="email" value={this.state.email} onChange={(event) => { this.searchBy(event) }}>
                    <option value="">Select Plan Type</option>
                    <option value={Constants.YEARLY}>{this.formatTitlePosLabel(Constants.YEARLY)}</option>
                    <option value={Constants.LIFETIME}>{this.formatTitlePosLabel(Constants.LIFETIME)}</option>
                    <option value={Constants.MONTHLY}>{this.formatTitlePosLabel(Constants.MONTHLY)}</option>
                  </Input>
                }
              </Col>

              <Col lg={3} sm={3} className="text-right">
                {
                  <Input type="select" name="type" id="type" value={this.state.type} onChange={(event) => { this.searchBy(event) }}>
                    <option value="">Select Plan Type</option>
                    <option value={Constants.YEARLY}>{this.formatTitlePosLabel(Constants.YEARLY)}</option>
                    <option value={Constants.LIFETIME}>{this.formatTitlePosLabel(Constants.LIFETIME)}</option>
                    <option value={Constants.MONTHLY}>{this.formatTitlePosLabel(Constants.MONTHLY)}</option>
                  </Input>
                }
              </Col>
              {/* <Col lg={3} sm={3} className="text-right">
                {
                  <Input type="select" name="subscriptionType" id="subscriptionType" value={this.state.subscriptionType} onChange={(event) => { this.searchBy(event) }}>
                    <option value="">Select Subscription Type</option>
                    <option value={Constants.TRIAL}>{this.formatTitlePosLabel(Constants.TRIAL)}</option>
                    <option value={Constants.PAID}>{this.formatTitlePosLabel(Constants.PAID)}</option>
                  </Input>
                }
              </Col> */}


            </Row>


            <Row className="pb-4">
              <Col lg={3} sm={3}>
                <label><b className="">From Date:</b></label>
                <DatePicker
                  className={'payment-history-dp form-control'}
                  readonly={true}
                  name="fromDate"
                  id="fromDate"
                  value={this.state.fromDate}
                  onChange={(event) => { this.setDate('fromDate', event); }}
                  minDate={null}
                  maxDate={null} />
              </Col>


              <Col lg={3} sm={3}>
                <label><b className="">To Date:</b></label>
                <DatePicker
                  className={'payment-history-dp form-control'}
                  readonly={true}
                  name="toDate"
                  id="toDate"
                  value={this.state.toDate}
                  onChange={(event) => { this.setDate('toDate', event); }}
                  minDate={null}
                  maxDate={null} />
              </Col>


              <Col lg={3} sm={3}>
                <label><b className="">Range</b></label>

                <select className="form-control" id="range" name="range" onChange={(event) => { this.setRange(event); }}>
                  <option value="none">--- Select Range ---</option>
                  <option value="today">Today</option>
                  <option value="last_7_days">Last 7 Days</option>
                  <option value="this_month">This Month</option>
                  <option value="this_year">This Year</option>
                </select>
              </Col>

              <Col lg={1} sm={1}></Col>
              <Col lg={2} sm={2}>
                <label>&nbsp;</label>
                <button type="button" class="form-control btn btn-primary" onClick={() => { this.downloadPaymentHistory(); }}>Download CSV</button>
              </Col>

            </Row>
            <div className="dataTable-wrapper pillarList_grid">
              {<DataGrid
                columns={this.paymentColumn}
                options={paymentGridOptions}
                rows={paymentList}
              />}
            </div>
          </Col>
        </Row>

        <Col lg={12} sm={12}>

        {this.state.openUserPaymentsModal && <UserPayments parentId={this.state.parentId} onClick={() => { this.openModal(); }} visible={this.state.openUserPaymentsModal} closeModal={() => this.closeModal()} />}
        </Col>

      </div>
    );
  }
}

export default PaymentHistory;
