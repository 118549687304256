import React, { Component } from 'react';
import { Col} from "reactstrap";
import moment from 'moment';
import Constants from '../../../config/constants';
import Session from '../../../utils/session';
import * as _ from 'underscore'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye,  faCopy } from '@fortawesome/free-solid-svg-icons'
import { faTwitter } from '@fortawesome/free-brands-svg-icons'
import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes';
import TwitterLogin from 'react-twitter-auth/lib/react-twitter-auth-component.js';


class Twitter extends Component {
  constructor(props) {

    super(props);
    this.state = {     
      twitterData : {},
      copyText: "Copy to clipboard",
    }
    this.sessionData = Session.getSessionData();
  }

  componentWillReceiveProps(nextProps) {
    
  }

  copyLink(link){
    if(link){
      this.setState({copyText: 'Copied!'}, ()=>{
        this.props.copyLink(link);
      })
    }
  }

  setCopyTextToInitial() {
    let self = this
    setTimeout(() => {
      self.setState({copyText : "Copy to clipboard"})
    }, 100)
  }



  render() {
    let {twitterData} = this.state;
    

    if(!twitterData || (twitterData && Object.keys(twitterData).length == 0)){
      return (
        <div className="card" style={{maxWidth: "540px"}}>
          <div className="row no-gutters">                     
            <div className="col-md-12 text-center">
              <div className="card-body">
                <TwitterLogin
                  loginUrl={"https://ninjadevmarketing.gate6.com/v10/oauth_verifier?userToken="+this.sessionData.jwtToken}
                  onFailure={this.onFailed}
                  onSuccess={this.onSuccess}
                  requestTokenUrl="https://ninjadevmarketing.gate6.com/v10/twitter_token_request?userToken="
                  showIcon={true}
                  customHeaders={{}}
                  className="social-media twitter-connect-button" 
                >
                <FontAwesomeIcon style={{marginRight: 20}} icon={faTwitter} /> Connect to Twitter 
                </TwitterLogin>
              </div>
            </div>
          </div>
        </div>
      )
    }


    return (
      <Col lg={4} sm={12} className="">
        
      </Col>
    );
  }
}

export default Twitter;
