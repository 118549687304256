import {createAction} from 'redux-act';
import httpClient from '../../../services/http';


export const requestDeepLinkList = createAction();
export const responseDeepLinkList = createAction();

export const requestAddUpdateUser = createAction();
export const responseAddUpdateUser = createAction();


export const requestInsightsGraphData = createAction();
export const responseInsightsGraphData = createAction();

export function getDeepLinks(params){
	return httpClient.call('MARKETING','/get_deep_link_list', params  , {method: 'GET', headers: {}});
}

export function getScreenSlug(params){
	return httpClient.call('USER','/get_marketing_screen_slug', params  , {method: 'GET', headers: {}})
}

export function getPillars(params) {
    return httpClient.call("PILLAR", "/get_pillars", params, { method: 'GET', headers: { 'content-type': 'application/json' } });
}

export function getCategories(pillarId) {
    let endPoint = "/get_pillar_categories";
    endPoint = pillarId ? endPoint + "/" + pillarId : endPoint;
    return httpClient.call("PILLAR", endPoint, {}, { method: 'GET', headers: { 'content-type': 'application/json' } });
}


export function getContentList(params) {
    return httpClient.call("PILLAR", "/get_contents_for_quest", params, { method: 'GET', headers: { 'content-type': 'application/json' } });
}

export function generateDeepLink(params){
	return httpClient.call('MARKETING','/generate_save_deep_link', params  , {method: 'POST', headers: {}})
}

export function deleteDeepLink(params){
	return httpClient.call('MARKETING','/remove_deep_link/'+params, {}  , {method: 'get', headers: {}})
}

export function getMediaSeries() {
  return httpClient.call("PILLAR", "/get_playlist_for_admin", { mediaSeries:true }, { method: 'GET', headers: { 'content-type': 'application/json' } });
}


