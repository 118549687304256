import { connect } from 'react-redux';

import EmailCampaign from './emailCampaign';
import { getCampaignList, uploadContactCSV, getTemplateList, uploadDefaultTemplate, downloadDefaultTemplate, deleteTemplate, sendTestEmail } from './action';


const mapStateToProps = (state) => {		
		var stateData = {
			requestProcess: state.emailCampaign.requestProcess
		};
		if(state.emailCampaign.emailCampaignList){
			stateData['emailCampaignList'] = state.emailCampaign.emailCampaignList;
		}
		if(state.emailCampaign.templateList){
			stateData['templateList'] = state.emailCampaign.templateList;
		}
		return stateData;
};

const mapDispatchToProps = (dispatch) => {
	return {
		_getCampaignList: (params)=>{
			dispatch(getCampaignList(params));
		},
		_uploadContactCSV: (data, processCallBack) => {
			return uploadContactCSV(data, processCallBack)
		},
		_getTemplateList: (params)=>{
			dispatch(getTemplateList(params));
		},
		_uploadDefaultTemplate: (data, processCallBack) => {
			return uploadDefaultTemplate(data, processCallBack)
		},
		_downloadDefaultTemplate : () =>{
			return downloadDefaultTemplate()
		},
		_deleteTemplate: (data) => {
			return deleteTemplate(data)
		},
		_sendTestEmail: (data) => {
			return sendTestEmail(data)
		},
	};
};


export default connect(
	mapStateToProps,
	mapDispatchToProps
)(EmailCampaign);
