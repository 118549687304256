import React, { Component } from 'react';
import { Row, Col, Modal, Button, ModalHeader, ModalBody, ModalFooter, Label, Input,  Form , Alert,  InputGroup, InputGroupAddon } from 'reactstrap';

import { Loader, createRandomStr} from '../../utils/common';
import EmailLayout  from './EmailLayout';
import SimpleReactValidator from 'simple-react-validator';
// import { Editor } from 'react-draft-wysiwyg';
// import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
// import { EditorState, convertToRaw, ContentState } from 'draft-js';
// import draftToHtml from 'draftjs-to-html';
// import htmlToDraft from 'html-to-draftjs';
import S3Client from '../../services/aws-s3/S3Client';
import CKEditor from 'ckeditor4-react';
import httpClient from '../../services/http';
import Session from '../../utils/session';

class EditSystemEmail extends Component {

  constructor(props) {
    super(props);
    this.validator = new SimpleReactValidator();
    this.onChange = this.onChange.bind(this);
    this.state = {
      templateId: props.rowData._id,
      slug: props.rowData.slug,
      subject: props.rowData.subject,
      title: props.rowData.title,
      description: props.rowData.description,
      content: props.rowData.content,
      headline: props.rowData.headline,
      subHeadline: props.rowData.subHeadline,
      status: props.rowData.status,
      imageFile:'',
      ImageUrl:'',
      errorMessage: null,
      imagesForEmail:props.imagesForEmail.result,
      copied:null
    };
    const s3Config = {
      bucketName: process.env.REACT_APP_S3_BUCKET,
      dirName: 'systemEmails', /* optional */
      region: process.env.REACT_APP_AWS_DEFAULT_REGION,
      accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
      s3Url: process.env.REACT_APP_AWS_BUCKET_URL, /* optional */
    }
    /*this.toolbar = [
      { name: 'document', groups: [ 'mode', 'document', 'doctools' ], items: [ 'Source', '-', 'Save', 'NewPage', 'Preview', 'Print', '-', 'Templates' ] },
      { name: 'clipboard', groups: [ 'clipboard', 'undo' ], items: [ 'Cut', 'Copy', 'Paste', 'PasteText', 'PasteFromWord', '-', 'Undo', 'Redo' ] },
      { name: 'editing', groups: [ 'find', 'selection', 'spellchecker' ], items: [ 'Find', 'Replace', '-', 'SelectAll', '-', 'Scayt' ] },
      { name: 'forms', items: [ 'Form', 'Checkbox', 'Radio', 'TextField', 'Textarea', 'Select', 'Button', 'ImageButton', 'HiddenField' ] },
      '/',
      { name: 'basicstyles', groups: [ 'basicstyles', 'cleanup' ], items: [ 'Bold', 'Italic', 'Underline', 'Strike', 'Subscript', 'Superscript', '-', 'CopyFormatting', 'RemoveFormat' ] },
      { name: 'paragraph', groups: [ 'list', 'indent', 'blocks', 'align', 'bidi' ], items: [ 'NumberedList', 'BulletedList', '-', 'Outdent', 'Indent', '-', 'Blockquote', 'CreateDiv', '-', 'JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock', '-', 'BidiLtr', 'BidiRtl', 'Language' ] },
      { name: 'links', items: [ 'Link', 'Unlink', 'Anchor' ] },
      { name: 'insert', items: [ 'Image', 'Flash', 'Table', 'HorizontalRule', 'Smiley', 'SpecialChar', 'PageBreak', 'Iframe' ] },
      '/',
      { name: 'styles', items: [ 'Styles', 'Format', 'Font', 'FontSize' ] },
      { name: 'colors', items: [ 'TextColor', 'BGColor' ] },
      { name: 'tools', items: [ 'Maximize', 'ShowBlocks' ] },
      { name: 'others', items: [ '-' ] },
      { name: 'about', items: [ 'About' ] }
    ];*/


    this.toolbar = [
      { name: 'document', groups: [ 'mode', 'document', 'doctools' ], items: [ 'Source', '-', 'Save', 'NewPage', 'Preview', 'Print', '-', 'Templates' ] },
      { name: 'clipboard', groups: [ 'clipboard', 'undo' ], items: [ 'Cut', 'Copy', 'Paste', 'PasteText', 'PasteFromWord', '-', 'Undo', 'Redo' ] },
      { name: 'editing', groups: [ 'find', 'selection', 'spellchecker' ], items: [ 'Find', 'Replace', '-', 'SelectAll', '-', 'Scayt' ] },
      { name: 'forms', items: [ 'Form', 'Checkbox', 'Radio', 'TextField', 'Textarea', 'Select', 'Button', 'ImageButton', 'HiddenField' ] },
      '/',
      { name: 'basicstyles', groups: [ 'basicstyles', 'cleanup' ], items: [ 'Bold', 'Italic', 'Underline', 'Strike', 'Subscript', 'Superscript', '-', 'CopyFormatting', 'RemoveFormat' ] },
      { name: 'paragraph', groups: [ 'list', 'indent', 'blocks', 'align', 'bidi' ], items: [ 'NumberedList', 'BulletedList', '-', 'Outdent', 'Indent', '-', 'Blockquote', 'CreateDiv', '-', 'JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock', '-', 'BidiLtr', 'BidiRtl', 'Language' ] },
      { name: 'links', items: [ 'Link', 'Unlink', 'Anchor' ] },
      { name: 'insert', items: [ 'Image', 'Flash', 'Table', 'HorizontalRule', 'Smiley', 'SpecialChar', 'PageBreak', 'Iframe' ] },
      '/',
      { name: 'styles', items: [ 'Styles', 'Format', 'Font', 'FontSize' ] },
      { name: 'colors', items: [ 'TextColor', 'BGColor' ] },
      { name: 'tools', items: [ 'Maximize', 'ShowBlocks' ] },
      { name: 'others', items: [ '-' ] },
      { name: 'about', items: [ 'About' ] }
    ];

    this.toolbarGroups = [
      { name: 'clipboard',   groups: [ 'clipboard', 'undo' ] },
      { name: 'editing',     groups: [ 'find', 'selection', 'spellchecker' ] },
      { name: 'links' },
      { name: 'insert' },
      { name: 'forms' },
      { name: 'tools' },
      { name: 'document',       groups: [ 'mode', 'document', 'doctools' ] },
      { name: 'others' },
      '/',
      { name: 'basicstyles', groups: [ 'basicstyles', 'cleanup' ] },
      { name: 'paragraph',   groups: [ 'list', 'indent', 'blocks', 'align', 'bidi' ] },
      { name: 'styles' },
      { name: 'colors' },
      { name: 'about' }
    ];


    this.S3Client = new S3Client(s3Config);
    this.session = Session.getSessionData('config');
    this.cloudFrontUrl = this.session && this.session.UTILS && this.session.UTILS.BUCKET_URL ? this.session.UTILS.BUCKET_URL : process.env.REACT_APP_CLOUDFRONT_URL;
  }


  componentDidMount() {
    //this.props._getImagesForEmail();
  }
  componentWillReceiveProps(nextProps){
    Loader(nextProps.requestProcess);
    if (nextProps.systemEmailData && nextProps.systemEmailData.statusCode === 200) {
      if(nextProps.systemEmailData.result && nextProps.systemEmailData.result){

        this.setState({systemEmailList: nextProps.systemEmailData.result});
      }
    }
    if (nextProps.imagesForEmail && nextProps.imagesForEmail.statusCode === 200) {
      if(nextProps.imagesForEmail.result){

        this.setState({imagesForEmail: nextProps.imagesForEmail.result});
      }
    }
  }

  updateState(name, value){
    value = value.replace('<div>', '');
    value = value.replace('</div>', '');
    //value = this.cleanString(value);
    this.setState({[name]: value});
  }

  cleanString(stringHtml) {
    var span = document.createElement('span');
    span.innerHTML = stringHtml;
    return span.textContent || span.innerText;
  }

  handleSubmit(e) {
    if( this.validator.allValid()){
      let templateObj = {
        templateId : this.state.templateId,
        content : this.state.content,
        headline : this.state.headline,
        subject : this.state.subject,
        subHeadline : this.state.subHeadline,
        status : this.state.status,
      }
      this.props.updateTemplate(templateObj);
    }else{
      this.validator.showMessages();
      this.forceUpdate();
    }
    e.preventDefault();
  }

  handleChange(event) {
    let target = event.target;
    let name = target.name;
    let value;

    if (target.type === 'checkbox') {
      if (target.name === 'serviceStatus') {
        value = (target.value === 'true') ? false : true;
      }

      if (target.name === 'authRequired') {
        value = (target.value === 'true') ? false : true;
      }
    }else {
      value = target.value;
    }
    this.setState({[name]: value});
  }

  onChange(evt){
    console.log("onChange fired with event info: ", evt);
    let subHeadline = evt.editor.getData();
    this.setState({
      subHeadline: subHeadline
    })
  }

  setImage(event) {
    let file = event.target.files[0];
    this.setState({imageFile: file});
  }

  uploadImage(){
    let {imageFile} = this.state;
    if(imageFile instanceof File){
        Loader(true);
        this.uploadFiles(imageFile).then((response) => {
            this.setState({ImageUrl:response.location})
            this.props._getImagesForEmail({})
            Loader(false);
        }, (error) => {
          this.setState({errorMessage: error});
            setTimeout(()=>{
              this.setState({errorMessage: null})
            }, 5000);
            Loader(false);
          })
    }
  }

  uploadFiles(fileObj) {
    let self = this;
    return new Promise((resolve, reject) => {
          self.S3Client.getPolicy().then((policyRes)=>{
            httpClient.call('PILLAR', '/get_signature', {policy: policyRes}, {method: 'GET', headers: {}}).then((signatureRes)=>{
              if(signatureRes && signatureRes.statusCode === 200 && signatureRes.result && signatureRes.result.signature){
                  createRandomStr((randString)=>{
                      let signature = signatureRes.result.signature;
                      let ext = fileObj.name.split('.')[1];
                      self.S3Client.uploadFile(fileObj, randString+'.'+ext, signature).then(data => {
                          resolve(data);
                      }).catch(err => {
                         reject(err);
                      })
                  });

              }else{
                reject(signatureRes.statusDescription);
              }

            }, (error) => {
              reject(error)
            })
          }, (error) => {
            reject(error)
          });
    })
  }

  copyUrl(url, index) {
    // Create new element
    let el = document.createElement('textarea');
    // Set value (string to be copied)
    el.value = url;
    // Set non-editable to avoid focus and move outside of view
    el.setAttribute('readonly', '');
    el.style = {position: 'absolute', left: '-9999px', display : 'none'};
    document.body.appendChild(el);
    // Select text inside element
    el.select();
    // Copy text to clipboard
    document.execCommand('copy');
    // Remove temporary element
    document.body.removeChild(el);
    let _self =this;
    this.setState((preState, nextState)=>{return preState.copied = index}, ()=>{
      setTimeout(()=>{
        _self.setState({copied: null});
      }, 1000)
    });
    console.log(index)
  }

  getAllImages(){
    //this.props.getImagesForEmail()
  }
  loading = () => <div className="animated fadeIn pt-1 text-center">Loading...</div>

  render() {

     let { content, headline, subHeadline, imagesForEmail, copied } = this.state;
     let {signedQueryString} = this.props;

    return (
        <Modal isOpen={true} size="lg" style={{maxWidth: (window.screen.width-200), width: '75%'}}>
            <ModalHeader toggle={()=>{this.props.closeModel()}}>Update {this.state.title}</ModalHeader>
            <Form onSubmit={(event) => {this.handleSubmit(event)}}>
            <ModalBody >
              <Row>
                <Col xs="6">
                  {/*<Row>
                                      <Col xs="12">
                                          <FormGroup>
                                            <Label htmlFor="title">Template Name*</Label>
                                            <Input type="text" id="title" placeholder="Template Name" name="title" value={this.state.title} onChange={($event)=>{this.handleChange(event)}} />
                                            <div className="validation-error">{ this.validator.message('Template Name', this.state.title, 'required') }</div>
                                          </FormGroup>
                                      </Col>
                                    </Row>

                                    <Row>
                                      <Col xs="12">
                                          <FormGroup>
                                            <Label htmlFor="description">Description</Label>
                                            <Input type="textarea" id="description"  placeholder="Description" name="description" value={this.state.description} onChange={($event)=>{this.handleChange(event)}} />
                                          </FormGroup>
                                      </Col>
                                    </Row>*/}
                  <Row>
                    <Col sm="12">
                      <Label htmlFor="title">Subject</Label>
                      <Input type="text" id="subject"  placeholder="Subject" name="subject" value={this.state.subject} onChange={(event)=>{this.handleChange(event)}} />
                      <div className="validation-error">{ this.validator.message('Subject', this.state.subject, 'required') }</div>
                    </Col>
                  </Row>


                  <Row>
                    <Col sm="12">
                      <Label htmlFor="title">Headline</Label>
                      <Input type="textarea" id="headline"  placeholder="Headline" name="headline" value={this.state.headline} onChange={(event)=>{this.handleChange(event)}} />
                       <div className="validation-error">{ this.validator.message('Headline', this.state.headline, 'required') }</div>
                    </Col>
                  </Row>


                  <Row>
                    <Col sm="12">
                      <Label htmlFor="title">Content</Label>
                       {/*<Input type="textarea" id="headline"  placeholder="Headline" name="subHeadline" value={this.state.subHeadline} onChange={($event)=>{this.handleChange(event)}} />*/}

                       <CKEditor
                       id = "ckeditor111"
                       activeClass="p10"
                       data={this.state.subHeadline}
                       onChange={this.onChange}

                        config={ {
                            toolbarGroups: this.toolbarGroups,
                            toolbar: this.toolbar,
                        } }
                       /*config={{
                         extraPlugins: 'uploadimage',
                      uploadUrl: 'https://ckeditor.com/apps/ckfinder/3.4.5/core/connector/php/connector.php?command=QuickUpload&type=Files&responseType=json',

                       // Configure your file manager integration. This example uses CKFinder 3 for PHP.
                       filebrowserBrowseUrl: 'https://ckeditor.com/apps/ckfinder/3.4.5/ckfinder.html',
                       filebrowserImageBrowseUrl: 'https://ckeditor.com/apps/ckfinder/3.4.5/ckfinder.html?type=Images',
                       filebrowserUploadUrl: 'http://localhost:8080/test/ckfinder/3.4.5/core/connector/php/connector.php?command=QuickUpload&type=Files',
                       filebrowserImageUploadUrl: 'http://localhost:3000/ckfinder/core/connector/php/index.js?command=QuickUpload&type=Images',

                       height: 560,

                       removeDialogTabs: 'image:advanced;link:advanced'
                      }}*/
                       /><br />
                        <Label htmlFor="title">Upload Image</Label>
                        <InputGroup style={{border: '1px solid #e4e7ea', borderRadius:'5px', background:'#efefef', padding:'5px'}}>
                          <Input type="file" name="mediaFile" id="mediaFile" style={{width:'50%', display:'inline-block'}} onChange={(event) => this.setImage(event)} />
                          <InputGroupAddon addonType="prepend ">
                            <Button onClick={() => { this.uploadImage() }}>Upload Image</Button>
                          </InputGroupAddon>
                        </InputGroup>
                        <Label htmlFor="title" style={{marginTop:'10px'}}>Uploaded Images List</Label>
                          <Row style={{height:400, overflow:'hidden', overflowY:'scroll', background:'#c8ced3'}} >
                            {imagesForEmail && imagesForEmail.length && imagesForEmail.map((item, index)=>{
                                return <Col sm="12" key={index}>
                                  <Row style={{background:'#efefef', marginBottom: '3px',}} className={(copied === index ? 'copied-url': '')}>
                                    <Col sm="4">
                                      <div style={{margin:'10px 0', border:'2px solid #efefef', borderRadius: '12px', overflow: 'hidden'}}>
                                        <img src={this.cloudFrontUrl+item.Key+signedQueryString} alt={this.cloudFrontUrl+item.Key} />
                                      </div>
                                    </Col>
                                    <Col sm="8" className="url-col">
                                      <div style={{background:'#efefef', padding:5}}>{this.cloudFrontUrl+item.Key+signedQueryString}</div>
                                      <Button  color="primary" onClick={() => {this.copyUrl(this.cloudFrontUrl+item.Key, index+signedQueryString)}}>Copy URL</Button>
                                    </Col>
                                  </Row>


                                </Col>
                            })}
                          </Row>
                        <div className="validation-error">{ this.validator.message('Sub-Headline', this.state.subHeadline, 'required') }</div>
                        </Col>
                        </Row>


                  {/*<Row>
                                      <Col sm="12">
                                        <Label htmlFor="title">Content</Label>
                                       <Input type="textarea" id="content"  placeholder="Content" name="content" value={this.state.content} onChange={($event)=>{this.handleChange(event)}} rows="20" />
                                         <div className="validation-error">{ this.validator.message('Content', this.state.content, 'required') }</div>
                                      </Col>
                                    </Row>*/}
                                          {(this.state.errorMessage) ? <Alert color="danger">{this.state.errorMessage}</Alert> : null}
                </Col>
                <Col sm="6">
                  <EmailLayout headline={headline} subHeadline={subHeadline} content={content}>

                  </EmailLayout>
                </Col>
              </Row>
            </ModalBody>
            <ModalFooter className="justify-content-left">
              <Button type="submit" size="sm" color="primary">Save</Button>{' '}
              <Button type="reset" size="sm" color="danger"  onClick={() => { this.props.closeModel() }}>Close</Button>
            </ModalFooter>
            </Form>
        </Modal>

    );
  }
}

export default EditSystemEmail;
