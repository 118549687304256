import React, { Component } from 'react';
import { Loader } from '../../utils/common';
import { Input, Button, Popover, PopoverHeader, PopoverBody } from "reactstrap";
import Session from '../../utils/session';
import * as _ from 'underscore'

class ExportParent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userService: null,
      columnFilterOption: false,
      columns: []
    }
  }

  componentDidMount() {
    let configSession = Session.getSessionData('config');
    if(configSession && configSession.SERVICES){
        let userService = _.where(configSession.SERVICES, {SERVICE: "USER"});
        userService = userService && userService.length > 0 ? userService[0] : null;
        this.setState({userService});
    }
  }

  toggleColumnFilter() {
    this.setState({
      columnFilterOption: !this.state.columnFilterOption
    });
  }

  downloadUserList(){
    let {userService, columns} = this.state;
    let {search, subscriptionType, fromDate, toDate} = this.props;
    this.setState({columnFilterOption: false});
    if(userService && Object.keys(userService).length > 0){
       Loader(true);
       let columnFilter = columns ? columns.toString() : '';
       this.props._getTokenToExportUserList({column: columnFilter, filter: {search, subscriptionType, fromDate, toDate}}).then((response)=>{
         if(response && response.statusCode === 200){
             const host = userService['HOST'];
             const version = process.env.REACT_APP_CURRENT_API_VERSION;
             const currentDateTime = new Date().getTime();
             let url = host+'/'+version+'/export_user?token='+response.result+'&date='+currentDateTime;
             Loader(false);
             try{
               this.setState({columns: []}, ()=>{
                 window.open(url, '_blank').focus();
               })
             }catch(error){
              this.setState({ isError: true, screenErrMsg : error });   
             }
          }else{
            this.setState({ isError: true, screenErrMsg : response.statusDescription });
          }
       }, error => {
         Loader(false);
         this.setState({ isError: true, screenErrMsg : JSON.stringify(error) });
       })
    }
  }

  toggleColumn(event){
    let {columns} = this.state;
    let name = event.target.name;
    //let value = event.target.value;
    let columnIndex = columns.indexOf(name);
    if(columnIndex === -1){
      columns.push(name);
    }else{
      columns.splice(columnIndex, 1);
    }
    this.setState({columns})    
  }




  render() {
    const {columns} = this.state;
    return (
      <div className="content">
        <Button id="columnFilterSection" type="button"  onClick={()=>{this.toggleColumnFilter()}}> Download: CSV </Button>
        <Popover placement="bottom" isOpen={this.state.columnFilterOption} target="columnFilterSection" toggle={()=>{this.toggleColumnFilter()}}>
          <PopoverHeader>Download: CSV</PopoverHeader>
          <PopoverBody>
            <div className="row" style={{padding: 10}}>
              <label><Input type="checkbox" defaultChecked={columns.indexOf('email') !== -1 ? true: false} onClick={(e)=>{this.toggleColumn(e)}} value="email" name="email" />Email</label>
              {/*<label><Input type="checkbox" defaultChecked={columns.indexOf('childName') !== -1 ? true: false} onClick={(e)=>{this.toggleColumn(e)}} value="childName" name="childName" />Child Name</label>*/}
              <label><Input type="checkbox" defaultChecked={columns.indexOf('childCount') !== -1 ? true: false}onClick={(e)=>{this.toggleColumn(e)}} value="childCount" name="childCount" />Child Count</label>
              {/*<label><Input type="checkbox" defaultChecked={columns.indexOf('modeOfSignup') !== -1 ? true: false}onClick={(e)=>{this.toggleColumn(e)}} value="modeOfSignup" name="modeOfSignup" />Mode Of Signup</label>*/}
              <label><Input type="checkbox" defaultChecked={columns.indexOf('isVerified') !== -1 ? true: false}onClick={(e)=>{this.toggleColumn(e)}} value="isVerified" name="isVerified" />Is Verified</label>
              <label><Input type="checkbox" defaultChecked={columns.indexOf('status') !== -1 ? true: false}onClick={(e)=>{this.toggleColumn(e)}} value="status" name="status" />Status</label>
              <label><Input type="checkbox" defaultChecked={columns.indexOf('isParent') !== -1 ? true: false}onClick={(e)=>{this.toggleColumn(e)}} value="isParent" name="isParent" />Is Parent</label>
              <label><Input type="checkbox" defaultChecked={columns.indexOf('subscription') !== -1 ? true: false}onClick={(e)=>{this.toggleColumn(e)}} value="subscription" name="subscription" />Subscription</label>
              <label><Input type="checkbox" defaultChecked={columns.indexOf('timezone') !== -1 ? true: false}onClick={(e)=>{this.toggleColumn(e)}} value="timezone" name="timezone" />Timezone</label>
              <label><Input type="checkbox" defaultChecked={columns.indexOf('isTest') !== -1 ? true: false}onClick={(e)=>{this.toggleColumn(e)}} value="isTest" name="isTest" />Test User</label>
            </div>
            <div className="row" style={{padding: 10, float: 'right'}}>
              <Button id="columnFilterSection" className="btn btn-primary" type="button"  onClick={(e)=>{this.downloadUserList();e.preventDefault()}}> Download </Button>
            </div>
          </PopoverBody>
        </Popover>
        
      </div>
    );
  }
}

export default ExportParent;
