import { createAction } from 'redux-act';
import store from './../store';
import config from '../config';
import httpClient from '../services/http';
import session from '../utils/session';

export const requestLoaderVisibility = createAction();
export const requestSuccessMessage = createAction();
export const requestErrorMessage = createAction();



export function SuccessToast(message) {
  store.dispatch(requestSuccessMessage(message));
  setTimeout(() => {
    store.dispatch(requestSuccessMessage(''));
  }, 3000)
}

export function ErrorToast(message) {
  store.dispatch(requestErrorMessage(message));
  setTimeout(() => {
    store.dispatch(requestErrorMessage(''));
  }, 3000)
}

export function Loader(isVisible, processBarPercent) {
  let param = {
    isVisible: isVisible,
    processBarPercent: processBarPercent
  }
  store.dispatch(requestLoaderVisibility(param))
}


export function serialize(obj) {
  var str = [];
  for (var p in obj)
    if (obj.hasOwnProperty.call(p)) {
      str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
    }
  return str.join("&");
}

export function setHttpClientConfig() {
  return new Promise(function (resolve, reject) {
    let sessionData = session.getSessionData();
    let headers = {};
    if (sessionData && sessionData['jwtToken']) {
      headers['Authorization'] = sessionData ? 'Bearer ' + sessionData['jwtToken'] : '';
    }
    headers['X-ADMIN-KEY'] = config.API_KEY;
    headers['X-Content-Type-Options'] = "nosniff";
    headers['Accept'] = "application/json";
    headers['Content-Type'] = 'application/json;charset=utf-8';
    httpClient.setConfig({
      API_URL: config.API_URL,
      HEADERS: headers
    });
    // httpClient.getAppConfig();
    resolve(true);
  });
}


export function cleanString(stringVal) {
  return stringVal
    .replace(/&amp;/g, '')
    .replace(/"/g, '&quot;')
    .replace(/#/g, '&num;')
    .replace(/'/g, '&apos;')
    .replace(/</g, '&lt;')
    .replace(/>/g, '&gt;');
}


export function FormatTitlePosLabel(s) {
  if (typeof s !== 'string') { return '' }
  s = s.replace(/_/g, " ");
  s = s.charAt(0).toUpperCase() + s.slice(1).toLowerCase();
  return s;
}

export function createRandomStr(cb) {
  cb(Math.random().toString(36).substring(7));
}


export function createCouponCode() {
  return (Math.random().toString(36).substring(2, 8)).toUpperCase()
}

export function roleBasedSideBar(roles) {
  let MenuItem = {
    ADMIN: ['parent', 'analytics', 'pillar', 'pillar-categories', 'pillar-content', 'daily-quest', 'notifications', 'system-email', 'discount', 'trophy', 'payment-history', 'users-feedback', 'app-versions', 'app-static-languages', 'settings', 'email-campaign', 'whatsapp-campaign', 'user-notification', 'admin-users', 'settings', 'free-printable', 'vimeo', 'playlist', 'customised-dashboard', 'marketing', 'whats-new'],
    AUTHOR: ['author-content'],
    MARKETING: ['/']
  };

  if (roles && roles.length > 0) {
    return MenuItem[roles[0]];
  }
  return ['/'];

}
