import { connect } from 'react-redux';

import Parent from './Parent';
import { getParents, getChildren,changeUserStatus, verifyUser, sendVerifivationLink, changeUserSubscription, getTokenToExportUserList, changeUserRecordingStatus, deleteParent } from './action';


const mapStateToProps = (state) => {

		var stateData = {
			requestProcess: state.parent.requestProcess
		};

		if(state.parent.data){
			stateData['data'] = state.parent.data;
		}

		if(state.parent.childData){
			stateData['childData'] = state.parent.childData;
		}

		if(state.parent.childData){
			stateData['childData'] = state.parent.childData;
		}
		if(state.parent.changeStatus){
			stateData['changeStatus'] = state.parent.changeStatus;
		}


		if(state.parent.userVerified){
			stateData['userVerified'] = state.parent.userVerified;
		}

		if(state.parent.verificationLinkSend){
			stateData['verificationLinkSend'] = state.parent.verificationLinkSend;
		}


		if(state.parent.subscriptionChanged){
			stateData['subscriptionChanged'] = state.parent.subscriptionChanged;
		}

    if(state.parent.parentDeletedResponse){
			stateData['parentDeletedResponse'] = state.parent.parentDeletedResponse;
		}

		return stateData;
};

const mapDispatchToProps = (dispatch) => {
	return {
		_getParents: (params) => {
			dispatch(getParents(params));
		},

		_deleteParent: (params) => {
			dispatch(deleteParent(params));
		},

		_getChildren: (param) => {
			dispatch(getChildren(param));
		},

		_changeUserStatus: (params) => {
			dispatch(changeUserStatus(params))
		},


		_verifyUser: (params) => {
			dispatch(verifyUser(params))
		},

		_sendVerifivationLink: (params) => {
			dispatch(sendVerifivationLink(params))
		},
		_changeUserSubscription: (params) => {
			dispatch(changeUserSubscription(params))
		},

		_getTokenToExportUserList: (params) => {
			return getTokenToExportUserList(params);
		},

		_changeUserRecordingStatus: (params) => {
			return dispatch(changeUserRecordingStatus(params));
		}

	};
};


export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Parent);
