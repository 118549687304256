import React, { Component } from 'react';
import { connect } from 'react-redux';

class Message extends Component {

  constructor(props) {
      super(props);
      this.state = {
          message: '',
          type: ''
      }
  }

  componentWillReceiveProps(nextProps) {
    if(nextProps.messageObj && typeof nextProps.messageObj.message !== undefined){
        this.setState(nextProps.messageObj)
    }
  }

  render() {
        let _self = this;
        let {message, type} = _self.state;
        if(typeof message == 'object'){
          message = JSON.stringify(message)
        }
        return (
          <div className={type+" toast"} >
            {message &&
              <div className="snackbar show "><div>{message}</div></div>
            }
          </div>
        )
    }
}

const mapStateToProps = (state) => {
	return {
    messageObj: state.messageObj
  }
};

const mapDispatchToProps = (dispatch) => {
	return {};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Message);
