import {createAction} from 'redux-act';
import httpClient from '../../services/http';	



export const requestVersionData = createAction();
export const responseVersionData = createAction();


export const requestServicesData = createAction();
export const responseServicesData = createAction();

export const requestupdateBuildVersion = createAction();
export const responserequestupdateBuildVersion = createAction();

export function getAppVersion(params){
  return (dispatch) => {
      dispatch(requestVersionData([]));
      httpClient.call('CONFIG','/get_app_versions', params, {method: 'GET', headers: {}}).then(function(response){
          dispatch(responseVersionData(response));
      }, function(error){
          dispatch(responseVersionData(error));
      });
  }
}


export function getAllservices(params){
  return (dispatch) => {
      dispatch(requestServicesData([]));
      httpClient.call('CONFIG','/get', params, {method: 'GET', headers: {}}).then(function(response){
          dispatch(responseServicesData(response));
      }, function(error){
          dispatch(responseServicesData(error));
      });
  }
}


export function updateBuildVersion(params){
  return (dispatch) => {
      dispatch(requestServicesData([]));
      httpClient.call('CONFIG','/update_version', params, {method: 'PUT', headers: {}}).then(function(response){
          dispatch(responseServicesData(response));
      }, function(error){
          dispatch(responseServicesData(error));
      });
  }
}
