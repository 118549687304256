import {createAction} from 'redux-act';
import httpClient from '../../services/http';	



export const requestFeaturesData = createAction();
export const responseFeaturesData = createAction();

export const requestUpdateFeature = createAction();
export const responseUpdateFeature = createAction();

export const requestGetSettings = createAction();
export const responseGetSettings = createAction();


export const requestUpdateSettings = createAction();
export const responseUpdateSettings = createAction();

export const requestAddSettings = createAction();
export const responseAddSettings = createAction();

export const requestServicesData = createAction();
export const responseServicesData = createAction();

export const requestAddService = createAction();
export const responseAddService = createAction();

export const requestUpdateService = createAction();
export const responseUpdateService = createAction();


export const requestGetUtility = createAction();
export const responseGetUtility = createAction();


export const requestAddUtility = createAction();
export const responseAddUtility = createAction();


export const requestUpdateUtility = createAction();
export const responseUpdateUtility = createAction();


export function getAppFeatures(params){
  return (dispatch) => {
      dispatch(requestFeaturesData([]));
      httpClient.call('CONFIG','/get_features', params, {method: 'GET', headers: {}}).then(function(response){
          dispatch(responseFeaturesData(response));
      }, function(error){
          dispatch(responseFeaturesData(error));
      });
  }
}


export function updateFeature(params){
  return (dispatch) => {
      dispatch(requestUpdateFeature([]));
      httpClient.call('CONFIG','/update_feature', params, {method: 'PUT', headers: {}}).then(function(response){
          dispatch(responseUpdateFeature(response));
      }, function(error){
          dispatch(responseUpdateFeature(error));
      });
  }
}


export function getAllSettings(params){
  return (dispatch) => {
      dispatch(requestGetSettings([]));
      httpClient.call('USER','/get_setting', params, {method: 'GET', headers: {}}).then(function(response){
          dispatch(responseGetSettings(response));
      }, function(error){
          dispatch(responseGetSettings(error));
      });
  }
}

export function updateSettings(params){
  return (dispatch) => {
      dispatch(requestUpdateSettings([]));
      httpClient.call('USER','/update_setting', params, {method: 'PUT', headers: {}}).then(function(response){
          dispatch(responseUpdateSettings(response));
      }, function(error){
          dispatch(responseUpdateSettings(error));
      });
  }
}

export function addSettings(params){
  return (dispatch) => {
      dispatch(requestAddSettings([]));
      httpClient.call('USER','/add_setting', params, {method: 'POSt', headers: {}}).then(function(response){
          dispatch(responseAddSettings(response));
      }, function(error){
          dispatch(responseAddSettings(error));
      });
  }
}


export function getAllServices(params){
  return (dispatch) => {
      dispatch(requestServicesData([]));
      httpClient.call('CONFIG','/get_all_services', params, {method: 'GET', headers: {}}).then(function(response){
          dispatch(responseServicesData(response));
      }, function(error){
          dispatch(responseServicesData(error));
      });
  }
}


export function addService(params){
  return (dispatch) => {
      dispatch(requestAddService([]));
      httpClient.call('CONFIG','/add', params, {method: 'POST', headers: {}}).then(function(response){
          dispatch(responseAddService(response));
      }, function(error){
          dispatch(responseAddService(error));
      });
  }
}



export function updateService(params){
  return (dispatch) => {
      dispatch(requestUpdateService([]));
      httpClient.call('CONFIG','/update', params, {method: 'PUT', headers: {}}).then(function(response){
          dispatch(responseUpdateService(response));
      }, function(error){
          dispatch(responseUpdateService(error));
      });
  }
}



export function getAllUtilities(params){
  return (dispatch) => {
      dispatch(requestGetUtility([]));
      httpClient.call('CONFIG','/get_all_utilities', params, {method: 'GET', headers: {}}).then(function(response){
          dispatch(responseGetUtility(response));
      }, function(error){
          dispatch(responseGetUtility(error));
      });
  }
}




export function updateAppUtility(params){
  return (dispatch) => {
      dispatch(requestUpdateUtility([]));
      httpClient.call('CONFIG','/update_utility', params, {method: 'PUT', headers: {}}).then(function(response){
          dispatch(responseUpdateUtility(response));
      }, function(error){
          dispatch(responseUpdateUtility(error));
      });
  }
}



export function addAppUtility(params){
  return (dispatch) => {
      dispatch(requestAddUtility([]));
      httpClient.call('CONFIG','/add_utility', params, {method: 'POST', headers: {}}).then(function(response){
          dispatch(responseAddUtility(response));
      }, function(error){
          dispatch(responseAddUtility(error));
      });
  }
}
