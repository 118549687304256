import {createAction} from 'redux-act';
import httpClient from '../../services/http';	



export const requestGetPrintable = createAction();
export const responseGetPrintable = createAction();

export const requestAddPrintable = createAction();
export const responseAddPrintable = createAction();

export function getAllFeePrintable(params){
  return (dispatch) => {
      dispatch(requestGetPrintable([]));
      httpClient.call('USER','/get_all_printables', params, {method: 'GET', headers: {}}).then(function(response){
          dispatch(responseGetPrintable(response));
      }, function(error){
          dispatch(responseGetPrintable(error));
      });
  }
}

export function addPrintable(params){
  return httpClient.call('USER', '/add_printable', params, {method: 'POST', headers: {}});
}

export function updatePrintable(params){
  return httpClient.call('USER', '/update_printable', params, {method: 'POST', headers: {}});
}

export function deleteAdminUser(params){
    return httpClient.call('USER', '/delete_printables', params, {method: 'DELETE', headers: {}})
}

