import React, { Component } from 'react';
import ReactTags from 'react-tag-autocomplete'

const KeyCodes = {
  comma: 188,
  enter: 13,
};

const delimiters = [KeyCodes.comma, KeyCodes.enter];

class Tags extends Component {
  constructor(props) {

    super(props);
    this.state = {     
      tags: props.tags,
      suggestions: [],
      userSocialConnectId: props.userSocialConnectId,
    }
    this.timeout = false;
  }


  componentWillReceiveProps(nextProps) {
    
  }

  updateTags(){
    const self = this;
    const {tags, userSocialConnectId} = this.state;
    if (self.timeout) { clearTimeout(self.timeout) }
    self.timeout = setTimeout(() => {
      self.props._updateSocialProfileTags({tags, userSocialConnectId});
    }, 3000)

  }

  handleDelete(i) {
    const { tags } = this.state;
    this.setState({tags: tags.filter((tag, index) => index !== i)}, ()=>{
      this.updateTags()
    });
  }

  handleAddition(tag) {
    this.setState(state => ({ tags: [...state.tags, tag] }), ()=>{
      this.updateTags();
    });
  }



  render() {
    let { tags, suggestions } = this.state;
    
    return (
      <ReactTags tags={tags}
        allowNew={true}
        suggestions={suggestions}
        handleDelete={(index)=>{this.handleDelete(index)}}
        handleAddition={(tag) => {this.handleAddition(tag)}}
        delimiters={delimiters} />
    );
  }
}

export default Tags;
