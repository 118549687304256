import {createAction} from 'redux-act';
import httpClient from '../../services/http';


export const requestGetUsers = createAction();
export const responseGetUsers = createAction();

export const requestImportData = createAction();
export const responseImportData = createAction();


export const requestSendSingleWhatsAppMessage = createAction();
export const responseSendSingleWhatsAppMessage = createAction();

export const requestSendSingleWhatsAppMessageToAll = createAction();
export const responseSendSingleWhatsAppMessageToAll = createAction();



export const requestGetCampaignList = createAction();
export const responseGetCampaignList = createAction();



export function getUsers(){
  return (dispatch) => {
    dispatch(requestGetUsers([]));
    httpClient.call('CAMPAIGN','/get_users', {}  , {method: 'GET', headers: {'content-type': 'application/json'}}).then(function(response){
        dispatch(responseGetUsers(response));
    }, function(error){
        dispatch(responseGetUsers(error));
    });
  }
}

export function uploadCSV(params, processCallBack){
    return (dispatch) => {
      dispatch(requestImportData([]));
      httpClient.call('CAMPAIGN', '/import_users', params, {method: 'POST', headers: {'content-type': 'multipart/form-data'}}, processCallBack).then(function(response){
          dispatch(responseImportData(response));
      }, function(error){
          dispatch(responseImportData(error));
      });
    }
}


export function sendSingleWhatsAppMessage(params){
    return (dispatch) => {
      dispatch(requestSendSingleWhatsAppMessage([]));
      httpClient.call("CAMPAIGN","/send_single_whats_app_message/" , params, {method: 'POST', headers: {'content-type': 'application/json'}}).then(function(response){
          dispatch(responseSendSingleWhatsAppMessage(response));
      }, function(error){
          dispatch(responseSendSingleWhatsAppMessage(error));
      });
  }
}


export function sendMessageToAll(params){
    return (dispatch) => {
      dispatch(requestSendSingleWhatsAppMessageToAll([]));
      httpClient.call("CAMPAIGN","/add_message_into_queue/" , params, {method: 'POST', headers: {'content-type': 'application/json'}}).then(function(response){
          dispatch(responseSendSingleWhatsAppMessageToAll(response));
      }, function(error){
          dispatch(responseSendSingleWhatsAppMessageToAll(error));
      });
    }
}


export function getCampaignList(params){
    return (dispatch) => {
      dispatch(requestGetCampaignList([]));
      httpClient.call("CAMPAIGN","/get_campaign_list/" , params, {method: 'GET', headers: {'content-type': 'application/json'}}).then(function(response){
          dispatch(responseGetCampaignList(response));
      }, function(error){
          dispatch(responseGetCampaignList(error));
      });
    }
}

