import React, { Component } from 'react';
import { Loader } from '../../utils/common';
import { Row, Col, TabContent, TabPane, Nav, NavItem, NavLink, Input, Label, FormGroup, Alert } from "reactstrap";
import classnames from 'classnames';
import { each } from 'underscore';
import List, { ItemDragging } from 'devextreme-react/list';
import httpClient from '../../services/http';
import CategoryList from './CategoryList';

import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
const contentType = { "NEW_CONTENT": "NEW_CONTENT", "TRENDING_CONTENT": "TRENDING_CONTENT", "CUSTOM_CONTENT": "CUSTOM_CONTENT" };
const settings = { "MANUAL": "MANUAL", "AUTOMATIC": "AUTOMATIC" };
const currentPageSetting = { "TRENDING_CONTENT_SETTING": "TRENDING_CONTENT_SETTING", "NEW_CONTENT_SETTING": "NEW_CONTENT_SETTING", "CUSTOM_CONTENT_SETTING": "CUSTOM_CONTENT_SETTING" };

class TrendingContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      trendingContent: [],
      content: [],
      searchField: '',
      activeTab: contentType.TRENDING_CONTENT,
      currentSetting: currentPageSetting.TRENDING_CONTENT_SETTING,
      selectedTrendingSetting: settings.AUTOMATIC,
      settingId: null,
      pillarId: "",
      categoryId : "",
      subSlug: '',
      customContent: [],
      screenError:""
    };
    this.onDragStart = this.onDragStart.bind(this);
    this.onAdd = this.onAdd.bind(this);
    this.onRemove = this.onRemove.bind(this);
    this.onReorder = this.onReorder.bind(this);
    this.timeout = null;
  }

  handleChange(event){
    let value = event.target.value;
    this.setState({pillarId : value});
  }
  categoryChange(event){
    let value = event.target.value;
    //console.log(this.state.subSlug)
    this.setState({searchField : '', categoryId : value, offset: 0, page: 0, screenError: ''}, ()=>{this.getContent(this.state.subSlug)});    
  }

  componentDidMount() {
    this.getCurrentSetting();
  }

  getCustomModule() {
    Loader(true);
    let params= {
      admin: true,
      customContent: true
    }
    httpClient.call('PILLAR', '/get_custom_modules', params, { method: 'GET', headers: {} }).then((response) => {
      if (response && response.result && response.statusCode == 200) {
        let subSlugVar = '';
        this.setState({
          customContent: response.result.map((obj, index) => {
            if(index == 0){
                subSlugVar  = obj.slug
            }
            return (<option key={obj.slug} value={obj.slug} >{obj.title}</option>);}),
        }, () => {
          this.setState({subSlug : subSlugVar})
          this.getContent(subSlugVar);
        })
      } else {
        console.error("Error code with status code!");
      }
      Loader(false)
    }, function (error) {
      console.error(error);
    });
  }

  searchContent(event) {
    let self = this;
    if (self.timeout) {
      clearTimeout(self.timeout);
    }
    let searchField = event.target.value;
    self.setState({ searchField, screenError : '' }, () => {
      self.timeout = setTimeout(() => {
        self.getContent(this.state.subSlug)
      }, 1000)
    })

  }

  getCurrentSetting() {
    Loader(true);
    let params = {
      slug: this.state.currentSetting
    }
    httpClient.call('PILLAR', '/get_all_settings', params, { method: 'GET', headers: {} }).then((response) => {
      if (response && response.statusCode == 200) {
        this.setState({
          selectedTrendingSetting: response.result && response.result.length ? response.result[0].value : "",
          settingId: response.result && response.result.length ? response.result[0]._id : "",
        }, () => {
          if(this.state.activeTab == 'CUSTOM_CONTENT'){
            this.getCustomModule();
            //this.getContent();
          } else {
            this.getContent();
          }
        })
      } else {
        console.error("Error code with status code!");
      }
    }, function (error) {
      console.error(error);
    });
  }

  getContent(subSlugVar='') {
    let params = {
      slug: this.state.activeTab,
      search: this.state.searchField,
      categoryId: this.state.categoryId,
      subSlug: this.state.activeTab == 'CUSTOM_CONTENT' ? subSlugVar : '',
      limit: 1000,
      offset: 0,
    }
    Loader(true);
    httpClient.call('PILLAR', '/get_trending_content_data', params, { method: 'GET', headers: {} }).then((response) => {
      if (response && response.statusCode == 200) {
        this.setState({
          content: response.result && response.result.fullData ? response.result.fullData : [],
          trendingContent: response.result && response.result.selectedData ? response.result.selectedData : [],
        })
      } else {
        console.error("Error code with status code!");
      }
      Loader(false);
    }, function (error) {
      console.error(error);
      Loader(false);
    });
  }

  onDragStart(e) {
    e.itemData = this.state[e.fromData][e.fromIndex];
  }
  onAdd(e) {
    if(this.state.trendingContent.length >= 10){
      this.setState({
        screenError: 'You can not add more than 10 content.'
      })
    } else {
      const tasks = this.state[e.toData];

      /* updating state */
      this.setState({
        [e.toData]: [...tasks.slice(0, e.toIndex), e.itemData, ...tasks.slice(e.toIndex)],
        screenError:''
      });

      /* Calling api for updating data */
      let payload = [];
      each(this.state.trendingContent, (value, index) => {
        value['order'] = index;
        payload.push(value);
      });

      console.log("payload")
      console.log(payload)

      Loader(true);
      httpClient.call('PILLAR', '/update_trending_new_content', { slug: this.state.activeTab == 'CUSTOM_CONTENT' ? this.state.subSlug : this.state.activeTab, content: payload }, { method: 'PUT', headers: {} }).then((response) => {
        Loader(false);
      }, function (error) {
        console.error(error);
        Loader(false);
      });
    }
    

  }
  onRemove(e) {
    const tasks = this.state[e.fromData];
    this.setState({
      [e.fromData]: [...tasks.slice(0, e.fromIndex), ...tasks.slice(e.fromIndex + 1)]
    });
  }
  onReorder(e) {
    this.onRemove(e);
    this.onAdd(e);
  }

  toggle = (tab, currentSetting) => {
    let { activeTab } = this.state;
    this.setState({
      categoryId: '',
      subSlug:'',
      screenError:''
    })
    if (activeTab !== tab) this.setState({ activeTab: tab, currentSetting }, () => {
      this.getCurrentSetting();
    })
  }

  changeSetting(event) {
    this.setState({ selectedTrendingSetting: event.target.value }, () => {
      this.getContent();
      let params = {
        settingId: this.state.settingId,
        value: this.state.selectedTrendingSetting
      }
      Loader(true);
      httpClient.call('PILLAR', '/update_setting', params, { method: 'PUT', headers: {} }).then((response) => {
        if (!response || response.statusCode != 200) {
          console.error("Error code with status code!");
        }
        Loader(false);
      }, function (error) {
        console.error(error);
        Loader(false);
      });

    });
  }

  handleSlug(event) {
    let value = event.target.value;
    this.setState({ subSlug: value, screenError: '' }, () => { this.getContent(value); })
  }

  render() {
    let self = this;
    let { trendingContent, content, activeTab, screenError, selectedTrendingSetting, customContent } = this.state;
    //console.log(customContent);

    return (
      <div>
        <div className="content">
          <Row className="pb-4">
            <Col lg={10} sm={10}>
              <h3>Trending Content</h3>
            </Col>
            {screenError && <Alert color="danger">
          {screenError}
        </Alert>}
          </Row>
          <Row className="trending-content-row-wrapper">
            <Col lg={12}>
              <div>
                <Nav tabs>
                  <NavItem>
                    <NavLink className={classnames({ active: activeTab === contentType.TRENDING_CONTENT })} onClick={() => { this.toggle(contentType.TRENDING_CONTENT, currentPageSetting.TRENDING_CONTENT_SETTING) }}>Trending Content</NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink className={classnames({ active: activeTab === contentType.NEW_CONTENT })} onClick={() => { this.toggle(contentType.NEW_CONTENT, currentPageSetting.NEW_CONTENT_SETTING) }}>New Content</NavLink>
                  </NavItem> 
                  <NavItem>
                    <NavLink className={classnames({ active: activeTab === contentType.CUSTOM_CONTENT })} onClick={() => { this.toggle(contentType.CUSTOM_CONTENT, currentPageSetting.CUSTOM_CONTENT_SETTING) }}>Custom Content</NavLink>
                  </NavItem>
                </Nav>
                <TabContent activeTab={activeTab}>
                  <TabPane tabId={contentType.TRENDING_CONTENT}>
                    {activeTab == contentType.TRENDING_CONTENT &&
                      <>
                        <Row className={'pl-5'}>
                          <Col lg={10}></Col>
                          <Col lg={1} sm={1}>
                            <Label htmlFor="selectedTrendingSettingAutometic">
                              <Input type="radio" name="selectedTrendingSetting" id="selectedTrendingSettingAutometic" checked={selectedTrendingSetting == settings.AUTOMATIC} value={settings.AUTOMATIC} onChange={(event) => this.changeSetting(event)} />{' '}
                              {settings.AUTOMATIC}
                            </Label>
                          </Col>
                          <Col lg={1} sm={1}>
                            <Label htmlFor="selectedTrendingSettingManual">
                              <Input type="radio" name="selectedTrendingSetting" id="selectedTrendingSettingManual" checked={selectedTrendingSetting == settings.MANUAL} value={settings.MANUAL} onChange={(event) => this.changeSetting(event)} />{' '}
                              {settings.MANUAL}
                            </Label>
                          </Col>
                        </Row>
                        <Row>
                          <Col xs="4">
                            <FormGroup>
                              <Label htmlFor="searchField">Search Field</Label>
                              <Input
                                type="searchField"
                                name="searchField"
                                id="searchField"
                                placeholder={'Search by content title'}
                                onChange={($event) => {
                                  this.searchContent($event);
                                }}
                              ></Input>
                            </FormGroup>
                          </Col>
                          <Col xs={3}>
                            <Label htmlFor="">Category</Label>   
                            <CategoryList selectedPillarId={this.state.pillarId} selectedCategoryId={this.state.categoryId} categoryChange={(event)=>{this.categoryChange(event)}}  /></Col>
                        </Row>

                      </>
                    }
                    <br />
                    <Row className="trending-content-row">

                      <Col lg={6}>
                        <div >
                          <List
                            items={content}
                            keyExpr="_id"
                            repaintChangesOnly={true}>
                            <ItemDragging
                              allowReordering={true}
                              group="tasks"
                              data="content"
                              onDragStart={this.onDragStart}
                              onAdd={this.onAdd}
                              onRemove={this.onRemove}
                              onReorder={this.onReorder}>
                            </ItemDragging>
                          </List>
                        </div>
                      </Col>
                      <Col lg={6}>
                        <div>
                          <List
                            items={trendingContent}
                            keyExpr="_id"
                            repaintChangesOnly={true}>
                            <ItemDragging
                              allowReordering={true}
                              group="tasks"
                              data="trendingContent"
                              onDragStart={this.onDragStart}
                              onAdd={this.onAdd}
                              onRemove={this.onRemove}
                              onReorder={this.onReorder}>
                            </ItemDragging>
                          </List>
                        </div>
                      </Col>
                    </Row>
                  </TabPane>
                  <TabPane tabId={contentType.NEW_CONTENT}>

                    {activeTab == contentType.NEW_CONTENT &&
                      <>
                        <Row className={'pl-5'}>
                          <Col lg={10}></Col>
                          <Col lg={1} sm={1}>
                            <Label htmlFor="selectedTrendingSettingAutometic">
                              <Input type="radio" name="selectedTrendingSetting" id="selectedTrendingSettingAutometic" checked={selectedTrendingSetting == settings.AUTOMATIC} value={settings.AUTOMATIC} onChange={(event) => this.changeSetting(event)} />{' '}
                              {settings.AUTOMATIC}
                            </Label>
                          </Col>
                          <Col lg={1} sm={1}>
                            <Label htmlFor="selectedTrendingSettingManual">
                              <Input type="radio" name="selectedTrendingSetting" id="selectedTrendingSettingManual" checked={selectedTrendingSetting == settings.MANUAL} value={settings.MANUAL} onChange={(event) => this.changeSetting(event)} />{' '}
                              {settings.MANUAL}
                            </Label>
                          </Col>
                        </Row>
                        <Row>
                          <Col xs="4">
                            <FormGroup>
                              <Label htmlFor="searchField">Search Field</Label>
                              <Input
                                type="searchField"
                                name="searchField"
                                id="searchField"
                                placeholder={'Search by content title'}
                                onChange={($event) => {
                                  this.searchContent($event);
                                }}
                              ></Input>
                            </FormGroup>
                          </Col>
                          <Col xs={3}>
                            <Label htmlFor="">Category</Label>   
                            <CategoryList selectedPillarId={this.state.pillarId} selectedCategoryId={this.state.categoryId} categoryChange={(event)=>{this.categoryChange(event)}}  /></Col>
                        </Row>
                      </>
                    }
                    <br />
                    <Row className="trending-content-row">
                      <Col lg={6}>
                        <div className="trending-content-list">
                          <List
                            items={content}
                            keyExpr="_id"
                            repaintChangesOnly={true}>
                            <ItemDragging
                              allowReordering={true}
                              group="tasks"
                              data="content"
                              onDragStart={this.onDragStart}
                              onAdd={this.onAdd}
                              onRemove={this.onRemove}
                              onReorder={this.onReorder}>
                            </ItemDragging>
                          </List>
                        </div>
                      </Col>
                      <Col lg={6}>
                        <div className="trending-content-list">
                          <List
                            items={trendingContent}
                            keyExpr="_id"
                            repaintChangesOnly={true}>
                            <ItemDragging
                              allowReordering={true}
                              group="tasks"
                              data="trendingContent"
                              onDragStart={this.onDragStart}
                              onAdd={this.onAdd}
                              onRemove={this.onRemove}
                              onReorder={this.onReorder}>
                            </ItemDragging>
                          </List>
                        </div>
                      </Col>
                    </Row>
                  </TabPane>
                  <TabPane tabId={contentType.CUSTOM_CONTENT}>
                    {activeTab == contentType.CUSTOM_CONTENT &&
                      <> 
                        <Row>
                        <Col sm={3}>
                        <Label htmlFor="customModule">Custom Module List</Label>
                          <Input type="select" name="Custom Module" id="customModule" 
                            value={this.state.slug}
                            onChange={($event) => {
                              this.handleSlug($event);
                            }} 
                            >
                            {customContent}
                          </Input> 
                        </Col>
                        <Col xs="4"></Col>
                        <Col xs="4"></Col>
                        </Row>
                        <br/>
                        <Row>
                          <Col xs="4">
                            <FormGroup>
                              <Label htmlFor="searchField">Search Field</Label>
                              <Input
                                type="searchField"
                                name="searchField"
                                id="searchField"
                                placeholder={'Search by content title'}
                                onChange={($event) => {
                                  this.searchContent($event);
                                }}
                              ></Input>
                            </FormGroup>
                          </Col>
                          <Col xs={3}>
                            <Label htmlFor="">Category</Label>   
                            <CategoryList selectedPillarId={this.state.pillarId} selectedCategoryId={this.state.categoryId} categoryChange={(event)=>{this.categoryChange(event)}}  /></Col>
                        </Row>

                      </>
                    }
                    <br />
                    <Row className="trending-content-row">

                      <Col lg={6}>
                        <div >
                          <List
                            items={content}
                            keyExpr="_id"
                            repaintChangesOnly={true}>
                            <ItemDragging
                              allowReordering={true}
                              group="tasks"
                              data="content"
                              onDragStart={this.onDragStart}
                              onAdd={this.onAdd}
                              onRemove={this.onRemove}
                              onReorder={this.onReorder}>
                            </ItemDragging>
                          </List>
                        </div>
                      </Col>
                      <Col lg={6}>
                        <div>
                          <List
                            items={trendingContent}
                            keyExpr="_id"
                            repaintChangesOnly={true}>
                            <ItemDragging
                              allowReordering={true}
                              group="tasks"
                              data="trendingContent"
                              onDragStart={this.onDragStart}
                              onAdd={this.onAdd}
                              onRemove={this.onRemove}
                              onReorder={this.onReorder}>
                            </ItemDragging>
                          </List>
                        </div>
                      </Col>
                    </Row>
                  </TabPane>
                </TabContent>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

export default TrendingContent;
