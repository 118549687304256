import { createReducer } from 'redux-act';
import { requestGetPaymentHistory, responseGetPaymentHistory } from './action';


const defaultState = {
	requestProcess: false,
};

const reducer = createReducer({

	[requestGetPaymentHistory]: (state) => {
		return {
			requestProcess: true,
		};
	},

	[responseGetPaymentHistory]: (state, params) => {
		return {
			requestProcess: false,
			paymentList: params,
		};
	}


}, defaultState);



export default reducer
