import { createReducer } from 'redux-act';
import { responsegetWhatsNewList, responsegetWhatsNewVersionList, responseDeleteWhatsNew } from './action';


const defaultState = {
	requestProcess: false,
};

const reducer = createReducer({

	[responsegetWhatsNewList]: (state, params) => {
		return {
			requestProcess: false,
      whatsNewList: params
		};
	},

  [responsegetWhatsNewVersionList]: (state, params) => {
		return {
			requestProcess: false,
      whatsNewVersionList: params
		};
	},

  [responseDeleteWhatsNew]: (state, params) => {
		return {
			requestProcess: false,
      whatsNewDeletedResponse: params
		};
	},
}, defaultState);



export default reducer
