import crypt from '../services/crypt';
import Constants from '../config/constants';



let Session = (function() {

  let getSessionData = function(key) {
    key = key || 'session'
    let storagedata = null;
    if (key === 'session' && getCookie('session')) {
      storagedata = getCookie('session');
    }else{
      storagedata = window.localStorage.getItem(key);
    }

    let decryptData = crypt.decript(storagedata)
    if(typeof decryptData === 'string'){
      decryptData = JSON.parse(decryptData);
    }
    if (decryptData && decryptData.status === 403) {
        deleteSessionData();
    }

    return decryptData;
  };

  let getSessionDataAsyncMode = function(key) {
    return new Promise((resolve, reject) => {
      key = key || 'session'
      let storagedata = null;
      if (key === 'session' && getCookie('session')) {
        storagedata = getCookie('session');
      }else{
        storagedata = window.localStorage.getItem(key);
      }

      let decryptData = crypt.decript(storagedata)
      if(typeof decryptData === 'string'){
        decryptData = JSON.parse(decryptData);
      }
      if (decryptData && decryptData.status === 403) {
          deleteSessionData();
      }
      resolve(decryptData);
    })
  };

  let isLoggedIn = function(){
    let session = getSessionData();
    return (session && session.jwtToken) ? true : false;
  }

  let setSessionData = function(sessionData, key) {
    let encryptData = crypt.encript(sessionData);
    key = key || 'session'
    if (key === 'session') {
      setCookie(key, encryptData);
    }else{
      window.localStorage.setItem(key, encryptData);
    }

  };


  let deleteSessionData = function(cb){
    deleteCookie('session');
    //deleteCookie('config');

    localStorage.removeItem('session');
    localStorage.removeItem('domain');
    //localStorage.clear();

    localStorage.setItem('session', null)
    localStorage.setItem('domain', null)
    if (cb) {
      cb(true)
    }
  }


  let setCookie = function(cname, cvalue, exdays) {
    var d = new Date();
    exdays = exdays || 365;
    d.setTime(d.getTime() + (exdays*24*60*60*1000));
    var expires = "expires="+ d.toUTCString();

    if (window.location.hostname === 'localhost') {
      document.cookie = cname + "=" + cvalue + ";" + expires+ ";domain="+window.location.hostname;
    }else{
      document.cookie = cname + "=" + cvalue + ";" + expires+ ";domain="+Constants.NINJAFOCUS_DOMAIN;
    }

  }


  let getCookie = function(cname, cvalue, exdays) {
    var value = "; " + document.cookie;
    var parts = value.split("; " + cname + "=");
    if (parts.length === 2) {
      return parts.pop().split(";").shift();
    }else {
      return "";
    }
  }

  let deleteCookie = function(cname) {
    var d = new Date();
    d.setTime(d.getTime());
    var expires = "expires="+ d.toUTCString();
    document.cookie = cname + "=" + "" + ";" + expires+ ";domain="+Constants.NINJAFOCUS_DOMAIN;
    document.cookie = cname + "=" + "" + ";" + expires+ ";domain="+window.location.hostname;
  };



  return {
    isLoggedIn: isLoggedIn,
    getSessionData: getSessionData,
    setSessionData: setSessionData,
    deleteSessionData: deleteSessionData,
    getSessionDataAsyncMode: getSessionDataAsyncMode
  }

})();

export default Session;
