import {createAction} from 'redux-act';
import httpClient from '../../../services/http';


export const requestSocialProfiles = createAction();
export const responseSocialProfiles = createAction();

export const requestAddUpdateUser = createAction();
export const responseAddUpdateUser = createAction();

export const requestGetSocialProfile = createAction();
export const responseGetSocialProfile = createAction();

export const requestFacebookPageStatusChange = createAction();
export const responseFacebookPageStatusChange = createAction();


export const requestInsightsGraphData = createAction();
export const responseInsightsGraphData = createAction();

export function getSocialProfiles(params){
	return (dispatch) => {
		dispatch(requestSocialProfiles([]));
		httpClient.call('MARKETING','/parent_list', params  , {method: 'GET', headers: {}}).then(function(response){
			dispatch(responseSocialProfiles(response));
		}, function(error){
			dispatch(responseSocialProfiles(error));
		});
	}
}

export function addUpdateUser(params){
	return (dispatch) => {
		dispatch(requestAddUpdateUser([]));
		httpClient.call('MARKETING','/add_update_user', params  , {method: 'POST', headers: {}}).then(function(response){
			dispatch(responseAddUpdateUser(response));
		}, function(error){
			dispatch(responseAddUpdateUser(error));
		});
	}
}

export function disconnectSocialProfile(params){
	return (dispatch) => {
		dispatch(requestAddUpdateUser([]));
		httpClient.call('MARKETING','/disconnect_account', params  , {method: 'POST', headers: {}}).then(function(response){
			dispatch(responseAddUpdateUser(response));
		}, function(error){
			dispatch(responseAddUpdateUser(error));
		});
	}
}

export function resetSocialSortUrl(params){
	return (dispatch) => {
		dispatch(requestAddUpdateUser([]));
		httpClient.call('MARKETING','/reset_short_url', params  , {method: 'PUT', headers: {}}).then(function(response){
			dispatch(responseAddUpdateUser(response));
		}, function(error){
			dispatch(responseAddUpdateUser(error));
		});
	}
}

export function getMySocialProfiles(params){
	return (dispatch) => {
		dispatch(requestGetSocialProfile([]));
		httpClient.call('MARKETING','/get_my_social_profiles', params  , {method: 'GET', headers: {}}).then(function(response){
			dispatch(responseGetSocialProfile(response));
		}, function(error){
			dispatch(responseGetSocialProfile(error));
		});
	}
}

export function applyFacebookPageChanges(params, tags){
	return (dispatch) => {
		dispatch(requestFacebookPageStatusChange([]));
		httpClient.call('MARKETING','/change_facebook_page_status', params, {method: 'POST', headers: {}}).then(function(response){
			dispatch(responseFacebookPageStatusChange(response));
		}, function(error){
			dispatch(responseFacebookPageStatusChange(error));
		});
	}
}

export function updateSocialProfileTags(params){
	return (dispatch) => {
		dispatch(requestFacebookPageStatusChange([]));
		httpClient.call('MARKETING','/update_social_profile_tags', params, {method: 'PUT', headers: {}}).then(function(response){
			dispatch(responseFacebookPageStatusChange(response));
		}, function(error){
			dispatch(responseFacebookPageStatusChange(error));
		});
	}
}

export function getInsightsGraphData(params){
	return (dispatch) => {
		dispatch(requestInsightsGraphData([]));
		httpClient.call('MARKETING','/get_social_insights_graph_data', params, {method: 'POST', headers: {}}).then(function(response){
			dispatch(responseInsightsGraphData(response));
		}, function(error){
			dispatch(responseInsightsGraphData(error));
		});
	}
}

export function getSocialShortLinks(params){
	return httpClient.call('MARKETING','/get_social_short_links', params, {method: 'GET', headers: {}});
}