import React, { Component } from 'react';
import { Loader } from '../../../utils/common';
import { Row, Col, Modal, Button, ModalHeader, ModalBody, ModalFooter, FormGroup, Label, Input, Form , Alert } from "reactstrap";
import { AppSwitch } from '@coreui/react'
 
import SimpleReactValidator from 'simple-react-validator';


class AddSystemLanguage extends Component {
  constructor(props) {
    super(props);
    this.validator = new SimpleReactValidator();
    this.state = {
      title :  "",
      code :  "",
      status :  true
    }
  }

  componentDidMount() {
  }

  componentWillReceiveProps(nextProps) {
    //let self = this;
    Loader(nextProps.requestProcess);
  }


  

  handleChange(event) {
    let target = event.target;
    let name = target.name;
    let value = target.value;
    if (name === 'status') {
      value = event.target.checked;
    }


    this.setState({[name]: value});
  }

  handleSubmit(e) {
    if( this.validator.allValid()){
      let params = {
        title : this.state.title,
        code : this.state.code,
        status : this.state.status,
      }  
      console.log(this.props)
      this.props.handleSubmit(e, params);
    }else{
      this.validator.showMessages();
      this.forceUpdate();
    }
    e.preventDefault();
  }

  



  render() {
   // let self = this;
    let { openAddLanguageModal, closeModal, popupError } = this.props;
    

    return (
      <Modal isOpen={openAddLanguageModal}>
          <ModalHeader toggle={()=>{closeModal(false)}}>Add System Language</ModalHeader>
          <Form onSubmit={(event) => {this.handleSubmit(event)}}>  
            <ModalBody>
              <Row>
                <Col xs="12">
                    <FormGroup>
                      <Label htmlFor="title">Language</Label>
                      <Input type="text" id="title" placeholder="Language Name" name="title" value={this.state.title} onChange={(event)=>{this.handleChange(event)}} />                                              
                      <div className="validation-error">{ this.validator.message('title', this.state.title, 'required') }</div>   
                    </FormGroup>
                </Col>
              </Row>


              <Row>
                <Col xs="12">
                    <FormGroup>
                      <Label htmlFor="code">Language code</Label>
                      <Input type="text" id="code" placeholder="Language Name" name="code" value={this.state.code} onChange={(event)=>{this.handleChange(event)}} />                                              
                      <div className="validation-error">{ this.validator.message('code', this.state.code, 'required') }</div>   
                    </FormGroup>
                </Col>
              </Row>


              <Row>
                  <Col xs="12">
                      <FormGroup>
                          <Label htmlFor="status">Status</Label>
                          <div>
                            <AppSwitch className={ 'mx-1'} variant={ 'pill'} color={ 'primary'}  id="status"  name="status"
                              onChange={($event)=>{this.handleChange($event)}}
                              checked={this.state.status}
                              value={this.state.status ? 'Active' : 'Inactive'} />
                          </div>
                      </FormGroup>
                  </Col>
              </Row>

              {(popupError) ? <Alert color="danger">{popupError}</Alert> : null}
            </ModalBody>
            <ModalFooter className="justify-content-left">
              <Button type="submit" size="sm" color="primary">Add</Button>{' '}
              <Button type="reset" size="sm" color="danger"  onClick={() => { closeModal() }}>Cancel</Button>
            </ModalFooter>
          </Form>
        </Modal>
      
    );
  }
}

export default AddSystemLanguage;
