import React, { Component } from 'react';
import { Row, Col, Modal, Button, ModalHeader, ModalBody, ModalFooter, FormGroup, Label, Input, Form, Alert } from "reactstrap";
import SimpleReactValidator from 'simple-react-validator';  
import httpClient from '../services/http';  



class CheckPasswordPopup extends Component {
  constructor(props) {
    super(props);
    this.validator = new SimpleReactValidator();
    this.state = {
      password :  "",
      showPasswordError :  false,
    }
  }

  componentDidMount() {
  }
  

  handleChange(event) {
    let target = event.target;
    let name = target.name;
    let value;
    value = target.value;
    this.setState({[name]: value});
  }

  handleSubmit(e) {
    this.setState({showPasswordError : false})
    if( this.validator.allValid()){
      if (this.state.password === process.env.REACT_APP_CONFIG_PASSWORD) {
        httpClient.setHeaderPassword(this.state.password);
        this.props.isPasswordMatched(true);
      }else {
        this.setState({showPasswordError : true})
        this.props.isPasswordMatched(false);
      }
    }else{
      this.validator.showMessages();
      this.forceUpdate();
    }
    e.preventDefault();
  }

  



  render() {
    let { openCheckPasswordModal, closeModal } = this.props;
    let { showPasswordError } = this.state;
    let { error } = this.state;
    

    return (
      <Modal isOpen={openCheckPasswordModal}>
          <ModalHeader toggle={()=>{closeModal(true)}}>Authorization</ModalHeader>
          <Form onSubmit={(event) => {this.handleSubmit(event)}}>  
            <ModalBody>
              <Row>
                <Col xs="12">
                    <FormGroup>
                      <Label htmlFor="title">Password Please</Label>
                      <Input  type="password" id="password" placeholder="password" name="password" value={this.state.password} onChange={(event)=>{this.handleChange(event)}} autoFocus />                                              
                      <div className="validation-error">{ this.validator.message('password', this.state.password, 'required') }</div>   
                    </FormGroup>
                </Col>
              </Row>

              {(showPasswordError) ? <Alert color="danger">Wrong Password</Alert> : null}
            </ModalBody>
            <ModalFooter className="justify-content-left">
              <Button type="submit" size="sm" color="primary">Submit</Button>{' '}
              <Button type="reset" size="sm" color="danger"  onClick={() => { closeModal(true) }}>Cancel</Button>
            </ModalFooter>
          </Form>
        </Modal>
      
    );
  }
}

export default CheckPasswordPopup;
