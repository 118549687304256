import React, { Component } from 'react';
import { Modal, ModalHeader, ModalBody, Table, Nav, NavItem, NavLink } from "reactstrap";
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit } from '@fortawesome/free-solid-svg-icons'
import * as _ from 'underscore';



class ShowAllTrophy extends Component {
  constructor(props) {
    super(props);
    this.state = { 
      type : (this.props && this.props.selectedRow && this.props.selectedRow.type) ? this.props.selectedRow.type : "",
      trophies : []
    }
  }

  componentDidMount() {
    let self = this;
    self.getSelectedTypeMilestones(self.state.type)
  }

  componentWillReceiveProps(nextProps) {
    let self = this
    self.state.trophies = [];
    if (nextProps.selectedTypeMilestones && nextProps.selectedTypeMilestones.length) {
      _.each(nextProps.selectedTypeMilestones, function(selectedMilestone, mIndex){
        selectedMilestone['selectedPillars'] = self.props.selectedRow.selectedPillars;
        self.state.trophies.push(selectedMilestone)
      })

      self.setState({trophies : self.state.trophies})
    }
  }


  getSelectedTypeMilestones(type) {
    this.props.getSelectedTypeMilestones(type);
  }

  updateTrophyFromShowPopup(event, trophyObj) {
    this.props.updateTrophyFromShowPopup(event, trophyObj)
  }


  

  TrophiesList(trophies){
    let self = this
    let trophyList = []
    _.each(trophies, function(trophyObj, index){
      trophyList.push(
        <tr key={index}>
          <th scope="row">{index + 1}</th>
          <td>{trophyObj.title}</td>
          <td>{trophyObj.defaultPoints}</td>
          <td>{moment(trophyObj.createdDate).format("DD-MMM-YYYY")}</td>
          <td>
            <Nav>
             <NavItem>
               <NavLink title="Update system language" href="#" onClick={(event) => { self.updateTrophyFromShowPopup(event, trophyObj); }}><FontAwesomeIcon icon={faEdit}  size='1x' /></NavLink>
             </NavItem>              
            </Nav>
          </td>
        </tr>
      )
    })
    return trophyList;
  }



  render() {
    let { visible, closeModal } = this.props;
    let { trophies } = this.state;

    return (
      <Modal isOpen={visible} size="lg">
        <ModalHeader toggle={()=>{closeModal(false)}}>Trophies list</ModalHeader>
        <ModalBody>
           <Table responsive hover>
            <thead>
              <tr>
                <th>#</th>
                <th>Trophy Title</th>
                <th>Default Points</th>
                <th>Created On</th>
                <th>Update</th>
              </tr>
            </thead>
            <tbody>

            {
             this.TrophiesList(trophies)
            }
            </tbody>
          </Table>
        </ModalBody>          
      </Modal>
      
    );
  }
}

export default ShowAllTrophy;
