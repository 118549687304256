import {createAction} from 'redux-act';
import httpClient from '../../../services/http';

export const requestInsightsGraphData = createAction();
export const responseInsightsGraphData = createAction();



export function getInsightsGraphData(params){
	return (dispatch) => {
		dispatch(requestInsightsGraphData([]));
		httpClient.call('MARKETING','/get_social_insights_graph_data', params, {method: 'GET', headers: {}}).then(function(response){
			dispatch(responseInsightsGraphData(response));
		}, function(error){
			dispatch(responseInsightsGraphData(error));
		});
	}
}

