import React, { Component } from 'react';
import { Row, Col, Table, Input } from "reactstrap";
import moment from 'moment';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilAlt, faTrash, faPlus, faSave } from '@fortawesome/free-solid-svg-icons'
import ReactPaginate from "react-paginate";

import { Loader } from '../../utils/common';
import UpdateWhatsNew from "./UpdateWhatsNew";
import CreateWhatsNew from "./CreateWhatsNew";
import {updateWhatsNewVersion} from "./action";
import DatePicker from 'react-date-picker';
import Session from '../../utils/session';


class WhatsNew extends Component {

  constructor(props) {
    super(props);

    this.state = {
      whatsNewList: [],
      whatsNewVersionList: [],
      whatsNewUpdatingVersionList: [],
      totalRecords: 0,
      limit: 10,
      isEditWhatsNewModalOpen: false,
      isCreateWhatsNewModalOpen: false,
      selectedWhatsNew: null,
      tempStateDateVar: new Date("2023-02-14T06:35:24.210Z")
    }
    this.session = Session.getSessionData('config');
    this.apiUrl = this.session && this.session.UTILS && this.session.UTILS.BUCKET_URL ? this.session.UTILS.BUCKET_URL : process.env.REACT_APP_CLOUDFRONT_URL;
  }

  componentDidMount() {
    Loader(true)
    this.getWhatsNewList();
    this.getWhatsNewVersionList();
  }

  componentWillReceiveProps(nextProps) {
    let self = this;

    //

    if (nextProps.whatsNewList) {
      let stateObj = {}
      if (nextProps.whatsNewList.statusCode === 200 && nextProps['whatsNewList']['result'] && nextProps['whatsNewList']['result']['length']) {
        stateObj.whatsNewList = nextProps['whatsNewList']['result'];

        if (nextProps.whatsNewList.statusCode === 200 && nextProps['whatsNewList']['totalRecords']) {
          stateObj.totalRecords = nextProps['whatsNewList']['totalRecords'];
        }
      }
      Loader(nextProps.requestProcess);
      self.setState(stateObj)
    }

    if (nextProps.whatsNewVersionList) {
      let stateObj = {}
      if (nextProps.whatsNewVersionList.statusCode === 200 && nextProps['whatsNewVersionList']['result'] && nextProps['whatsNewVersionList']['result']['length']) {
        stateObj.whatsNewVersionList = nextProps['whatsNewVersionList']['result'];
        stateObj.whatsNewUpdatingVersionList = nextProps['whatsNewVersionList']['result'];
      }
      //Loader(false)
      self.setState(stateObj)
    }

    if (nextProps.whatsNewDeletedResponse) {
      if (nextProps.whatsNewDeletedResponse.statusCode === 200) {
        alert('Whats new deleted successfully!!!')
        //Loader(true)
        this.getWhatsNewList();
      }
    }


  }


  getWhatsNewList() {
    //Loader(true)
    let reqObj = {}
    if (this.state.limit) {
      reqObj.limit = parseInt(this.state.limit)
    }
    if (this.state.page) {
      reqObj.page = parseInt(this.state.page)
    }

    this.props._getWhatsNewList(reqObj);
  }


  getWhatsNewVersionList() {
    //Loader(true)
    this.props._getWhatsNewVersionList({});
  }

  openUpdateWhatsNewModal(whatsNewObj) {
    this.setState({ selectedWhatsNew: whatsNewObj, isEditWhatsNewModalOpen: true })
  }

  openCreateWhatsNewModal(whatsNewObj) {
    this.setState({ isCreateWhatsNewModalOpen: true })
  }

  onCloseModal = () => {
    this.setState({ isEditWhatsNewModalOpen: false, isCreateWhatsNewModalOpen: false });
    Loader(true)
    this.getWhatsNewList()
  }

  deleteWhatsNew(rowData) {
    if (window.confirm("Are you sure you want to delete?") == true) {
      if (rowData && rowData._id) {
        let params = { _id: rowData._id };
        this.props._deleteWhatsNew(params)
      }
    }
  }


  createWhatsNew() {
    this.setState({ isCreateWhatsNewModalOpen: true })
  }

  saveVersionData(index, obj){

    let oldState = this.state
    if(oldState.whatsNewUpdatingVersionList[index].editMode){
      Loader(true)
      updateWhatsNewVersion({
        _id: obj._id,
        title: obj.title,
        version: obj.version,
        releaseDate: obj.releaseDate,
      }).then(response => {
        // this.props.refreshPlaylist();
      oldState.whatsNewUpdatingVersionList[index].editMode = false

        Loader(false)
        console.log('update whts new resp', response);
        if(response.success){
          this.setState({apiErrorMsg:null})
          this.onCloseModal()
          alert('Whats new version updated successfully!!!')
        }else{
          if(response.statusDescription){
            this.setState({apiErrorMsg: response.statusDescription})
          }
        }
      }, error => {
      oldState.whatsNewUpdatingVersionList[index].editMode = false
        console.log('error whats new version update',error);
        if(error.message){
          this.setState({
            apiErrorMsg: error.message
          })
        }
      })
    }else{
      oldState.whatsNewUpdatingVersionList[index].editMode = true
    }
    this.setState(oldState)

  }

  handleChange(event, index) {

    let name = event.target.name;
    let value = event.target.value;

    let oldState = this.state

    oldState.whatsNewUpdatingVersionList[index][name] = value

    this.setState(oldState);
  }

  setDate(value, index) {

    let oldState = this.state

    oldState.whatsNewUpdatingVersionList[index]['releaseDate'] = new Date(value)

    this.setState(oldState);

  }

  render() {

    let { whatsNewList, whatsNewVersionList, whatsNewUpdatingVersionList, totalRecords, limit, isEditWhatsNewModalOpen, isCreateWhatsNewModalOpen, selectedWhatsNew } = this.state;
    let self = this
    let linksCount = totalRecords > 0 && limit > 0 ? Math.ceil(totalRecords / limit) : 0;

    return (
      <div className="content">

        <Row className="pb-4">
          <Col lg={2} sm={2}>
            <h3 className="">Whats New Versions</h3>
          </Col>
          <Col lg={1} sm={1}>
          </Col>

        </Row>

        <Row className="pb-4">
          <Col lg={12} sm={12}>
            <Table striped >
              <thead>
                <tr>
                  <th>Title</th>
                  <th>Version</th>
                  <th>Release Date</th>
                  <th>Created Date</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>

                {whatsNewUpdatingVersionList && whatsNewUpdatingVersionList.length ?

                  whatsNewUpdatingVersionList.map((obj, index) => {
                    return (
                      <tr key={index}>

                        <td width="450px">
                          {obj.editMode && <>
                            <Input style={{width:'250px'}} type="text" name="title" placeholder="Title" value={obj.title} onChange={($event) => { this.handleChange($event, index) }} />
                          </>}
                          {!obj.editMode && <>{obj.title}</>}
                        </td>
                        <td width="450px">
                          {obj.editMode && <><Input style={{width:'250px'}} type="text" name="version" placeholder="version" value={obj.version} onChange={($event) => { this.handleChange($event, index) }} /></>}
                          {!obj.editMode && <>{obj.version}</>}
                        </td>

                        {/* <td>{moment(obj.releaseDate).format("YYYY-MMM-DD")}</td> */}
                        <td width="450px">
                          {obj.editMode && <>
                            <DatePicker
                              className={'payment-history-dp form-control'}
                              readonly={true}
                              name="fromDate"
                              value={new Date(obj.releaseDate)}
                              onChange={(event) => { this.setDate(event, index); }}
                              minDate={null}
                              maxDate={null} />
                          </>}
                          {!obj.editMode && <>{moment(obj.releaseDate).format("YYYY-MMM-DD")}</>}
                        </td>

                        <td>{moment(obj.createdDate).format("YYYY-MMM-DD")}</td>
                        <td>
                          {!obj.editMode &&
                            <a className="btn btn-primary" href="javascript:void(0)" title={`Edit Whats New Version`} onClick={() => { this.saveVersionData(index, obj) }}>
                              <FontAwesomeIcon icon={faPencilAlt} size='lg' />
                            </a>}

                          {obj.editMode &&
                            <a className="btn btn-primary" href="javascript:void(0)" title={`Save Whats New Version`} onClick={() => { this.saveVersionData(index, obj) }}>
                              <FontAwesomeIcon icon={faSave} size='lg' />
                            </a>}
                        </td>
                      </tr>
                    )
                  }) :
                  <tr>
                    <td colSpan="6" className="no-record-label">No Records </td>
                  </tr>
                }
              </tbody>
            </Table>
          </Col>
        </Row>

        <Row className="pb-4">
          <Col lg={12} sm={12}>
            <Row className="pb-4">
              <Col lg={8} sm={8}>
                <h3>Whats New</h3>
              </Col>
              <Col lg={3} sm={3}> </Col>
              <Col lg={1} sm={1}>
                <a style={{ float: 'right' }} className="btn btn-primary" href="javascript:void(0)" title={`Add Whats New`} onClick={() => this.createWhatsNew()}>
                  <FontAwesomeIcon icon={faPlus} size='lg' />
                </a>
              </Col>
            </Row>

            <Row>
              <Col lg={12} sm={12}>
                <div className="">
                  <Table striped >
                    <thead>
                      <tr>
                        <th>Title</th>
                        <th style={{width:'200px'}}>Description</th>
                        <th>Whats New Version Title</th>
                        <th>Button Title</th>
                        <th>Version</th>
                        <th>Created Date</th>
                        <th>Screen</th>
                        <th>Image</th>
                        <th>Sequence</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>

                      {whatsNewList && whatsNewList.length ?

                        whatsNewList.map((obj, index) => {
                          return (
                            <tr key={index}>

                              <td>{obj.title}</td>
                              <td>{obj.description}</td>
                              <td>{obj.WhatsNewVersionTitle}</td>
                              <td>{obj.buttonTitle}</td>
                              <td>{obj.version}</td>
                              <td>{moment(obj.createdDate).format("YYYY-MMM-DD")}</td>
                              <td>{(obj.screen && obj.screen.screenName)?obj.screen.screenName:''}</td>
                              {/* <td><img height={75} src={obj.image} alt="Whats New" /></td> */}
                              <td><img height={75} src={this.apiUrl+obj.image} alt="Whats New" /></td>
                              <td>{obj.sequence}</td>
                              <td>
                                <a className="action-btns" href="javascript:void(0)" title="Update Whats New" onClick={() => { this.openUpdateWhatsNewModal(obj) }}>
                                  <FontAwesomeIcon icon={faPencilAlt} size='lg' />
                                </a>
                                <a className="action-btns" href="javascript:void(0)" title={`Delete Whats New`} onClick={() => this.deleteWhatsNew(obj)}>
                                  <FontAwesomeIcon icon={faTrash} size='lg' />
                                </a>
                              </td>
                            </tr>
                          )
                        }) :
                        <tr>
                          <td colSpan="6" className="no-record-label">No Records </td>
                        </tr>
                      }
                    </tbody>
                  </Table>
                </div>
              </Col>
            </Row>
            <Row className="pt-4">
              <Col sm="5" lg="6">
                {totalRecords && <>{totalRecords} Item{totalRecords === 1 ? '' : 's'}</>}
                {(!totalRecords) && <>0 Item</>}
              </Col>
              <Col sm="6" lg="6">
                {whatsNewList && whatsNewList.length > 0 && (
                  <ReactPaginate
                    breakLabel={"..."}
                    pageCount={linksCount}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={2}
                    onPageChange={(e) => {
                      //Loader(true)
                      this.setState({ page: e.selected + 1, offset: parseInt(e.selected) }, () => this.getWhatsNewList());
                    }}
                    containerClassName={"pagination"}
                    pageClassName={"page-item"}
                    pageLinkClassName={"page-link"}
                    activeClassName={"active"}
                    breakClassName={"page-link"}
                  />
                )}
              </Col>
            </Row>
          </Col>
        </Row>
        {isEditWhatsNewModalOpen &&
          <UpdateWhatsNew
            visible={isEditWhatsNewModalOpen}
            selectedWhatsNew={selectedWhatsNew}
            whatsNewVersionList={whatsNewVersionList}
            closeModal={() => this.onCloseModal()}
            refreshPlaylist={() => this.refreshPlaylist()}
          />}
        {isCreateWhatsNewModalOpen &&
          <CreateWhatsNew
            visible={isCreateWhatsNewModalOpen}
            whatsNewVersionList={whatsNewVersionList}
            closeModal={() => this.onCloseModal()}
          />}
      </div>
    );
  }
}

export default WhatsNew;
