import React, { Component } from 'react';
import Autocomplete from "react-autocomplete";

import { updateWhatsNew, getScreenList, getPillars, getCategories, getContentList } from './action';
import { Loader, createRandomStr } from '../../utils/common';
import { Row, Col, Modal, Button, ModalHeader, ModalBody, Card, FormGroup, Label, Input, CardBody, CardFooter, Form, Alert } from "reactstrap";
import { AppSwitch } from '@coreui/react'
import SimpleReactValidator from 'simple-react-validator';
import Constants from '../../config/constants';
import httpClient from '../../../src/services/http';
import S3Client from '../../../src/services/aws-s3/S3Client';
import Session from '../../utils/session';





import * as _ from 'underscore'

class UpdateWhatsNew extends Component {


  constructor(props) {
    super(props);
    this.validator = new SimpleReactValidator();
    this.state = {
      title: "",
      status: true,
      forGuest: false,
      isFree: false,
      selectedWhatsNew: props.selectedWhatsNew,
      whatsNewVersionList: props.whatsNewVersionList,
      apiErrorMsg: '',
      selectedScreen: (props.selectedWhatsNew && props.selectedWhatsNew.screen && props.selectedWhatsNew.screen.screenName) ? props.selectedWhatsNew.screen.screenName : '',
      contentId: (props.selectedWhatsNew && props.selectedWhatsNew.screen && props.selectedWhatsNew.screen.contentId) ? props.selectedWhatsNew.screen.contentId : null,
      contentTitle: (props.selectedWhatsNew && props.selectedWhatsNew.screen && props.selectedWhatsNew.screen.contentTitle) ? props.selectedWhatsNew.screen.contentTitle : '',
      pillarId: (props.selectedWhatsNew && props.selectedWhatsNew.screen && props.selectedWhatsNew.screen.pillarId) ? props.selectedWhatsNew.screen.pillarId : null,
      categoryId: (props.selectedWhatsNew && props.selectedWhatsNew.screen && props.selectedWhatsNew.screen.categoryId) ? props.selectedWhatsNew.screen.categoryId : null,
      playlistId: (props.selectedWhatsNew && props.selectedWhatsNew.screen && props.selectedWhatsNew.screen.playlistId) ? props.selectedWhatsNew.screen.playlistId : null,
      searchKeyword: (props.selectedWhatsNew && props.selectedWhatsNew.screen && props.selectedWhatsNew.screen.searchKeyword) ? props.selectedWhatsNew.screen.searchKeyword : null,
      screenList: [],
      pillars: [],
      categories: [],
      buttonTitle: (props.selectedWhatsNew && props.selectedWhatsNew.buttonTitle) ? props.selectedWhatsNew.buttonTitle : '',
      searchField: ''
    }

    const s3Config = {
      bucketName: process.env.REACT_APP_S3_BUCKET,
      // dirName: 'whatsNewImages', /* optional */
      region: process.env.REACT_APP_AWS_DEFAULT_REGION,
      accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
      s3Url: process.env.REACT_APP_AWS_BUCKET_URL, /* optional */
    }

    this.S3Client = new S3Client(s3Config);
    this.session = Session.getSessionData('config');
    this.apiUrl = this.session && this.session.UTILS && this.session.UTILS.BUCKET_URL ? this.session.UTILS.BUCKET_URL : process.env.REACT_APP_CLOUDFRONT_URL;

  }


  componentDidMount() {

    this.getContent()
    getScreenList({}).then(getScreenListresponse => {
      console.log('getScreenListresponse', getScreenListresponse);
      if (getScreenListresponse && getScreenListresponse.result && getScreenListresponse.result.length) {
        this.setState({ screenList: getScreenListresponse.result })
      }
    }, error => {
      console.log('error whats new update', error);
    })

    //this.setScreenName({target:{value:'CATEGORY'}})

    if (this.state.selectedScreen == "CATEGORY") {
      setTimeout(() => {
        let oldState = this.state
        Loader(true);
        getPillars().then(
          (resposne) => {
            if (resposne && resposne.statusCode == 200) {
              //this.setState({ pillars: resposne.result });
              oldState.pillars = resposne.result

              let pillarId = this.state.pillarId;
              //this.setState({ pillarId });
              //Loader(true);
              getCategories(pillarId).then(
                (categories) => {
                  if (categories && categories.statusCode == 200) {
                    this.setState({ categories: categories.result });
                  } else {
                    this.setState({ categories: [] });
                  }
                  Loader(false);
                },
                (error) => {
                  console.log("error", error);
                  this.setState({ categories: [] });
                  Loader(false);
                }
              );

            } else {
              //this.setState({ pillars: [] });
            }
            //Loader(false);
            this.setState(oldState)
          },
          (error) => {
            console.log("error", error);
            this.setState({ pillars: [] });
            Loader(false);
          }
        );
      }, 500);

    }

  }

  componentWillReceiveProps(nextProps) {

  }

  handleChange(event) {
    let target = event.target;

    let value;

    if (target.type === 'checkbox' && target.name === 'status') {
      value = (target.value === 'Active') ? false : true;
    } else if (target.type === 'checkbox' && target.name === 'forGuest') {
      value = (target.value === 'Active') ? false : true;
    } else if (target.type === 'checkbox' && target.name === 'isFree') {
      value = (target.value === 'Active') ? false : true;
    } else {
      value = target.value;
    }


    const name = target.name;

    let oldState = this.state;
    let tempSelectedWhatsNew = this.state.selectedWhatsNew
    tempSelectedWhatsNew[name] = value
    oldState.selectedWhatsNew = tempSelectedWhatsNew
    oldState[name] = value

    this.setState({ oldState });
  }

  handleSubmit(event) {
    event.preventDefault();

    //this.setState({ apiErrorMsg: "" });
    console.log('this.validator.allValid()', this.validator.allValid());




    if (this.validator.allValid()) {

      let updateObj = {
        _id: this.state.selectedWhatsNew._id,
        title: this.state.selectedWhatsNew.title,
        description: this.state.selectedWhatsNew.description,
        whatsNewVersionId: this.state.selectedWhatsNew.whatsNewVersionId,
        image: this.state.selectedWhatsNew.image,

        buttonTitle: (this.state.buttonTitle && (this.state.selectedScreen != null || this.state.selectedScreen != '')) ? this.state.buttonTitle : '',

        sequence: parseInt(this.state.selectedWhatsNew.sequence)
      }

      if(this.state.selectedScreen === '' || this.state.selectedScreen === null){
        updateObj.buttonTitle = ''
      }

      if (this.state.selectedScreen) {
        let screen = { screenName: this.state.selectedScreen }
        if (this.state.selectedScreen === 'MUSIC_PLAYER') {
          screen.contentTitle = this.state.contentTitle
          screen.contentId = this.state.contentId
        } else if (this.state.selectedScreen === 'CATEGORY') {
          screen.pillarId = this.state.pillarId
          screen.categoryId = this.state.categoryId
        } else if (this.state.selectedScreen === 'SEARCH') {
          screen.searchKeyword = this.state.searchKeyword
        } else if (this.state.selectedScreen === 'SUGGESTED_PLAYLIST_PLAYER') {
          screen.playlistId = this.state.playlistId
        }
        updateObj.screen = screen
      }else{
        updateObj.screen = {}
      }

      updateWhatsNew(updateObj).then(response => {
        // this.props.refreshPlaylist();
        console.log('update whts new resp', response);
        if (response.success) {
          this.setState({ apiErrorMsg: null })
          alert('Whats new updated successfully!!!')
          this.props.closeModal()
        } else {
          if (response.statusDescription) {
            this.setState({ apiErrorMsg: response.statusDescription })
          }
        }
      }, error => {
        console.log('error whats new update', error);
        this.setState({
          apiErrorMsg: error.message
        })
      })
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  }


  handleThumbUpload(event) {
    Loader(true);

    let file = event.target.files[0]; // FileList object
    //let oldState = this.state
    //oldState.thumb_test = file
    //this.setState()

    let files = []
    files.push({ 'key': 'whatsNewImage', 'folder': 'whatsNewImages', file });

    this.uploadFilesToServers(files).then((uploadedFileRes) => {

      console.log('uploadedFileRes', uploadedFileRes);
      if (uploadedFileRes && uploadedFileRes.whatsNewImage) {
        let oldState = this.state
        oldState.selectedWhatsNew['image'] = uploadedFileRes.whatsNewImage;
        this.setState(oldState)
        Loader(false)
      }
    })

  }

  uploadFilesToServers(files) {
    let self = this;
    //let hasError = false;

    return new Promise((resolve, reject) => {
      if (files.length > 0) {
        //Loader(true);
        self.uploadFiles(files, function (responseObj, err) {
          // if any of the file processing produced an error, err would equal that error
          if (err) {
            // One of the iterations produced an error.
            // All processing will now stop.
            console.log('A file failed to process');
            //Loader(false);
            reject('File upload failed. Please try again.')
          } else {
            resolve(responseObj);
            console.log('All files have been processed successfully');
          }

        })
      } else {
        resolve({});
        Loader(false);
      }

    })
  }

  uploadFiles(files, callback) {

    //test push
    let self = this;
    let responseObj = {};
    let progressIndex = 0;
    self.S3Client.getPolicy().then((policyRes) => {
      console.log('policyRes', policyRes);
      httpClient.call('PILLAR', '/get_signature', { policy: policyRes }, { method: 'GET', headers: {} }).then((signatureRes) => {
        console.log('signatureRes', signatureRes);
        if (signatureRes && signatureRes.statusCode === 200 && signatureRes.result && signatureRes.result.signature) {
          _.each(files, function (fileObj, index) {
            let operations = {};
            if (fileObj.file instanceof File) {
              console.log('if condi');
              createRandomStr((randString) => {
                let signature = signatureRes.result.signature;
                let filename = fileObj.folder + '/' + randString;
                let ext = fileObj.file.name.split('.').pop();
                // UPLOADING IMAGES
                self.S3Client.uploadFile(fileObj.file, filename + '.' + ext, signature).then(data => {
                  responseObj[fileObj.key] = data.key;
                  progressIndex = progressIndex + 1;
                  if (progressIndex === files.length) {
                    callback(responseObj, null)
                  }
                }).catch(error => {
                  console.log('error 296', error);
                  callback(null, error)
                })
              });
            } else {
              console.log('else condi');
              responseObj[fileObj.key] = fileObj.file;
              progressIndex = progressIndex + 1;
              if (progressIndex === files.length) {
                callback(responseObj, null)
              }
            }
          });
        } else {
          console.log('ERROR:- SIGNATURE RESPONSE ', signatureRes);
          callback(null, signatureRes.statusDescription)
        }

      }, (error) => {
        console.log('ERROR:- SIGNATURE REQUEST ', error);
        callback(null, error)
      })
    }, (error) => {
      console.log('ERROR:- SIGNATURE REQUEST ', error);
      callback(null, error)
    });
  }

  setPillarOptions(pillars) {
    let pillarOptions = [];
    _.each(pillars, function (value, index) {
      {
        pillarOptions.push(
          <option key={value._id} value={value._id}>
            {value.title}
          </option>
        );
      }
    });

    return pillarOptions;
  }

  setCategory(event) {
    let categoryId =
      event && event.target && event.target.value ? event.target.value : null;
    this.setState({ categoryId });
  }

  setScreenName(event) {
    //this.resetNotificationData();

    let oldState = this.state
    oldState.selectedScreen = event.target.value
    // this.setState(oldState)

    if (event.target.value == "CATEGORY") {
      Loader(true);
      getPillars().then(
        (resposne) => {
          if (resposne && resposne.statusCode == 200) {
            oldState.pillars = resposne.result
            this.setState(oldState);
          } else {
            oldState.pillars = []
            this.setState(oldState);
          }
          Loader(false);
          //this.setState(oldState)
        },
        (error) => {
          console.log("error", error);
          oldState.pillars = []
          this.setState(oldState);
          // this.setState({ pillars: [] });
          Loader(false);
        }
      );
    }

    if (event.target.value == "MUSIC_PLAYER") {
      this.getContent(null);
    }
    //this.setState({ screenName: event.target.value });
    this.validator.hideMessages()
  }

  setPillarAndGetCategory(event) {
    let pillarId =
      event && event.target && event.target.value ? event.target.value : null;
    this.setState({ pillarId });
    Loader(true);
    getCategories(pillarId).then(
      (categories) => {
        if (categories && categories.statusCode == 200) {
          this.setState({ categories: categories.result });
        } else {
          this.setState({ categories: [] });
        }
        Loader(false);
      },
      (error) => {
        console.log("error", error);
        this.setState({ categories: [] });
        Loader(false);
      }
    );
  }

  setCategoryOptions(categories) {
    let categoriesOptions = [];
    _.each(categories, function (value) {
      {
        categoriesOptions.push(
          <option key={value._id} value={value._id}>
            {value.title}
          </option>
        );
      }
    });

    return categoriesOptions;
  }


  getContent(searchKey) {
    Loader(true);
    getContentList({
      search: searchKey,
      limit: 1000,
      offset: 0,
      contentType: Constants.AUDIO,
    })
      .then(
        (response) => {
          if (response && response.statusCode === 200) {
            this.setState({ contentData: response.result });
          } else {
            this.setState({ contentData: [] });
          }
          Loader(false);
        },
        (error) => {
          console.log("error", error);
          Loader(false);
        }
      );
  }


  onSelect(item) {
    this.setState({
      contentId: item._id,
      contentTitle: item.title,
      contentTitle: item.title,
    });
  }



  render() {

    let { visible, closeModal } = this.props;
    let { selectedWhatsNew, whatsNewVersionList, screenList, pillars, categories, contentData, contentTitle } = this.state;
    let { apiErrorMsg } = this.state;

    return (
      <Modal isOpen={visible}>
        <ModalHeader toggle={() => { closeModal() }}>Update Whats New</ModalHeader>
        <ModalBody>
          <Form onSubmit={(event) => { this.handleSubmit(event) }}>
            <Row>
              <Col sm="12">
                <Card>
                  <CardBody>
                    <Row>
                      <Col xs="12">
                        <FormGroup>
                          <Label htmlFor="title">Title</Label>
                          <Input type="text" name="title" id="title" placeholder="Title" value={selectedWhatsNew.title} onChange={($event) => { this.handleChange($event) }} />
                          <div className="validation-error">{this.validator.message('title', selectedWhatsNew.title, 'required')}</div>
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row>
                      <Col xs="12">
                        <FormGroup>
                          <Label htmlFor="description">Description</Label>
                          <Input type="text" name="description" id="description" placeholder="Description" value={selectedWhatsNew.description} onChange={($event) => { this.handleChange($event) }} />
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row>
                      <Col xs="12">
                        <FormGroup>
                          <Label htmlFor="sequence">sequence</Label>
                          <Input type="text" name="sequence" id="sequence" placeholder="sequence" value={selectedWhatsNew.sequence} onChange={($event) => { this.handleChange($event) }} />
                          <div className="validation-error">{this.validator.message('sequence', selectedWhatsNew.sequence, "numeric|min:1,num")}</div>
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row>
                      <Col xs="12">
                        <Input
                          type="select"
                          name="selectedWhatsNewVersionId"
                          id="selectedWhatsNewVersionId"
                          value={this.state.selectedWhatsNew.whatsNewVersionId}
                          onChange={(event) => {
                            let stateVar = this.state
                            stateVar.selectedWhatsNew.whatsNewVersionId = event.target.value
                            this.setState(stateVar)
                          }}
                        >
                          {whatsNewVersionList && whatsNewVersionList.map((item, index) =>
                            <option key={item._id} value={item._id}>{item.title}</option>
                          )}
                        </Input>
                      </Col>
                    </Row>



                    <br />
                    <Row>
                      <Col xs="12">

                        <FormGroup>
                          <Label htmlFor="screen">Screen</Label>
                          <Input
                            type="select"
                            name="selectedScreen"
                            id="selectedScreen"
                            value={this.state.selectedScreen}
                            onChange={(event) => {
                              console.log('event.target.value', event.target.value);
                              let oldState = this.state
                              oldState.selectedScreen = event.target.value
                              if (oldState.selectedWhatsNew.screen) {
                                oldState.selectedWhatsNew.screen.screenName = event.target.value
                              }
                              if (event.target.value === 'CATEGORY') {
                                this.setScreenName({ target: { value: 'CATEGORY' } })
                              }
                              this.setState(oldState)
                            }}
                          >
                            <option key={''} value={''}>--- Select Screen ---</option>
                            {screenList && screenList.map((item, index) =>
                              <option key={index} value={item}>{item}</option>
                            )}
                          </Input>
                        </FormGroup>
                      </Col>
                    </Row>



                    {this.state.selectedScreen && this.state.selectedScreen == "CATEGORY" ? (
                      <Row>
                        <Col xs="12">
                          <FormGroup>
                            <Label htmlFor="pillarId">Select Pillar</Label>
                            <Input
                              type="select"
                              name="pillarId"
                              id="pillarId"
                              value={this.state.pillarId}
                              onChange={($event) => {
                                this.setPillarAndGetCategory($event);
                              }}
                            >
                              <option key="" value={this.state.categoryId}>
                                Select pillar
                              </option>
                              {this.setPillarOptions(pillars)}
                            </Input>
                            <div className="validation-error">
                              {this.validator.message(
                                "Pillar",
                                this.state.pillarId,
                                "required"
                              )}
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                    ) : null}

                    {this.state.selectedScreen &&
                      this.state.selectedScreen == "CATEGORY" &&
                      categories &&
                      categories.length ? (
                      <Row>
                        <Col xs="12">
                          <FormGroup>
                            <Label htmlFor="categoryId">
                              Select Category
                            </Label>
                            <Input
                              type="select"
                              name="categoryId"
                              id="categoryId"
                              value={this.state.categoryId}
                              onChange={($event) => {
                                this.setCategory($event);
                              }}
                            >
                              <option key="" value={null}>
                                Select category
                              </option>
                              {this.setCategoryOptions(categories)}
                            </Input>
                            <div className="validation-error">
                              {this.validator.message(
                                "Category",
                                this.state.categoryId,
                                "required"
                              )}
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                    ) : null}


                    {this.state.selectedScreen && this.state.selectedScreen == "MUSIC_PLAYER" ? (
                      <Row>
                        <>
                          {(contentData && contentData[0] && contentData[0].title) ? (
                            <Col xs="12">
                              <FormGroup>
                                <Label htmlFor="content">Content</Label>
                                <div>
                                  <Autocomplete
                                    getItemValue={(item) => item.title}
                                    items={contentData}
                                    inputProps={{
                                      id: "autocomplete-input",
                                      className: "form-control",
                                      placeholder: "Content search by title",
                                    }}
                                    wrapperStyle={{}}
                                    renderItem={(item, isHighlighted) => (
                                      <div
                                        key={item._id}
                                        style={{
                                          background: isHighlighted
                                            ? "lightgray"
                                            : "white",
                                        }}
                                      >
                                        {item.title}
                                      </div>
                                    )}
                                    value={this.state.contentTitle}
                                    onChange={(event, value) => {
                                      this.setState(
                                        {
                                          contentTitle: value,
                                          contentId: null
                                        },
                                        () => {
                                          clearTimeout(this.requestTimer);
                                          this.requestTimer = setTimeout(() => {
                                            this.getContent(value);
                                          }, 1000);
                                        }
                                      );
                                    }}
                                    onSelect={(value, item) => {
                                      this.onSelect(item);
                                    }}
                                    selectOnBlur={true}
                                  />

                                </div>
                                <div className="validation-error">
                              {this.validator.message(
                                "Content",
                                this.state.contentTitle,
                                "required"
                              )}
                            </div>
                              </FormGroup>
                            </Col>
                          ) : null}
                        </>
                      </Row>
                    ) : null}


                    {this.state.selectedScreen &&
                      this.state.selectedScreen == "SUGGESTED_PLAYLIST_PLAYER" ? (
                      <Row>
                        <Col xs="12">
                          <FormGroup>
                            <Label htmlFor="searchField">Playlist Id</Label>
                            <Input
                              type="playlistId"
                              name="playlistId"
                              id="playlistId"
                              value={this.state.playlistId}
                              onChange={($event) => {
                                this.handleChange($event);
                              }}
                            ></Input>
                            <div className="validation-error">
                              {this.validator.message(
                                "Playlist Id",
                                this.state.playlistId,
                                "required"
                              )}
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                    ) : null}

                    {this.state.selectedScreen && this.state.selectedScreen == "SEARCH" ? (
                      <Row>
                        <Col xs="12">
                          <FormGroup>
                            <Label htmlFor="searchField">Search Field</Label>
                            <Input
                              type="searchField"
                              name="searchField"
                              id="searchField"
                              value={this.state.searchKeyword}
                              onChange={($event) => {
                                this.handleChange($event);
                              }}
                            ></Input>
                            <div className="validation-error">
                              {this.validator.message(
                                "Search Field",
                                this.state.searchKeyword,
                                "required"
                              )}
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                    ) : null}


                    {(this.state.selectedScreen && this.state.selectedScreen != "" && this.state.selectedScreen != null) ? (
                      <Row>
                        <Col xs="12">
                          <FormGroup>
                            <Label htmlFor="buttonTitle">buttonTitle</Label>
                            <Input type="text" name="buttonTitle" id="buttonTitle" placeholder="buttonTitle" value={selectedWhatsNew.buttonTitle} onChange={($event) => { this.handleChange($event) }} />
                          </FormGroup>
                        </Col>
                      </Row>
                    ) : null}



                    <br />
                    <Row>
                      <Col xs="6">
                        <input type="file" className="" placeholder="" onChange={(event) => this.handleThumbUpload(event)} />
                      </Col>
                      <Col xs="6">
                        {/* <img width={100} src={selectedWhatsNew.image} alt="Alt Text" /> */}
                        <img width={100} src={this.apiUrl + selectedWhatsNew.image} alt="Alt Text" />
                      </Col>
                    </Row>




                    <br />
                    <Row>
                      <br />
                      <Col xs="12">
                        {apiErrorMsg && <Alert color="danger">
                          {apiErrorMsg}
                        </Alert>}
                      </Col></Row>




                    {/* {JSON.stringify(whatsNewVersionList)} */}

                  </CardBody>
                  <CardFooter>
                    <Button type="submit" size="sm" color="primary">Save</Button>
                    <Button type="reset" size="sm" color="danger" onClick={() => { closeModal() }}>Cancel</Button>
                  </CardFooter>
                </Card>
              </Col>
            </Row>
          </Form>
        </ModalBody>
      </Modal>
    );
  }
}



export default UpdateWhatsNew;
