import React, { Component } from "react";
import DatePicker from 'react-date-picker';
import { Loader, FormatTitlePosLabel, SuccessToast } from "../../utils/common";
import Progress from "../../utils/Loader";
import {
  Row,
  Col,
  Table,
  Button,
  Input,
  Alert,
  Form,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Label,
  CardFooter,
} from "reactstrap";
import moment from "moment";
import Constants from "../../config/constants";
import SimpleReactValidator from "simple-react-validator";
import * as _ from "underscore";
import ReactPaginate from "react-paginate";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { AppSwitch } from "@coreui/react";
import Autocomplete from "react-autocomplete";
import InputEmoji from "../react-input-emoji-master";
import DateTimePicker from 'react-datetime-picker';
import Select from 'react-select';
const options = [
  { value: 'NEW_USER', label: 'New Users' },
  { value: 'EXPLORATORY_USER', label: 'Exploratory Users' },
  { value: 'ACTIVE_USER_FREE', label: 'Active Users - Free' },
  { value: 'ACTIVE_USER_PAID_TO_FREE', label: 'Active Users - Paid To Free' },
  { value: 'ACTIVE_USER_PAID', label: 'Active user - Paid' },
  { value: 'LAPSED_USER_CURRENT_SUBSCRIBER', label: 'Lapsed User - Current Subscriber' },
  { value: 'LAPSED_USER_NEVER_PAID', label: 'Lapsed User - Never Paid' },
  { value: 'RE_ENGAGED_AFTER_LAPSED_FREE', label: 'Re-engaged After Lapsing (unpaid)' },
  { value: 'RE_ENGAGED_AFTER_LAPSED_PAID', label: 'Re-engaged After Lapsing (paid)' },
];
class UserNotification extends Component {
  constructor(props) {
    super(props);
    this.timeout = null;
    this.requestTimer = null;
    var start = new Date(moment().subtract(7, 'days').format("YYYY-MM-DD"));
    start.setUTCHours(0,0,0,0);

    var end = new Date(moment().format("YYYY-MM-DD"));
    end.setUTCHours(23,59,59,999);
    this.state = {
      scheduleDateTime: new Date(moment().add(15, 'minutes')),
      scheduleDateTimeMsg:'',
      userList: [],
      notificationSegmentList: [],
      page: 0,
      offset: 0,
      rowsPerPage: 10,
      totalRecord: 0,
      searchString: "",
      subscriptionType: "",
      selectAll: false,
      isLoading: false,
      selectedUsers: [],
      notificationTitle: process.env.Ninjafocus
        ? process.env.Ninjafocus
        : "Ninja Focus",
      notificationMessage: "",
      userTypes: [
        {
          key: "EMAIL",
          value: "Selected Email",
        },
        {
          key: "ALL",
          value: "All Users",
        },
        {
          key: "PAID",
          value: "Paid Users",
        },
        {
          key: "FREE",
          value: "Free Users",
        },
        {
          key: "ANDROID",
          value: "Android Users",
        },
        {
          key: "IOS",
          value: "IOS Users",
        },
        {
          key: "SEGMENTS",
          value: "Segments",
        },
      ],

      selectedType: "EMAIL",
      isUpdate: false,
      requestMessageType: "",
      requestMessage: "",
      notificationData: [],
      notificationSuccess: "",
      screens: [],
      screenName: '',
      searchField: '',
      pillars: [],
      pillarId: '',
      categories: [],
      categoryId: '',
      typedContent: "",
      contentId: '',
      contentData: [],
      contentTitle: '',
      segments: [],
      countrylist: [],
      selectedCountry: '',
      timezone: '',
      selectedOption: null,
      fromDate : null,
      toDate : null,
      currentDate : new Date(moment().format("YYYY-MM-DD")),
      minDate : new Date(moment().subtract(4,"years").format("YYYY-MM-DD")),
      showPush: false,
    };
    this.columns = [
      { columnName: "email", displayName: "Email", sort: false },
      { columnName: "subscription", displayName: "Subscription", sort: false },
      { columnName: "status", displayName: "Status", sort: false },
      { columnName: "createdDate", displayName: "Created On", sort: false },
    ];
    this.segmentColumns = [
      { columnName: "title", displayName: "Title", sort: false },
      { columnName: "segments", displayName: "Segments", sort: false },
      { columnName: "sendTo", displayName: "Send To", sort: false },
      { columnName: "scheduleDateTime", displayName: "Schedule Date Time", sort: false },
      { columnName: "createdDate", displayName: "Created Date", sort: false },
      { columnName: "message", displayName: "Message", sort: false },
      { columnName: "totalCount", displayName: "Total Sent", sort: false },
      { columnName: "totalPageSent", displayName: "User Received", sort: false },
      { columnName: "totalPageView", displayName: "User Clicked", sort: false },
    ]
    this.validator = new SimpleReactValidator({
      validators: {
        contentId: {
          // name the rule
          message: "Please select at least one option from the list",
          rule: (val, params, validator) => {
            if (this.state.contentId) {
              return true;
            } else {
              return false;
            }
          },
          messageReplace: (message, params) =>
            message.replace(":values", this.helpers.toSentence(params)), // optional
          required: true, // optional
        },

        segments: {
          // name the rule
          message: "Please select at least one segment from the list",
          rule: (val, params, validator) => {
            if (this.state.segments) {
              return true;
            } else {
              return false;
            }
          },
          messageReplace: (message, params) =>
            message.replace(":values", this.helpers.toSentence(params)), // optional
          required: true, // optional
        },
      },
    });
    this.weeklyNotificationValidator = new SimpleReactValidator();
    this.getNotificationText();
    this.getCountryList();
  }

  componentDidMount() {
    let self = this;
    self.getUserList();
    self.getScreenSlug();
    // self.getSegmentNotificationList();
  }

  setDate(name, value) {
    if (value !== null) {
      if (this.state.fromDate !== null && name === 'toDate') {
        var start = new Date(moment(this.state.fromDate, "YYYY-MM-DD").add(1, "days"));
        start.setUTCHours(0, 0, 0, 0);
        var end = new Date(moment(value, "YYYY-MM-DD").add(1, "days"));
        end.setUTCHours(23, 59, 59, 999);
        if (start > end) {
          alert("From date must be less than or equal to To date");
          this.setState({ [name]: null });
          return;
        } else {
          this.setState({ [name]: new Date(value) });
        }
      } else if (this.state.toDate !== null && name === "fromDate") {
        var start = new Date(
          moment(value, "YYYY-MM-DD").add(1, "days")
        );
        start.setUTCHours(0, 0, 0, 0);
        var end = new Date(moment(this.state.toDate, "YYYY-MM-DD").add(1, "days"));
        end.setUTCHours(23, 59, 59, 999);
        if (start > end) {
          alert("From date must be less than or equal to To date");
          this.setState({ [name]: null });
          return;
        } else {
          this.setState({ [name]: new Date(value) });
        }
      } else {
        this.setState({ [name]: new Date(value) });
      }
    } else {
      this.setState({ [name]: null });
    }
  }

  getContent(searchKey) {
    Loader(true);
    this.props
      ._getContentList({
        search: searchKey,
        limit: 1000,
        offset: 0,
        contentType: Constants.AUDIO,
      })
      .then(
        (response) => {
          if (response && response.statusCode === 200) {
            this.setState({ contentData: response.result });
          } else {
            this.setState({ contentData: [] });
          }
          Loader(false);
        },
        (error) => {
          console.log("error", error);
          Loader(false);
        }
      );
  }

  getCountryList(){
    this.props._getCountryList().then( response => {
      if(response.statusCode == 200){
          this.setState({countrylist: response.result})
      }
    }, error => {
      console.log('Error ', error)
    })
  }

  getUserList() {
    const { searchString, subscriptionType, offset, rowsPerPage } = this.state;
    let params = {
      search: searchString,
      subscriptionType,
      offset,
      limit: rowsPerPage,
    };
    this.setState({isLoading: true});
    this.props._getUserList(params);
  }

  getSegmentNotificationList(){
    let params = {};
    this.props._getSegmentNotificationList(params);
  }

  getScreenSlug() {
    this.props._getScreenSlug({});
  }

  toggleEmoji() {
    let { showEmoji } = this.state;
    this.setState({ showEmoji: !showEmoji });
  }

  componentWillReceiveProps(nextProps) {
    let self = this;
    Loader(nextProps.requestProcess);

    if (nextProps.userList) {
      this.setState({ isLoading: false });
      if (nextProps.userList.statusCode === 200) {
        let userList = nextProps.userList.result;
        let { selectedUsers } = this.state;
        let selectedUsersIndex = [];
        if (userList && userList.length > 0) {
          for (let i = 0; i < userList.length; i++) {
            let index = _.findIndex(selectedUsers, userList[i]);
            if (index !== -1) {
              selectedUsersIndex.push(index);
            }
          }
        }
        self.setState({
          userList,
          selectAll: selectedUsersIndex.length === userList.length,
          totalRecord: nextProps.userList.totalRecords,
        });
      } else {
        // DO NOTHING FOR NOW
      }
    }
    if (nextProps.notificationSegmentList) {
      if (nextProps.notificationSegmentList.length>0) {
        let notificationSegmentList = nextProps.notificationSegmentList;
        self.setState({
          notificationSegmentList
        });
      } else {
        // DO NOTHING FOR NOW
      }
    }
    if (nextProps.screens) {
      if (nextProps.screens.statusCode === 200) {
        let screens = nextProps.screens.result;
        self.setState({ screens });
      } else {
        // DO NOTHING FOR NOW
      }
    }

    if (
      nextProps.notificationStatus &&
      nextProps.notificationStatus.statusCode &&
      this.state.isUpdate
    ) {
      let stateData = {};
      if (nextProps.notificationStatus.statusCode === 200) {
        stateData = {
          isUpdate: false,
          selectedUsers: [],
          selectAll: false,
          segments: '',
          screenName: '',
          searchField: '',
          categoryId: '',
          typedContent: "",
          contentId: '',
          pillarId: '',
          notificationTitle: process.env.Ninjafocus ? process.env.Ninjafocus : "Ninja Focus",
          selectedType: "EMAIL",
          requestMessage: nextProps.notificationStatus.statusDescription,
          requestMessageType: "SUCCESS",
        };
        this.validator.hideMessages();
        this.forceUpdate();
      } else {
        stateData = {
          isUpdate: false,
          requestMessage: nextProps.notificationStatus.statusDescription,
          requestMessageType: "ERROR",
        };
      }
      this.setState(stateData);
      setTimeout(() => {
        this.setState({ requestMessageType: "", requestMessage: "" });
      }, 3000);
    }
  }

  handleChange(event, key) {
    let _self = this;
    let target = event.target;
    let name = target.name;
    let value = target.value;
    let stateData = { [name]: value };
    if (name === "searchString") {
      stateData["offset"] = 0;
      stateData["page"] = 0;
    }

    this.setState(stateData);
    clearTimeout(this.timeout);
    if (name === "searchString") {
      this.timeout = setTimeout(function () {
        _self.getUserList();
      }, 1000);
    }

  }

  handleChangeForNotification(event, key) {
    let { value } = event.target;
    let { notificationData } = this.state;
    notificationData[key].body = value;
    this.setState({ notificationData });
  }

  setEmojiForWeeklyNotification(selectedEmoji, key) {
    let { notificationData } = this.state;
    notificationData[key].body += selectedEmoji.emoji;
    this.setState({ notificationData });
  }

  changeStatus(key) {
    let { notificationData } = this.state;
    notificationData[key].status = !notificationData[key].status;
    this.setState({ notificationData });
  }

  setGridHeaders(headingData) {
    let gridHeadings = [];
    let { selectAll } = this.state;
    gridHeadings.push(
      <th key={"index_0"}>
        <Input
          type="checkbox"
          checked={selectAll}
          style={{ position: "relative", margin: 0 }}
          name={"checkbox_0"}
          onClick={(e) => {
            this.selectAllUser();
          }}
        />
      </th>
    );
    _.each(headingData, function (value, index) {
      {
        gridHeadings.push(<th key={index}>{value["displayName"]}</th>);
      }
    });

    return <tr className="">{gridHeadings}</tr>;
  }

  setPushGridHeaders(headingData) {
    let gridHeadings = [];
    _.each(headingData, function (value, index) {
      {
        gridHeadings.push(<th key={index}>{value["displayName"]}</th>);
      }
    });

    return <tr className="">{gridHeadings}</tr>;
  }

  setScreenOptions(screens) {
    let screenOptions = [];
    _.each(screens, function (value, index) {
      {
        screenOptions.push(
          <option key={value} value={value}>
            {FormatTitlePosLabel(value)}
          </option>
        );
      }
    });

    return screenOptions;
  }

  setGridData(data) {
    let self = this;
    let gridData = [];
    let { selectedUsers, page } = this.state;
    page = (page - 1) * 10;
    page = page > 0 ? page - 1 : 0;

    if (data.length) {
      _.each(data, function (value, index) {
        {
          let selectedIndex = _.findIndex(selectedUsers, value);
          gridData.push(
            <tr key={"parent_" + index}>
              <th scope="row">
                <Input
                  type="checkbox"
                  style={{ position: "relative", margin: 0 }}
                  checked={selectedIndex !== -1 ? true : false}
                  name={"checkbox_" + index}
                  onClick={(e) => {
                    self.selectUser(value);
                  }}
                />
              </th>
              <td>{value["email"]}</td>
              <td>{value["subscription"]}</td>
              <td>{value["status"] ? "Active" : "Inactive"}</td>
              <td>{moment(value["createdDate"]).format("DD-MMM-YYYY")}</td>
            </tr>
          );
        }
      });
    } else {
      gridData.push(
        <tr key={"index-123"}>
          <td className="no-data-found">No Records Found</td>
        </tr>
      );
    }

    return gridData;
  }

  setPushGridData(data) {
    //Loader(true);
    let self = this;
    let gridData = [];
    if (data.length) {

      _.each(data, function (value, index) {
        {
          gridData.push(
            <tr key={"push_notification_" + index}>
              <td>{value["title"]}</td>
              <td>{value["segments"]}</td>
              <td>{value["sendTo"]}</td>
              <td>{moment(value["scheduleDateTime"]).utc().format("DD-MMM-YYYY h:mm a")}</td>
              <td>{moment(value["createdDate"]).utc().format("DD-MMM-YYYY")}</td>
              <td>{value["message"]}</td>
              <td>{value["totalCount"]}</td>
              <td>{value["totalPageSent"]}</td>
              <td>{value["totalPageView"]}</td>
            </tr>
          );
        }
      });
      //Loader(false);
    } else {
      gridData.push(
        <tr key={"index-123"}>
          <td className="no-data-found" colSpan={9}>No Records Found</td>
        </tr>
      );
    }

    return gridData;
  }

  selectUser(userObj) {
    let { userList, selectedUsers } = this.state;
    let index = _.findIndex(selectedUsers, userObj);
    if (index === -1) {
      selectedUsers.push(userObj);
    } else {
      selectedUsers.splice(index, 1);
    }
    let selectedUsersIndex = [];
    if (userList && userList.length > 0) {
      for (let i = 0; i < userList.length; i++) {
        let index = _.findIndex(selectedUsers, userList[i]);
        if (index !== -1) {
          selectedUsersIndex.push(index);
        }
      }
    }
    this.setState({
      selectedUsers,
      selectAll: selectedUsersIndex.length === userList.length,
      selectedType: "EMAIL",
    });
  }

  selectAllUser() {
    let { selectAll, userList, selectedUsers } = this.state;
    if (userList && userList.length > 0) {
      for (let i = 0; i < userList.length; i++) {
        let index = _.findIndex(selectedUsers, userList[i]);
        if (selectAll) {
          selectedUsers.splice(index, 1);
        } else {
          selectedUsers.push(userList[i]);
        }
        this.setState({ selectAll: !selectAll, selectedUsers });
      }
    }
  }

  setPaginationLinks(totalRecords, pageLimit) {
    let { page } = this.state;
    let linksCount =
      totalRecords > 0 && pageLimit > 0
        ? Math.ceil(totalRecords / pageLimit)
        : 0;
    return (
      <ReactPaginate
        previousLabel={""}
        nextLabel={""}
        breakLabel={"..."}
        pageCount={linksCount}
        marginPagesDisplayed={2}
        pageRangeDisplayed={5}
        forcePage={parseInt(page)}
        onPageChange={(e) => {
          this.getMoreUserListData(e.selected);
        }}
        containerClassName={"pagination"}
        pageClassName={"page-item"}
        pageLinkClassName={"page-link"}
        activeClassName={"active"}
        breakClassName={"page-link"}
      />
    );
  }

  getMoreUserListData(offset) {
    let { rowsPerPage } = this.state;
    this.setState({ offset: offset * rowsPerPage, page: offset }, () => {
      this.getUserList();
    });
  }

  sendPushNotification(e) {
    if (this.validator.allValid()) {
      const {
        selectedType,
        selectedUsers,
        notificationTitle,
        notificationMessage,
        screenName,
        searchField,
        pillarId,
        categoryId,
        contentId,
        contentTitle,
        playlistId,
        segments,
        selectedCountry,
        timezone,
        scheduleDateTime,
        scheduleDateTimeMsg,
        fromDate,
        toDate
      } = this.state;
      if (selectedType === "EMAIL" && selectedUsers.length === 0) {
        this.setState({
          requestMessageType: "ERROR",
          requestMessage: "Please select at least one email from User List!",
        });
        setTimeout(() => {
          this.setState({ requestMessageType: "", requestMessage: "" });
        }, 3000);
        alert("Please select at least one email from User List!");
        e.preventDefault();
        return false;
      }
      // if ( fromDate === null || toDate === null) {
      //   this.setState({
      //     requestMessageType: "ERROR",
      //     requestMessage: "Please select from & to date!",
      //   });
      //   setTimeout(() => {
      //     this.setState({ requestMessageType: "", requestMessage: "" });
      //   }, 3000);
      //   alert("Please select from & to date!");
      //   e.preventDefault();
      //   return false;
      // } else if (fromDate !== null && toDate !== null) {
      //   var start = new Date(moment(fromDate, "YYYY-MM-DD").add(1, "days"));
      //   start.setUTCHours(0, 0, 0, 0);
      //   var end = new Date(moment(toDate, "YYYY-MM-DD").add(1, "days"));
      //   end.setUTCHours(23, 59, 59, 999);
      //   if (start > end) {
      //     alert("From date must be less than or equal to To date");
      //     return;
      //   }
      // }
      const params = {
        sendTo: selectedType,
        title: notificationTitle,
        message: notificationMessage,
        selectedUsers: selectedType === "EMAIL" ? selectedUsers : [],
        segments,
        country: selectedCountry,
        scheduleDateTime: scheduleDateTime.toString(),
        timezone,
        fromDate,
        toDate
      };
      if (screenName) {
        let data = {
          screenName,
        };

        if (searchField) {
          data["searchKeyword"] = searchField;
        }

        if (pillarId) {
          data["pillarId"] = pillarId;
        }

        if (categoryId) {
          data["categoryId"] = categoryId;
        }

        if (contentId) {
          data["contentId"] = contentId;
        }

        if (contentTitle) {
          data["contentTitle"] = contentTitle;
        }
        if (playlistId) {
          data["playlistId"] = playlistId;
        }
        params["data"] = data;
      }

      this.setState({ isUpdate: true }, () => {
        this.resetNotificationData();
        this.props._sendPushNotification(params);
      });
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
    e.preventDefault();
  }

  resetNotificationData() {
    this.setState({
      notificationMessage:'',
      screenName: '',
      searchField: '',
      pillars: [],
      pillarId: '',
      categories: [],
      categoryId: '',
      contentId: '',
      contentTitle: '',
      playlistId: '',
      searchField: '',
      contentData: [],
      typedContent: "",
      selectedCountry: '',
      timezone: '',
      segments: [],
      selectedOption:null,
      scheduleDateTime: new Date(moment().add(15, 'minutes')),
    }, ()=> {
      this.handleEnter();
    });
    this.validator.hideMessages();
  }

  getNotificationText() {
    this.props._getNotificationText().then(
      (resposne) => {
        if (resposne && resposne.statusCode == 200) {
          this.setState({ notificationData: resposne.result });
        } else {
          this.setState({ notificationData: [] });
        }
      },
      (error) => {
        console.log("error", error);
        this.setState({ notificationData: [] });
      }
    );
  }

  toggleNotification() {
    let { showPush } = this.state;
    const loadPush = !showPush;
    this.setState({ showPush: loadPush });
    if (showPush === false) {
      this.getSegmentNotificationList();
    }
  }

  updateWeeklyNotificationText(e) {
    if (this.weeklyNotificationValidator.allValid()) {
      let { notificationData } = this.state;
      Loader(true);
      this.props._updateNotificationText(notificationData).then(
        (response) => {
          this.setState(
            { notificationSuccess: "Updated successfully!" },
            () => {
              setTimeout(() => {
                this.setState({ notificationSuccess: "" });
              }, 3000);
            }
          );
          Loader(false);
        },
        (error) => {
          console.log("error", error);
          Loader(false);
        }
      );
    } else {
      this.weeklyNotificationValidator.showMessages();
      this.forceUpdate();
    }
    e.preventDefault();
  }

  setEmojiForCustom(selectedEmoji, stateInput, textAreaId) {
    const inputElement = document.getElementById(textAreaId);
    let selectionStart = inputElement.selectionStart;

    let startString = inputElement.value.substring(0, selectionStart);
    let endString = inputElement.value.substring(selectionStart, 1000);
    let conbineString = startString + selectedEmoji.emoji + endString;

    this.setState({ [stateInput]: conbineString });
  }

  setAnalyticsSegment(event){
    this.setState({segments: event.target.value})
  }

  setScreenName(event) {
    //this.resetNotificationData();
    if (event.target.value == "CATEGORY") {
      Loader(true);
      this.props._getPillars().then(
        (resposne) => {
          if (resposne && resposne.statusCode == 200) {
            this.setState({ pillars: resposne.result });
          } else {
            this.setState({ pillars: [] });
          }
          Loader(false);
        },
        (error) => {
          console.log("error", error);
          this.setState({ pillars: [] });
          Loader(false);
        }
      );
    }

    if (event.target.value == "MUSIC_PLAYER") {
      this.getContent(null);
    }
    this.setState({ screenName: event.target.value });
    this.validator.hideMessages()
  }

  setPillarOptions(pillars) {
    let pillarOptions = [];
    _.each(pillars, function (value, index) {
      {
        pillarOptions.push(
          <option key={value._id} value={value._id}>
            {value.title}
          </option>
        );
      }
    });

    return pillarOptions;
  }

  setCategoryOptions(categories) {
    let categoriesOptions = [];
    _.each(categories, function (value) {
      {
        categoriesOptions.push(
          <option key={value._id} value={value._id}>
            {value.title}
          </option>
        );
      }
    });

    return categoriesOptions;
  }

  setPillarAndGetCategory(event) {
    let pillarId =
      event && event.target && event.target.value ? event.target.value : null;
    this.setState({ pillarId });
    Loader(true);
    this.props._getCategories(pillarId).then(
      (categories) => {
        if (categories && categories.statusCode == 200) {
          this.setState({ categories: categories.result });
        } else {
          this.setState({ categories: [] });
        }
        Loader(false);
      },
      (error) => {
        console.log("error", error);
        this.setState({ categories: [] });
        Loader(false);
      }
    );
  }

  setCategory(event) {
    let categoryId =
      event && event.target && event.target.value ? event.target.value : null;
    this.setState({ categoryId });
  }

  onSelect(item) {
    this.setState({
      contentId: item._id,
      contentTitle: item.title,
      typedContent: item.title,
    });
  }

  handleTitle(event){
    this.setState({
      notificationTitle : event
    })
  }

  handleEnter(event){
    if(!this.state.notificationSuccess && event != undefined){
      this.setState({
        notificationMessage : event
      })
    } else {
      this.setState({
        notificationMessage : ''
      })
      document.querySelector(".notification-msg").innerHTML = '';
      document.querySelector(".notification-title").innerHTML = process.env.Ninjafocus ? process.env.Ninjafocus : "Ninja Focus";
    }

  }

  handleSegment = selectedOption => {
    let segments = [];
    if(!this.state.notificationSuccess){

      _.each(selectedOption, function (value, index) {
        segments.push(value.value);
      });
      this.setState({ segments : segments, selectedOption:selectedOption });
    } else {
      this.setState({ segments : segments, selectedOption:null });
    }
  };

  handleScheduleTime(value){
    let diffMs = (new Date(value).getTime() - new Date().getTime());
    let diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000);
    if(diffMins >=15 ){
      this.setState({scheduleDateTime: new Date(value), scheduleDateTimeMsg:''})
    } else {
      this.setState({scheduleDateTimeMsg: 'Time should be greater 15 minutes from current time.'})
    }
  }

  render() {
    let {
      userList,
      notificationSegmentList,
      rowsPerPage,
      totalRecord,
      selectedType,
      userTypes,
      searchString,
      selectedUsers,
      requestMessage,
      requestMessageType,
      notificationData,
      notificationSuccess,
      screens,
      screenName,
      pillars,
      categories,
      contentData,
      typedContent,
      segments,
      countrylist,
      selectedCountry,
      timezone,
      selectedOption,
      scheduleDateTimeMsg,
      isLoading,
      showPush
    } = this.state;
    const togglePushClass = showPush ? " toggle-push-container-show" : " toggle-push-container-hide";
    return (
      <div className="content">
        <Row className="pb-4">
          <Col lg={6} sm={6}>
            <h3>User Notification</h3>
          </Col>
        </Row>
        <Row>
          <Col lg={7} sm={6}>
            <Card>
              <CardHeader>User List </CardHeader>
              <CardBody>
                <Row className="mb-4">
                  <Col sm={4}>
                    <Input
                      type="text"
                      id="searchString"
                      placeholder="Search by Email"
                      name="searchString"
                      value={searchString}
                      onChange={(event) => {
                        this.handleChange(event);
                      }}
                    />
                  </Col>
                  <Col sm={4}>
                    <Input
                      type="select"
                      name="subscriptionType"
                      id="subscriptionType"
                      value={this.state.subscriptionType}
                      onChange={(event) => {
                        this.setState(
                          {
                            subscriptionType: event.target.value,
                            selectedType:
                              event.target.value === ""
                                ? "ALL"
                                : event.target.value,
                            offset: 0,
                            page: 0,
                          },
                          () => {
                            this.getUserList();
                          }
                        );
                      }}
                    >
                      <option value="">Select Subscription Type</option>
                      <option value={Constants.FREE}>{Constants.FREE}</option>
                      <option value={Constants.PAID}>{Constants.PAID}</option>
                    </Input>
                  </Col>
                  <Col sm={4}></Col>
                </Row>
                <Table responsive hover>
                  <thead>{this.setGridHeaders(this.columns)}</thead>
                  <tbody>
                    {isLoading ? <Progress /> : this.setGridData(userList)}
                  </tbody>
                </Table>
              </CardBody>
              <CardFooter>
                <Row>
                  <Col sm={12} lg={3}>
                    <span>Total Users : {totalRecord}</span>
                  </Col>
                  <Col sm={12} lg={9} className="pagination-block-right">
                    {this.setPaginationLinks(totalRecord, rowsPerPage)}
                  </Col>
                </Row>
              </CardFooter>
            </Card>
          </Col>

          <Col lg={5} sm={6}>
            <Row className="pb-4">
              <Col lg={12} sm={12}>
                <Form
                  onSubmit={(event) => {
                    event.preventDefault();
                  }}
                >
                  <Card>
                    <CardHeader>Push Notification</CardHeader>
                    <CardBody>
                      <Row>
                        <Col xs="12">
                          <FormGroup>
                            <Label>Select User Type</Label>
                            <Input
                              type="select"
                              name="selectedType"
                              id="selectedType"
                              onChange={($event) => {
                                this.handleChange($event);
                                this.validator.purgeFields();
                              }}
                            >
                              {userTypes.map((element, key) => {
                                return (
                                  <option
                                    key={key}
                                    selected={element.key === selectedType}
                                    value={element.key}
                                  >
                                    {element.value}
                                  </option>
                                );
                              })}
                            </Input>
                          </FormGroup>
                        </Col>
                      </Row>

                      {selectedType === "EMAIL" && (
                        <Row>
                          <Col xs="12">
                            {selectedUsers && selectedUsers.length > 0 && (
                              <div className="selected-email-box">
                                {selectedUsers.map((element, index) => {
                                  return (
                                    <div
                                      key={"user_" + index}
                                      className="selected-emails"
                                    >
                                      <span>{element.email}</span>
                                      <FontAwesomeIcon
                                        className="pointer"
                                        icon={faTrashAlt}
                                        onClick={() => {
                                          this.selectUser(element);
                                        }}
                                        size="sm"
                                      />
                                    </div>
                                  );
                                })}
                              </div>
                            )}
                          </Col>
                        </Row>
                      )}
                      {selectedType == "SEGMENTS" && (
                        <Row>
                          <Col xs="12">
                            <FormGroup>
                              <Label htmlFor="segments">
                                Analytics Segments
                              </Label>
                              {/* <Input
                                  type="select"
                                  name="segments"
                                  id="segments"
                                  onChange={($event) => {
                                    this.setAnalyticsSegment($event);
                                  }}
                                >
                                  <option value={''}>Select Segment</option>
                                  <option value={'NEW_USER'}>New Users</option>
                                  <option value={'EXPLORATORY_USER'}>Exploratory Users</option>
                                  <option value={'ACTIVE_USER_FREE'}>Active Users - Free</option>
                                  <option value={'ACTIVE_USER_PAID_TO_FREE'}>Active Users - Paid To Free</option>
                                  <option value={'ACTIVE_USER_PAID'}>Active user - Paid</option>
                                  <option value={'LAPSED_USER_CURRENT_SUBSCRIBER'}>Lapsed User - Current Subscriber</option>
                                  <option value={'LAPSED_USER_NEVER_PAID'}>Lapsed User - Never Paid</option>
                                  <option value={'RE_ENGAGED_AFTER_LAPSED_FREE'}>Re-engaged After Lapsing (unpaid)</option>
                                  <option value={'RE_ENGAGED_AFTER_LAPSED_PAID'}>Re-engaged After Lapsing (paid)</option>
                                </Input> */}
                              <Select
                                value={selectedOption}
                                isMulti
                                onChange={this.handleSegment}
                                options={options}
                                placeholder="Start typing or select from below"
                              />
                              <div className="validation-error">
                                {this.validator.message(
                                  "Segment",
                                  this.state.segments,
                                  "required"
                                )}
                              </div>
                            </FormGroup>
                          </Col>
                        </Row>
                      )}
                      {selectedType != "EMAIL" && !timezone && (
                        <Row>
                          <Col xs="12">
                            <FormGroup>
                              <Label>Select Country</Label>
                              <Input
                                type="select"
                                name="selectedCountry"
                                id="selectedCountry"
                                onChange={($event) => {
                                  this.handleChange($event);
                                  this.validator.purgeFields();
                                }}
                              >
                                <option value={""}>Select Country</option>
                                {countrylist.map((element, key) => {
                                  return (
                                    <option
                                      key={key}
                                      selected={
                                        element.name === selectedCountry
                                      }
                                      value={element.name}
                                    >
                                      {element.name}
                                    </option>
                                  );
                                })}
                              </Input>
                            </FormGroup>
                          </Col>
                        </Row>
                      )}
                      {/* {selectedType != "EMAIL" && !selectedCountry && (
                        <Row>
                          <Col xs="12">
                            <FormGroup>
                              <Label htmlFor="segments">Select Timezone</Label>
                              <Input
                                type="select"
                                name="timezone"
                                id="timezone"
                                onChange={($event) => {
                                  this.handleChange($event);
                                  this.validator.purgeFields();
                                }}
                              >
                                <option value={""}>Select Timezone</option>
                                <option value={"asia"}>Asia</option>
                                <option value={"europe"}>Europe Users</option>
                                <option value={"northAmerica"}>
                                  North America
                                </option>
                              </Input>
                            </FormGroup>
                          </Col>
                        </Row>
                      )} */}
                      {selectedType != "EMAIL" &&
                        selectedType != "SEGMENTS" &&
                        selectedType != "ANDROID" &&
                        selectedType != "IOS" && (
                          <Row>
                            <Col xs="12">
                              <FormGroup>
                                <Label>From Date:</Label>{" "}
                                <DatePicker
                                  readonly={true}
                                  name="fromDate"
                                  id="fromDate"
                                  value={this.state.fromDate}
                                  onChange={($event) => {
                                    this.setDate("fromDate", $event);
                                  }}
                                  minDate={this.state.minDate}
                                  maxDate={this.state.currentDate}
                                />
                                <Label style={{ marginLeft: 10 }}>
                                  To Date:
                                </Label>
                                <DatePicker
                                  readonly={true}
                                  name="toDate"
                                  id="toDate"
                                  value={this.state.toDate}
                                  onChange={($event) => {
                                    this.setDate("toDate", $event);
                                  }}
                                  minDate={this.state.minDate}
                                  maxDate={this.state.currentDate}
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                        )}
                      <Row>
                        <Col xs="12">
                          <FormGroup>
                            <Label htmlFor="notificationTitle">Title</Label>
                            {/* <Input
                              type="text"
                              id="notificationTitle"
                              placeholder="Title"
                              name="notificationTitle"
                              maxLength="20"
                              value={this.state.notificationTitle}
                              onChange={(event) => {
                                this.handleChange(event);
                              }}
                            /> */}

                            <InputEmoji
                              style={{ marginLeft: 10 }}
                              inputClass={"form-control notification-title"}
                              height={10}
                              maxLength="20"
                              type="input"
                              id="notificationTitle"
                              placeholder="Message"
                              name="notificationTitle"
                              value={this.state.notificationTitle}
                              onChange={(event) => {
                                this.handleTitle(event);
                              }}
                              zIndex={0}
                            />
                            <div className="validation-error">
                              {this.validator.message(
                                "Title",
                                this.state.notificationTitle,
                                "required"
                              )}
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs="12">
                          <FormGroup>
                            <Label htmlFor="notificationMessage">Message</Label>
                            <InputEmoji
                              style={{ marginLeft: 10 }}
                              inputClass={
                                "form-control area-height notification-msg"
                              }
                              height={60}
                              maxLength="200"
                              type="textarea"
                              id="notificationMessage"
                              placeholder="Message"
                              name="notificationMessage"
                              value={this.state.notificationMessage}
                              onChange={(val) => {
                                this.handleEnter(val);
                              }}
                            />
                            <div className="validation-error">
                              {this.validator.message(
                                "Message",
                                this.state.notificationMessage,
                                "required"
                              )}
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>

                      {/* Screen Name */}
                      <Row>
                        <Col xs="12">
                          <FormGroup>
                            <Label htmlFor="screenNames">Screen Name</Label>
                            <Input
                              type="select"
                              name="screenName"
                              id="screenName"
                              value={screenName}
                              onChange={($event) => {
                                this.setScreenName($event);
                              }}
                            >
                              <option value={""}>Select screen</option>
                              {this.setScreenOptions(screens)}
                            </Input>
                          </FormGroup>
                        </Col>
                      </Row>

                      {screenName && screenName == "SEARCH" ? (
                        <Row>
                          <Col xs="12">
                            <FormGroup>
                              <Label htmlFor="searchField">Search Field</Label>
                              <Input
                                type="searchField"
                                name="searchField"
                                id="searchField"
                                onChange={($event) => {
                                  this.handleChange($event);
                                }}
                              ></Input>
                              <div className="validation-error">
                                {this.validator.message(
                                  "Search Field",
                                  this.state.searchField,
                                  "required"
                                )}
                              </div>
                            </FormGroup>
                          </Col>
                        </Row>
                      ) : null}

                      {screenName &&
                      screenName == "SUGGESTED_PLAYLIST_PLAYER" ? (
                        <Row>
                          <Col xs="12">
                            <FormGroup>
                              <Label htmlFor="searchField">Playlist Id</Label>
                              <Input
                                type="playlistId"
                                name="playlistId"
                                id="playlistId"
                                onChange={($event) => {
                                  this.handleChange($event);
                                }}
                              ></Input>
                              <div className="validation-error">
                                {this.validator.message(
                                  "Playlist Id",
                                  this.state.playlistId,
                                  "required"
                                )}
                              </div>
                            </FormGroup>
                          </Col>
                        </Row>
                      ) : null}

                      {screenName && screenName == "CATEGORY" ? (
                        <Row>
                          <Col xs="12">
                            <FormGroup>
                              <Label htmlFor="pillarId">Select Pillar</Label>
                              <Input
                                type="select"
                                name="pillarId"
                                id="pillarId"
                                onChange={($event) => {
                                  this.setPillarAndGetCategory($event);
                                }}
                              >
                                <option key="" value={null}>
                                  Select pillar
                                </option>
                                {this.setPillarOptions(pillars)}
                              </Input>
                              <div className="validation-error">
                                {this.validator.message(
                                  "Pillar",
                                  this.state.pillarId,
                                  "required"
                                )}
                              </div>
                            </FormGroup>
                          </Col>
                        </Row>
                      ) : null}

                      {screenName &&
                      screenName == "CATEGORY" &&
                      categories &&
                      categories.length ? (
                        <Row>
                          <Col xs="12">
                            <FormGroup>
                              <Label htmlFor="categoryId">
                                Select Category
                              </Label>
                              <Input
                                type="select"
                                name="categoryId"
                                id="categoryId"
                                onChange={($event) => {
                                  this.setCategory($event);
                                }}
                              >
                                <option key="" value={null}>
                                  Select category
                                </option>
                                {this.setCategoryOptions(categories)}
                              </Input>
                              <div className="validation-error">
                                {this.validator.message(
                                  "Category",
                                  this.state.categoryId,
                                  "required"
                                )}
                              </div>
                            </FormGroup>
                          </Col>
                        </Row>
                      ) : null}
                      {screenName && screenName == "MUSIC_PLAYER" ? (
                        <Row>
                          <Col xs="12">
                            <FormGroup>
                              <Label htmlFor="content">Content</Label>
                              <div>
                                <Autocomplete
                                  getItemValue={(item) => item.title}
                                  items={contentData}
                                  inputProps={{
                                    id: "autocomplete-input",
                                    className: "form-control",
                                    placeholder: "Content search by title",
                                  }}
                                  wrapperStyle={{}}
                                  renderItem={(item, isHighlighted) => (
                                    <div
                                      key={item._id}
                                      style={{
                                        background: isHighlighted
                                          ? "lightgray"
                                          : "white",
                                      }}
                                    >
                                      {item.title}
                                    </div>
                                  )}
                                  value={typedContent ? typedContent : ""}
                                  onChange={(event, value) => {
                                    this.setState(
                                      {
                                        typedContent: value,
                                        contentId: null,
                                        contentTitle: null,
                                      },
                                      () => {
                                        clearTimeout(this.requestTimer);
                                        this.requestTimer = setTimeout(() => {
                                          this.getContent(value);
                                        }, 1000);
                                      }
                                    );
                                  }}
                                  onSelect={(value, item) => {
                                    this.onSelect(item);
                                  }}
                                  selectOnBlur={true}
                                />
                              </div>
                              <div className="validation-error">
                                {this.validator.message(
                                  "Content",
                                  this.state.contentId,
                                  "contentId"
                                )}
                              </div>
                            </FormGroup>
                          </Col>
                        </Row>
                      ) : null}
                      {["EMAIL"].indexOf(selectedType) === -1 && (
                        <Row>
                          <Col xs="12">
                            <FormGroup>
                              <Label htmlFor="campaignTemplate">
                                Schedule Date & Time
                              </Label>
                              <DateTimePicker
                                name={"scheduleDateTime"}
                                className={"form-control"}
                                value={this.state.scheduleDateTime}
                                onChange={(value) => {
                                  this.handleScheduleTime(value);
                                }}
                                timeIntervals={15}
                              />
                              <div className="validation-error">
                                {this.validator.message(
                                  "Schedule Date & Time",
                                  this.state.scheduleDateTime,
                                  "required"
                                )}
                              </div>
                              {scheduleDateTimeMsg && (
                                <div className="validation-error">
                                  {scheduleDateTimeMsg}
                                </div>
                              )}
                            </FormGroup>
                          </Col>
                        </Row>
                      )}
                      {requestMessage && (
                        <Alert
                          color={
                            requestMessageType === "ERROR"
                              ? "danger"
                              : "success"
                          }
                        >
                          {" "}
                          {requestMessage}{" "}
                        </Alert>
                      )}
                    </CardBody>

                    <CardFooter>
                      <Button
                        type="button"
                        onClick={(event) => {
                          this.sendPushNotification(event);
                        }}
                        size="sm"
                        color="primary"
                        className="float-right"
                      >
                        Save
                      </Button>
                    </CardFooter>
                  </Card>
                </Form>
              </Col>
            </Row>
            <Row className="pb-4">
              <Col lg={12} sm={12}>
                <Form
                  onSubmit={(event) => {
                    event.preventDefault();
                  }}
                >
                  <Card>
                    <CardHeader>Weekly Push Notifications</CardHeader>
                    <CardBody>
                      {notificationData &&
                        notificationData.length > 0 &&
                        notificationData.map((element, key) => {
                          return (
                            <Row key={key}>
                              <Col xs="10">
                                <FormGroup>
                                  <Label htmlFor="">{element.label}</Label>
                                  <InputEmoji
                                    key={"notification_" + key}
                                    tabIndex={key}
                                    style={{ marginLeft: 10 }}
                                    inputClass={"form-control area-height"}
                                    height={60}
                                    maxLength="200"
                                    type="textarea"
                                    keepOpenend={true}
                                    id={"newContentNotificationText" + key}
                                    //placeholder="Message"
                                    name={"newContentNotificationText" + key}
                                    value={element.body}
                                    onChange={(val) => {
                                      this.handleChangeForNotification(
                                        { target: { value: val } },
                                        key
                                      );
                                    }}
                                  />
                                  <div className="validation-error">
                                    {this.weeklyNotificationValidator.message(
                                      element.title,
                                      notificationData[key].body,
                                      "required"
                                    )}
                                  </div>
                                </FormGroup>
                              </Col>
                              <Col xs="2" className={"pt-4"}>
                                <FormGroup>
                                  <AppSwitch
                                    disabled={element.slug == "INACTIVE"}
                                    className={"mt-2"}
                                    variant={"pill"}
                                    color={"primary"}
                                    label
                                    checked={element.status}
                                    onChange={() => {
                                      this.changeStatus(key);
                                    }}
                                  />
                                </FormGroup>
                              </Col>
                            </Row>
                          );
                        })}
                      {notificationSuccess && (
                        <Alert color="success"> {notificationSuccess} </Alert>
                      )}
                    </CardBody>
                    <CardFooter>
                      {notificationData && notificationData.length > 0 && (
                        <Button
                          type="button"
                          onClick={(event) => {
                            this.updateWeeklyNotificationText(event);
                          }}
                          size="sm"
                          color="primary"
                          className="float-right"
                        >
                          Save
                        </Button>
                      )}
                    </CardFooter>
                  </Card>
                </Form>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="pb-4">
          <Col lg={12} sm={12}>
            <CardHeader>
              <h3>Push Notification Data
                <Button
                  type="button"
                  onClick={(event) => {
                    this.toggleNotification();
                  }}
                  size="lg"
                  color="primary"
                  className="float-right"
                  style={{ position: 'relative', top: '-2px' }}
                >
                Expand
                </Button>
                </h3>
            </CardHeader>
          </Col>
        </Row>
        <Row>
          <Col lg={12} sm={12}>
            <Card>
              <CardBody>
                <Table responsive hover className={`toggle-push-container${togglePushClass}`}>
                  <thead>{this.setPushGridHeaders(this.segmentColumns)}</thead>
                  <tbody>{this.setPushGridData(notificationSegmentList)}</tbody>
                </Table>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

export default UserNotification;
