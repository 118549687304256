import { connect } from 'react-redux';
import Vimeo from './Vimeo';



const mapStateToProps = (state) => {
	return {};
};

const mapDispatchToProps = (dispatch) => {
	return {
		
	};
};


export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Vimeo);
