import {createReducer} from 'redux-act';
import { requestInsightsGraphData, responseInsightsGraphData } from './action';


const defaultState = {
	requestProcess: false,
};

const reducer = createReducer({


	[requestInsightsGraphData]: (state) => {
		return {
			requestProcess: true,
		};
	},

	[responseInsightsGraphData]: (state, params) => {
		return {
			requestProcess: false,
			insightsGraphData: params
		};
	},


}, defaultState);



export default reducer
