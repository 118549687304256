import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Input } from "reactstrap";
import { getPillarCategory } from '../category/action';
import { getPillars } from '../pillar/action';
import * as _ from 'underscore';

class CategoryList extends Component {


  constructor(props) {
    super(props);
    this.state = {
      categories : []
    }
    this.props._getPillars();
    this.getPillarCategory(null);    
  }

  getPillarCategory(selectedPillarId) { 
    this.props._getPillarCategory(selectedPillarId);

  }

  createSelectOption(categories){
    const { pillarList } = this.props;
    if(!categories){
      return null;
    }

    if(categories && categories.length > 0 && pillarList && pillarList.length > 0){
      return pillarList.map(function(obj){
        let filterCategories = _.where(categories, {pillarId: obj._id }); 
        return <optgroup key={obj._id} label={obj.title}>{filterCategories && filterCategories.map(function(obj){ return <option key={obj._id} value={obj._id} >{obj.title}</option> })}</optgroup>         
      })
    }
    
    return null
  }

  render() {

    let {categoryList, selectedCategoryId, categoryChange} = this.props;
    let { categories } = this.state;
    categories = categoryList;  
        
    return (
        <Input type="select" name="categoryId" id="categoryId" value={ selectedCategoryId } onChange={($event)=>{categoryChange($event)}}>
            <option value="">Select Category</option>
            {this.createSelectOption(categories)}
        </Input>
    );
  }
}


const mapStateToProps = (state) => {
		var stateData = {
			requestProcess: state.category.requestProcess
		};

		if(state.category && state.category.data){
			stateData['categoryList'] = state.category.data.result;
    }
    
    if(state.pillar && state.pillar.data){
			stateData['pillarList'] = state.pillar.data.result;
		}
		return stateData;
};

const mapDispatchToProps = (dispatch) => {
	return {
		_getPillarCategory: (params) => {
			dispatch(getPillarCategory(params));
    },
    _getPillars: (params) => {
			dispatch(getPillars(params));
		},

	}
};


export default connect(
	mapStateToProps,
	mapDispatchToProps
)(CategoryList);
