import { connect } from 'react-redux';

import Discount from './Discount';
import { getAllDiscount, deleteDiscount, addDiscount, getAlldifferences, changeUserSubscription, getCouponUsers, addCommissionPayment, getParents, addUserDiscount } from './action';


const mapStateToProps = (state) => {
	var stateData = {
		requestProcess: state.discount.requestProcess
	};
	if (state.discount.data) {
		stateData['discountData'] = state.discount.data;
	}

	if (state.discount.addData) {
		stateData['addData'] = state.discount.addData;
	}

	if (state.discount.deleteData) {
		stateData['deleteData'] = state.discount.deleteData;
	}


	if (state.discount.appstoreDifferences) {
		stateData['appstoreDifferences'] = state.discount.appstoreDifferences;
	}

	if (state.discount.subscriptionChanged) {
		stateData['subscriptionChanged'] = state.discount.subscriptionChanged;
	}


	if (state.discount.couponUsers) {
		stateData['couponUsers'] = state.discount.couponUsers;
	}


	if (state.discount.paymentAdded) {
		stateData['paymentAdded'] = state.discount.paymentAdded;
	}


	if (state.discount.addUserDiscount) {
		stateData['addUserDiscount'] = state.discount.addUserDiscount;
	}


	return stateData;
};

const mapDispatchToProps = (dispatch) => {
	return {
		_getAlldiscounts: (params) => {
			dispatch(getAllDiscount(params));
		},
		_addDiscount: (params) => {
			dispatch(addDiscount(params));
		},
		_deleteDiscount: (params) => {
			dispatch(deleteDiscount(params));
		},

		_getAlldifferences: (params) => {
			dispatch(getAlldifferences(params));
		},

		_changeUserSubscription: (params) => {
			dispatch(changeUserSubscription(params))
		},


		_getCouponUsers: (params) => {
			dispatch(getCouponUsers(params))
		},


		_addCommissionPayment: (params) => {
			dispatch(addCommissionPayment(params))
		},

		_addUserDiscount: (params) => {
			dispatch(addUserDiscount(params))
		},
	};
};


export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Discount);
