import {createReducer} from 'redux-act';
import { requestSocialInsights, responseSocialInsights, requestInsightsGraphData, responseInsightsGraphData } from './action';


const defaultState = {
	requestProcess: false,
};

const reducer = createReducer({

	[requestSocialInsights]: (state) => {
		return {
			requestProcess: true,
			data: []
		};
	},

	[responseSocialInsights]: (state, params) => {
		return {
			requestProcess: false,
			data: params
		};
	},

	[requestInsightsGraphData]: (state) => {
		return {
			requestProcess: true,
		};
	},

	[responseInsightsGraphData]: (state, params) => {
		return {
			requestProcess: false,
			insightsGraphData: params
		};
	},


}, defaultState);



export default reducer
