import React, { Component } from 'react';
import { createRandomStr, Loader } from '../../utils/common';
import { Row, Col,  Modal, Button, ModalHeader, ModalBody, ModalFooter, FormGroup, Label, Input, Form , Alert } from "reactstrap";
import { AppSwitch } from '@coreui/react'
import SimpleReactValidator from 'simple-react-validator';
import Constants from '../../config/constants';  
import * as _ from 'underscore';
import httpClient from '../../services/http';
import S3Client from '../../services/aws-s3/S3Client';
import each from 'async/each';
import Session from '../../utils/session';


class AddTrophy extends Component {
  constructor(props) {
    super(props);
    this.validator = new SimpleReactValidator();
    this.state = {
      customValidator : [],
      
      trophies : [{
        title: "",
        defaultPoints: 1,
        info: ""
      }],
      pillarData : [],

      type : (this.props && this.props.selectedRow && this.props.selectedRow.type) ? this.props.selectedRow.type : "",
      oldType : (this.props && this.props.selectedRow && this.props.selectedRow.type) ? this.props.selectedRow.type : "",
      color : "",
      trophyImage : "",
      image : "",
      customValidatorMsg:{ trophyImage:"" },
      status : "true",

      selectedPillar : {},
      typedContent : "",
      systemLanguages : props.systemLanguages,
      defaultLanguages : props.systemLanguages,
      trophyTypes : [],
      trophyImageError: [{image: null, pillarImage: null}],
      groupImage : "",
      validatedImgs : [],
      typeImage : [],
    }
    this.session = Session.getSessionData('config');
    this.cloudFUrl = this.session && this.session.UTILS && this.session.UTILS.BUCKET_URL ? this.session.UTILS.BUCKET_URL : process.env.REACT_APP_CLOUDFRONT_URL;

    this.selectedImages = [];
    const s3Config = {
        bucketName: process.env.REACT_APP_S3_BUCKET,
        dirName: 'points/trophy',
        region: process.env.REACT_APP_AWS_DEFAULT_REGION,
        accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
        s3Url: process.env.REACT_APP_AWS_BUCKET_URL, /* optional */
    }
    this.S3Client = new S3Client(s3Config);
  }



  componentDidMount() {
    let self = this;
    self.getSelectedTypeMilestones(self.state.type)
  }

  componentWillReceiveProps(nextProps) {
    let self = this;
    self.state.trophies = [];
    self.state.pillarData = [];
    self.state.trophyTypes = [];
    let signedQueryString = nextProps.signedQueryString ? nextProps.signedQueryString : '';
    if (nextProps.selectedTypeMilestones && nextProps.selectedTypeMilestones.length) {

        let selectedTypeMilestones = (_.first(nextProps.selectedTypeMilestones));
        _.each(self.state.systemLanguages, function(langObj, langIndex){
          let trophyTypeLangObj = _.findWhere(selectedTypeMilestones['milestoneMasterTypesLang'], {languageSlug: langObj['slug']});
          if (!_.isEmpty(trophyTypeLangObj)) {
            trophyTypeLangObj['title'] = langObj.title;
            self.state.trophyTypes.push(trophyTypeLangObj)
          }else{
            self.state.trophyTypes.push({
              type : "",
              title : langObj['title'],
              languageSlug : langObj['slug'],
              slug : self.state.type
            })
          }
        })


        self.setState({
          groupImage : (
            nextProps.selectedTypeMilestones && 
            nextProps.selectedTypeMilestones.length && 
            nextProps.selectedTypeMilestones[0].milestoneMasterTypesLang && 
            nextProps.selectedTypeMilestones[0].milestoneMasterTypesLang.length && 
            nextProps.selectedTypeMilestones[0].milestoneMasterTypesLang[0] && 
            nextProps.selectedTypeMilestones[0].milestoneMasterTypesLang[0].image) ?  this.cloudFUrl+nextProps.selectedTypeMilestones[0].milestoneMasterTypesLang[0].image+signedQueryString : Constants.PLACEHOLDER_TROPHY_ACTUAL_IMG})



      _.each(nextProps.selectedTypeMilestones, function(selectedMilestone, mIndex){
        _.each(self.state.systemLanguages, function(langObj, langIndex){
          let trophyObj = _.findWhere(selectedMilestone['milestoneLang'], {languageSlug : langObj['slug']});
          trophyObj = (_.isEmpty(trophyObj)) ? {title : "", info : "", languageSlug : langObj['slug']} : trophyObj;
          self.state.systemLanguages[langIndex]['trophyLang'] = trophyObj;
        })


        self.state.trophies.push({
          "title": selectedMilestone.title,
          "defaultPoints": selectedMilestone.defaultPoints,
          "image": selectedMilestone.image,
          "imageDisplay": self.cloudFUrl+selectedMilestone.image+signedQueryString,
          "pillarImage": selectedMilestone.trophyImage,
          "pillarImageDisplay": self.cloudFUrl+selectedMilestone.trophyImage+signedQueryString,
          "trophyPlaceholderImage": selectedMilestone.trophyPlaceholderImage,
          "trophyPlaceholderImageDisplay": self.cloudFUrl+selectedMilestone.trophyPlaceholderImage+signedQueryString,
          "info": selectedMilestone.info,
          "trophyDescription": selectedMilestone.trophyDescription,
          "trophyId": selectedMilestone._id,
          "systemLanguages": JSON.parse(JSON.stringify(self.state.systemLanguages))
        })


        // let trophyTypelangObj = _.findWhere(selectedMilestone['milestoneMasterTypesLang'], {languageSlug : langObj['slug']});
        // trophyTypelangObj = (_.isEmpty(trophyObj)) ? {type : "", title : langObj['title'], languageSlug : langObj['slug']} : trophyObj;
        // self.state.trophyTypes.push(trophyTypelangObj);
      })
    }


    if (nextProps.selectedRow && nextProps.selectedRow.selectedPillars && nextProps.selectedRow.selectedPillars.length) {
      _.each(nextProps.selectedRow.selectedPillars, function(pillarObj, pIndex){
        self.state.pillarData.push({
          "pillarId": pillarObj._id,
          "slug": pillarObj.slug,
          "pillarTitle": pillarObj.title
        })
      })
    }
    let stateVars = {
      color : (nextProps.selectedRow && nextProps.selectedRow['color']) ? nextProps.selectedRow && nextProps.selectedRow['color'] : "#00000",
      status : (nextProps.selectedRow && nextProps.selectedRow['status']) ? "true" : "false",
      type : (nextProps.selectedRow && nextProps.selectedRow['type']) ? nextProps.selectedRow['type'] : "" ,
      trophies : self.state.trophies,
      trophyTypes : self.state.trophyTypes,
      pillarData : self.state.pillarData,
      typeImage: nextProps.selectedTypeMilestones && nextProps.selectedTypeMilestones.length > 0 && nextProps.selectedTypeMilestones[0].milestoneMasterTypesLang && nextProps.selectedTypeMilestones[0].milestoneMasterTypesLang.length > 0 ? nextProps.selectedTypeMilestones[0].milestoneMasterTypesLang[0].image : ''
    }
    self.setState(stateVars)
    
  }

  getSelectedTypeMilestones(type) {
    this.props.getSelectedTypeMilestones(type);
  }


  

  handleChange(event) {
    let target = event.target;
    let name = target.name;
    let value;
    value = target.value;

    if (target.type === 'checkbox') {
      value = event.currentTarget.checked
    }

    this.setState({[name]: value});
  }

  handleSubmit(e) {

    let validatedImage =  this.validateImageType('image', this.state['image']);
    let trophyValidated = this.validateImageType('pillarImage', this.state['pillarImage']);
    let {trophies, typeImage} = this.state;

    if( this.validator.allValid() && validatedImage && trophyValidated &&  !_.contains(this.state.validatedImgs, false) && this.state.groupImage){
      Loader(true)
      this.uploadImage(trophies).then((uploadedImage)=>{
        // console.log("uploadedImage")
        // console.log(uploadedImage)
        if(uploadedImage){
          uploadedImage.map((element, key) => {
              if(element.type === 'list'){
                trophies[element.index][element.name] = element.path;
              }else if(element.type === 'group'){
                typeImage = element.path;
              }
              return element;
          });
        }


        let trophiesObj = []
        _.each(trophies, function(value, index){
          trophiesObj.push({
            defaultPoints :   value["defaultPoints"],
            trophyLang :   _.pluck(value['systemLanguages'], 'trophyLang'),
            image:   value['image'],
            pillarImage: value['pillarImage'],
            trophyPlaceholderImage: value['trophyPlaceholderImage'],
            trophyId :   value["trophyId"],
          })
        })
        
        let params = {
          trophyData: trophiesObj,
          status: this.state.status.toString(),
          trophyTypes: this.state.trophyTypes,
          pillarData: this.state.pillarData,
          typeImage: typeImage,
          color: this.state.color,
          oldType: this.state.oldType
        }

        this.props.updateTrophy(e, params);
        Loader(false)
      });

    }else{
      this.validator.showMessages();
      this.forceUpdate();
    }
    e.preventDefault();
  }

  uploadImage(){
    const selectedImages = this.selectedImages;
    const self = this;
    return new Promise((resolve, reject)=>{
      if(selectedImages.length > 0){
        self.S3Client.getPolicy().then((policyRes)=>{
          httpClient.call('PILLAR', '/get_signature', {policy: policyRes}, {method: 'GET', headers: {}}).then((signatureRes)=>{
            if(signatureRes && signatureRes.statusCode === 200 && signatureRes.result && signatureRes.result.signature){
                let uploadedImageObj = [];
                each(selectedImages, function(imageObj, callback) {
                  if(imageObj.file instanceof File){
                    createRandomStr((randString)=>{ 
                      let signature = signatureRes.result.signature;
                      let filename = imageObj.folder+'/'+randString;
                      let ext = imageObj.file.name.split('.')[1];

                      self.S3Client.uploadFile(imageObj.file, filename+'.'+ext, signature).then(data => {
                        uploadedImageObj.push({index: imageObj.index, name: imageObj.name, path: data.key, type: imageObj.type});
                        callback();
                      }).catch(err => {
                        console.log(err);
                        return callback(null, err); 
                      })
                    });
                  }else{
                    callback()
                  }
                }, function(err) {
                  // if any of the file processing produced an error, err would equal that error
                  if( err ) {
                    // One of the iterations produced an error.
                    // All processing will now stop.
                    console.log('A file failed to process', err);
                    reject(err);
                  } else {
                    console.log('All files have been processed successfully');
                    console.log('uploadedImageObj', uploadedImageObj)
                    resolve(uploadedImageObj);                  
                  }
              });
            }else{
              console.log('ERROR:- SIGNATURE RESPONSE ', signatureRes);
              reject(signatureRes.statusDescription);
            }
          }, (error) => {
            console.log('ERROR:- SIGNATURE REQUEST ', error);
            reject(error);
          })
        }, (error) => {
          console.log('ERROR:- SIGNATURE REQUEST ', error);
          reject(error);
        });
      }else{
        resolve();   
      }
    })
  }

  formatTitlePosLabel (s) {
    if (typeof s !== 'string') return ''
      s = s.replace("_", " ");
      s = s.charAt(0).toUpperCase() + s.slice(1).toLowerCase();
      return s;
  }

  /*setImg(event) {
    let self = this;
    var file = event.target.files[0];
    this.validateImageType(event.target.name, file)

    if (event.target.files && event.target.files[0]) {
      this.setState({
        groupImage: URL.createObjectURL(event.target.files[0])
      });
    }


    this.selectedImages.push({index: 0, name: event.target.name, file: file, folder: 'type', type: 'group'});
    self.setState({[event.target.name] : file})
  }*/


  setImg(event) {
    let self = this;
    var file = event.target.files[0];
    this.setState({[event.target.name] : file});
    this.validateImageType(event.target.name, file)

    if (this.validateImageType(event.target.name, file) && event.target.files && event.target.files[0]) {
      this.selectedImages.push({index: 0, name: event.target.name, file: file, folder: 'type', type: 'group'});
      self.setState({groupImage: URL.createObjectURL(event.target.files[0])})
    }else {
      self.setState({groupImage: ""})
    }
  }


  setImageForTrophy(event, index){
    const name = event.target.name;
    const file = event.target.files[0];
    let proceed = this.validateImageTypeForTrophy(index, name, file)

    if (!this.state.validatedImgs[index]) {
      this.state.validatedImgs[index] = true;
    }


    if (proceed) {
      this.state.validatedImgs[index] = true;
      this.state.trophies[index][name] = file;
      
      this.state.trophies[index][name+'Display'] = (file) ? URL.createObjectURL(file) : "";

      this.selectedImages.push({index: index, name: name, file: file, folder: name, type: 'list'});
      this.setState({trophies:this.state.trophies, validatedImgs : this.state.validatedImgs});
    }else{
      this.state.trophies[index][name+'Display'] = "";
      this.state.validatedImgs[index] = false;
      this.setState({validatedImgs : this.state.validatedImgs, trophies : this.state.trophies})
    }
  }

  /*setImageForTrophy(event, index){
    const name = event.target.name;
    const file = event.target.files[0];
    this.validateImageTypeForTrophy(index, name, file)
    this.state.trophies[index][name] = file;    

    this.state.trophies[index][name+'Display'] = URL.createObjectURL(file)
    


    this.selectedImages.push({index: index, name: name, file: file, folder: name, type: 'list'});
    this.setState(this.state.trophies);
  }*/

validateImageTypeForTrophy(index, name, file){
    let self = this;
    let trophyImageError = self.state.trophyImageError;
    if (!trophyImageError[index]) {
      trophyImageError[index] = {}
    }

    if(!file){
      trophyImageError[index][name] = ''
      self.setState({trophyImageError: trophyImageError});
      return true;
    }else if(['image/jpeg', 'image/png'].indexOf(file.type) === -1){
      trophyImageError[index][name] = 'Please select valid image(.jpg, .png) file.'
      self.setState({trophyImageError: trophyImageError}, ()=>{
        console.log('this', this.state);
      });
      return false;
    } else {
      trophyImageError[index][name] = ''
      self.setState({trophyImageError: trophyImageError});
      return true;
    }
  }
  validateImageType(name, file){
    let self = this;
    let currentCustomValidatorMsg = self.state.customValidatorMsg;
    if(!file){
      currentCustomValidatorMsg[name] = '';
      self.setState({customValidatorMsg: currentCustomValidatorMsg});
      return true;
    }else if(['image/jpeg', 'image/png'].indexOf(file.type) === -1){
      currentCustomValidatorMsg[name] = 'Please select valid image(.jpg, .png) file.';
      self.setState({customValidatorMsg: currentCustomValidatorMsg});
      return false;
    } else {
      currentCustomValidatorMsg[name] = '';
      self.setState({customValidatorMsg: currentCustomValidatorMsg});
      return true;
    }
  }

  onSelect(item){
    this.setState({selectedPillar: item, typedContent: item.title, pillarId : item['_id']})
  }

  



  addNewTrophy(event) {
    let defaultLanguages = JSON.parse(JSON.stringify(this.state.defaultLanguages));
    _.each(defaultLanguages, function(value, index){
      defaultLanguages[index]['trophyLang'] = {title : "", info : "", trophyDescription: "", languageSlug :  value['slug']};
    });
    

    this.state.trophies.push({
      defaultPoints: (this.state.trophies.length) ? _.last(this.state.trophies)['defaultPoints'] + 100 : 1, 
      systemLanguages : defaultLanguages,
      image: "",
      pillarImage: "",
      imageDisplay: "",
      pillarImageDisplay: "",
    })
    this.state.trophyImageError.push({image: null, pillarImage: null});
    

    this.setState({
      trophiesCount : this.state.trophiesCount + 1,
      trophies : this.state.trophies,
      trophyImageError: this.state.trophyImageError
    });
    event.preventDefault();
  }



  trophyCollectionChange(event, index) {
    let target = event.currentTarget;
    let name = target.name;
    let value = target.value;
    if (name === "defaultPoints") {
      value = parseInt(value);
    }
    this.state.trophies[index][name] = value;
    this.setState({trophies : this.state.trophies});
  }

  removeTrophyRow(index)  {
    this.state.trophies.splice(index, 1);
    this.setState({trophies : this.state.trophies})
  }

  


  PillarOptions(pillarData){
    let pillarOptions = []    
    pillarData = (this.props.selectedRow && this.props.selectedRow.selectedPillars && this.props.selectedRow.selectedPillars.length) ? _.union(this.props.selectedRow.selectedPillars, pillarData)  : pillarData;
      _.each(pillarData, function(pillarObj, index){
        pillarOptions.push(
          <option key={pillarObj._id} value={pillarObj._id}>{pillarObj.title}</option>
        )
      })
    return pillarOptions;
  }

  addRemovePillars(event) {
    let self = this
    let options = event.currentTarget.options;
    self.state.pillarData = [];
    for (let i = 0, l = options.length; i < l; i++) {
      if (options[i].selected) {
        let pillarObj = {}
        if(_.findWhere(self.props.pillarData, {_id : options[i].value})) {
          pillarObj = _.findWhere(self.props.pillarData, {_id : options[i].value});
          self.state.pillarData.push({
            pillarId : pillarObj['_id'],
            slug : pillarObj['slug'],
            pillarTitle : pillarObj['title'],
          })
        }
      }
    }
    self.setState({pillarData : self.state.pillarData});
    event.preventDefault();
  }


  setTitleDesc(event, trophyIndex, langIndex, languageSlug) {
    let self = this;
    let name = event.target.name; 
    let value = event.target.value;

    self.state.trophies[trophyIndex]['systemLanguages'][langIndex]['trophyLang'][name] = value;
    self.state.trophies[trophyIndex]['systemLanguages'][langIndex]['trophyLang']['languageSlug'] = languageSlug;
    this.setState({
      trophies : self.state.trophies
    })
  }

  TitleDescriptionLayout(trophy, trophyIndex) {
    let self = this;
    let trophyHtml = [];
    let { trophies } = this.state;
    _.each(trophies[trophyIndex].systemLanguages, function(langObj, langIndex){
      trophyHtml.push(
          <Col xs="6" key={langObj.title + langObj.slug + langIndex + trophyIndex}>
            <Row>
              <Col xs="12">
                  <FormGroup>
                    <Label htmlFor={langObj.title + langObj.slug + langIndex + trophyIndex}>{langObj.title} Title</Label>
                    
                    <Input type="text" id={langObj.title + langObj.slug + langIndex + trophyIndex} placeholder="Title" name="title" value={self.state.trophies[trophyIndex]['systemLanguages'][langIndex]['trophyLang']['title']} onChange={(event)=>{self.setTitleDesc(event, trophyIndex, langIndex, langObj['slug'])}} />                                              

                    {<div className="validation-error">{ self.validator.message(langObj.title + ' title', self.state.trophies[trophyIndex]['systemLanguages'][langIndex]['trophyLang']['title'], 'required') }</div>}   
                    
                  </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col xs="12">
                  <FormGroup>
                      <Label htmlFor={langObj.title + langObj.slug}>{langObj.title} Short Description</Label>
                      
                      <Input type="textarea" id={langObj.trophyDescription + langObj.slug} placeholder="Description" name="trophyDescription" value={self.state.trophies[trophyIndex]['systemLanguages'][langIndex]['trophyLang']['trophyDescription']} onChange={(event)=>{self.setTitleDesc(event, trophyIndex, langIndex, langObj['slug'])}} />
                      
                      {<div className="validation-error">{ self.validator.message(langObj.title + ' trophyDescription', self.state.trophies[trophyIndex]['systemLanguages'][langIndex]['trophyLang']['trophyDescription'], 'required') }</div> }


                  </FormGroup>
              </Col>
            </Row>

            {<Row>
              <Col xs="12">
                  <FormGroup>
                      <Label htmlFor={langObj.title + langObj.slug}>{langObj.title} Description</Label>
                      
                      <Input type="textarea" id={langObj.title + langObj.slug} placeholder="Description" name="info" value={self.state.trophies[trophyIndex]['systemLanguages'][langIndex]['trophyLang']['info']} onChange={(event)=>{self.setTitleDesc(event, trophyIndex, langIndex, langObj['slug'])}} />
                      
                      {<div className="validation-error">{ self.validator.message(langObj.title + ' info', self.state.trophies[trophyIndex]['systemLanguages'][langIndex]['trophyLang']['info'], 'required') }</div> }


                  </FormGroup>
              </Col>
            </Row>}  
          </Col>
      );
    })

    return trophyHtml;
  }


  TrophyAttrubutesLayout() {
    let self = this;
    let trophyHtml = [];
    let {trophies, trophyPlaceholderImage} = this.state;
    let {signedQueryString} = this.props;
    _.each(trophies, function(trophy, index){
      let imageName = self.state.trophies[index]['image'] && typeof self.state.trophies[index]['image'] == 'object' ? self.state.trophies[index]['image'].name : '';
      let pillarImage = self.state.trophies[index]['pillarImage'] && typeof self.state.trophies[index]['pillarImage'] == 'object' ? self.state.trophies[index]['pillarImage'].name : '';
      let trophyPlaceholderImage = self.state.trophies[index]['trophyPlaceholderImage'] && typeof self.state.trophies[index]['trophyPlaceholderImage'] == 'object' ? self.state.trophies[index]['trophyPlaceholderImage'].name : '';


      trophyHtml.push(
        <div key={index} style={{backgroundColor: '#dee0e2', padding: 20, borderRadius: 5, marginBottom: 20, position:'relative'}}>
          <Button  size="sm" color="danger" className="remove-trophy" onClick={(event) => { self.removeTrophyRow(index) }} disabled={self.state.trophies.length < 2}>Remove Trophy</Button>
            <Row>{self.TitleDescriptionLayout(trophy, index)}</Row>
            <Row>
                <Col xs="4">
                    <FormGroup>
                      <Label htmlFor="title">Points</Label>
                      <Input type="number" id="defaultPoints" placeholder="Points" name="defaultPoints" value={self.state.trophies[index]['defaultPoints']} onChange={(event)=>{self.trophyCollectionChange(event, index);}}   min="1" />                                              
                      <div className="validation-error">{ self.validator.message('Points', self.state.trophies[index]['defaultPoints'], 'required') }</div>
                      
                    </FormGroup>
                </Col>
            </Row>
            <hr/>
            <Row>
             
              <Col xs="4">
              <Label htmlFor={'image_'+index}>Banner Image for Trophy winning screen</Label>
              <div className="trophy-img-wrap">
                  <FormGroup>
                   
                    {
                      (trophy.imageDisplay) ? <img src={trophy.imageDisplay} alt=""  /> :  <div className="placeholder-trophy">No Image Selected</div>
                    }
                    <Input type="file" id={'image_'+index} name="image" onChange={(event)=>{self.setImageForTrophy(event, index);}}   min="1" style={{display: 'none'}} />
                    <label htmlFor={'image_'+index} className="btn btn-primary" style={{display: 'block'}}>{imageName ? imageName : 'Choose Banner Image'}</label>                                      
                    {(self.state.trophyImageError && self.state.trophyImageError[index] && !self.state.trophyImageError[index]['image']) ? <div className="validation-error">{ self.validator.message('Image', self.state.trophies[index]['image'], 'required') }</div> : null}


                    {(self.state.trophyImageError && self.state.trophyImageError[index] && self.state.trophyImageError[index]['image']) ?  <div className="validation-error align-validation-text">{ self.state.trophyImageError[index]['image']}</div> : null}


                  </FormGroup>
               </div>
              </Col>
              <Col xs="4">
              <Label htmlFor={'pillarImage_'+index}>Trophy Image for Trophy Room</Label>
                <div className="trophy-img-wrap">
                    <FormGroup>

                      {
                        (trophy.pillarImageDisplay) ? <img src={trophy.pillarImageDisplay} alt=""  /> :  <div className="placeholder-trophy">No Image Selected</div>
                      }

                      <Input type="file" id={'pillarImage_'+index} name="pillarImage" onChange={(event)=>{self.setImageForTrophy(event, index);}} min="1" style={{display: 'none'}}/>  
                      <label htmlFor={'pillarImage_'+index} className="btn btn-primary" style={{display: 'block'}}>{pillarImage ? pillarImage : 'Choose Trophy Room Image'}</label>                                            
                      {(self.state.trophyImageError && self.state.trophyImageError[index] && !self.state.trophyImageError[index]['pillarImage']) ? <div className="validation-error">{ self.validator.message('Trophy Image', self.state.trophies[index]['pillarImage'], 'required') }</div> : null}


                      {(self.state.trophyImageError && self.state.trophyImageError[index] && self.state.trophyImageError[index]['pillarImage']) ?  <div className="validation-error align-validation-text">{ self.state.trophyImageError[index]['pillarImage']}</div> : null}  


                    </FormGroup>
                </div>
              </Col>
              <Col xs="4">
                <Label htmlFor={'trophyPlaceholderImage_'+index}>Trophy Placeholder Image for Trophy Room</Label>
                  <div className="trophy-img-wrap">
                      <FormGroup>

                        {
                          (trophy.trophyPlaceholderImageDisplay) ? <img src={trophy.trophyPlaceholderImageDisplay} alt=""  /> :  <div className="placeholder-trophy">No Image Selected</div>
                        }

                        <Input type="file" id={'trophyPlaceholderImage_'+index} name="trophyPlaceholderImage" onChange={(event)=>{self.setImageForTrophy(event, index);}} min="1" style={{display: 'none'}}/>  
                        <label htmlFor={'trophyPlaceholderImage_'+index} className="btn btn-primary" style={{display: 'block'}}>{trophyPlaceholderImage ? trophyPlaceholderImage : 'Choose Trophy Room Image'}</label>                                            
                        {(self.state.trophyImageError && self.state.trophyImageError[index] && !self.state.trophyImageError[index]['trophyPlaceholderImage']) ? <div className="validation-error">{ self.validator.message('Trophy Image', self.state.trophies[index]['trophyPlaceholderImage'], 'required') }</div> : null}


                        {(self.state.trophyImageError && self.state.trophyImageError[index] && self.state.trophyImageError[index]['trophyPlaceholderImage']) ?  <div className="validation-error align-validation-text">{ self.state.trophyImageError[index]['trophyPlaceholderImage']}</div> : null}  


                      </FormGroup>
                  </div>
                </Col>
               
            </Row>
        </div>
      );
    })

    return (
      <div>
        {trophyHtml}
        <Row>
            <Col xs="10">&nbsp;</Col>
            <Col xs="2">
              <Button  size="sm" color="primary" onClick={(event) => { this.addNewTrophy(event) }}>Add More Trophy</Button>
            </Col>            
        </Row>
      </div>
    )
  }


  changeTrophyTypes(event, index, languageSlug) {
    let self  = this;
    this.state.trophyTypes[index][event.target.name] = event.target.value;
    this.state.trophyTypes[index]['languageSlug'] = languageSlug;
    this.state.trophyTypes[index]['slug'] = self.state.type;
    this.setState({trophyTypes : this.state.trophyTypes})
  }


  TrophyTypeSection() {
     let self = this;
    return this.state.trophyTypes.map(function(obj, index){
      return (
        <Col xs="6" key={obj.title}>
            <FormGroup>
              <Label htmlFor="title">{obj.title} Type</Label>
              <Input type="text" id={obj.title + 'Type'} disabled={obj.languageSlug === Constants.ENGLISH} placeholder="Type" name="type" value={self.state.trophyTypes[index]['type']} onChange={(event)=>{self.changeTrophyTypes(event, index, obj.languageSlug)}} />                                              
              {<div className="validation-error">{ self.validator.message(obj.title + ' type', self.state.trophyTypes[index]['type'], 'required') }</div> }  
            </FormGroup>
        </Col>
      );
    })
  }



  render() {
    let self = this;
    let { openEditTrophyModal, closeModal, editTrophyPopupError, trophyFreePillars, selectedRow } = this.props;
    let { groupImage } = this.state;
    let selectedPillarIds = [];
    if(selectedRow && selectedRow.selectedPillars && selectedRow.selectedPillars.length > 0){
      selectedRow.selectedPillars.map((element, key)=>{
        selectedPillarIds.push(element._id);
        return element;
      })
    }

    let typeImage = this.state.typeImage && typeof this.state.typeImage == 'object' ? this.state.typeImage.name : '';
    
    return (
      <Modal isOpen={openEditTrophyModal} size="lg">
          <ModalHeader toggle={()=>{closeModal(false)}}>Update Trophy</ModalHeader>
          <Form onSubmit={(event) => {this.handleSubmit(event)}}>  
            <ModalBody>
              
              {self.TrophyAttrubutesLayout()}
              <div style={{padding: 20, borderTop: '2px solid #dee0e2', marginTop: 20, marginBottom : "auto"}}>
                <Row>
                  <Col xs="4">
                      <FormGroup>
                        <Label htmlFor="pillars">Pillars</Label>
                        <Input type="select" name="pillars[]" id="pillars" onChange={($event)=>{this.addRemovePillars($event)}} multiple className="multi-select" defaultValue={selectedPillarIds}>
                         {this.PillarOptions(trophyFreePillars)}
                        </Input>
                         <div className="validation-error">{ this.validator.message('pillar', this.state.pillarData, 'required') }</div>   

                      </FormGroup>
                  </Col>
                  <Col xs="8">
                    <Row>{this.TrophyTypeSection()}</Row>
                    <Row>                                 
                        <Col xs="4">
                          <FormGroup>
                              <Label htmlFor="color">Background Color</Label>
                              <Input type="color" name="color" id="color" value={this.state.color} onChange={(event)=>{this.handleChange(event)}}></Input>
                          </FormGroup>
                        </Col>
                        <Col xs="4">
                          <Label htmlFor="typeImage">Type Image</Label>
                          <div className="trophy-img-wrap">
                              <FormGroup>                          
                                  {
                                    (groupImage) ? <img src={groupImage} alt=""  /> :  <div className="placeholder-trophy">No Image Selected</div>
                                  }

                                  <Input type="file" name="typeImage" id="typeImage" onChange={(event) => this.setImg(event)}  style={{display: 'none'}}  />
                                  <label htmlFor="typeImage" className="btn btn-primary" style={{display: 'block'}}>{typeImage ? typeImage : 'Choose A Group Image'}</label>
                                  
                                  {(!this.state.customValidatorMsg.typeImage) ? <div className="validation-error">{ self.validator.message('Group Image', self.state.typeImage, 'required') }</div> : null}

                                  {<div className="validation-error">{this.state.customValidatorMsg.typeImage && <div className="srv-validation-message">{this.state.customValidatorMsg.typeImage}</div>}</div>}

                              </FormGroup>
                          </div>
                        </Col>
                        <Col xs="4">
                            <FormGroup>
                                <Label htmlFor="status">Status</Label>
                                <div>
                                  <AppSwitch className={ 'mx-1'} variant={ 'pill'} color={ 'primary'}  id="status"  name="status"
                                    onChange={($event)=>{this.handleChange($event)}}
                                    checked={(this.state.status === 'true')}
                                    value={this.state.status ? 'true' : 'false'} />
                                </div>
                            </FormGroup>

                        </Col>
                      </Row>
                  </Col>
                </Row>
              </div>
              {(editTrophyPopupError) ? <Alert color="danger">{editTrophyPopupError}</Alert> : null} 
            </ModalBody>
            <ModalFooter className="justify-content-right">
              <Button type="submit" size="sm" color="primary">Update</Button>
              <Button type="reset" size="sm" color="danger"  onClick={() => { closeModal() }}>Cancel</Button>
            </ModalFooter>
          </Form>
        </Modal>
      
    );
  }
}

export default AddTrophy;
