import { connect } from 'react-redux';

import WhatsNew from './WhatsNew';
import { getWhatsNewList, getWhatsNewVersionList, deleteWhatsNew } from './action';


const mapStateToProps = (state) => {
	var stateData = {
		requestProcess: state.whatsNew.requestProcess
	};
	if(state.whatsNew.whatsNewList){
		stateData['whatsNewList'] = state.whatsNew.whatsNewList;
		stateData['requestProcess'] = false;
	}

  if(state.whatsNew.whatsNewVersionList){
		stateData['whatsNewVersionList'] = state.whatsNew.whatsNewVersionList;
    stateData['requestProcess'] = false;
	}

  if(state.whatsNew.whatsNewDeletedResponse){
    stateData['requestProcess'] = false;
    stateData['whatsNewDeletedResponse'] = state.whatsNew.whatsNewDeletedResponse;
  }
	return stateData;
  // return {}
};

const mapDispatchToProps = (dispatch) => {
	return {
		_getWhatsNewList: (params) => {
			dispatch(getWhatsNewList(params));
		},
    _getWhatsNewVersionList: (params) => {
			dispatch(getWhatsNewVersionList(params));
		},
    _deleteWhatsNew: (params) => {
			dispatch(deleteWhatsNew(params));
		}
	};
};


export default connect(
	mapStateToProps,
	mapDispatchToProps
)(WhatsNew);
