import React, { Component } from 'react'; 
import { Col,Row,  Nav, NavItem, NavLink } from 'reactstrap'; 
 import DataGrid from "g6reactdatagrid"; 
 import { Loader} from '../../utils/common';
 import EditSystemEmail from './EditSystemEmail';
 

class SystemEmail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editModel: false,
      offset: 0,
      rowsPerPage: 10,
      totalRecord: 0,
      systemEmailList: [],
      rowData: false,
      imagesForEmail:{},
      signedQueryString: null
    };

    this.columns = [
      { "columnName": "subject", "displayName": "Subject"},
      { "columnName": "title", "displayName": "Title"},
      { "columnName": "slug", "displayName": "Slug"},
      { "columnName": "lastUpdate", "displayName": "Last Update", "type": "date", "format": "MM-DD-YYYY HH:mm:ss  "}
    ];
  }

  componentDidMount() {
    this.getSystemEmails();
    this.getImagesForEmail();
  }

  getSystemEmails(){
    this.props._getSystemEmails({});
  }

  getImagesForEmail(){
    this.props._getImagesForEmail({});
  }


  componentWillReceiveProps(nextProps){
    Loader(nextProps.requestProcess);
    if (nextProps.systemEmailData && nextProps.systemEmailData.statusCode === 200) {
      if(nextProps.systemEmailData.result && nextProps.systemEmailData.result){
        this.setState({systemEmailList: nextProps.systemEmailData.result, signedQueryString: nextProps.systemEmailData.signedQueryString});
      }
    }

    if (nextProps.updateSystemEmail && nextProps.updateSystemEmail.statusCode === 200) {
      this.getSystemEmails(); 
      this.closeModel();
    }

    if (nextProps.imagesForEmail && nextProps.imagesForEmail.statusCode === 200) {
      this.setState({imagesForEmail: nextProps.imagesForEmail});
    }
    
  }

  editEmailTemplateModel(rowData){
    this.setState({editModel: true, rowData: rowData});
  }

  closeModel(){
    this.setState({editModel: false, rowData: false});
  }

  updateTemplate(templateObj) {

    this.props._updateSystemEmail(templateObj);
  }





  loading = () => <div className="animated fadeIn pt-1 text-center">Loading...</div>

  render() {

    let {editModel, rowData, page, rowsPerPage, totalRecord, systemEmailList, imagesForEmail, signedQueryString} = this.state;

    let totalRecordsLabel = '##TOTAL_RECORDS## System Email'
    let options = {
      totalRecords: systemEmailList.length,
      totalRecordsLabel: totalRecordsLabel,
      rowsPerPage: rowsPerPage,
      page: page,
      loadMore: false,
      customColumn: {
        isActive: true,
        columnTitle: 'Action',
        columnContent: (rowData) => {
          return (
          <div>
            <Nav>
              <NavItem>
                <NavLink href="#" onClick={() => { this.editEmailTemplateModel(rowData)}}>Edit</NavLink>
              </NavItem>              
            </Nav>
          </div>
          );
        }
      },
      onPageChange(pageNumber) {
              
      },

      onColumnSortChange: (column, direction) => {
        
      }
    };

    
    return (
      <div className="">
        <Row className="pb-4">
          <Col lg={8} sm={12}>
            <h3>System Emails</h3>                     
          </Col>
        </Row>        
        <Row>
          <Col>
            {editModel && <EditSystemEmail imagesForEmail={imagesForEmail} signedQueryString={signedQueryString} {...this.props} rowData={rowData} closeModel={()=>{this.closeModel()}} updateTemplate={(templateObj) => {this.updateTemplate(templateObj)}} />}
            <DataGrid
              columns={this.columns}
              options={options}
              rows={systemEmailList}
            />
          </Col>
        </Row>
      </div>
    );
  }
}

export default SystemEmail;
