import React, { Component } from 'react';
import { Loader, FormatTitlePosLabel } from '../../../utils/common';
import { Alert, Row, Col, Card, CardBody, CardHeader, CardFooter, Table, Button, Modal, ModalHeader, ModalBody, ModalFooter, Pagination, PaginationItem, PaginationLink, FormGroup, Label, Input, Tooltip } from "reactstrap";
import Constants from '../../../config/constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash, faCopy, faPlus, faSearch} from '@fortawesome/free-solid-svg-icons';
import SimpleReactValidator from 'simple-react-validator';
import Autocomplete from "react-autocomplete";

class DeepLink extends Component {
  constructor(props) {
    super(props);
    this.validator = new SimpleReactValidator();
    this.state = {
      openDeepLinkModal: false,
      total: 0,
      screens: [],
      screenName: null,
      searchField: null,
      pillars: [],
      pillarId: null,
      pillarName: null,
      categories: [],
      categoryId: null,
      categoryName: null,
      typedContent: "",
      contentId: null,
      contentData: [],
      contentTitle: null,
      deepLinks: [],
      isErrorMessaeg: '',
      copyText: "Copy to clipboard",
      page: 0,
      limit: 10,
      searchString: '',
      tooltipIndex: -1,
      openConfirmModal: false,
      deepLinkId: null,
      mediaSeriesList: [],
      mediaSeries:null,
      mediaSeriesTitle:null
    }
    this.getDeepLinks();
    this.getScreenSlug()
    this.getMediaSeries()
  }

  getMediaSeries() {
    Loader(true);
    this.props._getMediaSeries().then((getMediaSeriesResponse) => {
      if (getMediaSeriesResponse.statusCode == 200 && getMediaSeriesResponse.result && getMediaSeriesResponse.result.length) {
        this.setState({ mediaSeriesList: getMediaSeriesResponse.result });
      }
      Loader(false)
    }, error => {
      Loader(false)
    })
  }

  getDeepLinks() {
    let { page, limit, searchString } = this.state;
    page = parseInt(page * limit);
    Loader(true);
    this.props._getDeepLinks({ offset: page, limit, searchString }).then((response) => {
      if (response.statusCode == 200) {
        this.setState({ deepLinks: response.result.list, total: response.result.total });
      }
      Loader(false)
    }, error => {
      Loader(false)
    })
  }

  getScreenSlug(){
    this.props._getScreenSlug().then(  response => {
        if(response.statusCode && response.statusCode == 200){
          this.setState({screens: response.result});
        }
    }, error => {
      console.log('ERROR ', error)
    })
  }


  copyLink(link) {
      this.setState({ copyText: 'Copied!' }, () => {

        // navigator.clipboard.writeText(link).then(function () {
        //   console.log('Async: Copying to clipboard was successful!');
        // }, function (err) {
        //   console.error('Async: Could not copy text: ', err);
        // });
        if (window.clipboardData && window.clipboardData.setData) {
          // IE specific code path to prevent textarea being shown while dialog is visible.
          return window.clipboardData.setData("Text", link);

        } else if (document.queryCommandSupported && document.queryCommandSupported("copy")) {
          var textarea = document.createElement("textarea");
          textarea.textContent = link;
          textarea.style.position = "fixed";  // Prevent scrolling to bottom of page in MS Edge.
          document.body.appendChild(textarea);
          textarea.select();
          try {
            return document.execCommand("copy");  // Security exception may be thrown by some browsers.
          } catch (ex) {
            console.warn("Copy to clipboard failed.", ex);
            return false;
          } finally {
            document.body.removeChild(textarea);
          }
        }

      })
  }




  toggleDeepLinkModal() {
    this.setState({
      openDeepLinkModal: !this.state.openDeepLinkModal,
      isErrorMessaeg: null
    });
    this.validator.hideMessages();
  }



  resetDeepLink() {
    this.toggleDeepLinkModal();
  }


  handleChange(event) {
    let target = event.target;
    let name = target.name;
    let value = target.value;
    this.setState({ [name]: value });
  }

  setCopyTextToInitial() {
    let self = this
    setTimeout(() => {
      self.setState({ copyText: "Copy to clipboard" })
    }, 100)
  }

  generateNewDeepLink(e) {
    if (this.validator.allValid()) {
      const {screenName, contentId, contentTitle, pillarId, pillarName, categoryId, categoryName, typedContent, searchField, playlistId, mediaSeries, mediaSeriesTitle} = this.state;
      const params = {screenName, contentId, contentTitle, pillarId, pillarName, categoryId, categoryName, typedContent, searchField, playlistId, mediaSeries, mediaSeriesTitle};


      this.setState({ isErrorMessaeg: null }, () => {
        Loader(true)
        this.props._generateDeepLink(params).then( response => {
          this.getDeepLinks();
          this.toggleDeepLinkModal()
          Loader(false)
        }, error => {
          this.setState({isErrorMessaeg: error})
          Loader(false)
        });
      })
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
    e.preventDefault();
  }

  setPillarOptions(pillars) {
    let pillarOptions = [];
    pillars.forEach((value, index) => {
      {
        pillarOptions.push(
          <option key={value._id} value={value._id}>
            {value.title}
          </option>
        );
      }
    });
    return pillarOptions;
  }


  setPaginationLinks() {
    let { page, total, limit } = this.state;
    let linksCount = (total > 0 && limit > 0) ? Math.ceil(total / limit) : 0;
    let links = [];
    for (let i = 0; i < linksCount; i++) {
      links.push(<PaginationItem key={i} active={page == i} >
        <PaginationLink disabled={page == i} href="javascript:void)(0)" onClick={(event) => { this.setState({ page: i }, () => { this.getDeepLinks() }) }} >
          {i + 1}
        </PaginationLink>
      </PaginationItem>);
    }

    return (<Pagination aria-label="Page navigation example" className="float-right"> {links} </Pagination>)
  }


  setScreenName(event) {
    this.validator.purgeFields();
    let updateData = {contentId: '', contentTitle: '', pillarId: '', categoryId: '', typedContent: '', searchField: ''}
    if (event.target.value == "CATEGORY") {
      Loader(true);
      this.props._getPillars().then(
        (resposne) => {
          if (resposne && resposne.statusCode == 200) {
            updateData['pillars'] = resposne.result;
          } else {
            updateData['pillars'] = [];
          }
          console.log('updateData',updateData)
          this.setState(updateData)
          Loader(false);
        },
        (error) => {
          console.log("error", error);
          updateData['pillars'] = [];
          this.setState(updateData)
          Loader(false);
        }
      );
    }

    if (event.target.value == "MUSIC_PLAYER") {
      this.getContent(null);
    }
    this.setState({ screenName: event.target.value });
    this.validator.hideMessages()
  }

  getContent(searchKey) {
    Loader(true);
    this.props
      ._getContentList({
        search: searchKey,
        limit: 1000,
        offset: 0,
        contentType: Constants.AUDIO,
      })
      .then(
        (response) => {
          if (response && response.statusCode === 200) {
            this.setState({ contentData: response.result });
          } else {
            this.setState({ contentData: [] });
          }
          Loader(false);
        },
        (error) => {
          console.log("error", error);
          Loader(false);
        }
      );
  }

  onSelect(item) {
    this.setState({
      contentId: item._id,
      contentTitle: item.title,
      typedContent: item.title,
    });
  }

  setCategory(event) {
    let categoryId =
      event && event.target && event.target.value ? event.target.value : null;
    let index = event.nativeEvent.target.selectedIndex;
    let categoryName = event.nativeEvent.target[index].text
    this.setState({ categoryId, categoryName });
  }


  setPillarAndGetCategory(event) {
    let pillarId = event && event.target && event.target.value ? event.target.value : null;
    let index = event.nativeEvent.target.selectedIndex;
    let pillarName = event.nativeEvent.target[index].text
    Loader(true);
    this.props._getCategories(pillarId).then(
      (categories) => {
        if (categories && categories.statusCode == 200) {
          this.setState({ categories: categories.result, pillarId, pillarName });
        } else {
          this.setState({ categories: [], pillarId, pillarName });
        }
        Loader(false);
      },
      (error) => {
        console.log("error", error);
        this.setState({ categories: [], pillarId, pillarName });
        Loader(false);
      }
    );
  }


  setScreenOptions(screens) {
    let screenOptions = [];
    screens.forEach((value, index) => {
      {
        screenOptions.push(
          <option key={value} value={value}>
            {FormatTitlePosLabel(value)}
          </option>
        );
      }
    });

    return screenOptions;
  }

  setCategoryOptions(categories) {
    let categoriesOptions = [];
    categories.forEach((value) => {
      {
        categoriesOptions.push(
          <option key={value._id} value={value._id}>
            {value.title}
          </option>
        );
      }
    });

    return categoriesOptions;
  }

  deleteDeepLinkModal(deepLinkId){
    this.setState({openConfirmModal: true, deepLinkId});
  }

  closeConfirmModal(){
    this.setState({openConfirmModal: false, deepLinkId: null});
  }

  confirmDelete(e){
    Loader(true)
    e.preventDefault();
    const {deepLinkId, deepLinks, page} = this.state;
    this.props._deleteDeepLink(deepLinkId).then( response => {
      if(deepLinks && deepLinks.length == 1 && page > 0){
        this.setState({page: page -1}, ()=>{this.getDeepLinks()})
      }else{
        this.getDeepLinks();
      }

      this.closeConfirmModal();

    }, error => {
      console.log('Error', error)
      Loader(false);
    })

  }


  render() {
    const { screens, screenName, categoryId, categories, typedContent, contentData, pillarId, pillars, total, deepLinks, openDeepLinkModal, isErrorMessaeg, searchString, copyText, tooltipIndex , openConfirmModal} = this.state;

    return (
      <Row>
        <Col lg={12}>
        <Card>
          <CardBody>
            <CardHeader>
              <Row>
                <Col lg={8} sm={12}>
                  <h3>Deep Link</h3>
                </Col>
                <Col lg={4} sm={12}>
                  <button className="social-media connect-button" onClick={(e) => { this.resetDeepLink(0); e.preventDefault(); }} >
                    <FontAwesomeIcon icon={faPlus} /> Generate New Link
                    </button>
                </Col>
              </Row>
              <Row>
                <Col lg={6} sm={12} className="searchwrap">
                  <Input type="text"
                    placeholder={'Search by Screen Name'}
                    onChange={(e) => { this.setState({ searchString: e.target.value }) }}
                    onKeyDown={(e) => { if (e.key == 'Enter') { this.getDeepLinks() } }} />
                  <FontAwesomeIcon icon={faSearch} className="search-icon" onClick={() => { this.getDeepLinks() }} />
                </Col>
                <Col lg={6} sm={12}>

                </Col>
              </Row>
            </CardHeader>
            <Table responsive hover>
              <thead>
                <tr>
                  <th scope="col">Screen Name</th>
                  <th scope="col">Short Link</th>
                  <th scope="col">Data</th>
                  <th scope="col">Create Date</th>
                  <th scope="col">Action</th>
                </tr>
              </thead>
              <tbody>
                {deepLinks &&
                  deepLinks.map((element, index) => {
                    let tooltipIndexDom = 'tooltip-' + index;
                    return (
                      <tr key={index}>
                        <td>{element.screenName}</td>
                        <td>{element.link}</td>
                        <td>{
                        element.screenName == 'MUSIC_PLAYER' ?
                          `Content Title: ${element.data.contentTitle}` :
                          (element.screenName == 'CATEGORY' ?
                          `Pillar: ${element.data.pillarName}, Category: ${element.data.categoryName}` : (element.screenName == 'SEARCH' ? `Seach Keyword:  ${element.data.searchField}` : ''))
                      }
                      {
                        element.screenName == 'MEDIA_SERIES' ? `Media Series Title: ${element.data.mediaSeriesTitle}` : ''
                      }
                       </td>
                        <td> {element.crDate} </td>
                        <td>
                          <button className="btn btn-outline-primary tooltip-custom ml-2 p-1"
                            onClick={(e) => { this.copyLink(element.link); e.preventDefault(); }}
                          >
                            <FontAwesomeIcon icon={faCopy} /> Copy Link to clipboard <span className="tooltiptext" id="myTooltip">{copyText}</span>
                          </button>
                          <button className="btn btn-outline-primary tooltip-custom ml-2 p-1"
                            onClick={(e) => { this.deleteDeepLinkModal(element._id); e.preventDefault(); }}
                          >
                            <FontAwesomeIcon icon={faTrash} /> Delete
                          </button>
                        </td>
                      </tr>
                    )
                  })
                }

              </tbody>
            </Table>
          </CardBody>
          <CardFooter>
            <span>Total Short Links : {total}</span>
            {this.setPaginationLinks()}
          </CardFooter>
        </Card>
        </Col>
        <Modal fade={false} isOpen={openDeepLinkModal} toggle={() => { this.toggleDeepLinkModal() }} >
          <form>
            <ModalHeader toggle={() => { this.toggleDeepLinkModal() }}>Generate Deep Link</ModalHeader>
            <ModalBody className="m-3">
              <Row>
                <Col xs="12">
                  <FormGroup>
                    <Label htmlFor="screenNames">Screen Name</Label>
                    <Input
                      type="select"
                      name="screenName"
                      id="screenName"
                      onChange={($event) => {
                        this.setScreenName($event);
                      }}
                    >
                      <option value={null}>Select screen</option>
                      {this.setScreenOptions(screens)}
                    </Input>
                  </FormGroup>
                </Col>
              </Row>
              {screenName && screenName == "SEARCH" ? (
                  <Row>
                    <Col xs="12">
                      <FormGroup>
                        <Label htmlFor="searchField">Search Field</Label>
                        <Input
                          type="searchField"
                          name="searchField"
                          id="searchField"
                          onChange={($event) => {
                            this.handleChange($event);
                          }}
                        ></Input>
                        <div className="validation-error">
                          {this.validator.message(
                            "Search Field",
                            this.state.searchField,
                            "required"
                          )}
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>
                ) : null}

                {screenName && screenName == "SUGGESTED_PLAYLIST_PLAYER" ? (
                  <Row>
                    <Col xs="12">
                      <FormGroup>
                        <Label htmlFor="searchField">Playlist Id</Label>
                        <Input
                          type="playlistId"
                          name="playlistId"
                          id="playlistId"
                          onChange={($event) => {
                            this.handleChange($event);
                          }}
                        ></Input>
                        <div className="validation-error">
                          {this.validator.message(
                            "Playlist Id",
                            this.state.playlistId,
                            "required"
                          )}
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>
                ) : null}


                {screenName && screenName == "CATEGORY" ? (
                  <Row>
                    <Col xs="12">
                      <FormGroup>
                        <Label htmlFor="pillarId">Select Pillar</Label>
                        <Input
                          type="select"
                          name="pillarId"
                          id="pillarId"
                          onChange={($event) => {
                            this.setPillarAndGetCategory($event);
                          }}
                        >
                          <option key="" value={null}>
                            Select pillar
                          </option>
                          {this.setPillarOptions(pillars)}
                        </Input>
                        <div className="validation-error">
                          {this.validator.message(
                            "Pillar",
                            this.state.pillarId,
                            "required"
                          )}
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>
                ) : null}

                {screenName &&
                screenName == "CATEGORY" &&
                categories &&
                categories.length ? (
                  <Row>
                    <Col xs="12">
                      <FormGroup>
                        <Label htmlFor="categoryId">
                          Select Category
                        </Label>
                        <Input
                          type="select"
                          name="categoryId"
                          id="categoryId"
                          onChange={($event) => {
                            this.setCategory($event);
                          }}
                        >
                          <option key="" value={null}>
                            Select category
                          </option>
                          {this.setCategoryOptions(categories)}
                        </Input>
                        <div className="validation-error">
                          {this.validator.message(
                            "Category",
                            this.state.categoryId,
                            "required"
                          )}
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>
                ) : null}
                {screenName && screenName == "MUSIC_PLAYER" ? (
                  <Row>
                    <Col xs="12">
                      <FormGroup>
                        <Label htmlFor="content">Content</Label>
                        <div>
                          <Autocomplete
                            getItemValue={(item) => item.title}
                            items={contentData}
                            inputProps={{
                              id: "autocomplete-input",
                              className: "form-control",
                              placeholder: "Content search by title",
                            }}
                            wrapperStyle={{}}
                            renderItem={(item, isHighlighted) => (
                              <div
                                key={item._id}
                                style={{
                                  background: isHighlighted
                                    ? "lightgray"
                                    : "white",
                                }}
                              >
                                {item.title}
                              </div>
                            )}
                            value={typedContent ? typedContent : ""}
                            onChange={(event, value) => {
                              this.setState(
                                {
                                  typedContent: value,
                                  contentId: null,
                                  contentTitle: null,
                                },
                                () => {
                                  clearTimeout(this.requestTimer);
                                  this.requestTimer = setTimeout(() => {
                                    this.getContent(value);
                                  }, 1000);
                                }
                              );
                            }}
                            onSelect={(value, item) => {
                              this.onSelect(item);
                            }}
                            selectOnBlur={true}
                          />
                        </div>
                        <div className="validation-error">
                          {this.validator.message(
                            "Content",
                            this.state.contentId,
                            "contentId"
                          )}
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>
                ) : null}


                {screenName && screenName == "MEDIA_SERIES" ? (
                  <Row>
                    <Col xs="12">
                      <FormGroup>
                        <Label htmlFor="content">Select Media Series</Label>
                        <div>
                        <Input
                          type="select"
                          name="mediaSeries"
                          id="mediaSeries"
                          onChange={($event) => {
                            if($event.nativeEvent &&
                              $event.nativeEvent.target &&
                              $event.nativeEvent.target.selectedIndex &&
                              $event.nativeEvent.target[$event.nativeEvent.target.selectedIndex] &&
                              $event.nativeEvent.target[$event.nativeEvent.target.selectedIndex]['text']){

                              this.setState({mediaSeries:$event.target.value, mediaSeriesTitle: $event.nativeEvent.target[$event.nativeEvent.target.selectedIndex]['text']});
                            }

                          }}
                        >
                          <option value={null}>Select screen</option>
                          {this.state.mediaSeriesList && this.state.mediaSeriesList.map((mediaSeries) => {
                            return (
                              <option key={mediaSeries._id} value={mediaSeries._id} title={mediaSeries.title}>{mediaSeries.title}</option>
                            );
                          })}
                        </Input>
                        </div>
                        <div className="validation-error">
                          {this.validator.message(
                            "mediaSeries",
                            this.state.mediaSeries,
                            "required"
                          )}
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>
                ) : null}




              {isErrorMessaeg && <Alert fade={false} color="danger">{isErrorMessaeg}</Alert>}

            </ModalBody>
            <ModalFooter>
              <Button color="primary" onClick={(e) => { this.generateNewDeepLink(e) }} >Generate</Button>
              <Button color="secondary" onClick={() => { this.toggleDeepLinkModal() }} >Close</Button>
            </ModalFooter>
          </form>
        </Modal>
        <Modal fade={false} isOpen={openConfirmModal} toggle={() => { this.closeConfirmModal() }} >
          <form>
            <ModalHeader toggle={() => { this.closeConfirmModal() }}>Confirm</ModalHeader>
            <ModalBody className="m-3">
              <Row>
                <Col xs="12">
                  Are you sure, you want to delete deep-link?
                </Col>
              </Row>
            </ModalBody>
          </form>
          <ModalFooter>
              <Button color="primary" onClick={(e) => { this.confirmDelete(e) }} >Confirm</Button>
              <Button color="secondary" onClick={() => { this.closeConfirmModal() }} >Close</Button>
            </ModalFooter>
        </Modal>
      </Row>
    );
  }
}

export default DeepLink;
