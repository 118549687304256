import {createAction} from 'redux-act';
import httpClient from '../../services/http';

export const requestGetEmailCampaignList = createAction();
export const responseGetEmailCampaignList = createAction();
export const responseGetTemplateList = createAction();

export function getCampaignList(params){
    return (dispatch) => {
      dispatch(requestGetEmailCampaignList([]));
      httpClient.call("CAMPAIGN","/get_email_campaign_list" , params, {method: 'GET', headers: {'content-type': 'application/json'}}).then(function(response){
          dispatch(responseGetEmailCampaignList(response));
      }, function(error){
          dispatch(responseGetEmailCampaignList(error));
      });
    }
}

export function uploadContactCSV(params, processCallBack){      
    return httpClient.call('CAMPAIGN', '/import_campaign_contacts', params, {method: 'POST', headers: {'content-type': 'multipart/form-data'}}, processCallBack)
}

export function getTemplateList(params){
    return (dispatch) => {
      dispatch(requestGetEmailCampaignList([]));
      httpClient.call("CAMPAIGN","/get_template_list" , params, {method: 'GET', headers: {'content-type': 'application/json'}}).then(function(response){
          dispatch(responseGetTemplateList(response));
      }, function(error){
          dispatch(responseGetTemplateList(error));
      });
    }
}



export function uploadDefaultTemplate(params, processCallBack){      
    return httpClient.call('CAMPAIGN', '/upload_default_template', params, {method: 'POST', headers: {'content-type': 'multipart/form-data'}}, processCallBack)
}

export function downloadDefaultTemplate(){      
    return httpClient.call('CAMPAIGN', '/download_default_template', {}, {method: 'GET', headers: {'content-type': 'application/json'}})
}

export function deleteTemplate(params){      
    return httpClient.call('CAMPAIGN', '/delete_template', params, {method: 'POST', headers: {'content-type': 'application/json'}})
}

export function sendTestEmail(params){      
    return httpClient.call('CAMPAIGN', '/send_test_email', params, {method: 'POST', headers: {'content-type': 'application/json'}})
}

