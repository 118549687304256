import { connect } from 'react-redux';

import Printable from './Printable';
import { getAllFeePrintable, addPrintable, updatePrintable, deleteAdminUser } from './action';


const mapStateToProps = (state) => {

	var stateData = {
		requestProcess: state.printable.requestProcess
	};
	if(state.printable.data){
		stateData['data'] = state.printable.data;
	}

	if(state.printable.addUpdateData){		
		stateData['addUpdateData'] = state.printable.addUpdateData;
	}
	
	return stateData;
};

const mapDispatchToProps = (dispatch) => {
	return {
		_getAllFeePrintable: (params) => {
			dispatch(getAllFeePrintable(params));
		},
		_addPrintable: (params) => {
			return addPrintable(params);
		},
		_updatePrintable: (params) => {
			return updatePrintable(params);
		},
		_deleteAdminUser: (params) => {
			return deleteAdminUser(params);
		}
	};
};


export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Printable);
