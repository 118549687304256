import { connect } from 'react-redux';
import Login from './Login';
import { loginAction } from './action';


const mapStateToProps = (state) => {
	return {
		data: state.auth.data,
		requestProcess: state.auth.requestProcess
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		_login: (param) => {
			dispatch(loginAction(param));
		}
	};
};


export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Login);
