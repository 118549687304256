import { connect } from 'react-redux';

import Trophy from './Trophy';
import { getAllTrophies, addTrophy, deleteTrophy, changeTrophyStatus, updateTrophy, getTophyFreePillars, getSelectedTypeMilestones } from './action';
import { getPillars } from '../pillar/action';


const mapStateToProps = (state) => {
	var stateData = {
		requestProcess: state.trophy.requestProcess
	};
	if(state.trophy.trophiesList){
		stateData['trophiesList'] = state.trophy.trophiesList;
	}


	if(state.trophy.addTrophy){
		stateData['addTrophy'] = state.trophy.addTrophy;
	}

	if(state.trophy.deleteTrophy){
		stateData['deleteTrophy'] = state.trophy.deleteTrophy;
	}


	if(state.trophy.trophyStatus){
		stateData['trophyStatus'] = state.trophy.trophyStatus;
	}

	if(state.trophy.updateTrophy){
		stateData['updateTrophy'] = state.trophy.updateTrophy;
	}


	if(state.pillar.data){
		stateData['pillarData'] = state.pillar.data;
	}


	if(state.trophy.trophyFreePillars){
		stateData['trophyFreePillars'] = state.trophy.trophyFreePillars;
	}


	if(state.trophy.selectedTypeMilestones){
		stateData['selectedTypeMilestones'] = state.trophy.selectedTypeMilestones;
	}
	
	
	return stateData;
};

const mapDispatchToProps = (dispatch) => {
	return {
		_getAllTrophies: (params) => {
			dispatch(getAllTrophies(params));
		},

		_addTrophy: (params) => {
			dispatch(addTrophy(params));
		},


		_deleteTrophy: (params) => {
			dispatch(deleteTrophy(params));
		},

		_changTrophyStatus: (params) => {
			dispatch(changeTrophyStatus(params));
		},

		_updateTrophy: (params) => {
			dispatch(updateTrophy(params));
		},


		_getPillars: (params) => {
			dispatch(getPillars(params));
		},

		_getTophyFreePillars: (params) => {
			dispatch(getTophyFreePillars(params));
		},

		_getSelectedTypeMilestones: (params) => {
			dispatch(getSelectedTypeMilestones(params));
		},
	};
};


export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Trophy);
