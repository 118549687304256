import React, { Component } from 'react';
import { connect } from 'react-redux';
import { AppSidebarToggler } from '@coreui/react';
import { roleBasedSideBar } from '../utils/common';
import Session from '../utils/session';

class DefaultSidebar extends Component {

  constructor(props) {
    super(props);
    this.state = {
      roles: [],
      expandCustome: false,
      expandMarketing: false,

    }
    this.session = Session.getSessionData('config');
    this.apiUrl = this.session && this.session.UTILS && this.session.UTILS.BUCKET_URL ? this.session.UTILS.BUCKET_URL : process.env.REACT_APP_CLOUDFRONT_URL;
    this.signedQueryString = this.session && this.session.signedQueryString ? this.session.signedQueryString : '?';

  }

  componentWillMount() {
    let sessionData = Session.getSessionData();
    console.log('sessionData.roles',sessionData.roles);
    this.setState({ roles: sessionData.roles })
  }

  navigate(path) {
    if (this.props.router.location.pathname !== path) {
      this.props.router.push(path)
    } else {
      return;
    }
  }


  render() {
    const roleBasedMenuItem = roleBasedSideBar(this.state.roles);
    let { pathname } = this.props.location;
    pathname = pathname.replace('/', '');
    return (
      <div className="scrollbar-container">

        <ul className="nav">
          <li className="sidebar-logo">
            <div><AppSidebarToggler className="d-md-down-none" display="lg" /></div>
            <a href="/" onClick={(e) => { this.navigate("dashboard"); e.preventDefault() }}>
              <img src={this.apiUrl + "admin/img/logo.png" + this.signedQueryString} alt="" /></a>
          </li>
          {roleBasedMenuItem.indexOf('analytics') > -1 &&
            <li className="nav-item" >
              <a className="nav-link" href={process.env.REACT_APP_ANALYTICS_URL}>
                <img src={this.apiUrl + "admin/img/icon-analytics.png" + this.signedQueryString} alt={this.signedQueryString} /> Analytics
              </a>
            </li>
          }

          {roleBasedMenuItem.indexOf('marketing') > -1 &&
            <li className="nav-item" disabled={pathname === 'marketing-social' || pathname === 'deep-link'}>
              <a className={pathname === 'marketing-social' || pathname === 'deep-link' ? 'nav-link active' : 'nav-link'} href="javascript:void(0)"
                onClick={(e) => { this.setState({ expandMarketing: !this.state.expandMarketing }); e.preventDefault() }} >
                <img src={this.apiUrl + "admin/img/icon-content.png" + this.signedQueryString} alt={this.signedQueryString} />  Marketing
              </a>
              {this.state.expandMarketing &&
                <ul className="nav">
                  <li className="nav-item" ><a className={pathname === 'marketing-social' ? 'nav-link active' : 'nav-link'} href="/marketing-social" onClick={(e) => { this.navigate("marketing-social"); e.preventDefault() }} disabled={pathname === 'marketing-social'}>Social Link</a></li>
                  <li className="nav-item" ><a className={pathname === 'deep-link' ? 'nav-link active' : 'nav-link'} href="/deep-link" onClick={(e) => { this.navigate("deep-link"); e.preventDefault() }} disabled={pathname === 'deep-link'}>Deep Link</a></li>
                </ul>
              }
            </li>
          }

          {roleBasedMenuItem.indexOf('admin-users') > -1 &&
            <li className="nav-item" disabled={pathname === 'admin-users'}>
              <a className={pathname === 'admin-users' ? 'nav-link active' : 'nav-link'} href="/admin-users" onClick={(e) => { this.navigate("admin-users"); e.preventDefault() }} disabled={pathname === 'admin-users'}>
                <img src={this.apiUrl + "admin/img/icon-admin-user.png" + this.signedQueryString} alt={this.signedQueryString} /> Admin Users
              </a>
            </li>
          }

          {roleBasedMenuItem.indexOf('parent') > -1 &&
            <li className="nav-item" disabled={pathname === 'parent'}>
              <a className={pathname === 'parent' ? 'nav-link active' : 'nav-link'} href="/" onClick={(e) => { this.navigate("parent"); e.preventDefault() }} disabled={pathname === 'parent'}>
                <img src={this.apiUrl + "admin/img/icon-parent.png" + this.signedQueryString} alt={this.signedQueryString} />  Parent
              </a>
            </li>
          }
          {roleBasedMenuItem.indexOf('pillar') > -1 &&
            <li className="nav-item" disabled={pathname === 'pillar'}>
              <a className={pathname === 'pillar' ? 'nav-link active' : 'nav-link'} href="/pillar" onClick={(e) => { this.navigate("pillar"); e.preventDefault() }}>
                <img src={this.apiUrl + "admin/img/icon-pillar.png" + this.signedQueryString} alt={this.signedQueryString} />  Pillar
              </a>
            </li>
          }
          {roleBasedMenuItem.indexOf('pillar-categories') > -1 &&
            <li className="nav-item" disabled={pathname === 'pillar-categories'}>
              <a className={pathname === 'pillar-categories' ? 'nav-link active' : 'nav-link'} href="/pillar-categories" onClick={(e) => { this.navigate("pillar-categories"); e.preventDefault() }} disabled={pathname === 'pillar-categories'}>
                <img src={this.apiUrl + "admin/img/icon-categories.png" + this.signedQueryString} alt={this.signedQueryString} /> Categories
              </a>
            </li>
          }

          {roleBasedMenuItem.indexOf('pillar-content') > -1 &&
            <li className="nav-item" disabled={pathname === 'pillar-content'}>
              <a className={pathname === 'pillar-content' ? 'nav-link active' : 'nav-link'} href="/pillar-content" onClick={(e) => { this.navigate("pillar-content"); e.preventDefault() }} disabled={pathname === 'pillar-content'}>
                <img src={this.apiUrl + "admin/img/icon-content.png" + this.signedQueryString} alt={this.signedQueryString} />  Content
              </a>
            </li>
          }


          {roleBasedMenuItem.indexOf('customised-dashboard') > -1 &&
            <li className="nav-item" disabled={pathname === 'custom-module' || pathname === 'custom-content'}>
              <a className={pathname === 'custom-module' || pathname === 'custom-content' ? 'nav-link active' : 'nav-link'} href="javascript:void(0)"
                onClick={(e) => { this.setState({ expandCustome: !this.state.expandCustome }); e.preventDefault() }} >
                <img src={this.apiUrl + "admin/img/icon-content.png" + this.signedQueryString} alt={this.signedQueryString} />  Customise Dashboard
              </a>
              {this.state.expandCustome &&
                <ul className="nav">
                  <li className="nav-item" ><a className={pathname === 'custom-module' ? 'nav-link active' : 'nav-link'} href="/custom-module" onClick={(e) => { this.navigate("custom-module"); e.preventDefault() }} disabled={pathname === 'custom-module'}>Custom Module</a></li>
                  <li className="nav-item" ><a className={pathname === 'custom-content' ? 'nav-link active' : 'nav-link'} href="/custom-content" onClick={(e) => { this.navigate("custom-content"); e.preventDefault() }} disabled={pathname === 'custom-content'}>Custom Content</a></li>
                </ul>
              }
            </li>
          }

          {roleBasedMenuItem.indexOf('pillar-content') > -1 &&
            <li className="nav-item" disabled={pathname === 'playlist'}>
              <a className={pathname === 'playlist' ? 'nav-link active' : 'nav-link'} href="/playlist" onClick={(e) => { this.navigate("playlist"); e.preventDefault() }} disabled={pathname === 'playlist'}>
                <img src={this.apiUrl + "admin/img/icon-content.png" + this.signedQueryString} alt={this.signedQueryString} />  Playlist / Series
              </a>
            </li>
          }


          {roleBasedMenuItem.indexOf('daily-quest') > -1 &&
            <li className="nav-item" disabled={pathname === 'daily-quest'}>
              <a className={pathname === 'daily-quest' ? 'nav-link active' : 'nav-link'} href="/daily-quest" onClick={(e) => { this.navigate("daily-quest"); e.preventDefault() }} disabled={pathname === 'daily-quest'}>
                <img src={this.apiUrl + "admin/img/icon-quest.png" + this.signedQueryString} alt={this.signedQueryString} />  Daily Quest
              </a>
            </li>
          }
          {roleBasedMenuItem.indexOf('notifications') > -1 &&
            <li className="nav-item" disabled={pathname === 'notifications'}>
              <a className={pathname === 'notifications' ? 'nav-link active' : 'nav-link'} href="/notifications" onClick={(e) => { this.navigate("notifications"); e.preventDefault() }} disabled={pathname === 'notifications'}>
                <img src={this.apiUrl + "admin/img/icon-push-notify.png" + this.signedQueryString} alt={this.signedQueryString} />Push  Notifications
              </a>
            </li>
          }
          {roleBasedMenuItem.indexOf('system-email') > -1 &&
            <li className="nav-item" disabled={pathname === 'system-email'}>
              <a className={pathname === 'system-email' ? 'nav-link active' : 'nav-link'} href="/system-email" onClick={(e) => { this.navigate("system-email"); e.preventDefault() }} disabled={pathname === 'system-email'}>
                <img src={this.apiUrl + "admin/img/icon-email-setting.png" + this.signedQueryString} alt={this.signedQueryString} />System Emails
              </a>
            </li>
          }

          {/* <li className="nav-item" disabled={pathname == 'email-notifications'}>
              <a className={pathname == 'email-notifications' ? 'nav-link active' : 'nav-link'} href="Javascript:void(0)" onClick={ () => {this.navigate("email-notifications")}} disabled={pathname == 'email-notifications'}>
                <img src={this.apiUrl+"admin/img/icon-content.png"} />  Email Notification
              </a>
            </li> */}
          {roleBasedMenuItem.indexOf('discount') > -1 &&
            <li className="nav-item" disabled={pathname === 'discount'}>
              <a className={pathname === 'discount' ? 'nav-link active' : 'nav-link'} href="/discount" onClick={(e) => { this.navigate("discount"); e.preventDefault() }} disabled={pathname === 'discount'}>
                <img src={this.apiUrl + "admin/img/discount.png" + this.signedQueryString} alt={this.signedQueryString} />  Discount
              </a>
            </li>
          }


          {roleBasedMenuItem.indexOf('trophy') > -1 &&
            <li className="nav-item" disabled={pathname === 'trophy'}>
              <a className={pathname === 'trophy' ? 'nav-link active' : 'nav-link'} href="/trophy" onClick={(e) => { this.navigate("trophy"); e.preventDefault() }} disabled={pathname === 'trophy'}>
                <img src={this.apiUrl + "admin/img/trophy.png" + this.signedQueryString} alt={this.signedQueryString} /> Trophies
              </a>
            </li>
          }
          {roleBasedMenuItem.indexOf('payment-history') > -1 &&
            <li className="nav-item" disabled={pathname === 'payment-history'}>
              <a className={pathname === 'payment-history' ? 'nav-link active' : 'nav-link'} href="/payment-history" onClick={(e) => { this.navigate("payment-history"); e.preventDefault() }} disabled={pathname === 'payment-history'}>
                <img src={this.apiUrl + "admin/img/payment-history.png" + this.signedQueryString} alt={this.signedQueryString} />  Payment History
              </a>
            </li>
          }

          {roleBasedMenuItem.indexOf('users-feedback') > -1 &&
            <li className="nav-item" disabled={pathname === 'users-feedback'}>
              <a className={pathname === 'users-feedback' ? 'nav-link active' : 'nav-link'} href="/users-feedback" onClick={(e) => { this.navigate("users-feedback"); e.preventDefault() }} disabled={pathname === 'users-feedback'}>
                <img src={this.apiUrl + "admin/img/icon-feedback.png" + this.signedQueryString} alt={this.signedQueryString} /> Feedback
              </a>
            </li>
          }
          {roleBasedMenuItem.indexOf('app-versions') > -1 &&
            <li className="nav-item" disabled={pathname === 'app-versions'}>
              <a className={pathname === 'app-versions' ? 'nav-link active' : 'nav-link'} href="/app-versions" onClick={(e) => { this.navigate("app-versions"); e.preventDefault() }} disabled={pathname === 'app-versions'}>
                <img src={this.apiUrl + "admin/img/app-version_icon.png" + this.signedQueryString} alt={this.signedQueryString} /> App Version
              </a>
            </li>
          }

          {roleBasedMenuItem.indexOf('app-static-languages') > -1 &&
            <li className="nav-item" disabled={pathname === 'app-static-languages'}>
              <a className={pathname === 'app-static-languages' ? 'nav-link active' : 'nav-link'} href="/app-static-languages" onClick={(e) => { this.navigate("app-static-languages"); e.preventDefault() }} disabled={pathname === 'app-static-languages'}>
                <img src={this.apiUrl + "admin/img/icon-lang.png" + this.signedQueryString} alt={this.signedQueryString} /> Static Languages
              </a>
            </li>
          }
          {roleBasedMenuItem.indexOf('settings') > -1 &&
            <li className="nav-item" disabled={pathname === 'settings'}>
              <a className={pathname === 'settings' ? 'nav-link active' : 'nav-link'} href="/settings" onClick={(e) => { this.navigate("settings"); e.preventDefault() }} disabled={pathname === 'settings'}>
                <img src={this.apiUrl + "admin/img/setting.png" + this.signedQueryString} alt={this.signedQueryString} /> Settings
              </a>
            </li>
          }
          {roleBasedMenuItem.indexOf('free-printable') > -1 &&
            <li className="nav-item" disabled={pathname === 'free-printable'}>
              <a className={pathname === 'free-printable' ? 'nav-link active' : 'nav-link'} href="/free-printable" onClick={(e) => { this.navigate("free-printable"); e.preventDefault() }} disabled={pathname === 'free-printable'}>
                <img src={this.apiUrl + "admin/img/icon-import-user.png" + this.signedQueryString} alt={this.signedQueryString} /> Free Printable
              </a>
            </li>
          }
          {roleBasedMenuItem.indexOf('email-campaign') > -1 &&
            <li className="nav-item" disabled={pathname === 'email-campaign'}>
              <a className={pathname === 'email-campaign' ? 'nav-link active' : 'nav-link'} href="/email-campaign" onClick={(e) => { this.navigate("email-campaign"); e.preventDefault() }} disabled={pathname === 'email-campaign'}>
                <img src={this.apiUrl + "admin/img/icon-import-user.png" + this.signedQueryString} alt={this.signedQueryString} /> Email Campaign
              </a>
            </li>
          }
          {roleBasedMenuItem.indexOf('whatsapp-campaign') > -1 &&
            <li className="nav-item" disabled={pathname === 'whatsapp-campaign'}>
              <a className={pathname === 'whatsapp-campaign' ? 'nav-link active' : 'nav-link'} href="/whatsapp-campaign" onClick={(e) => { this.navigate("whatsapp-campaign"); e.preventDefault() }} disabled={pathname === 'whatsapp-campaign'}>
                <img src={this.apiUrl + "admin/img/icon-import-user.png" + this.signedQueryString} alt={this.signedQueryString} /> WhatsApp Campaign
              </a>
            </li>
          }

          {roleBasedMenuItem.indexOf('user-notification') > -1 &&
            <li className="nav-item" disabled={pathname === 'user-notification'}>
              <a className={pathname === 'user-notification' ? 'nav-link active' : 'nav-link'} href="/user-notification" onClick={(e) => { this.navigate("user-notification"); e.preventDefault() }} disabled={pathname === 'user-notification'}>
                <img src={this.apiUrl + "admin/img/icon-import-user.png" + this.signedQueryString} alt={this.signedQueryString} /> Custom Notification
              </a>
            </li>
          }
          {roleBasedMenuItem.indexOf('author-content') > -1 &&
            <li className="nav-item" disabled={pathname === 'author-content'}>
              <a className={pathname === 'author-content' ? 'nav-link active' : 'nav-link'} href="/author-content" onClick={(e) => { this.navigate("author-content"); e.preventDefault() }} disabled={pathname === 'author-content'}>
                <img src={this.apiUrl + "admin/img/icon-content.png" + this.signedQueryString} alt={this.signedQueryString} />  Content
              </a>
            </li>
          }

          {roleBasedMenuItem.indexOf('whats-new') > -1 &&
            <li className="nav-item" disabled={pathname === 'whats-new'}>
              <a className={pathname === 'whats-new' ? 'nav-link active' : 'nav-link'} href="/whats-new" onClick={(e) => { this.navigate("whats-new"); e.preventDefault() }} disabled={pathname === 'whats-new'}>
                <img src={this.apiUrl + "admin/img/payment-history.png" + this.signedQueryString} alt={this.signedQueryString} />  Whats New
              </a>
            </li>
          }


          <li className="nav-item" >
            <a className="nav-link" onClick={e => { this.props.onLogout(e); e.preventDefault() }} href="/" disabled={pathname == 'settings'}>
              <img src={this.apiUrl + "admin/img/icon-logout.png" + this.signedQueryString} alt={this.signedQueryString} /> Logout
            </a>
          </li>

        </ul>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {

  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    /*_signOut: ()=>{
      return signOutAction();
    }*/
  };
};


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DefaultSidebar);
