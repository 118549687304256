import { createAction } from 'redux-act';
import httpClient from '../../services/http';



export const requestgetWhatsNewList = createAction();
export const responsegetWhatsNewList = createAction();

export function getWhatsNewList(params) {
  return (dispatch) => {
    dispatch(requestgetWhatsNewList([]));
    httpClient.call('USER', '/getWhatsNewList', params, { method: 'GET', headers: {} }).then(function (response) {
      console.log('response', response);
      dispatch(responsegetWhatsNewList(response));
    }, function (error) {
      dispatch(responsegetWhatsNewList(error));
    });
  }
}

export function updateWhatsNew(params) {
  return new Promise((resolve, reject) => {
    httpClient.call('USER', '/updateWhatsNew', params, { method: 'PUT', headers: {} }).then(function (response) {
      resolve(response);
    }, function (error) {
      reject(error);
    });
  })
}


export function updateWhatsNewVersion(params) {
  return new Promise((resolve, reject) => {
    httpClient.call('USER', '/updateWhatsNewVersion', params, { method: 'PUT', headers: {} }).then(function (response) {
      resolve(response);
    }, function (error) {
      reject(error);
    });
  })
}



export const requestgetWhatsNewVersionList = createAction();
export const responsegetWhatsNewVersionList = createAction();

export function getWhatsNewVersionList(params) {
  return (dispatch) => {
    dispatch(requestgetWhatsNewVersionList([]));
    httpClient.call('USER', '/getWhatsNewVersionList', params, { method: 'GET', headers: {} }).then(function (response) {
      dispatch(responsegetWhatsNewVersionList(response));
    }, function (error) {
      dispatch(responsegetWhatsNewVersionList(error));
    });
  }
}


export const requestDeleteWhatsNew = createAction();
export const responseDeleteWhatsNew = createAction();

export function deleteWhatsNew(params){
  return (dispatch) => {
      dispatch(requestDeleteWhatsNew([]));
      httpClient.call('USER','/deleteWhatsNew', params  , {method: 'DELETE', headers: {}}).then(function(response){
          dispatch(responseDeleteWhatsNew(response));
      }, function(error){
          dispatch(responseDeleteWhatsNew(error));
      });
  }
}

export function createWhatsNew(params) {
  return new Promise((resolve, reject) => {
    httpClient.call('USER', '/createWhatsNew', params, { method: 'POST', headers: {} }).then(function (response) {
      resolve(response);
    }, function (error) {
      reject(error);
    });
  })
}


export function getScreenList(params) {
  return new Promise((resolve, reject) => {
    httpClient.call('USER', '/get_marketing_screen_slug', params, { method: 'GET', headers: {} }).then(function (response) {
      resolve(response);
    }, function (error) {
      reject(error);
    });
  })
}



export function getCategories(params) {
  return new Promise((resolve, reject) => {
    httpClient.call('PILLAR', '/get_pillar_categories', params, { method: 'GET', headers: {} }).then(function (response) {
      resolve(response);
    }, function (error) {
      reject(error);
    });
  })
}


export function getPillars(params) {
  return new Promise((resolve, reject) => {
    httpClient.call('PILLAR', '/get_pillars', params, { method: 'GET', headers: {} }).then(function (response) {
      resolve(response);
    }, function (error) {
      reject(error);
    });
  })
}

export function getContentList(params) {
  return new Promise((resolve, reject) => {
    httpClient.call('PILLAR', '/get_contents_for_quest', params, { method: 'GET', headers: {} }).then(function (response) {
      resolve(response);
    }, function (error) {
      reject(error);
    });
  })
}
