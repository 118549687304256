import React, { Component } from 'react';
import { Loader } from '../../utils/common';
import { Row, Col, Modal, Button, ModalHeader, ModalBody, ModalFooter, FormGroup, Label, Input,  Form , Alert } from "reactstrap";  
import SimpleReactValidator from 'simple-react-validator';

import Autocomplete from 'react-autocomplete';



class UpdateSiriShortcuts extends Component {
  constructor(props) {
    super(props);
    console.log(props)
    this.validator = new SimpleReactValidator();
    this.formatTitlePosLabel = this.formatTitlePosLabel.bind(this);
    this.requestTimer = "";
    this.state = {
      shortcutId : (props.selectedRow && props.selectedRow._id) ? props.selectedRow._id : "",

      title : (props.selectedRow && props.selectedRow.title) ? props.selectedRow.title : "",
      
      subTitle : (props.selectedRow && props.selectedRow.subTitle) ? props.selectedRow.subTitle : "",

      status : (props.selectedRow && props.selectedRow.status) ? props.selectedRow.status : true,
      
      categoryId : (props.selectedRow && props.selectedRow.categoryId) ? props.selectedRow.categoryId : "",

      typedContent :(props.selectedRow && props.selectedRow.category && props.selectedRow.category.title) ? props.selectedRow.category.title : "",
      filteredCategories : JSON.parse(JSON.stringify(this.props.allCategories))
    }
  }

  componentDidMount() {
    this.setSelectedCategory()
  }

  setSelectedCategory() {

  }



  componentWillReceiveProps(nextProps) {
   // let self = this;
    Loader(nextProps.requestProcess);
  }


  

  handleChange(event) {
    let target = event.target;
    let name = target.name;
    let value = target.value;

    if (target.name === 'status' && target.type === 'checkbox') {
      value = target.checked;
    }


    this.setState({[name]: value});
  }

  handleSubmit(e) {
    if( this.validator.allValid()){
      let params = {
        shortcutId : this.state.shortcutId,
        title : this.state.title,
        subTitle : this.state.subTitle,
        categoryId : this.state.categoryId,
        status : this.state.status,
      }  
      this.props.updateSiriShortcut(e, params);
    }else{
      this.validator.showMessages();
      this.forceUpdate();
    }
    e.preventDefault();
  }

  formatTitlePosLabel (s) {
    if (typeof s !== 'string') return ''
      s = s.replace("_", " ");
      s = s.charAt(0).toUpperCase() + s.slice(1).toLowerCase();
      return s;
  }

  selectCategory(categoryObj) {
    this.setState({
      categoryId : categoryObj._id,
      typedContent : categoryObj.title,
      filteredCategories : JSON.parse(JSON.stringify(this.props.allCategories))
    })
  }

  searchCategories(value) {
    this.state.filteredCategories = JSON.parse(JSON.stringify(this.props.allCategories))
    
    let result = this.state.filteredCategories.filter(obj => obj.title.toLowerCase().startsWith(value.toLowerCase()));
    this.setState({
      filteredCategories : result
    })
  }

  



  render() {
    let self = this;
    let { openUpdateShortCutPopup, closeModal, updateShortcutError } = this.props;
    let { typedContent, filteredCategories } = this.state;
    
    return (
      <Modal isOpen={openUpdateShortCutPopup}>
          <ModalHeader toggle={()=>{closeModal(false)}}>Update Shortcut</ModalHeader>
          <Form onSubmit={(event) => {this.handleSubmit(event)}}>  
            <ModalBody>
              <Row>
                <Col xs="12">
                    <FormGroup>
                      <Label htmlFor="title">Title*</Label>
                      <Input type="text" id="title" placeholder="Title" name="title" value={this.state.title} onChange={(event)=>{this.handleChange(event)}} />                                              
                      <div className="validation-error">{ this.validator.message('title', this.state.title, 'required') }</div>   
                    </FormGroup>
                </Col>
              </Row>


              <Row>
                <Col xs="12">
                    <FormGroup>
                      <Label htmlFor="subTitle">Sub Title*</Label>
                      <Input type="text" id="subTitle" placeholder="Sub Title" name="subTitle" value={this.state.subTitle} onChange={(event)=>{this.handleChange(event)}} />                                              
                      <div className="validation-error">{ this.validator.message('Sub Title', this.state.subTitle, 'required') }</div>   
                    </FormGroup>
                </Col>
              </Row>


              <Row>
                <Col xs="12">
                  <FormGroup>
                      <Label htmlFor="content">Categories</Label>
                      <div>
                      <Autocomplete
                          getItemValue={(item) => item.title}
                          items={filteredCategories}
                          inputProps={{ id: '' , className: "form-control", readOnly : false, autoComplete:"nope"}}
                          wrapperStyle={{  }}
                          renderItem={(item, isHighlighted) =>
                            <div key={item._id} style={{ background: isHighlighted ? 'lightgray' : 'white' }}>
                              {item.title}
                            </div>
                          }
                          value={typedContent ? typedContent : ''}
                          onChange={(event, value)=>{
                            this.setState({typedContent: value, categoryId: ""}, ()=>{
                              clearTimeout(self.requestTimer)
                              self.requestTimer = setTimeout(()=>{
                                self.searchCategories(value)
                              }, 1000)
                            });
                          }}
                          onSelect={(value, item)=>{self.selectCategory(item)}}
                          selectOnBlur={true}
                        />
                        </div>
                        <div className="validation-error">{ this.validator.message('Category', this.state.categoryId, 'required') }</div>   
                    </FormGroup>
                </Col>
              </Row>


              {(updateShortcutError) ? <Alert color="danger">{updateShortcutError}</Alert> : null}
            </ModalBody>
            <ModalFooter className="justify-content-left">
              <Button type="submit" size="sm" color="primary">Update</Button>{' '}
              <Button type="reset" size="sm" color="danger"  onClick={() => { closeModal() }}>Cancel</Button>
            </ModalFooter>
          </Form>
        </Modal>
      
    );
  }
}

export default UpdateSiriShortcuts;
