import { connect } from 'react-redux';

import Content from './Content';
import { getContentList, changeContentStatus, deleteContent, getAgeGroups, getAuthors } from './action';


const mapStateToProps = (state) => {
		var stateData = {
			requestProcess: state.content.requestProcess
		};

		if(state.content.data){
			stateData['data'] = state.content.data;
		}

		if(state.content.addUpdateCategory){
			stateData['statusChange'] = state.category.addUpdateCategory;
		}


		if(state.content.updateContent){
			stateData['statusChange'] = state.content.updateContent;
		}
		return stateData;
};

const mapDispatchToProps = (dispatch) => {
	return {
		_getContentList: (params) => {
			dispatch(getContentList(params));
		},

		_changeContentStatus: (params) => {
				dispatch(changeContentStatus(params))
		},

		_deleteContent: (params) => {
			dispatch(deleteContent(params))
		},

		_getAgeGroups: () => {
			return getAgeGroups();
		},
		
		_getAuthors: () => {
			return getAuthors();
		}


	}
};


export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Content);
