import {createReducer} from 'redux-act';
import { requestGetPillars, responseGetPillars, requestGetPillar, responseGetPillar, requestPillerChangeStatus, responsePillerChangeStatus, requestUpdatePillar, responseUpdatePillar} from './action';


const defaultState = {
	requestProcess: false,
};

const reducer = createReducer({

	[requestGetPillars]: (state) => {
		return {
			requestProcess: true,
			data: [],
		};
	},

	[responseGetPillars]: (state, params) => {
		return {
			requestProcess: false,
			data: params,
		};
	},

	[requestGetPillar]: (state) => {
		return {
			requestProcess: true,
			data: [],
		};
	},

	[responseGetPillar]: (state, params) => {
		return {
			requestProcess: false,
			data: params,
		};
	},

	[requestPillerChangeStatus]: (state) => {
		return {
			requestProcess: true
		};
	},

	[responsePillerChangeStatus]: (state, params) => {
		return {
			requestProcess: false,
			changeStatus: params
		};
	},


	[requestUpdatePillar]: (state) => {
		return {
			requestProcess: true
		};
	},

	[responseUpdatePillar]: (state, params) => {
		return {
			requestProcess: false,
			updatedData: params
		};
	},


}, defaultState);



export default reducer
