import { createReducer } from 'redux-act';
import {requestGetAllShortcuts, responseGetAllShortcuts, requestAddShortcut, responseAddShortcut, requestUpdateShortcut, responseUpdateShortcut} from './action';


const defaultState = {
	requestProcess: false,
};

const reducer = createReducer({

	[requestGetAllShortcuts]: (state) => {
		return {
			requestProcess: true,
			data: [],
		};
	},

	[responseGetAllShortcuts]: (state, params) => {
		return {
			requestProcess: false,
			shortcuts: params,
		};
	},



	[requestAddShortcut]: (state) => {
		return {
			requestProcess: true,
			data: [],
		};
	},

	[responseAddShortcut]: (state, params) => {
		return {
			requestProcess: false,
			shortcutAdded: params,
		};
	},


	[requestUpdateShortcut]: (state) => {
		return {
			requestProcess: true,
		};
	},

	[responseUpdateShortcut]: (state, params) => {
		return {
			requestProcess: false,
			shortcutUpdated: params,
		};
	}

}, defaultState);



export default reducer
