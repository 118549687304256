import React, { Component } from 'react';
import { Loader } from '../../utils/common';
import DataGrid from "g6reactdatagrid";
import { Row, Col, Input, Nav, NavItem, NavLink, Alert } from "reactstrap";
import { AppSwitch } from '@coreui/react'
import moment from 'moment';
import AddEditCategory from './AddEditCategory';
import PillarList from './PillarList';
import Constants from '../../config/constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit } from '@fortawesome/free-solid-svg-icons'
import Session from '../../utils/session';
import * as _ from 'underscore';



class Category extends Component {


  constructor(props) {
    super(props);
    this.state = {
      totalRecord: 0,
      pillarCategoryList: [],
      dropdownOpen: {},
      page: 0,
      sortColumn: "createdDate",
      sortValue: "-1",
      openAddEditCategoryModal: false,
      categoryObj: {},
      searchKey: '',
      type: '',
      pillarFilter: '',
      formData: {
        _id: 0,
        title: "",
        priority: 1,
        layoutType: Constants.GRID_SQ,
        priorityText: 'High',
        status: 'Active',
        pillarId: ""
      },
      screenError: "",
      // systemLanguages : Session.getSessionData('config')['SYSTEM_LANGUAGES']
      systemLanguages: Session.getSessionData('languages')['languages']
    }
    this.intervalInstance = '';

    this.columns = [
      { "columnName": "title", "displayName": "Title", "sort": true },
      { "columnName": "pillarTitle", "displayName": "Pillar", "sort": false },
      { "columnName": "priorityText", "displayName": "Priority", "sort": true },
      { "columnName": "status", "displayName": "Status", "sort": true },
      { "columnName": "layoutTypelabel", "displayName": "Layout Type", "sort": false },
      { "columnName": "createdDate", "displayName": "Created On", "sort": true }
    ];
  }

  componentDidMount() {
    this.getPillarCategoryList();
  }

  getPillarCategoryList() {
    let { searchKey, sortColumn, sortValue, pillarFilter, type } = this.state;
    let param = {
      search: searchKey,
      sortField: sortColumn,
      sortValue: sortValue,
      pillarFilter: pillarFilter,
      type: type
    }
    this.props._getPillarCategory(param);
  }

  componentWillReceiveProps(nextProps) {
    let self = this;
    let pillarCategoryList = [];
    Loader(nextProps.requestProcess);

    if (nextProps.statusChange && nextProps.statusChange.statusCode === 200) {
      this.getPillarCategoryList();
    }
    if (nextProps.data) {
      if (nextProps.data.statusCode === 200) {
        let listData = (nextProps.data.result.length) ? nextProps.data.result : [];
        listData.map(function (element, key) {
          if (element.status) { element.status = 'Active' } else { element.status = 'Inactive' }
          if (element.layoutType === Constants.GRID_SQ) { element['layoutTypelabel'] = Constants.GRID_SQ_LABEL }
          else if (element.layoutType === Constants.GRID_RT) { element['layoutTypelabel'] = Constants.GRID_RT_LABEL }
          else if (element.layoutType === Constants.LIST) { element['layoutTypelabel'] = Constants.LIST_LABEL }

          element.createdDate = moment(element.createdDate).format("DD-MMM-YYYY")
          pillarCategoryList.push(element);
          return true;
        });
        self.setState({ pillarCategoryList: pillarCategoryList, openAddEditCategoryModal: false, categoryObj: {} });
      } else {
        self.setState({ screenError: Constants.SOMETHING_WRONG })
      }

    }
  }

  toggle(id) {
    let self = this;
    self.state.dropdownOpen[id] = !self.state.dropdownOpen[id]
    this.setState({});
  }

  appUpdatePillarCategory(rowData) {
    let self = this
    _.each(this.state.systemLanguages, function (value, index) {
      let cateObj = _.findWhere(rowData['categoryLang'], { languageSlug: value['slug'] });
      self.state.systemLanguages[index]['cateObj'] = (!_.isEmpty(cateObj)) ? cateObj : { title: "", languageSlug: value['slug'] };
    })
    self.setState({ systemLanguages: self.state.systemLanguages, openAddEditCategoryModal: true, categoryObj: rowData });
  }


  onCloseModal = (shouldRefresh) => {
    this.setState({ openAddEditCategoryModal: false, categoryObj: {} });
    if (shouldRefresh) {
      this.getPillarCategoryList();
    }
  };

  changeStatus(rowData) {
    rowData['status'] = rowData['status'] === 'Active' ? false : true;
    rowData['categoryId'] = rowData['_id'];
    this.props._changeCategoryStatus(rowData);
  }

  handleChange(event) {
    let value = event.target.value;
    this.setState({ pillarFilter: value }, () => { this.getPillarCategoryList(); })
  }


  searchCategory() {
    if (this.intervalInstance) { clearInterval(this.intervalInstance); }
    this.intervalInstance = setInterval(() => {
      this.getPillarCategoryList();
      if (this.intervalInstance) { clearInterval(this.intervalInstance); }
    }, 1000)
  }

  render() {


    let self = this;
    let { pillarCategoryList, page, openAddEditCategoryModal, categoryObj, systemLanguages, screenError } = this.state;
    let totalRecordsLabel = '##TOTAL_RECORDS## ' + (pillarCategoryList.length > 1 ? 'Categories' : 'Category')
    let options = {
      totalRecords: pillarCategoryList.length,
      totalRecordsLabel: totalRecordsLabel,
      rowsPerPage: 10000,
      page: page,
      loadMore: false,
      customColumn: {
        isActive: true,
        columnTitle: '',
        columnContent: (rowData) => {
          return (
            <div>
              <Nav>
                <NavItem>
                  <NavLink href="#" onClick={() => { this.appUpdatePillarCategory(rowData); }}><FontAwesomeIcon icon={faEdit} size='1x' /></NavLink>
                </NavItem>
                <NavItem>
                  <AppSwitch className={'mx-1'} variant={'pill'} color={'primary'} label checked={rowData.status === 'Active'} onChange={() => { this.changeStatus(rowData); }} />
                </NavItem>
              </Nav>
            </div>
          );
        }
      },

      onPageChange(pageNumber) {
        self.state.page = pageNumber;
        self.state.offset = parseInt(pageNumber) * parseInt(self.state.rowsPerPage)

      },
      onColumnSortChange: (column, direction) => {
        //direction = (direction === "ASC") ? -1 : 1;
        direction = column === 'status' ? ((direction === "ASC") ? 1 : -1) : ((direction === "ASC") ? -1 : 1);
        self.setState({ sortColumn: column, sortValue: direction }, () => { self.getPillarCategoryList(); })
      }
    };

    return (
      <div className="content">
        <Row className="pb-4">
          <Col lg={12} sm={12}>
            <h3>Category List</h3>
          </Col>
        </Row>


        <Row className="pb-4">

          <Col lg={3}></Col>


          <Col lg={3}><Input type="text" id="searchKey" placeholder="Search String" name="searchKey" value={this.state.searchKey}
            onChange={($event) => { this.setState({ searchKey: $event.target.value }); this.searchCategory() }} /></Col>

          <Col lg={3}><PillarList selectedPillarId={this.state.pillarId} handleChange={(event) => { this.handleChange(event) }} showAllOption={true} /></Col>
          <Col lg={3}><button onClick={() => { this.appUpdatePillarCategory(this.state.formData) }} className="btn btn-primary btn-block">Add New</button></Col>
        </Row>

        {screenError && <Alert color="danger">
          {screenError}
        </Alert>}

        <Row>
          <Col lg={12} sm={12}>
            {openAddEditCategoryModal && <AddEditCategory visible={openAddEditCategoryModal} closeModal={this.onCloseModal} formData={categoryObj} systemLanguages={systemLanguages} />}
            <div className="dataTable-wrapper pillarList_grid">
              <DataGrid
                columns={this.columns}
                options={options}
                rows={pillarCategoryList}
              />
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

export default Category;
