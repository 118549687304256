import React, { Component } from 'react';
import { connect } from 'react-redux';
import { addUpdateCategory } from './action';
import { Loader } from '../../utils/common';
import { Row, Col, Modal, Button, ModalHeader, ModalBody, Card, FormGroup, Label, Input, CardBody, CardFooter, Form, Alert } from "reactstrap";
import { AppSwitch } from '@coreui/react'
import PillarList from './PillarList';
import SimpleReactValidator from 'simple-react-validator';
import Constants from '../../config/constants';

import * as _ from 'underscore'

class AddEditCategory extends Component {


  constructor(props) {
    super(props);
    this.validator = new SimpleReactValidator();
    this.state = {
      _id: props.formData._id,
      title: props.formData.title,
      description: props.formData.description,
      priority: props.formData.priority,
      status: props.formData.status === 'Active' ? true : false,
      pillarId: props.formData.pillarId,
      layoutType: props.formData.layoutType,
      categoryLang: props.formData.categoryLang,
      systemLanguages: props.systemLanguages,
      screenError: "",
    }
  }



  componentWillReceiveProps(nextProps) {
    Loader(nextProps.requestProcess);
    if (nextProps.addUpdateCategory) {
      if (nextProps.addUpdateCategory.statusCode === 200) {
        this.props.closeModal(true);
      } else {
        this.setState({ screenError: (nextProps.addUpdateCategory['data'] && nextProps.addUpdateCategory['data']['statusDescription']) ? nextProps.addUpdateCategory['data']['statusDescription'] : Constants.SOMETHING_WRONG })
      }
    }
  }

  handleChange(event) {
    let target = event.target;

    let value;

    if (target.type === 'checkbox' && target.name === 'status') {
      value = (target.value === 'Active') ? false : true;
    } else {
      value = target.value;
    }

    const name = target.name;
    this.setState({ [name]: value });
  }

  handleSubmit(event) {
    if (this.validator.allValid()) {

      let languageData = [];
      _.each(this.state.systemLanguages, function (value, index) {
        languageData.push(value['cateObj']);
      })

      let pillarCatData = {
        priority: parseInt(this.state.priority),
        status: this.state.status,
        categoryId: this.state._id,
        pillarId: this.state.pillarId,
        layoutType: this.state.layoutType,
        categoryLang: languageData
      }
      this.props._addUpdateCategory(pillarCatData);
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
    event.preventDefault();
  }

  createSelectOption() {
    let priorities = [
      { value: 1, displayValue: "High" },
      { value: 2, displayValue: "Medium" },
      { value: 3, displayValue: "Low" }
    ];
    return priorities.map(function (obj) { return (<option key={obj.value} value={obj.value} >{obj.displayValue}</option>); })
  }

  createGridsOption() {
    let layoutType = [
      { value: Constants.GRID_SQ, displayValue: Constants.GRID_SQ_LABEL },
      { value: Constants.GRID_RT, displayValue: Constants.GRID_RT_LABEL },
      { value: Constants.LIST, displayValue: Constants.LIST_LABEL },
    ];
    return layoutType.map(function (obj) { return (<option key={obj.value} value={obj.value} >{obj.displayValue}</option>); })
  }

  setAttr(event, index, languageSlug) {
    this.state.systemLanguages[index]['cateObj'][event.target.name] = event.target.value;
    this.state.systemLanguages[index]['cateObj']['languageSlug'] = languageSlug;
    this.setState({ systemLanguages: this.state.systemLanguages })
  }



  LanguagesToRender() {
    let self = this;
    return this.state.systemLanguages.map(function (obj, index) {
      return (
        <div key={obj.slug}>
          <Row >
            <Col xs="12">
              <FormGroup >
                <Label htmlFor="title">{obj.title} Title</Label>

                <Input type="text" id="" placeholder="Title" name="title" value={(obj.cateObj && obj.cateObj['title']) ? obj.cateObj['title'] : ""} onChange={(event) => { self.setAttr(event, index, obj.slug) }} />
                <div className="validation-error">{self.validator.message(obj.title + ' Title', self.state.systemLanguages[index].cateObj['title'], 'required')}</div>


                <Label htmlFor="description">{obj.title} Description</Label>
                <Input type="textarea" id="" placeholder="Description" name="description" value={(obj.cateObj && obj.cateObj['description']) ? obj.cateObj['description'] : ""} onChange={(event) => { self.setAttr(event, index, obj.slug) }} />

                <div className="validation-error">{self.validator.message(obj.title + ' Description', self.state.systemLanguages[index].cateObj['description'], 'required')}</div>

              </FormGroup>
            </Col>
          </Row>
        </div>
      );
    })
  }


  render() {

    let { visible, closeModal, formData } = this.props;
    let { screenError } = this.state;



    return (
      <Modal isOpen={visible}>
        <ModalHeader toggle={() => { closeModal(false) }}>{formData._id === 0 ? 'Add' : 'Update'} Pillar Category</ModalHeader>
        <ModalBody>
          <Form onSubmit={(event) => { this.handleSubmit(event) }}>
            <Row>
              <Col sm="12">
                <Card>
                  <CardBody>
                    {formData._id === 0 &&
                      <Row>
                        <Col xs="12">
                          <FormGroup>
                            <Label htmlFor="title">Pillar</Label>
                            <PillarList selectedPillarId={this.state.pillarId} handleChange={(event) => { this.handleChange(event) }} showAllOption={false} />
                            <div className="validation-error">{this.validator.message('pillarId', this.state.pillarId, 'required')}</div>
                          </FormGroup>
                        </Col>
                      </Row>
                    }

                    {this.LanguagesToRender()}

                    {/*<Row>
                                                                        <Col xs="12">
                                                                            <FormGroup>
                                                                                <Label htmlFor="title">Title</Label>
                                                                              <Input type="text" id="title" placeholder="Title" name="title" value={this.state.title} onChange={($event)=>{this.handleChange($event)}} />
                                                                              <div className="validation-error">{ this.validator.message('title', this.state.title, 'required') }</div>
                                                                            </FormGroup>
                                                                        </Col>
                                                                    </Row>*/}
                    <Row>
                      <Col xs="12">
                        <FormGroup>
                          <Label htmlFor="priority">Priority</Label>
                          <Input type="select" name="priority" id="priority" value={this.state.priority} onChange={($event) => { this.handleChange($event) }}>
                            {this.createSelectOption()}
                          </Input>
                          <div className="validation-error">{this.validator.message('priority', this.state.priority, 'required')}</div>
                        </FormGroup>

                      </Col>
                    </Row>


                    <Row>
                      <Col xs="12">
                        <FormGroup>
                          <Label htmlFor="priority">Layout Type</Label>
                          <Input type="select" name="layoutType" id="layoutType" value={this.state.layoutType} onChange={($event) => { this.handleChange($event) }}>
                            {this.createGridsOption()}
                          </Input>
                          <div className="validation-error">{this.validator.message('layout type', this.state.layoutType, 'required')}</div>
                        </FormGroup>

                      </Col>
                    </Row>

                    <Row>
                      <Col xs="12">
                        <FormGroup>
                          <Label htmlFor="status">Status</Label>
                          <div>
                            <AppSwitch className={'mx-1'} variant={'pill'} color={'primary'} id="status" name="status"
                              onChange={($event) => { this.handleChange($event) }}
                              checked={this.state.status}
                              value={this.state.status ? 'Active' : 'Inactive'} />
                          </div>
                        </FormGroup>

                      </Col>
                    </Row>
                    {screenError && <Alert color="danger">
                      {screenError}
                    </Alert>}


                  </CardBody>
                  <CardFooter>
                    <Button type="submit" size="sm" color="primary">{formData._id === 0 ? 'Save' : 'Update'}</Button>
                    <Button type="reset" size="sm" color="danger" onClick={() => { closeModal() }}>Cancel</Button>
                  </CardFooter>
                </Card>
              </Col>
            </Row>
          </Form>
        </ModalBody>
      </Modal>
    );
  }
}


const mapStateToProps = (state) => {
  var stateData = {
    requestProcess: state.category.requestProcess
  };
  if (state.category.addUpdateCategory) {
    stateData['addUpdateCategory'] = state.category.addUpdateCategory;
  }
  return stateData;
};

const mapDispatchToProps = (dispatch) => {
  return {
    _addUpdateCategory: (params) => {
      dispatch(addUpdateCategory(params));
    },

  }
};


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddEditCategory);
