import { connect } from 'react-redux';

import InstallVsSignup from './installVsSignup';
import { getInsightsGraphData } from './action';


const mapStateToProps = (state) => {		
	var stateData = {
		requestProcess: state.socialInsights.requestProcess,
	};

	if(state.installVsSignup.insightsGraphData){
		stateData['insightsGraphData'] = state.installVsSignup.insightsGraphData;
	}

	return stateData;
};

const mapDispatchToProps = (dispatch) => {
	return {
		_getInsightsGraphData: (params) => {
			dispatch(getInsightsGraphData(params));	
		}
	};
};


export default connect(
	mapStateToProps,
	mapDispatchToProps
)(InstallVsSignup);
