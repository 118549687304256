import { createAction } from 'redux-act';
import httpClient from '../../services/http';

export const requestGetSegmentList = createAction();
export const responseGetSegmentList = createAction();

export const requestGetUserList = createAction();
export const responseGetUserList = createAction();

export const requestSendNotification = createAction();
export const responseSendNotification = createAction();

export const requestScreensSlugs = createAction();
export const responseScreensSlugs = createAction();

export function getSegmentNotificationList(params) {
    return (dispatch) => {
        dispatch(requestGetSegmentList([]));
        httpClient.call("USER", "/get_segment_notification_data", params, { method: 'GET', headers: { 'content-type': 'application/json' } }).then(function (response) {
            dispatch(responseGetSegmentList(response));
        }, function (error) {
            dispatch(responseGetSegmentList(error));
        });
    }
}


export function getUserList(params) {
    return (dispatch) => {
        dispatch(requestGetUserList([]));
        httpClient.call("USER", "/notifications_user_list", params, { method: 'GET', headers: { 'content-type': 'application/json' } }).then(function (response) {
            dispatch(responseGetUserList(response));
        }, function (error) {
            dispatch(responseGetUserList(error));
        });
    }
}


export function sendPushNotification(params) {
    return (dispatch) => {
        dispatch(requestSendNotification([]));
        let endPoint = "/send_marketing_notificaton";
        if(['EMAIL'].indexOf(params.sendTo) == -1 && params.scheduleDateTime){
            endPoint = "/send_scheduled_marketing_notification"
        }
        httpClient.call("USER", endPoint, params, { method: 'POST', headers: { 'content-type': 'application/json' } }).then(function (response) {
            dispatch(responseSendNotification(response));
        }, function (error) {
            dispatch(responseSendNotification(error));
        });
        
    }
}


export function getScreenSlug(params) {
    return (dispatch) => {
        dispatch(responseScreensSlugs([]));
        httpClient.call("USER", "/get_marketing_screen_slug", params, { method: 'GET', headers: { 'content-type': 'application/json' } }).then(function (response) {
            dispatch(responseScreensSlugs(response));
        }, function (error) {
            dispatch(responseScreensSlugs(error));
        });
    }
}

export function getNotificationText() {
    return httpClient.call("USER", "/get_notification_text", {}, { method: 'GET', headers: { 'content-type': 'application/json' } });
}

export function updateNotificationText(params) {
    return httpClient.call("USER", "/update_notification_text", params, { method: 'POST', headers: { 'content-type': 'application/json' } });
}

export function getPillars(params) {
    return httpClient.call("PILLAR", "/get_pillars", params, { method: 'GET', headers: { 'content-type': 'application/json' } });
}


export function getCategories(pillarId) {
    let endPoint = "/get_pillar_categories";
    endPoint = pillarId ? endPoint + "/" + pillarId : endPoint;
    return httpClient.call("PILLAR", endPoint, {}, { method: 'GET', headers: { 'content-type': 'application/json' } });
}


export function getContentList(params) {
    return httpClient.call("PILLAR", "/get_contents_for_quest", params, { method: 'GET', headers: { 'content-type': 'application/json' } });
}

export function getCountryList(params) {
    return httpClient.call("ANALYTICS", "/get_all_countries", params, { method: 'GET', headers: { 'content-type': 'application/json' } });
}

