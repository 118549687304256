import {createReducer} from 'redux-act';
import { requestGetUsers, responseGetUsers, requestImportData, responseImportData, responseSendSingleWhatsAppMessage, requestSendSingleWhatsAppMessage, requestSendSingleWhatsAppMessageToAll, responseSendSingleWhatsAppMessageToAll, requestGetCampaignList, responseGetCampaignList} from './action';


const defaultState = {
	requestProcess: false,
};

const reducer = createReducer({

	[requestGetUsers]: (state) => {
		return {
			requestProcess: true,
			users: []
		};
	},

	[responseGetUsers]: (state, params) => {
		return {
			requestProcess: false,
			users: params
		};
	},

	[requestImportData]: (state) => {
		return {
			requestProcess: true,
		};
	},

	[responseImportData]: (state, params) => {
		return {
			requestProcess: false,
			dataImported: params,
		};
	},



	[requestSendSingleWhatsAppMessage]: (state) => {
		return {
			requestProcess: true,
		};
	},

	[responseSendSingleWhatsAppMessage]: (state, params) => {
		return {
			requestProcess: false,
			singleMessageSend: params,
		};
	},

	[requestSendSingleWhatsAppMessageToAll]: (state) => {
		return {
			requestProcess: true,
		};
	},

	[responseSendSingleWhatsAppMessageToAll]: (state, params) => {
		return {
			requestProcess: false,
			sentAllMessage: params,
		};
	},


	[requestGetCampaignList]: (state) => {
		return {
			requestProcess: true,
		};
	},

	[responseGetCampaignList]: (state, params) => {
		return {
			requestProcess: false,
			campaignList: params,
		};
	},


}, defaultState);



export default reducer