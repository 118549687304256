import {createReducer} from 'redux-act';
import { requestGetParents, responseGetParents, requestGetChildren, responseGetChildren, requestChangeStatus, responseChangeStatus, requestVerifyUser, responseVerifyUser, requestSendVerificationLink, responseSendVerificationLink, requestChangeSubscription, responseChangeSubscription, responseDeleteParent} from './action';


const defaultState = {
	requestProcess: false,
};

const reducer = createReducer({

	[requestGetParents]: (state) => {
		return {
			requestProcess: true,
			data: []
		};
	},

	[responseGetParents]: (state, params) => {
		return {
			requestProcess: false,
			data: params
		};
	},

	[requestGetChildren]: (state) => {
		return {
			requestProcess: true,
			childData: []
		};
	},

	[responseGetChildren]: (state, params) => {
		return {
			requestProcess: false,
			childData: params
		};
	},

	[requestChangeStatus]: (state) => {
		return {
			requestProcess: true
		};
	},

	[responseChangeStatus]: (state, params) => {
		return {
			requestProcess: false,
			changeStatus: params
		};
	},



	[requestVerifyUser]: (state) => {
		return {
			requestProcess: true
		};
	},

	[responseVerifyUser]: (state, params) => {
		return {
			requestProcess: false,
			userVerified: params
		};
	},


	[requestSendVerificationLink]: (state) => {
		return {
			requestProcess: true
		};
	},

	[responseSendVerificationLink]: (state, params) => {
		return {
			requestProcess: false,
			verificationLinkSend: params
		};
	},


	[requestChangeSubscription]: (state) => {
		return {
			requestProcess: true
		};
	},

	[responseChangeSubscription]: (state, params) => {
		return {
			requestProcess: false,
			subscriptionChanged: params
		};
	},

  [responseDeleteParent]: (state, params) => {
		return {
			requestProcess: false,
      parentDeletedResponse: params
		};
	},


}, defaultState);



export default reducer
