import {createAction} from 'redux-act';
import httpClient from '../../services/http';	



export const requestData = createAction();
export const responseData = createAction();

export const requestUpdateData = createAction();
export const responseUpdateData = createAction();

export function getBackgrroundMusic(params){
  return (dispatch) => {
      dispatch(requestData([]));
      httpClient.call('CONFIG','/get_background_media', {offset: 0, limit: 10, status: 'all'}, {method: 'GET', headers: {}}).then(function(response){
          dispatch(responseData(response));
      }, function(error){
          dispatch(responseData(error));
      });
  }
}

export function updateBackgroundMedia(params){
  return (dispatch) => {
      dispatch(requestUpdateData([]));
      httpClient.call('CONFIG','/update_media', params, {method: 'PUT', headers: {}}).then(function(response){
          dispatch(responseUpdateData(response));
      }, function(error){
          dispatch(responseUpdateData(error));
      });
  }
}