import React, { Component } from 'react';
import { Loader } from '../../utils/common';
import DataGrid from "g6reactdatagrid";
import { Row, Col, Nav, NavItem, NavLink, Input, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup, Label, Form, Alert, Button } from "reactstrap";
import { AppSwitch } from '@coreui/react'
import moment from 'moment';
import Constants from '../../config/constants';
import Session from '../../utils/session';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit } from '@fortawesome/free-solid-svg-icons'
import AddQuest from './addQuest'
import UpdateQuest from './updateQuest'
import SimpleReactValidator from 'simple-react-validator';
import * as _ from 'underscore';




class DailyQuest extends Component {
  constructor(props) {
    super(props);
    this.state = {
      limit: 10,
      offset: 0,
      sortField: "createdDate",
      sortValue: -1,
      totalRecord: 0,
      questList: [],
      page: 0,
      openAddAppQuestModal: false,
      addQuestPopupError: "",
      contentData: [],
      selectedRow: {},
      updatedOrder: 0,
      openUpdateAppQuestModal: false,
      updateQuestPopupError: "",
      systemLanguages: Session.getSessionData('languages')['languages'],
      openUpdateOrderModal: false
    }
    this.columns = [
      { "columnName": "description", "displayName": "Tagline", "sort": false },
      { "columnName": "contentTitle", "displayName": "Content", "sort": false },
      { "columnName": "contentStatus", "displayName": "Content Status", "sort": false },
      { "columnName": "occurrence", "displayName": "No. of events", "sort": true },
      { "columnName": "additionalPoints", "displayName": "Bonus Points", "sort": true },
      { "columnName": "createdDate", "displayName": "Created On", "sort": true },
      { "columnName": "order", "displayName": "Order", "sort": true },
    ];
    this.validator = new SimpleReactValidator();
  }


  componentDidMount() {
    this.getAllQuest();
    this.getContent('');
  }

  getContent(searchKey) {
    this.props._getContentList({ search: searchKey, limit: 1000, offset: 0, contentType: Constants.AUDIO });
  }


  componentWillReceiveProps(nextProps) {
    let self = this;
    let questList = [];
    Loader(nextProps.requestProcess);

    if (nextProps.questData && nextProps.questData['statusCode'] === 200) {
      if (nextProps.questData['result'] && nextProps.questData['result'].length) {
        _.each(nextProps.questData['result'], (value, index) => {
          value['contentTitle'] = (value.content && value.content['title']) ? value.content['title'] : "";
          value['contentStatus'] = (value.content && value.content['status']) ? (value.content['status'] == Constants.PUBLISHED) ? `<p style="color:#00FF00">${value.content['status']}<p>` : `<p style="color:#FF0000">${value.content['status']}<p>` : "--";
          value['contentId'] = (value.content && value.content['_id']) ? value.content['_id'] : "";
          value.createdDate = moment(value.createdDate).format("DD-MMM-YYYY");
          questList.push(value)
        })

        self.setState({
          questList: questList,
          totalRecord: nextProps.questData['totalRecords']
        });
      }
    }

    if (nextProps.questUpdated && nextProps.questUpdated['statusCode'] === 200) {
      self.getAllQuest()
      self.closeModal();
    }


    if (nextProps.questAdded && nextProps.questAdded['statusCode'] === 200) {
      self.getAllQuest();
      self.closeModal();
    }


    if (nextProps.contentList && nextProps.contentList.statusCode === 200) {
      this.setState({ contentData: nextProps.contentList.result });
    }
  }

  getAllQuest() {
    let self = this;
    let params = {
      limit: self.state.limit,
      offset: self.state.offset,
      sortField: self.state.sortField,
      sortValue: self.state.sortValue,
    }
    self.props._getAllQuest(params)
  }

  changeStatus(rowData) {
    let params = {
      order: rowData.order,
      description: rowData.description,
      contentId: rowData.contentId,
      additionalPoints: rowData.additionalPoints,
      occurrence: rowData.occurrence,
      //questId : rowData.questId,
      status: (rowData.status) ? false : true,
      questId: rowData._id,
      dailyQuestLang: rowData.dailyQuestLang
    }

    this.props._updateQuest(params)

  }




  updateQuest(event, questObj) {
    let params = {
      updatedOrder: questObj.updatedOrder ? questObj.updatedOrder : questObj.order,
      order: questObj.order,
      description: questObj.description,
      contentId: questObj.contentId,
      additionalPoints: parseInt(questObj.additionalPoints),
      occurrence: parseInt(questObj.occurrence),
      status: questObj.status,
      questId: questObj.questId,
      dailyQuestLang: questObj.dailyQuestLang
    }
    console.log('params', questObj, params)
    this.props._updateQuest(params)

  }

  addNewQuestPopup() {
    let self = this;
    _.each(self.state.systemLanguages, function (value, index) {
      self.state.systemLanguages[index]['dailyQuestLang'] = { description: "", languageSlug: value['slug'] };
    })


    self.setState({
      selectedRow: {},
      openAddAppQuestModal: true,
      addQuestPopupError: "",
      systemLanguages: self.state.systemLanguages
    })
  }


  updateQuestPopup(rowData) {
    let self = this;
    _.each(this.state.systemLanguages, function (value, index) {
      let langObj = _.findWhere(rowData['dailyQuestLang'], { languageSlug: value['slug'] });
      self.state.systemLanguages[index]['dailyQuestLang'] = (!_.isEmpty(langObj)) ? langObj : { description: "", languageSlug: value['slug'] };
    })
    this.setState({
      selectedRow: rowData,
      openUpdateAppQuestModal: true,
      updateQuestPopupError: "",
      systemLanguages: self.state.systemLanguages

    })
  }

  closeModal() {
    this.setState({
      openAddAppQuestModal: false,
      addQuestPopupError: "",
      openUpdateAppQuestModal: false,
      openUpdateOrderModal: false,
      updateQuestPopupError: "",
      selectedRow: {}
    })
  }

  addQuest(event, questObj) {
    this.props._addQuest(questObj);
    event.preventDefault();
  }

  updateOrder(event) {
    let { updatedOrder, selectedRow } = this.state
    if (this.validator.allValid() && !isNaN(updatedOrder)) {
      selectedRow['updatedOrder'] = parseInt(updatedOrder);
      selectedRow['questId'] = selectedRow._id;
      this.updateQuest(event, selectedRow);
      this.closeModal()
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
    event.preventDefault();
  }


  updateOrderPopup(selectedRow) {
    this.setState({ selectedRow, updatedOrder: selectedRow.order, openUpdateOrderModal: true });
  }




  render() {
    let self = this;
    const { openAddAppQuestModal, addQuestPopupError, contentData, openUpdateAppQuestModal, updateQuestPopupError, selectedRow } = this.state;
    let { page, limit, totalRecord, questList, systemLanguages, openUpdateOrderModal } = this.state;
    let totalRecordsLabel = '##TOTAL_RECORDS## ' + (totalRecord > 1 ? 'Quests' : 'Quest')
    let options = {
      totalRecords: totalRecord,
      totalRecordsLabel: totalRecordsLabel,
      rowsPerPage: limit,
      page: page,
      loadMore: false,
      customColumn: {
        isActive: true,
        columnTitle: '',
        columnContent: (rowData) => {
          // let rowIndex = questList.indexOf(rowData)
          return (

            <div>

              <Nav>
                <NavItem>
                  {/*<Input className="order" style={{width: 50}} type="text" name={"listOrder_"+rowData._id} 
                  onChange={(event)=>{
                    console.log('event', event.target.value)
                  }}
                 onBlur={(event)=>{
                  self.changeListOrder(event, rowIndex, rowData)
                }} 
                 onKeyDown={(event)=>{
                    if(event.key === 'Enter') {
                      event.target.blur()
                      //self.changeListOrder(event, rowIndex, rowData)
                    }
                  }} 
                />*/}
                  <NavLink href="#" onClick={() => { self.updateOrderPopup(rowData); }}>Update Order</NavLink>
                </NavItem>
                <NavItem>
                  <NavLink href="#" onClick={() => { self.updateQuestPopup(rowData); }}><FontAwesomeIcon icon={faEdit} size='1x' /></NavLink>
                </NavItem>

                <NavItem>
                  <AppSwitch className={'mx-1'} variant={'pill'} color={'primary'} label checked={rowData.status} onChange={() => { self.changeStatus(rowData) }} />

                </NavItem>


              </Nav>


            </div>


          );
        }
      },

      onPageChange(pageNumber) {

        self.setState({
          page: pageNumber,
          offset: parseInt(pageNumber) * parseInt(self.state.limit),
        }, () => {
          self.getAllQuest();
        })

      },

      onColumnSortChange: (column, direction) => {
        self.setState({
          sortField: column,
          sortValue: (direction === "ASC") ? -1 : 1
        }, () => {
          self.getAllQuest();
        })
      }
    };


    return (
      <div className="content">
        <Row className="pb-4">
          <Col lg={12} sm={12}>
            <h3>Daily Quest</h3>
          </Col>
        </Row>

        <Row className="pb-4">
          <Col lg={12} sm={12} className="text-right">
            {
              <button className="btn btn-primary" color="link" onClick={() => self.addNewQuestPopup()}>Add New Quest</button>
            }
          </Col>
        </Row>

        <Row>
          <Col lg={12} sm={12}>
            <div className="dataTable-wrapper pillarList_grid">
              <DataGrid
                columns={this.columns}
                options={options}
                rows={questList}
              />
            </div>
          </Col>
        </Row>
        <Modal isOpen={openUpdateOrderModal}>
          <ModalHeader toggle={() => { self.closeModal(false) }}>Update Order</ModalHeader>
          <Form onSubmit={(event) => { this.updateOrder(event) }}>
            <ModalBody>
              <Row>
                <Col xs="12">
                  <FormGroup>
                    <Label htmlFor="tagline">Updated Order</Label>
                    <Input className="order" type="text" name={"updatedOrder"} onChange={(event) => { this.setState({ updatedOrder: event.target.value }) }} />
                    <div className="validation-error">{this.validator.message('Updated Order', this.state.updatedOrder, 'required|num')}</div>
                  </FormGroup>
                </Col>
              </Row>
              {(updateQuestPopupError) ? <Alert color="danger">{updateQuestPopupError}</Alert> : null}
            </ModalBody>
            <ModalFooter className="justify-content-left">
              <Button type="submit" size="sm" color="primary">Update</Button>{' '}
              <Button type="reset" size="sm" color="danger" onClick={() => { self.closeModal() }}>Cancel</Button>
            </ModalFooter>
          </Form>
        </Modal>
        {openAddAppQuestModal && <AddQuest openAddAppQuestModal={openAddAppQuestModal} addQuestPopupError={addQuestPopupError} getContent={(searchKey) => this.getContent(searchKey)} closeModal={(event) => self.closeModal()} contentData={contentData} addQuest={(event, questObj) => { self.addQuest(event, questObj) }} systemLanguages={systemLanguages}></AddQuest>}


        {selectedRow._id && openUpdateAppQuestModal && <UpdateQuest openUpdateAppQuestModal={openUpdateAppQuestModal} getContent={(searchKey) => this.getContent(searchKey)} updateQuestPopupError={updateQuestPopupError} closeModal={(event) => self.closeModal()} contentData={contentData} updateQuest={(event, questObj) => { self.updateQuest(event, questObj) }} selectedRow={selectedRow} systemLanguages={systemLanguages}></UpdateQuest>}
      </div>
    );
  }
}

export default DailyQuest;
