 import React, { Component } from 'react';
import { Loader } from '../../utils/common';
import { Row, Col, Modal, Button, ModalHeader, ModalBody, ModalFooter, FormGroup, Label, Input, Form , Alert } from "reactstrap";
import moment from 'moment'; 
import SimpleReactValidator from 'simple-react-validator';
import Constants from '../../config/constants';  
import { createCouponCode } from '../../utils/common';  

import DatePicker from 'react-date-picker'; 

import * as _ from 'underscore'

class AddPromotionalDiscount extends Component {
  constructor(props) {
    super(props);
    this.validator = new SimpleReactValidator();
    this.state = {
      couponCode : createCouponCode(),
      discount : this.props.activeTab == Constants.SCHOOL_AFFILIATES ? "80" : "30",
      lifetimeDiscount : "0",
      monthlyDiscount : "0",
      yearlyDiscount : "0",
      discountType : Constants.PERCENTAGE,
      maxDiscount : Constants.MAX_DISCOUNT_PERCRNTAGE,
      description : "",
      email : "",
      activeTab : this.props.activeTab,
      startOn : new Date(moment().format("YYYY-MM-DD")),
      expiredOn : new Date(moment().add(1, 'year').format("YYYY-MM-DD")),
      currentDate : new Date(moment().format("YYYY-MM-DD")),
      totalPercentageError : "",
      totalDiscountPercentage : ""
    }
  }

  componentDidMount() {
    this.setDifferences(this.state.discount)
  }

  componentWillReceiveProps(nextProps) {
    //let self = this;
    Loader(nextProps.requestProcess);
  }

  setDifferences(discount) {
    let discountDifference = _.findWhere(this.props.appstoreDifferences, {couponSlug: "PERCENTAGE_"+discount});
    if (!_.isEmpty(discountDifference)) {
      this.setState({
        lifetimeDiscount : discountDifference.storeDiffLifetime,
        monthlyDiscount : discountDifference.storeDiffMonthly,
        yearlyDiscount : discountDifference.storeDiffYearly,
      })
    }
  }
  

  handleChange(event) {
    let target = event.target;
    let name = target.name;
    let value;


    if (name === "couponCode") {
      if (!(/^\S*$/.test(target.value))) {
        return false;
      }
      target.value = target.value.toUpperCase();
    }


    if (name === "startOn") {
      this.setState({expiredOn : target.value});
    }

    if (target.name === 'discountType') {
      this.state.maxDiscount = (target.value === Constants.PERCENTAGE) ? Constants.MAX_DISCOUNT_PERCRNTAGE : Constants.MAX_DISCOUNT_OFF;
      this.state.discount = 1;
    }

    if (name === "discount") {
      target.value = (target.value > this.state.maxDiscount) ? this.state.maxDiscount : target.value;
      if (this.props.appstoreDifferences && this.props.appstoreDifferences.length) {
        this.setDifferences(target.value)
      }
    }


    value = target.value;
    this.setState({[name]: value}, (() => {
      /*if (name === "monthlyDiscount" || name === "yearlyDiscount" || name === "discount" || name === "lifetimeDiscount") {
        let errMessage = ""
        if (parseInt(value) < 0) {
          this.setState({[name]: 0})
        }

        if (((parseInt(this.state.discount) + parseInt(this.state.monthlyDiscount) + parseInt(this.state.yearlyDiscount) + parseInt(this.state.lifetimeDiscount)) > 100)) {
          errMessage = "Sum of all discounts cannot be greater than 100%";
        }


        if (((parseInt(this.state.discount) + parseInt(this.state.monthlyDiscount) + parseInt(this.state.yearlyDiscount) + parseInt(this.state.lifetimeDiscount)) < 0)) {
          errMessage = "Sum of all discounts cannot be less than 0%";
        }
        this.setState({totalDiscountPercentage : (parseInt(this.state.discount) + parseInt(this.state.monthlyDiscount) + parseInt(this.state.yearlyDiscount) + parseInt(this.state.lifetimeDiscount)), totalPercentageError : errMessage
        })
      }*/
    }));


  }

  handleSubmit(e) {
    if( this.validator.allValid()/* && this.state.totalPercentageError === ""*/){
      let discountsObj = {
        couponCode : this.state.couponCode,
        discount : this.state.discount,
        monthlyDiscount : this.state.monthlyDiscount,
        yearlyDiscount : this.state.yearlyDiscount,
        lifetimeDiscount : this.state.lifetimeDiscount,
        type : this.state.discountType,
        description : this.state.description,
        email : this.state.email,
        discountType : this.state.activeTab,
        expiredOn : this.state.expiredOn,
        startOn : this.state.startOn,
      }  
      this.props._addDiscount(discountsObj);
    }else{
      this.validator.showMessages();
      this.forceUpdate();
    }
    e.preventDefault();
  }

  
formatTitlePosLabel (s) {
    if (typeof s !== 'string') return ''
      s = s.replace("_", " ");
      s = s.charAt(0).toUpperCase() + s.slice(1).toLowerCase();
      return s;
  }


  setDate(name, value) {
    this.setState({[name] : new Date(value)})
  }

  render() {
   // let self = this;
    let { openAddPromotionalDiscountModal, closeModal, addPromotionalDiscountPopupError } = this.props;
    let { totalPercentageError } = this.state;
    

    return (
      <Modal isOpen={openAddPromotionalDiscountModal}>
          <ModalHeader toggle={()=>{closeModal(false)}}>{this.props.modelTitle ? this.props.modelTitle : 'Add Promotional Coupons'}</ModalHeader>
          <Form onSubmit={(event) => {this.handleSubmit(event)}}>  
            <ModalBody>
              <Row>
                <Col xs="12">
                    <FormGroup>
                      <Label htmlFor="couponCode">Coupon Code</Label>
                      <Input type="text" maxLength="100" id="couponCode" placeholder="Coupon code" name="couponCode" value={this.state.couponCode} onChange={(event)=>{this.handleChange(event)}} min="5" pattern="^\S*$" />                                              
                      <div className="validation-error">{ this.validator.message('Coupon code', this.state.couponCode, 'required|min:5') }</div>   
                    </FormGroup>
                </Col>
              </Row>


              {/*<Row>
                              <Col xs="12">
                                  <FormGroup>
                                      <Label htmlFor="discountType">Discount Type</Label>
                                      <Input type="select" name="discountType" id="discountType" value={this.state.discountType} onChange={($event)=>{this.handleChange($event)}}>
                                        <option value={Constants.PERCENTAGE}>{this.formatTitlePosLabel(Constants.PERCENTAGE)}</option>
                                        <option value={Constants.OFF}>{this.formatTitlePosLabel(Constants.OFF)}</option>
                                      </Input>
                                      <div className="validation-error">{ this.validator.message('discountType', this.state.discountType, 'required') }</div>
                                  </FormGroup>
                              </Col>
                            </Row>*/}


              <Row>
                <Col xs="12">
                    <FormGroup>
                      <Label htmlFor="couponCode">Discount value (%)</Label>
                      {/*<Input type="number" id="discount" placeholder="Discount value" name="discount" value={this.state.discount} onChange={(event)=>{this.handleChange(event)}} min="1" max={ maxDiscount }/>                                              
                                               <div className="validation-error">{ this.validator.message('Discount', this.state.discount, 'required') }</div>*/}
                      <Input type="select" id="discount" placeholder="Discount value" name="discount" value={this.state.discount} onChange={(event)=>{this.handleChange(event)}}>
                        {this.props.activeTab == Constants.PROMOTIONAL ?
                            <>
                              <option value="30">30</option>
                              <option value="40">40</option>
                              <option value="50">50</option>
                            </>
                        :
                          <option value="80">80</option>
                        }
                      </Input>
                      <div className="validation-error">{ this.validator.message('Discount', this.state.discount, 'required') }</div>
                    </FormGroup>
                </Col>
              </Row>


               <Row>
                <Col xs="12">
                    <FormGroup>
                      <Label htmlFor="monthlyDiscount">Monthly Discount ($)</Label>

                      <Input type="text" id="monthlyDiscount" placeholder="Monthly Discount" name="monthlyDiscount" value={this.state.monthlyDiscount} onChange={(event)=>{this.handleChange(event)}} step="any" readOnly>
                      </Input>
                      <div className="validation-error">{ this.validator.message('Monthly Discount', this.state.monthlyDiscount, 'required') }</div>
                    </FormGroup>
                </Col>
              </Row>


              <Row>
                <Col xs="12">
                    <FormGroup>
                      <Label htmlFor="yearlyDiscount">Yearly Discount ($)</Label>

                      <Input type="text" id="yearlyDiscount" placeholder="Yearly Discount" name="yearlyDiscount" value={this.state.yearlyDiscount} onChange={(event)=>{this.handleChange(event)}} step="any" readOnly>
                      </Input>
                      <div className="validation-error">{ this.validator.message('Yearly Discount', this.state.yearlyDiscount, 'required') }</div>
                    </FormGroup>
                </Col>
              </Row>


              <Row style={{display: 'none'}}>
                <Col xs="12">
                    <FormGroup>
                      <Label htmlFor="lifetimeDiscount">Lifetime Discount ($)</Label>

                      <Input type="text" id="lifetimeDiscount" placeholder="Lifetime Discount" name="lifetimeDiscount" value={this.state.lifetimeDiscount} onChange={(event)=>{this.handleChange(event)}} step="any" readOnly>
                      </Input>
                      <div className="validation-error">{ this.validator.message('Lifetime Discount', this.state.lifetimeDiscount, 'required') }</div>
                    </FormGroup>
                </Col>
              </Row>

               <Row>
                <Col xs="12">
                    <FormGroup>
                      <Label htmlFor="description">Start On</Label>                                              
                      <DatePicker value={this.state.startOn} onChange={(event)=>{this.setDate('startOn',event)}} minDate={this.state.currentDate} />
                      <div className="validation-error">{ this.validator.message('Expire Date', this.state.expiredOn, 'required') }</div>   
                    </FormGroup>
                </Col>
              </Row>


               <Row>
                <Col xs="12">
                    <FormGroup>
                      <Label htmlFor="description">Expired On</Label>

                      <DatePicker value={this.state.expiredOn} onChange={(event)=>{this.setDate('expiredOn',event)}} minDate={this.state.startOn} />
                      <div className="validation-error">{ this.validator.message('Expire Date', this.state.expiredOn, 'required') }</div>   
                    </FormGroup>
                </Col>
              </Row>
              



              <Row>
                <Col xs="12">
                    <FormGroup>
                      <Label htmlFor="description">Description</Label>
                      <Input type="textarea" id="description" maxLength="500" placeholder="Description" name="description" value={this.state.description} onChange={(event)=>{this.handleChange(event)}} />                                              
                      <div className="validation-error">{ this.validator.message('Description', this.state.description, 'required') }</div>   
                    </FormGroup>
                </Col>
              </Row>

              {/*<Row>
                              <Col xs="12">
                                  <FormGroup>
                                    <Label htmlFor="email">Email</Label>
                                    <Input type="text" id="email" placeholder="Email" name="email" value={this.state.email} onChange={(event)=>{this.handleChange(event)}} />
                                    <div className="validation-error">{ this.validator.message('Email', this.state.email, 'email') }</div>   
                                  </FormGroup>
                              </Col>
                            </Row>*/}

              {(addPromotionalDiscountPopupError) ? <Alert color="danger">{addPromotionalDiscountPopupError}</Alert> : null}
              {(totalPercentageError) ? <Alert color="danger">{totalPercentageError}</Alert> : null}
            </ModalBody>
            <ModalFooter className="justify-content-left">
              <Button type="submit" size="sm" color="primary" >Add</Button>{' '}
              <Button type="reset" size="sm" color="danger"  onClick={() => { closeModal() }}>Cancel</Button>
            </ModalFooter>
          </Form>
        </Modal>
      
    );
  }
}

export default AddPromotionalDiscount;
