import { connect } from 'react-redux';

import StaticLanguage from './StaticLanguage';
import { getStaticLanguage, addStaticLanguage, changeStaticLanguageStatus, updateStaticLanguage, triggerNotificaton } from './action';


const mapStateToProps = (state) => {
		var stateData = {
			requestProcess: state.category.requestProcess
		};
		if(state.staticLanguage.languageList){
			stateData['languageList'] = state.staticLanguage.languageList;
		}


		if(state.staticLanguage.languageAdded){
			stateData['languageAdded'] = state.staticLanguage.languageAdded;
		}


		if(state.staticLanguage.statusChanged){
			stateData['statusChanged'] = state.staticLanguage.statusChanged;
		}


		if(state.staticLanguage.languageUpdated){
			stateData['languageUpdated'] = state.staticLanguage.languageUpdated;
		}

		if(state.staticLanguage.triggerNotificaton){
			stateData['triggerNotificaton'] = state.staticLanguage.triggerNotificaton;
		}
		return stateData;
};

const mapDispatchToProps = (dispatch) => {
	return {
		_getStaticLanguage: (params) => {
			dispatch(getStaticLanguage(params));
		},


		_addStaticLanguage: (params) => {
			dispatch(addStaticLanguage(params));
		},


		_changeStaticLanguageStatus: (params) => {
			dispatch(changeStaticLanguageStatus(params));
		},


		_updateStaticLanguage: (params) => {
			dispatch(updateStaticLanguage(params));
		},

		_triggerNotificaton: (params) => {
			dispatch(triggerNotificaton(params));
		},
	}
};


export default connect(
	mapStateToProps,
	mapDispatchToProps
)(StaticLanguage);
