import React, { Component } from 'react';
import { Loader } from '../../utils/common';
import DataGrid from "g6reactdatagrid";
import { Row, Col, Modal, Button, ModalHeader, ModalBody, Card, FormGroup, Label, Input, CardBody, CardFooter, Form, Nav, NavItem, NavLink, ModalFooter } from "reactstrap";
import moment from 'moment';
import SimpleReactValidator from 'simple-react-validator';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faWindowClose } from '@fortawesome/free-solid-svg-icons'
import Session from '../../utils/session';
import  Constants from '../../config/constants';


class Feedback extends Component {
  constructor(props) {
    super(props);
    // Loader(true);
    this.session = Session.getSessionData('config');
    this.apiUrl = this.session && this.session.UTILS && this.session.UTILS.BUCKET_URL ? this.session.UTILS.BUCKET_URL : process.env.REACT_APP_CLOUDFRONT_URL;

    this.getFeedback = this.getFeedback.bind(this);
    this.UpdateSection = this.UpdateSection.bind(this);
    this.FeedbackDetail = this.FeedbackDetail.bind(this);
    this.validator = new SimpleReactValidator();
    this.myForm = null;

    this.state = {
      limit : 10,
      offset : 0,
      page:0,      sortField : "",
      sortValue : null,
      feedbackList : [],
      totalRecords : 0,
      remark : "",
      openmodalbox : false,
      selectedFeedbackId : "",
      showFeedbackModal : false,
      selectedRow : {},
      status : Constants.OPEN,
      resolvedBy : "",
    }

    this.columns = [
      { "columnName": "title", "displayName": "Title", "sort": true},
      { "columnName": "shortDescription", "displayName": "Description"},
      { "columnName": "email", "displayName": "Email"},
      { "columnName": "deviceType", "displayName": "Device Type", sort : true},
      { "columnName": "buildVersion", "displayName": "Build Version", sort: true},
      // { "columnName": "status", "displayName": "Status", "sort": true},
      { "columnName": "type", "displayName": "Type", "sort": true},
      { "columnName": "createdDate", "displayName": "Created On", "sort": true},
      { "columnName": "modifiedDate", "displayName": "Modified On", "sort": true}
    ];
  }

  componentDidMount() {
    this.getFeedback(this.state.offset, this.state.limit, this.state.sortField, this.state.sortValue);
  }




  componentWillReceiveProps(nextProps) {
    //let self = this;
    let feedbackList = [];
    Loader(nextProps.requestProcess);
    if (nextProps.getFeedback && nextProps.getFeedback.statusCode === 200) {
      let listData = (nextProps.getFeedback.result.length) ? nextProps.getFeedback.result   : [];
      listData.map(function (element, key) {
        if (element.status) {
          element.status = 'Open'
        }else{
          element.status = 'Closed'
        }

        if (element.description && element.description.length >= 30) {
          element['shortDescription'] = element['description'].substring(0, 30);
          element['shortDescription'] = element['shortDescription']+"...";
        }else{
          element['shortDescription'] = element['description']
        }

        if (element._userId && Object.keys(element._userId).length) {
          element.userId = element._userId['_id'];
          element.email = element._userId['email'];
        }else{
          element.userId = "";
          element.email = "";
        }

        element.createdDate = moment(element.createdDate).format("DD-MMM-YYYY")
        element.modifiedDate = moment(element.modifiedDate).format("DD-MMM-YYYY")


        feedbackList.push(element);
        return true;
      });

      this.setState({
        feedbackList : feedbackList,
        totalRecords : nextProps['getFeedback']['totalRecords'],
      })
    }

    if (nextProps.closedFeedbackData && nextProps.closedFeedbackData.statusCode === 200) {
      this.setState({
        remark : "",
        selectedFeedbackId : "",
      }) 
      this.getFeedback(this.state.offset, this.state.limit, this.state.sortField, this.state.sortValue);
      this.onCloseModal();
    }
  }


  onCloseModal = () => {
    this.setState({ openmodalbox: false, showFeedbackModal:false });
  }


  issueModal(feedbackObj) {
    this.setState({
      selectedFeedbackId: feedbackObj['_id'],
      openmodalbox: true,
      resolvedBy : "",
      remark : ""
    });
  }

  getFeedback(offset, limit, sortField, sortValue, search) {
    let params = {
      limit: limit,
      offset: offset,
      search: search,
      sortField: sortField,
      sortValue: sortValue,
      status: this.state.status,
    };

    this.props._getFeedback(params);
  }

  closeFeedback(event) {
    if( this.validator.allValid() ){
      let params = {
        remark : this.state.remark,
        resolvedBy : this.state.resolvedBy,
        feedbackId : this.state.selectedFeedbackId
      };
      this.props._closeFeedback(params);
    }else{
      this.validator.showMessages();
      this.forceUpdate();
    }
    event.preventDefault();
  } 

  /*closeFeedback(rowData) {
    var confirm = window.confirm("Are you sure you want to close this feedback");
    if (confirm) {
      let params = {
        feedbackId : rowData._id
      };
      this.props._closeFeedback(params);
    }
  }*/

  showFeedbackDetails(rowData) {
    this.setState({
      showFeedbackModal : true,
      selectedRow : rowData
    })
  }  

  UpdateSection (props) {
    return (
      <Modal isOpen={props.visible}>
          <ModalHeader toggle={props.closeModal}>Close Feedback</ModalHeader>
            <Form onSubmit={(event) => {this.closeFeedback(event)}}>
              <ModalBody>
                    <Row>
                        <Col sm="12">
                            <Card>
                                <CardBody>
                                    <Row>
                                        <Col xs="12">
                                            <FormGroup>
                                                <Label htmlFor="resolvedBy">Your Name*</Label>
                                                <Input type="text" id="resolvedBy" placeholder="Your Name" name="resolvedBy" value={this.state.resolvedBy} onChange={(event) => {this.handleChange(event)}} />
                                                <div className="validation-error">{ this.validator.message('Name', this.state.resolvedBy, 'required') }</div>
                                            </FormGroup>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col xs="12">
                                            <FormGroup>
                                                <Label htmlFor="remark">Remark*</Label>
                                                <Input type="textarea" id="remark" placeholder="Remark on this issue" name="remark" value={this.state.remark} onChange={(event) => {this.handleChange(event)}} />
                                                <div className="validation-error">{ this.validator.message('remark', this.state.remark, 'required') }</div>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>

                    </Row>
              </ModalBody>
              <ModalFooter className="justify-content-left">
                <Button type="submit" size="sm" color="primary">Close</Button>
                <Button type="reset" size="sm" color="danger"  onClick={() => { this.onCloseModal() }}>Cancel</Button>
              </ModalFooter>
            </Form>
      </Modal>
    );
  }

  FeedbackDetail (props) {
    return (
      <Modal isOpen={props.visible} size={props.selectedRow.screenShotUrl ? "lg" : ""}>
          <ModalHeader toggle={props.closeModal}>Feedback Details</ModalHeader>
          <ModalBody>
                <Row>
                  <Col sm="12">
                      <Card>
                          <CardBody>
                              <Row>
                                  <Col xs={props.selectedRow.screenShotUrl ? "8" : "12"} className="feedback-popup">
                                     <h5> Title</h5>
                                     <p>{props.selectedRow.title}</p>
                                    <hr />
                                     <h5> Description</h5>
                                     <p>{props.selectedRow.description}</p>
                                     <hr />
                                     <h5> Email</h5>
                                     <p>{props.selectedRow.email}</p>
                                     <hr />
                                     <h5> Device Type	</h5>
                                     <p>{props.selectedRow.deviceType}</p>
                                     <hr />
                                     <h5> Build Version</h5>
                                     <p>{props.selectedRow.buildVersion}</p>
                                     <hr />
                                     <h5> Status</h5>
                                     <p>{props.selectedRow.status}</p>
                                     <hr />
                                     <h5> Type</h5>
                                     <p>{props.selectedRow.type}</p>
                                     <hr />


                                     {
                                       props.selectedRow.remark ?
                                       <div >
                                         <h5> Resolved By</h5><p className="resolved-by">{props.selectedRow.resolvedBy}</p>
                                          <hr />
                                         <h5> Remark</h5><p>{props.selectedRow.remark}</p>
                                         <hr />
                                       </div> 
                                       : null
                                     }  

                                     <h5> Created On	</h5>
                                     <p>{moment(props.selectedRow.createdDate).format("DD-MMM-YYYY")}</p>
                                  </Col >
                                  

                                  {
                                  props.selectedRow.screenShotUrl ?
                                    <Col xs="4" className="feedback-popup">
                                    <div ><h5> Screenshot</h5><img src={this.apiUrl + props.selectedRow.screenShotUrl} />
                                    </div> 
                                    </Col>
                                    : null
                                  }  
                              </Row>
                          </CardBody>
                          <CardFooter>
                              <Button type="reset" size="sm" color="primary" onClick={() => { this.onCloseModal() }}>Close</Button>
                          </CardFooter>
                      </Card>
                  </Col>

                </Row>
          </ModalBody>
      </Modal>
    );
  }

  handleChange(event) {
    let target = event.target;
    let value;
    value = target.value 

    const name = target.name;
    this.setState({
      [name]: value
    });
  }

  changeFeedbackStatus(event) {
    let self = this
    self.setState({
      status: event.target.value,
      limit : 10,
      offset : 0,
      page:0
    }, (() => {
      self.getFeedback(self.state.offset, self.state.limit, self.state.sortField, self.state.sortValue)
    }));
  }




  render() {
    let self = this;
    var UpdateSection = self.UpdateSection;
    var FeedbackDetail = self.FeedbackDetail;
    let { feedbackList, totalRecords, limit, openmodalbox, page, selectedRow, showFeedbackModal } = this.state;
    let totalRecordsLabel = '##TOTAL_RECORDS## '+('Feedback')
    let options = {
      totalRecords: totalRecords,
      totalRecordsLabel: totalRecordsLabel,
      rowsPerPage: limit,
      page: page,
      loadMore: false,
      customColumn: {
        isActive: true,
        columnTitle: '',
        columnContent: (rowData) => {
          return (
            <div>
            <Nav>
            {/*<NavItem>
              <NavLink href="#" onClick={() => { this.issueModal(rowData); }} disabled={rowData['status'] == 'Closed'} >{(rowData['status'] == 'Open') ?  'Close Feedback' : 'Closed'}</NavLink>
            </NavItem>*/}
              

              <NavItem>
                <NavLink href="#" onClick={() => { this.showFeedbackDetails(rowData); }} ><FontAwesomeIcon icon={ faEye } size='1x' /></NavLink>
              </NavItem>

              <NavItem>
                <NavLink href="#" onClick={() => { this.issueModal(rowData); }} disabled={rowData['status'] === 'Closed'} ><FontAwesomeIcon icon={ faWindowClose } size='1x' /></NavLink>
              </NavItem>

            </Nav>
            </div>
            );
        }
      },

      onPageChange(pageNumber) {
        self.state.page = pageNumber;
        self.state.offset = parseInt(pageNumber) * parseInt(self.state.limit)
        self.getFeedback(self.state.offset, self.state.limit, self.state.sortField, self.state.sortValue);
      },

      onColumnSortChange: (column, direction) => {
        self.state.sortField = column;
        self.state.sortValue = (direction === "ASC") ? 1 : -1;
        self.getFeedback(self.state.offset, self.state.limit, self.state.sortField, self.state.sortValue);

      }
    };

    

    return (
      <div className="content">
        <Row className="pb-4">
          <Col lg={12} sm={12}>
            <h3>Feedback</h3>
          </Col>
        </Row>

        <Row className="pb-4">
            <Col lg={3}>
              <Input type="select" name="status" id="status" value={this.state.status} onChange={(event) => {this.changeFeedbackStatus(event)}}>
                <option value={Constants.OPEN}>Open Feedback</option>
                <option value={Constants.CLOSE}>Close Feedback</option>
              </Input>
            </Col>
        </Row>

        <Row>
          <Col lg={12} sm={12}>

            <FeedbackDetail  visible={showFeedbackModal}  closeModal={this.onCloseModal} selectedRow={selectedRow} />
            <UpdateSection  visible={openmodalbox}  closeModal={this.onCloseModal} state={self.state} handleChange={self.handleChange} />
            
            <div className="dataTable-wrapper pillarList_grid">
              <DataGrid
                columns={this.columns}
                options={options}
                rows={ feedbackList }
              />
            </div>
          </Col>
        </Row>
        
      </div>

      
    );
  }
}

export default Feedback;
