import { createReducer } from 'redux-act';
import {requestGetTrophies, responserGetTrophies, requestAddTrophy, responseAddTrophy, requestDeleteTrophy, responseDeleteTrophy, requestChangeTrophyStatus, responseChangeTrophyStatus, requestUpdateTrophy, responseUpdateTrophy, requestTrophyFreePillars, responseTrophyFreePillars, requestGetSelectedTypeMilestones, responseGetSelectedTypeMilestones } from './action';


const defaultState = {
	requestProcess: false,
};

const reducer = createReducer({

	[requestGetTrophies]: (state) => {
		return {
			requestProcess: true,
			trophiesList: [],
		};
	},

	[responserGetTrophies]: (state, params) => {
		return {
			requestProcess: false,
			trophiesList: params,
		};
	},


	[requestAddTrophy]: (state) => {
		return {
			requestProcess: true,
		};
	},

	[responseAddTrophy]: (state, params) => {
		return {
			requestProcess: false,
			addTrophy: params,
		};
	},

	[requestDeleteTrophy]: (state) => {
		return {
			requestProcess: true,
		};
	},

	[responseDeleteTrophy]: (state, params) => {
		return {
			requestProcess: false,
			deleteTrophy: params,
		};
	},

	[requestChangeTrophyStatus]: (state) => {
		return {
			requestProcess: true,
		};
	},

	[responseChangeTrophyStatus]: (state, params) => {
		return {
			requestProcess: false,
			trophyStatus: params,
		};
	},

	[requestUpdateTrophy]: (state) => {
		return {
			requestProcess: true,
		};
	},

	[responseUpdateTrophy]: (state, params) => {
		return {
			requestProcess: false,
			updateTrophy: params,
		};
	},


	[requestTrophyFreePillars]: (state) => {
		return {
			requestProcess: true,
		};
	},

	[responseTrophyFreePillars]: (state, params) => {
		return {
			requestProcess: false,
			trophyFreePillars: params,
		};
	},



	[requestGetSelectedTypeMilestones]: (state) => {
		return {
			requestProcess: true,
		};
	},

	[responseGetSelectedTypeMilestones]: (state, params) => {
		return {
			requestProcess: false,
			selectedTypeMilestones: params,
		};
	}


}, defaultState);



export default reducer
