import React, { Component } from 'react';
import { Loader } from '../../utils/common';
import { Row, Col, Modal, Button, ModalHeader, ModalBody, ModalFooter, FormGroup, Label, Input, Form , Alert } from "reactstrap";
import SimpleReactValidator from 'simple-react-validator';
import Autocomplete from 'react-autocomplete';



class NotificationForm extends Component {

  constructor(props) {
    super(props);
    this.validator = new SimpleReactValidator();
    this.state = {
      _id : props.formData && props.formData._id ?  props.formData._id : null,
      title : props.formData && props.formData.title ?  props.formData.title : '',
      description : props.formData && props.formData.description ?  props.formData.description : '',
      days : props.formData && props.formData.days ?  props.formData.days : [],
      alertTime: props.formData && props.formData.alertTime ?  props.formData.alertTime : '',
      contentId: props.formData && props.formData.contentId ?  props.formData.contentId : '',
      contentTitle: props.formData && props.formData.contentTitle ?  props.formData.contentTitle : '',
      status : props.formData && props.formData.status ?  props.formData.status : false,
      repeatStatus : props.formData && props.formData.repeatStatus ?  props.formData.repeatStatus : false,
      minAge : props.formData && props.formData.minAge ?  props.formData.minAge : 1,
      maxAge: props.formData && props.formData.maxAge ?  props.formData.maxAge : 9,
      daysOptions: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
      typedContent: props.formData && props.formData.contentTitle ?  props.formData.contentTitle : '',
      systemLanguages: props.systemLanguages,
    }
  }

  componentDidMount() {
  }

  componentWillReceiveProps(nextProps) {
    //let self = this;
    Loader(nextProps.requestProcess);
  }


  

  handleChange(event) {
    let target = event.target;
    let name = target.name;
    let value = target.value;
   // let type = target.type;
    if (target.name === 'minAge') {
      if (target.value && parseInt(target.value) > parseInt(this.state.maxAge)) {
        value = this.state.maxAge
      }

      if (target.value && parseInt(target.value) < 1) {
        value = 1
      }
    }


    if (target.name === 'maxAge'  || value < 0) {
      if (value > 120) {
        value = 120
      }
      this.setState({
        minAge : 1  
      })
    }

    if(target.name === 'repeatStatus'){
      value = (target.checked) ? true : false;
    }

    if(target.name === 'status'){
      value = (target.checked) ? true : false;
    }


    this.setState({[name]: value});    
  }

  handleDays(event){
    let value = event.target.value;
    let {days, daysOptions} = this.state;
    if(value === 'All'){      
      daysOptions = JSON.parse(JSON.stringify(daysOptions));
      this.setState({days: days.length === daysOptions.length ? [] : daysOptions}, ()=>{console.log('value',this.state.days);});      
      return false;
    }    
    if(days.indexOf(value) === -1){
      days.push(value);
    }else{
      days.splice(days.indexOf(value), 1);
    }
    this.setState({days: days});
  }

  handleSubmit(e) {
    let self = this;
    let languages = [];
    self.state.systemLanguages.map(function(obj, index){
      languages.push(obj.lang)
    })
    if( this.validator.allValid()){
      let notificationObj = {
        reminderId: this.state._id,
        alertTime: this.state.alertTime,
        days: this.state.days,
        // title : this.state.title, 
        // description: this.state.description,
        languages : languages,
        minAge: parseInt(this.state.minAge),
        maxAge: parseInt(this.state.maxAge),
        repeatStatus: this.state.repeatStatus,
        status: this.state.status,        
        contentTitle: this.state.contentTitle,        
        contentId: this.state.contentId
      }  
      this.props._addUpdateNotification(notificationObj);
    }else{
      this.validator.showMessages();
      this.forceUpdate();
    }
    e.preventDefault();
  }

  onSelect(item){
    this.setState({contentId: item._id, contentTitle: item.title, typedContent: item.title})
    
  }


  setTextAttrs(event, index, languageSlug) {
    this.state.systemLanguages[index]['lang'][event.target.name] = event.target.value;
    this.state.systemLanguages[index]['lang']['languageSlug'] = languageSlug;
    this.setState({systemLanguages : this.state.systemLanguages})

  }


  LanguagesToRender() {
    let self = this;
    return self.state.systemLanguages.map(function(obj, index){
      return (
        <div key={obj.slug}>
           <Row>
            <Col xs="12">
                <FormGroup>
                  <Label htmlFor={obj.title + 'notificationTitle'}>{obj.title} Title</Label>
                  <Input type="text" maxLength="100" id={obj.title + 'notificationTitle'}  placeholder="Title" name="title" value={self.state.systemLanguages[index]['lang']['title']} onChange={(event)=>{self.setTextAttrs(event, index, obj.slug)}}  />
                  <div className="validation-error">{ self.validator.message(obj.title+' Title', self.state.systemLanguages[index]['lang']['title'], 'required') }</div>   
                </FormGroup>
            </Col>
          </Row>

          <Row>
            <Col xs="12">
                <FormGroup>
                  <Label htmlFor={obj.title + 'notificationDescription'}>{obj.title} Description</Label>
                  <Input type="textarea" maxLength="100" id={obj.title + 'notificationDescription'} placeholder="Description" name="description" value={self.state.systemLanguages[index]['lang']['description']} onChange={(event)=>{self.setTextAttrs(event, index, obj.slug)}}  />
                  <div className="validation-error">{ self.validator.message(obj.title+' Description', self.state.systemLanguages[index]['lang']['description'], 'required') }</div>   
                </FormGroup>
            </Col>
          </Row>
        </div>
        );
    })
  }
  

  render() {
    let self = this;
    let { openAddNotificationModal, closeModal, addNitificationPopupError } = this.props;
    let {daysOptions, days, typedContent, _id} = this.state;
    return (
      <Modal isOpen={openAddNotificationModal}>
          <ModalHeader toggle={()=>{closeModal(false)}}>Add Notification</ModalHeader>
          <Form onSubmit={(event) => {this.handleSubmit(event)}}>  
            <ModalBody>
              {this.LanguagesToRender()}


              <Row>
                <Col xs="12">
                    <FormGroup>
                      <Label htmlFor="maxAge">Max Age</Label>
                      <Input type="number" maxLength="100" id="maxAge" placeholder="Max age" name="maxAge" value={this.state.maxAge} onChange={(event)=>{this.handleChange(event)}} max="120" min="1" />
                      <div className="validation-error">{ this.validator.message('Min age', this.state.maxAge, 'required') }</div>   
                    </FormGroup>
                </Col>
              </Row>

              <Row>
                <Col xs="12">
                    <FormGroup>
                      <Label htmlFor="number">Min Age</Label>
                      <Input type="number" maxLength="100" id="minAge" placeholder="Min age" name="minAge" value={this.state.minAge} onChange={(event)=>{this.handleChange(event)}} max={this.state.maxAge} min="1"/>
                      <div className="validation-error">{ this.validator.message('Min age', this.state.minAge, 'required') }</div>   
                    </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col xs="12">
                    <FormGroup>
                      <Label htmlFor="content">Content</Label>
                      <div>
                      <Autocomplete
                          getItemValue={(item) => item.title}
                          items={this.props.contentData}
                          inputProps={{ id: 'autocomplete-input' , className: "form-control", readOnly : true}}
                          wrapperStyle={{  }}
                          renderItem={(item, isHighlighted) =>
                            <div key={item._id} style={{ background: isHighlighted ? 'lightgray' : 'white' }}>
                              {item.title}
                            </div>
                          }
                          value={typedContent ? typedContent : ''}
                          onChange={(event, value)=>{
                            this.setState({typedContent: value, contentId: null, contentTitle: ''}, ()=>{
                              clearTimeout(self.requestTimer)
                              self.requestTimer = setTimeout(()=>{
                                self.props.getContent(value)
                              }, 1000)
                            });
                          }}
                          onSelect={(value, item)=>{this.onSelect(item)}}
                          selectOnBlur={true}
                        />
                        </div>
                        <div className="validation-error">{ this.validator.message('Content', this.state.contentId, 'required') }</div>   
                    </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col xs="12">
                    <FormGroup>
                      <Label htmlFor="alertTime">Alert Time</Label>
                      <Input type="time" id="alertTime" placeholder="alertTime" name="alertTime" value={this.state.alertTime} onChange={(event)=>{this.handleChange(event)}} />

                      <div className="validation-error">{ this.validator.message('Alert Time', this.state.alertTime, 'required') }</div>
                    </FormGroup>
                </Col>
              </Row>

              

              <Row>
                <Col xs="12">
                    <FormGroup>
                      <Label htmlFor="couponCode">Days</Label>
                        <label className="checkbox-inline pl-2 pr-2"> 
                          <input type="checkbox" checked={days.length === daysOptions.length ? true : false} className="mr-2" value="All" onChange={(event)=>{this.handleDays(event)}} />All
                        </label> 
                      <div>
                        {daysOptions.map((element, key)=>{
                          return (
                            <label className="checkbox-inline pl-2 pr-2" key={key}> 
                              <input type="checkbox" className="mr-2" name={'day_'+element} checked={days.indexOf(element) !== -1 ? true : false} value={element} onChange={(event)=>{this.handleDays(event)}} />{element}
                            </label>                          
                          )
                        })}
                      </div>            
                      <div className="validation-error">{ this.validator.message('Days', this.state.days, 'required') }</div>   
                    </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col xs="12">
                    <FormGroup>
                      <Label htmlFor="repeatStatus">Repeat</Label>
                      <Input className="ml-4" type="checkbox" id="repeatStatus" placeholder="repeatStatus" name="repeatStatus" value={this.state.repeatStatus} defaultChecked={this.state.repeatStatus} onChange={(event)=>{this.handleChange(event)}} />
                    </FormGroup>

                    <FormGroup>
                      <Label htmlFor="status">Status</Label>
                      <Input className="ml-4" type="checkbox" id="status" placeholder="status" name="status" value={this.state.status} defaultChecked={this.state.status} onChange={(event)=>{this.handleChange(event)}} />
                    </FormGroup>
                </Col>
              </Row>
              


              {(addNitificationPopupError) ? <Alert color="danger">{addNitificationPopupError}</Alert> : null}
            </ModalBody>
            <ModalFooter className="justify-content-left">
              <Button type="submit" size="sm" color="primary">
                {_id  ? 'Update' : 'Add'}
              </Button>{' '}
              <Button type="reset" size="sm" color="danger"  onClick={() => { closeModal() }}>Cancel</Button>
            </ModalFooter>
          </Form>
        </Modal>
      
    );
  }
}

export default NotificationForm;
