import React, { Component } from 'react';
import { Loader } from '../../utils/common';
import { Row, Col, Modal, Button, ModalHeader, ModalBody, ModalFooter, FormGroup, Label, Input,  Form , Alert } from "reactstrap";
import { AppSwitch } from '@coreui/react'
import SimpleReactValidator from 'simple-react-validator';
import Autocomplete from 'react-autocomplete';
import * as _ from 'underscore';



class UpdateQuest extends Component {
  constructor(props) {
    super(props);
    this.validator = new SimpleReactValidator();
    this.state = {
      order: props.selectedRow.order,
      questId : props.selectedRow._id,
      contentId : props.selectedRow.contentId,
      contentTitle : props.selectedRow.contentTitle,
      additionalPoints: props.selectedRow.additionalPoints,
      occurrence: props.selectedRow.occurrence,
      status : props.selectedRow.status,
      tagline : props.selectedRow.description,
      typedContent: props.selectedRow.contentTitle,
      systemLanguages: props.systemLanguages,
    }
  }

  componentDidMount() {
  }


  componentWillReceiveProps(nextProps) {
   // let self = this;
    Loader(nextProps.requestProcess);
  }




  handleChange(event) {
    let target = event.currentTarget;
    let name = target.name;
    let value;
    value = target.value;

    if (target.type === 'checkbox') {
      value = target.checked;
    }


    if (target.name === 'additionalPoints' && value > 1000 ) {
      return false;
    }


    if (target.name === 'occurrence' && value > 100 ) {
      return false;
    }

    this.setState({[name]: value});
  }

  handleSubmit(e) {
    if( this.validator.allValid()){

      let languageData = [];
      _.each(this.state.systemLanguages, function(value, index){
        languageData.push(value['dailyQuestLang']);
      })

      let params = {
        order: this.state.order,
        description : this.state.tagline,
        contentId : this.state.contentId,
        additionalPoints: parseInt(this.state.additionalPoints),
        occurrence: parseInt(this.state.occurrence),
        questId : this.state.questId,
        status : this.state.status,
        dailyQuestLang : languageData
      }

      this.props.updateQuest(e, params)
    }else{
      this.validator.showMessages();
      this.forceUpdate();
    }
    e.preventDefault();
  }


  onSelect(item){
    this.setState({contentId: item._id, contentTitle: item.title, typedContent: item.title})
  }


  LanguagesToRender() {
    let self = this;
    return self.state.systemLanguages.map(function(obj, index){
      return (
        <div key={obj.slug}>
          <Row>
            <Col xs="12">
                <FormGroup>
                  <Label htmlFor="title">{obj.title} Tagline</Label>

                  <Input type="text" id={obj.title} placeholder={obj.title + ' Tagline'} name="description" value={(self.state.systemLanguages[index].dailyQuestLang && self.state.systemLanguages[index].dailyQuestLang['description']) ? self.state.systemLanguages[index].dailyQuestLang['description'] : ""} onChange={(event) => {self.setTitle(event, index, obj.slug)}}  />


                  <div className="validation-error">{ self.validator.message(self.state.systemLanguages[index].title+ ' tagline', self.state.systemLanguages[index].dailyQuestLang['description'], 'required') }</div>


                </FormGroup>
            </Col>
          </Row>
        </div>
      );
    })
  }


  setTitle(event, index, languageSlug) {
    this.handleChange(event);
    if (this.state.systemLanguages[index] && this.state.systemLanguages[index]['dailyQuestLang'] && this.state.systemLanguages[index]['dailyQuestLang'][event.target.name]) {
      this.state.systemLanguages[index]['dailyQuestLang'][event.target.name] = event.target.value;
      this.state.systemLanguages[index]['dailyQuestLang']['languageSlug'] = languageSlug;
      this.state.systemLanguages[index]['dailyQuestLang']['languageSlug'] = languageSlug;
    }else{
      this.state.systemLanguages[index]['dailyQuestLang'] = {};
      this.state.systemLanguages[index]['dailyQuestLang']= {
        [event.target.name] : event.target.value,
        languageSlug : languageSlug
      };
    }
    this.setState({systemLanguages : this.state.systemLanguages})

  }





  render() {
    let self = this;
    let { openUpdateAppQuestModal, closeModal, updateQuestPopupError, contentData } = this.props;
    let { typedContent } = this.state;

    return (
      <Modal isOpen={openUpdateAppQuestModal}>
          <ModalHeader toggle={()=>{closeModal(false)}}>Update Quest</ModalHeader>
          <Form onSubmit={(event) => {this.handleSubmit(event)}}>
            <ModalBody>
              {/*<Row>
                              <Col xs="12">
                                  <FormGroup>
                                    <Label htmlFor="tagline">Tagline*</Label>
                                    <Input type="text" id="tagline" placeholder="Tagline" name="tagline" value={this.state.tagline} onChange={(event)=>{this.handleChange(event)}} />
                                    <div className="validation-error">{ this.validator.message('Tagline', this.state.tagline, 'required') }</div>
                                  </FormGroup>
                              </Col>
                            </Row>*/}


              {this.LanguagesToRender()}

              <Row>
                <Col xs="12">
                    {/*<FormGroup>
                                          <Label htmlFor="tagline">Content*</Label>
                                          <Input type="text" id="content" placeholder="Content" name="content" value={this.state.content} onChange={(event)=>{this.handleChange(event)}} />
                                          <div className="validation-error">{ this.validator.message('content', this.state.content, 'required') }</div>
                                        </FormGroup>*/}

                  <FormGroup>
                      <Label htmlFor="content">Content</Label>
                      <div>
                      <Autocomplete
                          getItemValue={(item) => item.title}
                          items={contentData}
                          inputProps={{ id: 'autocomplete-input' , className: "form-control", placeholder:"Content search by title"}}
                          wrapperStyle={{  }}
                          renderItem={(item, isHighlighted) =>
                            <div key={item._id} style={{ background: isHighlighted ? 'lightgray' : 'white' }}>
                              {item.title}
                            </div>
                          }
                          value={typedContent ? typedContent : ''}
                          onChange={(event, value)=>{
                            this.setState({typedContent: value, contentId: null, contentTitle: ''}, ()=>{
                              clearTimeout(self.requestTimer)
                              self.requestTimer = setTimeout(()=>{
                                self.props.getContent(value)
                              }, 1000)
                            });
                          }}
                          onSelect={(value, item)=>{this.onSelect(item)}}
                          selectOnBlur={true}
                        />
                        </div>
                        <div className="validation-error">{ this.validator.message('Content', this.state.contentId, 'required') }</div>
                    </FormGroup>
                </Col>
              </Row>


              <Row>
                <Col xs="12">
                    <FormGroup>
                      <Label htmlFor="tagline">Additional Points</Label>
                      <Input type="number" id="additionalPoints" placeholder="Additional Points" name="additionalPoints" value={this.state.additionalPoints} onChange={(event)=>{this.handleChange(event)}} min="1" max="1000"/>
                      <div className="validation-error">{ this.validator.message('Additional Points', this.state.additionalPoints, 'required') }</div>
                    </FormGroup>
                </Col>
              </Row>



              <Row>
                <Col xs="12">
                    <FormGroup>
                      <Label htmlFor="occurrence">Occurrence</Label>
                      <Input type="number" id="occurrence" placeholder="Occurrence" name="occurrence" value={this.state.occurrence} onChange={(event)=>{this.handleChange(event)}} min="1" max="100" />
                      <div className="validation-error">{ this.validator.message('Occurrence', this.state.occurrence, 'required') }</div>
                    </FormGroup>
                </Col>
              </Row>


              <Row>
                <Col xs="12">
                    <FormGroup>
                        <Label htmlFor="status">Status</Label>
                        <div>
                          <AppSwitch className={ 'mx-1'} variant={ 'pill'} color={ 'primary'}  id="status"  name="status"
                            onChange={($event)=>{self.handleChange($event)}}
                            checked={(self.state.status)}
                            value={(self.state.status)} />
                        </div>
                    </FormGroup>

                </Col>
              </Row>

              {(updateQuestPopupError) ? <Alert color="danger">{updateQuestPopupError}</Alert> : null}
            </ModalBody>
            <ModalFooter className="justify-content-left">
              <Button type="submit" size="sm" color="primary">Update</Button>{' '}
              <Button type="reset" size="sm" color="danger"  onClick={() => { closeModal() }}>Cancel</Button>
            </ModalFooter>
          </Form>
        </Modal>

    );
  }
}

export default UpdateQuest;
