import {createReducer} from 'redux-act';
import { requestGetStaticLanguage, responseGetStaticLanguage, requestAddStaticLanguage, responseAddStaticLanguage, requestStaticLanguageStatus,responseStaticLanguageStatus, requestUpdateStaticLanguage, responseUpdateStaticLanguage, requestTriggerNotification, responseTriggerNotification} from './action';


const defaultState = {
	requestProcess: false,
};

const reducer = createReducer({

	[requestGetStaticLanguage]: (state) => {
		return {
			requestProcess: true,
		};
	},

	[responseGetStaticLanguage]: (state, params) => {
		return {
			requestProcess: false,
			languageList: params,
		};
	},


	[requestAddStaticLanguage]: (state) => {
		return {
			requestProcess: true,
		};
	},

	[responseAddStaticLanguage]: (state, params) => {
		return {
			requestProcess: false,
			languageAdded: params,
		};
	},


	[requestStaticLanguageStatus]: (state) => {
		return {
			requestProcess: true,
		};
	},

	[responseStaticLanguageStatus]: (state, params) => {
		return {
			requestProcess: false,
			statusChanged: params,
		};
	},



	[requestUpdateStaticLanguage]: (state) => {
		return {
			requestProcess: true,
		};
	},

	[responseUpdateStaticLanguage]: (state, params) => {
		return {
			requestProcess: false,
			languageUpdated: params,
		};
	},


	[requestTriggerNotification]: (state) => {
		return {
			requestProcess: true,
		};
	},

	[responseTriggerNotification]: (state, params) => {
		return {
			requestProcess: false,
			triggerNotificaton: params,
		};
	},
}, defaultState);



export default reducer
