import {createAction} from 'redux-act';
import httpClient from '../../services/http';

export const requestCompaigndData = createAction();
export const responseCompaigndData = createAction();

export const requestListData = createAction();
export const responseListData = createAction();

export const requestUpdateData = createAction();
export const responseUpdateData = createAction();




export function getCampaignList(offset, rowsPerPage){
  return (dispatch) => {
      dispatch(requestCompaigndData([]));
      httpClient.call('REMINDER', '/get_campaign_list', {offset: offset, rowsPerPage:rowsPerPage}, {method: 'GET', headers: {}}).then(function(response){
          dispatch(responseCompaigndData(response));
      }, function(error){
          dispatch(responseCompaigndData(error));
      });
  }
}

export function getListList(){
    return (dispatch) => {
        dispatch(requestListData([]));
        httpClient.call('REMINDER', '/get_list', {}, {method: 'GET', headers: {}}).then(function(response){
            dispatch(responseListData(response));
        }, function(error){
            dispatch(responseListData(error));
        });
    }
}

export function updateTemplate(params){
    return (dispatch) => {
        dispatch(requestUpdateData([]));
        httpClient.call('REMINDER','/update_template', params, {method: 'POST', headers: {}}).then(function(response){
            dispatch(responseUpdateData(response));
        }, function(error){
            dispatch(responseUpdateData(error));
        });
    }
}

export function onScheduleSubmit(params){
    return (dispatch) => {
        dispatch(requestUpdateData([]));
        httpClient.call('REMINDER','/update_schedule', params, {method: 'POST', headers: {}}).then(function(response){
            dispatch(responseUpdateData(response));
        }, function(error){
            dispatch(responseUpdateData(error));
        });
    }
}
