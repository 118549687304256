import React, { Component } from 'react';
import { Loader, createRandomStr } from '../../utils/common';
import { Row, Col, Input, Alert, Table, Modal, Button, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { AppSwitch } from '@coreui/react'
import { getPlaylist, deletePlaylist, updatePlaylist } from "./action";
import moment from 'moment';
import Constants from '../../config/constants';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMusic, faPencilAlt, faTrash, faFileImage } from '@fortawesome/free-solid-svg-icons'
import AddPlaylist from "./AddPlaylist";
import AddMediaSeries from "./AddMediaSeries";
import UpdatePlaylist from "./UpdatePlaylist";
import UpdateMediaSeries from "./UpdateMediaSeries";
import TrackDetails from "./TrackDetails";


import httpClient from '../../../src/services/http';
import S3Client from '../../../src/services/aws-s3/S3Client';
import * as _ from 'underscore';
import Session from '../../utils/session';





class Playlist extends Component {

  constructor(props) {
    super(props);
    this.session = Session.getSessionData('config');
    this.apiUrl = this.session && this.session.UTILS && this.session.UTILS.BUCKET_URL ? this.session.UTILS.BUCKET_URL : process.env.REACT_APP_CLOUDFRONT_URL;


    this.intervalInstance = null;
    this.state = {
      screenError: "",
      openAddPlaylistModel: false,
      openAddMediaSeriesModel: false,
      openTracksModel: false,
      openUpdatePlaylistModel: false,
      openUpdateMediaSeriesModel: false,
      searchKey: "",
      selectedObj: null,
      playListData: [],
      totalRecords: 0,
      openDeleteConfirmModel: false,
      selectedThumbFileRow: null,
      selectedTitleRow: "",
      deletingType: 'playlist'

    }

    this.fileUpload = React.createRef();


    const s3Config = {
      bucketName: process.env.REACT_APP_S3_BUCKET,
      dirName: 'pillar/content', /* optional */
      region: process.env.REACT_APP_AWS_DEFAULT_REGION,
      accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
      s3Url: process.env.REACT_APP_AWS_BUCKET_URL, /* optional */
    }

    this.S3Client = new S3Client(s3Config);
  }

  componentDidMount() {
    this.getPlaylist()
  }



  refreshPlaylist() {
    this.closeModal();
    this.getPlaylist();
  }

  getPlaylist() {
    let params = {
      search: this.state.searchKey,
      mediaSeries: this.state.mediaSeries

    }
    Loader(true)
    getPlaylist(params).then(response => {
      if (response && response.statusCode == 200) {
        this.setState({
          totalRecords: response.totalRecords,
          playListData: response.result,
        })
      } else {
        this.setState({
          screenError: "No Data found"
        })
      }
      Loader(false)
    }, error => {
      this.setState({
        screenError: (error && error.data && error.data.statusDescription) ? error.data.statusDescription : Constants.SOMETHING_WRONG
      })
    })
  }

  searchPlaylist() {
    clearTimeout(this.intervalInstance)
    this.intervalInstance = setTimeout(() => {
      this.getPlaylist()
    }, 1000)
  }

  addPlaylist(playList) {
    let filteredList = playList.filter(item => item.mediaSeries!==true)
    if (filteredList.length >= 5) {
      this.setState({
        screenError: 'You can not add more than 5 palylist.'
      })

      setTimeout(() => {
        this.setState({
          screenError: ''
        })
      }, 7000);
    } else {
      this.setState({
        openAddPlaylistModel: true
      })
    }
  }

  addMediaSeries(playListCount) {
    this.setState({
      openAddMediaSeriesModel: true
    })
  }

  closeModal() {
    this.setState({
      openAddPlaylistModel: false,
      openAddMediaSeriesModel: false,
      openUpdatePlaylistModel: false,
      openUpdateMediaSeriesModel: false,
      openDeleteConfirmModel: false,
      openTracksModel: false,
      selectedObj: null,
      searchKey: ""
    })
  }

  updatePlaylist(playListObj) {
    console.log('updatePlaylist v')
    this.setState({
      openUpdatePlaylistModel: true,
      selectedObj: playListObj
    })
  }

  updateMediaSeries(playListObj) {
    this.setState({
      openUpdateMediaSeriesModel: true,
      selectedObj: playListObj,
      author: playListObj.authorId ? playListObj.authorId : null
    })
  }


  deletePlaylistModal(playListObj) {
    if (this.state.totalRecords > 1) {
      this.setState({
        openDeleteConfirmModel: true,
        selectedObj: playListObj,
        deletingType: playListObj.mediaSeries===true ? 'Media Series' : 'Playlist'
      })
    } else {
      this.setState({
        screenError: "Can not delete last playlist.",
      })
    }
  }

  deletePlaylist() {
    deletePlaylist({ playlistId: this.state.selectedObj._id }).then(response => {
      this.refreshPlaylist();
    }, error => {
      this.setState({
        screenError: (error && error.data && error.data.statusDescription) ? error.data.statusDescription : Constants.SOMETHING_WRONG
      })
    })
  }

  showDetails(selectedObj) {
    this.setState({
      selectedObj,
      openTracksModel: true
    })
  }

  updateMediaSeriesOption(evt) {


    /* #region  copied code */
    let params = {
      search: this.state.searchKey
    }
    if (evt.target && evt.target.value && (evt.target.value === 'true' || evt.target.value === 'false')) {
      if (evt.target.value === 'true') {
        params.mediaSeries = true
      } else if (evt.target.value === 'false') {
        params.mediaSeries = false
      }
    }

    Loader(true)
    getPlaylist(params).then(response => {
      if (response && response.statusCode == 200) {
        this.setState({
          totalRecords: response.totalRecords,
          playListData: response.result
        })
      } else {
        this.setState({
          screenError: "No Data found"
        })
      }
      Loader(false)
    }, error => {
      this.setState({
        screenError: (error && error.data && error.data.statusDescription) ? error.data.statusDescription : Constants.SOMETHING_WRONG
      })
    })
    /* #endregion */

    // this.setState({
    //   ...this.state, mediaSeries: evt.target.velue
    // })
  }

  showFileUpload(playlistObj) {

    if(playlistObj._id){
      let tmpState = this.state
      tmpState.selectedThumbFileRow = playlistObj._id
      tmpState.selectedTitleRow = playlistObj.title

      this.fileUpload.current.click();
    }

  }

  handleThumbUpload(event){
    Loader(true);

    let file = event.target.files[0]; // FileList object
    //let oldState = this.state
    //oldState.thumb_test = file
    //this.setState()

    let files = []
    files.push({ 'key': 'thumb_GRID-RT', 'folder': 'thumb', file });

    this.uploadFilesToServers(files).then((uploadedFileRes) => {
      if(uploadedFileRes["thumb_GRID-RT"]){
        updatePlaylist({ playlistId: this.state.selectedThumbFileRow, title: this.state.selectedTitleRow, image: uploadedFileRes["thumb_GRID-RT"] }).then(updateResp => {

          this.refreshPlaylist();
        }, error => {
          this.setState({
            screenError: error.message
          })
        })
      }
      console.log('uploadedFileRes',uploadedFileRes);
    })

  }

  uploadFilesToServers(files) {
    let self = this;
    //let hasError = false;

    return new Promise((resolve, reject) => {
      if (files.length > 0) {
        //Loader(true);
        self.uploadFiles(files, function (responseObj, err) {
          // if any of the file processing produced an error, err would equal that error
          if (err) {
            // One of the iterations produced an error.
            // All processing will now stop.
            console.log('A file failed to process');
            //Loader(false);
            reject('File upload failed. Please try again.')
          } else {
            resolve(responseObj);
            console.log('All files have been processed successfully');
          }

        })
      } else {
        resolve({});
        Loader(false);
      }

    })
  }

  uploadFiles(files, callback) {

    //test push
    let self = this;
    let responseObj = {};
    let progressIndex = 0;
    self.S3Client.getPolicy().then((policyRes) => {
      console.log('policyRes',policyRes);
      httpClient.call('PILLAR', '/get_signature', { policy: policyRes }, { method: 'GET', headers: {} }).then((signatureRes) => {
        console.log('signatureRes',signatureRes);
        if (signatureRes && signatureRes.statusCode === 200 && signatureRes.result && signatureRes.result.signature) {
          _.each(files, function (fileObj, index) {
            let operations = {};
            if (fileObj.file instanceof File) {
              console.log('if condi');
              createRandomStr((randString) => {
                let signature = signatureRes.result.signature;
                let filename = fileObj.folder + '/' + randString;
                let ext = fileObj.file.name.split('.').pop();
                // UPLOADING IMAGES
                self.S3Client.uploadFile(fileObj.file, filename + '.' + ext, signature).then(data => {
                  responseObj[fileObj.key] = data.key;
                  progressIndex = progressIndex + 1;
                  if (progressIndex === files.length) {
                    callback(responseObj, null)
                  }
                }).catch(error => {
                  console.log('error 296',error);
                  callback(null, error)
                })
              });
            } else {
              console.log('else condi');
              responseObj[fileObj.key] = fileObj.file;
              progressIndex = progressIndex + 1;
              if (progressIndex === files.length) {
                callback(responseObj, null)
              }
            }
          });
        } else {
          console.log('ERROR:- SIGNATURE RESPONSE ', signatureRes);
          callback(null, signatureRes.statusDescription)
        }

      }, (error) => {
        console.log('ERROR:- SIGNATURE REQUEST ', error);
        callback(null, error)
      })
    }, (error) => {
      console.log('ERROR:- SIGNATURE REQUEST ', error);
      callback(null, error)
    });
  }

  render() {


    let self = this;
    let { screenError, openAddPlaylistModel, openAddMediaSeriesModel, openUpdatePlaylistModel, openUpdateMediaSeriesModel, playListData, selectedObj, totalRecords, openDeleteConfirmModel, openTracksModel, mediaSeries } = this.state;


    return (
      <div className="content">
        <Row className="pb-4">
          <Col lg={12} sm={12}>
            <h3>Playlist / Media Series</h3>
          </Col>
        </Row>

        {/* <input ref={this.fileUpload} type="file" className='form-control' style={{display:'none'}}/> */}
        <input ref={this.fileUpload} type="file" name={'thumb_test'} id={'thumb_test'} onChange={(event) => this.handleThumbUpload(event)} style={{display:'none'}} />


        <Row className="pb-4">
          <Col lg={3}><Input type="text" id="searchKey" placeholder="Search Playlist" name="searchKey" value={this.state.searchKey}
            onChange={($event) => { this.setState({ searchKey: $event.target.value }); this.searchPlaylist() }} /></Col>

          <Col lg={3}>
            <Input type="select" name="mediaSeries" id="mediaSeries" value={mediaSeries} onChange={($event) => { this.updateMediaSeriesOption($event) }}>
              <option value="all">All</option>
              <option value="true">Media Series</option>
              <option value="false">Playlist</option>
            </Input>
          </Col>
          <Col lg={6} className="text-right">
            <div className="dropdown">
              <button className="btn btn-primary" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <h4 className="text-white mb-0">+</h4>
              </button>
              <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                  <button onClick={() => { this.addMediaSeries(playListData.length) }} className="dropdown-item">Add New Series</button>
                  <button onClick={() => { this.addPlaylist(playListData) }} className="dropdown-item">Add New Playlist</button>
              </div>
            </div>
          </Col>


          <Col lg={3}></Col>
        </Row>

        {screenError && <Alert color="danger">
          {screenError}
        </Alert>}

        <Row>
          <Col lg={12} sm={12}>
            <div className="">
              <Table striped >
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Title</th>
                    <th>Created Date</th>
                    <th>Playlist / Media</th>
                    <th>Image</th>
                    <th>Action</th>
                    <th>Total Time</th>
                  </tr>
                </thead>
                <tbody>

                  {playListData && playListData.length ?

                    playListData.map((obj, index) => {
                      return (
                        <tr key={index}>
                          <th scope="row">{index + 1}</th>
                          <td>
                            <a className="action-btns" href="#" title="Show Details" onClick={() => this.showDetails(obj)}>{obj.title}</a>
                          </td>
                          <td>{moment(obj.createdDate).format("YYYY-MMM-DD")}</td>
                          <td>{obj.mediaSeries ? `Media` : 'Playlist'}</td>
                          <td>
                            {obj.image && <img style={{height: '70px'}} src={this.apiUrl+obj.image} />}
                          </td>
                          <td>
                            <a className="action-btns" href="#" title="Update Playlist Attribites" onClick={() => {
                              if (obj.mediaSeries === true) {
                                this.updateMediaSeries(obj)
                              } else {
                                this.updatePlaylist(obj)
                              }
                            }}>
                              <FontAwesomeIcon icon={faPencilAlt} size='lg' /></a>

                              <a className="action-btns" href="#" title="Upload Thumbnail" onClick={() => this.showFileUpload(obj)}><FontAwesomeIcon icon={faFileImage} size='lg' /></a>

                            <a className="action-btns" href="#" title={obj.mediaSeries ? `Delete Media Series` : 'Delete Playlist'} onClick={() => this.deletePlaylistModal(obj)}><FontAwesomeIcon icon={faTrash} size='lg' /></a>
                          </td>
                          <td>{obj.duration}</td>
                        </tr>
                      )
                    }) :
                    <tr>
                      <td colSpan="6" className="no-record-label">No Records </td>
                    </tr>
                  }

                </tbody>
              </Table>
            </div>
            <div>Total Records : {totalRecords}</div>
          </Col>
        </Row>

        {openAddPlaylistModel && <AddPlaylist visible={openAddPlaylistModel} closeModal={() => this.closeModal()} refreshPlaylist={() => this.refreshPlaylist()} />}
        {openAddMediaSeriesModel && <AddMediaSeries visible={openAddMediaSeriesModel} closeModal={() => this.closeModal()} refreshPlaylist={() => this.refreshPlaylist()} />}
        {openUpdatePlaylistModel && selectedObj && <UpdatePlaylist visible={openUpdatePlaylistModel} closeModal={() => this.closeModal()} selectedObj={selectedObj} refreshPlaylist={() => this.refreshPlaylist()} />}
        {openUpdateMediaSeriesModel && selectedObj && <UpdateMediaSeries visible={openUpdateMediaSeriesModel} closeModal={() => this.closeModal()} selectedObj={selectedObj} refreshPlaylist={() => this.refreshPlaylist()} />}

        {openTracksModel && selectedObj && <TrackDetails visible={openTracksModel} closeModal={() => this.closeModal()} selectedObj={selectedObj} refreshPlaylist={() => this.refreshPlaylist()} />}

        {selectedObj && openDeleteConfirmModel ?
          <Modal isOpen={openDeleteConfirmModel}>
            <ModalHeader toggle={() => { this.closeModal() }}>Delete {this.state.deletingType}</ModalHeader>
            <ModalBody>
              Are you sure, want to delete {this.state.deletingType}? It will remove all tracks from it.
            </ModalBody>
            <ModalFooter className="justify-content-left">
              <Button type="button" size="sm" color="primary" onClick={() => { this.deletePlaylist() }}>Delete</Button>{' '}
              <Button type="reset" size="sm" color="danger" onClick={() => { this.closeModal() }}>Cancel</Button>
            </ModalFooter>
          </Modal>
          : null
        }



      </div>
    );
  }
}

export default Playlist;
