import React, { Component } from 'react';
import { connect } from 'react-redux';
import { ErrorToast, SuccessToast, Loader,  setHttpClientConfig } from '../utils/common';

class DefaultFooter extends Component {

  constructor(props){
    super(props);
    this.state = {}

  }


  componentWillMount(){}

  signOut(){
    let _self = this;
    Loader(true);
    _self.props._signOut().then(function(response){
      if(response.status === 'success'){
        SuccessToast(response.message);
        setHttpClientConfig();
        _self.navigate('/login');
      }else{
        ErrorToast(response.message);
      }
      Loader(false);
    }, function(error){
      let message = error && error.message ? error.message : JSON.stringify(error);
      ErrorToast(message);
      Loader(false);
    })
  }

  navigate(path){
    this.props.router.push(path);
  }


  render() {
    return (
      <div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {

  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    _signOut: ()=>{
      //return signOutAction();
    }
  };
};


export default connect(
  mapStateToProps,
  mapDispatchToProps
  )(DefaultFooter);
