import React, { Component } from 'react';
import { Loader } from '../../../utils/common';
import { Row, Col, Modal, Button, ModalHeader, ModalBody, ModalFooter, FormGroup, Label, Input, Form , Alert } from "reactstrap";
import { AppSwitch } from '@coreui/react' 
import SimpleReactValidator from 'simple-react-validator';
 
import * as _ from 'underscore';



class UpdateStaticLanguage extends Component {
  constructor(props) {
    super(props);
    this.validator = new SimpleReactValidator();
    this.state = {
      key : props.selectedRow.key,
      status : props.selectedRow.status,
      systemLanguages : props.systemLanguages,
    }
  }


  componentWillMount() {
  }

  componentDidMount() {
  }  

  


  

  componentWillReceiveProps(nextProps) {
    //let self = this;
    Loader(nextProps.requestProcess);
    this.setState({requestProcess : nextProps.requestProcess})
  }


  

  handleChange(event) {
    let target = event.currentTarget;
    let name = target.name;
    let value = target.value;

    if (name === 'key') { 
      value = value.toUpperCase();
      value=value.replace(/ /g,"_");
    }



    if (name === 'status') { 
      value = target.checked
    }


    this.setState({[name]: value});
  }

  handleSubmit(e) {
    let self = this;
    if( this.validator.allValid()){
      let languages = []
      _.each(this.state.systemLanguages, function(value, index){
        value['staticLangObj']['status'] = self.state.status;
        value['staticLangObj']['code'] = value.code;
        languages.push(value['staticLangObj']);
      })

      let params =   {
        key : this.state.key,
        language : languages
      }  
      this.props.handleSubmit(e, params);

    }else{
      this.validator.showMessages();
      this.forceUpdate();
    }
    e.preventDefault();
  }

  LanguagesToRender(languages) {
    let self = this;
   return self.state.systemLanguages.map(function(obj, index){
      return (
        <div key={obj.slug}>
           <Row >
              <Col xs="12">
                  <FormGroup >
                      <Label htmlFor={obj.title}>Value in {obj.title}</Label>
                      
                      <Input type="text" id={obj.title} placeholder={"Value in "+ obj.title} name="value" value={(self.state.systemLanguages.length && self.state.systemLanguages[index] && self.state.systemLanguages[index]['staticLangObj'] && self.state.systemLanguages[index]['staticLangObj']['value']) ? self.state.systemLanguages[index]['staticLangObj']['value'] : ""} onChange={(event) => {self.setTitle(event, index, obj.slug)}} />

                      <div className="validation-error">{ self.validator.message('Value', self.state.systemLanguages[index]['staticLangObj']['value'], 'required') }</div>   
                  </FormGroup>
              </Col>
          </Row>
        </div>
      );
    })
  }

  setTitle(event, index, languageSlug) {
    let self = this;
    if(self.state.systemLanguages[index  ]) {
      self.state.systemLanguages[index]['staticLangObj'][event.target.name] = event.target.value;
      self.state.systemLanguages[index]['staticLangObj']['languageSlug'] = languageSlug;
    }else{
      self.state.languages[index] = {};
      self.state.languages[index]['staticLangObj'] = {};
      self.state.systemLanguages[index]['staticLangObj'][event.target.name] = event.target.value;
      self.state.systemLanguages[index]['staticLangObj']['languageSlug'] = languageSlug;
    }

    self.setState({systemLanguages : self.state.systemLanguages})
  }


  render() {
    let self = this;
    let { visible, closeModal, popupError } = this.props;
    let { languages } = this.state;

    return (
      <Modal isOpen={visible}>
          <ModalHeader toggle={()=>{closeModal(false); }}>Update Static Language</ModalHeader>
          <Form onSubmit={(event) => {this.handleSubmit(event)}}>  
            <ModalBody>
              <Row>
                <Col xs="12">
                    <FormGroup>
                      <Label htmlFor="key">Key*</Label>
                      <Input type="text" id="key" placeholder="Key" name="key" value={this.state.key} onChange={(event)=>{this.handleChange(event)}} />                                              
                      <div className="validation-error">{ this.validator.message('Key', this.state.key, 'required') }</div>   
                    </FormGroup>
                </Col>
              </Row>
              {this.LanguagesToRender(languages)}
              <Row>
                <Col xs="12">
                    <FormGroup>
                        <Label htmlFor="status">Status</Label>
                        <div>
                          <AppSwitch className={ 'mx-1'} variant={ 'pill'} color={ 'primary'}  id="status"  name="status"
                            onChange={($event)=>{self.handleChange($event)}}
                            checked={(self.state.status)}
                            value={(self.state.status) ? 'Active' : 'Inactive'} />
                        </div>
                    </FormGroup>

                </Col>
              </Row>

              {(popupError) ? <Alert color="danger">{popupError}</Alert> : null}
            </ModalBody>
            <ModalFooter className="justify-content-left">
              <Button type="submit" size="sm" color="primary">Update</Button>{' '}
              <Button type="reset" size="sm" color="danger"  onClick={() => { closeModal();  }}>Cancel</Button>
            </ModalFooter>
          </Form>
        </Modal>
      
    );
  }
}

export default UpdateStaticLanguage;
