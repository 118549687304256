import { connect } from 'react-redux';

import PaymentHistory from './PaymentHistory';
import { getPaymentHistory, getTokenToExportPaymentHistory } from './action';


const mapStateToProps = (state) => {
	var stateData = {
		requestProcess: state.paymentHistory.requestProcess
	};
	if(state.paymentHistory.paymentList){
		stateData['paymentList'] = state.paymentHistory.paymentList;
	}


	return stateData;
};

const mapDispatchToProps = (dispatch) => {
	return {
		_getPaymentHistory: (params) => {
			dispatch(getPaymentHistory(params));
		},
    _getTokenToExportPaymentHistory: (params) => {
			return getTokenToExportPaymentHistory(params);
		},
	};
};


export default connect(
	mapStateToProps,
	mapDispatchToProps
)(PaymentHistory);
