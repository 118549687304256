import {createAction} from 'redux-act';
import httpClient from '../../services/http';	



export const requestGetAdminUser = createAction();
export const responseGetAdminUser = createAction();

export const requestAddUpdateAdminUser = createAction();
export const responseAddUpdateAdminUser = createAction();

export function getAllAdminUsers(params){
  return (dispatch) => {
      dispatch(requestGetAdminUser([]));
      httpClient.call('USER','/get_admin_user_list', params, {method: 'GET', headers: {}}).then(function(response){
          dispatch(responseGetAdminUser(response));
      }, function(error){
          dispatch(responseGetAdminUser(error));
      });
  }
}

export function addUpdateAdminUser(params){
  return (dispatch) => {
      dispatch(requestAddUpdateAdminUser([]));
      let endPoint = params._id ? '/admin_update_profile' : '/admin_signup';
      let apiMethod = params._id ? 'PUT' : 'POST';
      
      httpClient.call('USER', endPoint, params, {method: apiMethod, headers: {}}).then(function(response){
          dispatch(responseAddUpdateAdminUser(response));
      }, function(error){
          dispatch(responseAddUpdateAdminUser(error));
      });
  }
}
export function deleteAdminUser(params){
    return (dispatch) => {
        dispatch(requestAddUpdateAdminUser([]));
        let endPoint = '/delete_admin_user';
        let method = 'DELETE';
        httpClient.call('USER', endPoint, params, {method: method, headers: {}}).then(function(response){
            dispatch(responseAddUpdateAdminUser(response));
        }, function(error){
            dispatch(responseAddUpdateAdminUser(error));
        });
    }
}
