import React from "react";

function Loader() {
  return (
    <div
      className="animated fadeIn pt-1 text-center"
      style={{ height: "310px" }}
    >
      <div className="d-flex justify-content-center">
        <div
          className="spinner-grow text-info"
          role="status"
          style={{ marginTop: "100px", width: '30px', height: '30px' }}
        >
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    </div>
  );
}

export default Loader;
