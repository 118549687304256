import { connect } from 'react-redux';

import UserNotification from './UserNotification';
import { getUserList, getSegmentNotificationList, sendPushNotification, getNotificationText, updateNotificationText, getScreenSlug, getPillars, getCategories, getContentList, getCountryList } from './action';


const mapStateToProps = (state) => {

	var stateData = {
		requestProcess: state.userNotification.requestProcess
	};

	if (state.userNotification.userList) {
		stateData['userList'] = state.userNotification.userList;
	}

	if (state.userNotification.notificationSegmentList) {
		stateData['notificationSegmentList'] = state.userNotification.notificationSegmentList;
	}

	if (state.userNotification.notificationStatus) {
		stateData['notificationStatus'] = state.userNotification.notificationStatus;
	}


	if (state.userNotification.screens) {
		stateData['screens'] = state.userNotification.screens;
	}

	return stateData;
};

const mapDispatchToProps = (dispatch) => {
	return {
		_getUserList: (params) => {
			dispatch(getUserList(params));
		},

		_getSegmentNotificationList: (params) => {
			dispatch(getSegmentNotificationList(params));
		},

		_sendPushNotification: (params) => {
			dispatch(sendPushNotification(params))
		},

		_getScreenSlug: (params) => {
			dispatch(getScreenSlug(params));
		},

		_getNotificationText: () => {
			return getNotificationText();
		},

		_updateNotificationText: (params) => {
			return updateNotificationText(params);
		},

		_getPillars: (params) => {
			return getPillars(params);
		},
		_getCategories: (pillarId) => {
			return getCategories(pillarId);
		},


		_getContentList: (params) => {
			return getContentList(params);
		},

		_getCountryList: () => {
			return getCountryList()
		}
	};
};


export default connect(
	mapStateToProps,
	mapDispatchToProps
)(UserNotification);
