import React, { Component } from 'react';
import Facebook from './Facebook'



class SocialMedia extends Component {
  
  
  render() {
    
    return (
        <div className="card" style={{maxWidth: "540px"}}>
            <div className="row no-gutters">                     
              <div className="col-md-12 text-center">
                <div className="card-body">
                  <Facebook {...this.props} displayAccount={false}/>
                </div>
              </div>
            </div>
        </div>
    );
  }
}

export default SocialMedia;
