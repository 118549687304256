import { createReducer } from 'redux-act';
import {requestCompaigndData, responseCompaigndData, requestListData, responseListData, requestUpdateData, responseUpdateData} from './action';


const defaultState = {
	requestProcess: false,
};

const reducer = createReducer({

	[requestCompaigndData]: (state) => {
		return {
		requestProcess: true,
			data: [],
		};
	},

	[responseCompaigndData]: (state, params) => {
		return {
			requestProcess: false,
			data: params,
		};
	},

	[requestListData]: (state) => {
		return {
			requestProcess: true,
			templateListData: [],
		};
	},

	[responseListData]: (state, params) => {
		return {
			requestProcess: false,
			templateListData: params,
		};
	},

	[requestUpdateData]: (state) => {
		return {
			requestProcess: true,
			updateData: [],
		};
	},

	[responseUpdateData]: (state, params) => {
		return {
			requestProcess: false,
			updateData: params,
		};
	},


}, defaultState);



export default reducer
