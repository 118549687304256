import {createAction} from 'redux-act';
import httpClient from '../../services/http';	



export const requestGetTrophies = createAction();
export const responserGetTrophies = createAction();

export const requestAddTrophy = createAction();
export const responseAddTrophy = createAction();

export const requestDeleteTrophy = createAction();
export const responseDeleteTrophy = createAction();

export const requestChangeTrophyStatus = createAction();
export const responseChangeTrophyStatus = createAction();

export const requestUpdateTrophy = createAction();
export const responseUpdateTrophy = createAction();


export const requestTrophyFreePillars = createAction();
export const responseTrophyFreePillars = createAction();


export const requestGetSelectedTypeMilestones = createAction();
export const responseGetSelectedTypeMilestones = createAction();

export function getAllTrophies(params){
  return (dispatch) => {
      dispatch(requestGetTrophies([]));
      httpClient.call('PILLAR','/get_all_default_milestones', params, {method: 'GET', headers: {}}).then(function(response){
          dispatch(responserGetTrophies(response));
      }, function(error){
          dispatch(responserGetTrophies(error));
      });
  }
}


export function addTrophy(params){
  return (dispatch) => {
      dispatch(requestAddTrophy([]));
      httpClient.call('PILLAR','/add_milestone_master', params, {method: 'POST', headers: {}}).then(function(response){
          dispatch(responseAddTrophy(response));
      }, function(error){
          dispatch(responseAddTrophy(error));
      });
  }
}


export function deleteTrophy(params){
  return (dispatch) => {
      dispatch(requestDeleteTrophy([]));
      httpClient.call('PILLAR','/delete_milestone_master', params, {method: 'PUT', headers: {}}).then(function(response){
          dispatch(responseDeleteTrophy(response));
      }, function(error){
          dispatch(responseDeleteTrophy(error));
      });
  }
}


export function changeTrophyStatus(params){
  return (dispatch) => {
      dispatch(requestChangeTrophyStatus([]));
      httpClient.call('PILLAR','/change_milestone_master_status', params, {method: 'PUT', headers: {}}).then(function(response){
          dispatch(responseChangeTrophyStatus(response));
      }, function(error){
          dispatch(responseChangeTrophyStatus(error));
      });
  }
}


export function updateTrophy(params){
  return (dispatch) => {
      dispatch(requestUpdateTrophy([]));
      httpClient.call('PILLAR','/update_milestone_master', params, {method: 'PUT', headers: {}}).then(function(response){
          dispatch(responseUpdateTrophy(response));
      }, function(error){
          dispatch(responseUpdateTrophy(error));
      });
  }
}


export function getTophyFreePillars(params){
  return (dispatch) => {
      dispatch(requestTrophyFreePillars([]));
      httpClient.call('PILLAR','/get_trophy_free_pillars', params, {method: 'GET', headers: {}}).then(function(response){
          dispatch(responseTrophyFreePillars(response));
      }, function(error){
          dispatch(responseTrophyFreePillars(error));
      });
  }
}


export function getSelectedTypeMilestones(params){
  return (dispatch) => {
      dispatch(requestGetSelectedTypeMilestones([]));
      httpClient.call('PILLAR','/get_single_default_milestones', params, {method: 'GET', headers: {}}).then(function(response){
          dispatch(responseGetSelectedTypeMilestones(response));
      }, function(error){
          dispatch(responseGetSelectedTypeMilestones(error));
      });
  }
}