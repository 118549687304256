import React, { Component } from 'react';
import { Loader } from '../../utils/common';
import DataGrid from "g6reactdatagrid";
import { Row, Col, Modal, Button, ModalHeader, ModalBody, ModalFooter, Nav, NavItem, NavLink, Alert  } from "reactstrap";
import { AppSwitch } from '@coreui/react'
import moment from 'moment';  
import Constants from '../../config/constants';  

import EditTrophy from './editTrophy';  
import AddTrophy from './addTrophy';  
import ShowAllTrophy from './showAllTrophy';  


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashAlt, faEdit, faEye } from '@fortawesome/free-solid-svg-icons'

import Session from '../../utils/session';


import * as _ from 'underscore'



class Trophy extends Component {

  constructor(props) {
    super(props);
    this.closeModal = this.closeModal.bind(this)
    this.addTrophy = this.addTrophy.bind(this)
    this.state = {
        trophys : [],
        totalTrophies : 0,
        openAddTrophyModal : false,
        openEditTrophyModal : false,
        openDeleteTrophyModal : false,
        selectedRow : {},
        addTrophyPopupError : "",
        updateTrophyPopupError : "",
        limit : 10,
        offset : 0,
        page : 0,
        deleteTrophyPopupError : "",
        sortColumn : "createdDate",
        sortValue : -1,
        search : "",
        pillarsData : [],
        trophyFreePillars : [],
        selectedTypeMilestones : [],
        openShowAllTrophiesModal : false,
        signedQueryString: null,
        systemLanguages : Session.getSessionData('languages')['languages']
    }


    this.trophysColumns = [
      { "columnName": "typeToShow", "displayName": "Type/Group", sort : false},
      { "columnName": "pillarTitle", "displayName": "Pillar", sort : false},
      { "columnName": "createdDate", "displayName": "Created On", sort : false},
    ];
  }

  componentDidMount() {
    this.getAllTrophies();
    this.getAllPillars();
    this.getTophyFreePillars();
  }

  getAllPillars() {
    let {search} = this.state;
    let param = {
      limit: 1000,
      search: search,
      type: Constants.ALL  
    }
    this.props._getPillars(param);
  }

  getTophyFreePillars() {
    this.props._getTophyFreePillars({});
  }


  


  componentWillReceiveProps(nextProps) {
    let self = this;
    Loader(nextProps.requestProcess);
    if (nextProps.trophiesList && nextProps.trophiesList.statusCode === 200) {
      let trophies = []
      let totalRecords = 0
      if (nextProps.trophiesList && nextProps.trophiesList.statusCode === 200 && nextProps.trophiesList.result.length) {
        nextProps.trophiesList.result.map(milestone => {
          let pillarsTitle = "";
          milestone['selectedPillars'] = [];
          pillarsTitle = (milestone['pillar'].length) ? _.pluck(milestone['pillar'], "pillarTitle").join() : "";
          if (_.isArray(milestone['pillar'])) {
            _.each(milestone['pillar'], function(pillarObj, pkey){
              milestone['selectedPillars'].push({
                _id : pillarObj.pillarId,
                title : pillarObj.pillarTitle,
                slug : pillarObj.slug,
                selected : true,
              })
            })        
          }
          trophies.push({
            '_id' : milestone['_id'],
            'title' : milestone['title'],
            'info' : milestone['info'],
            'type' : milestone['type'],
            'typeToShow' : milestone['typeToShow'],
            'defaultPoints' : milestone['defaultPoints'],
            'status' : milestone['status'],
            'color' : milestone['color'],
            'pillarTitle' : pillarsTitle,
            'selectedPillars' : milestone['selectedPillars'],
            'createdDate' : moment(milestone['createdDate']).format("DD-MMM-YYYY")
          })
        })

        totalRecords = nextProps.trophiesList.totalRecords;
      }
      self.setState({trophys : trophies, totalTrophies : totalRecords})
      self.getTophyFreePillars();
    }

    if (nextProps.addTrophy) {

      if (nextProps.addTrophy.statusCode === 200) {
        self.getAllTrophies();
        this.closeModal(); 
      }else if(nextProps.addTrophy.data && nextProps.addTrophy.data.statusDescription) {
        this.setState({addTrophyPopupError : nextProps.addTrophy.data.statusDescription});
      }
    }


    if (nextProps.deleteTrophy) {

      if (nextProps.deleteTrophy.statusCode === 200) {
        self.getAllTrophies();
        this.closeModal(); 
      }else if(nextProps.deleteTrophy.data && nextProps.deleteTrophy.data.statusDescription) {
        this.setState({deleteTrophyPopupError : nextProps.deleteTrophy.data.statusDescription});
      }
    }

    if (nextProps.updateTrophy) {
      if (nextProps.updateTrophy.statusCode === 200) {
        self.getAllTrophies();
        this.closeModal(); 
      }else if(nextProps.updateTrophy.data && nextProps.updateTrophy.data.statusDescription) {
        this.setState({updateTrophyPopupError : nextProps.updateTrophy.data.statusDescription});
      }
    }

    if (nextProps.trophyStatus && nextProps.trophyStatus.statusCode === 200) {
      self.getAllTrophies();
    }

    if (nextProps.pillarData && nextProps.pillarData.statusCode === 200) {
      self.setState({
        pillarData : nextProps.pillarData['result']
      })
    }


    if (nextProps.trophyFreePillars && nextProps.trophyFreePillars.statusCode === 200) {
      self.setState({
        trophyFreePillars : nextProps.trophyFreePillars['result']
      })
    }


    if (nextProps.selectedTypeMilestones && nextProps.selectedTypeMilestones.statusCode === 200) {
      self.setState({
        selectedTypeMilestones : nextProps.selectedTypeMilestones['result']['data'],
        signedQueryString : nextProps.selectedTypeMilestones['signedQueryString']
      })
    }
  }



  getAllTrophies() {
    let getTrophyObj = {
      limit : this.state.limit,
      offset : this.state.offset,
      sortField : this.state.sortColumn,
      sortValue : this.state.sortValue,
    }
    this.props._getAllTrophies(getTrophyObj);
  }

  deleteTrophyModal(rowData) {
    this.setState({
      openDeleteTrophyModal : true,
      selectedRow : rowData
    })
  }

  updateTrophyModal(rowData) {
    this.setState({
      openEditTrophyModal : true,
      selectedRow : rowData
    })
  }



  addTrophyModal(rowData) {
    this.setState({
      openAddTrophyModal : true
    })
  }

  closeModal(){
    this.setState({
      openDeleteTrophyModal: false,
      openAddTrophyModal: false,
      openEditTrophyModal: false,
      selectedRow : {},
      openShowAllTrophiesModal : false
    });
  }


  

  handleChange(event) {
    let target = event.target;
    let name = target.name;
    let value;
    if (target.type === 'checkbox') {
      value = (this.state[name]) ? false : true
    }else {
      value = target.value;
    }
    this.setState({[name]: value});
  }

  
  deleteTrophy(event) {
    this.props._deleteTrophy({type : this.state.selectedRow.type});
    this.setState({selectedRow : ""});
    event.preventDefault();
  }


  addTrophy(event, trophyObj) {
    this.props._addTrophy(trophyObj);
    this.setState({addTrophyPopupError : ""});
    event.preventDefault();
  }


  updateTrophy(event, trophyObj) {
    this.props._updateTrophy(trophyObj);
    this.setState({updateTrophyPopupError : ""});
    event.preventDefault();
  }


  updateTrophyFromShowPopup(event, trophyObj) {
    let self = this
    this.setState({openShowAllTrophiesModal : false}, () => {
      self.updateTrophyModal(trophyObj)
    })
  }

  changeStatus(rowData){
    rowData['status'] = rowData['status'] ? false : true;
    rowData['milestoneId'] = rowData['_id'];
    this.props._changTrophyStatus(rowData);
  }

  getMorePillars(value) {
    let self = this
    self.setState({
      search : value
    }, () => {
      self.getAllPillars();
    })
  }

  getSelectedTypeMilestones(type) {
    this.props._getSelectedTypeMilestones({type : type});
  }

  showAllTrophies(rowData) {
    this.setState({
      openShowAllTrophiesModal : true,
      selectedRow : rowData,
    })
  }

  



  render() {
    let self = this;
    let { openDeleteTrophyModal, selectedRow, openAddTrophyModal, openEditTrophyModal, addTrophyPopupError, updateTrophyPopupError, trophys, totalTrophies, limit, page, deleteTrophyPopupError, pillarData, trophyFreePillars, selectedTypeMilestones, openShowAllTrophiesModal, systemLanguages } = this.state;
    let totalRecordsLabel = '##TOTAL_RECORDS## '+('Trophies')
    

    let trophysOptions = {
      totalRecords: totalTrophies,
      totalRecordsLabel: totalRecordsLabel,
      rowsPerPage: limit,
      page: page,
      loadMore: false,
      customColumn: {
        isActive: true,
        columnTitle: 'Update',
        columnContent: (rowData) => {
          return (
            <div>
              <Nav>
                <NavItem>
                  <AppSwitch className={'mx-1, switch-inline'} variant={'pill'} color={'primary'} label checked={rowData.status} onChange={() => { this.changeStatus(rowData); }} />
                </NavItem>
                <NavItem>
                  <NavLink title="Update all trophies" href="/" onClick={(e) => { this.updateTrophyModal(rowData); e.preventDefault() }}><FontAwesomeIcon icon={faEdit} size='lg' /> </NavLink>
                </NavItem>


                <NavItem>
                  <NavLink title="Show all trophies" href="/" onClick={(e) => { this.showAllTrophies(rowData); e.preventDefault() }}><FontAwesomeIcon icon={faEye} size='lg' /> </NavLink>
                </NavItem>

                <NavItem>
                  <NavLink title="Delete all trophies" href="/" onClick={(e) => { this.deleteTrophyModal(rowData); e.preventDefault() }}><FontAwesomeIcon icon={faTrashAlt} size='lg' /> </NavLink>
                </NavItem>

              </Nav>
            </div>
          );
        }
      },

      onPageChange(pageNumber) {
        self.state.page = pageNumber;
        self.state.offset = parseInt(pageNumber) * parseInt(self.state.limit)
        self.getAllTrophies();
        
      },

      onColumnSortChange: (column, direction) => {
        self.state.sortColumn = column;
        self.state.sortValue = (direction === "ASC") ? 1 : -1;
        self.getAllTrophies();
      }
    };




    return (
      <div className="content">
        <Row className="pb-4">
          <Col lg={12} sm={12}>
            <Row className="pb-4">
              <Col lg={12} sm={12}>
                <h3>Trophies</h3>                 
              </Col> 
              <Col lg={12} sm={12} className="text-right">
                {
                   <button className="btn btn-primary" color="link" onClick={() => this.addTrophyModal()}>Add New Trophy</button>
                }
              </Col> 
            </Row>
              {openAddTrophyModal && <AddTrophy  openAddTrophyModal={openAddTrophyModal}  closeModal={this.closeModal} addTrophy={(event, trophysObj) => {this.addTrophy(event,trophysObj)}}  addTrophyPopupError={addTrophyPopupError} pillarData={pillarData} trophyFreePillars={trophyFreePillars} getMorePillars={(value) => this.getMorePillars(value)} systemLanguages={systemLanguages}/>}

              {openEditTrophyModal && <EditTrophy  openEditTrophyModal={openEditTrophyModal}  closeModal={this.closeModal} updateTrophy={(event, trophysObj) => {this.updateTrophy(event,trophysObj)}}  updateTrophyPopupError={updateTrophyPopupError} selectedRow={selectedRow} pillarData={pillarData} getMorePillars={(value) => this.getMorePillars(value)} trophyFreePillars={trophyFreePillars} getSelectedTypeMilestones={(type) => this.getSelectedTypeMilestones(type)} signedQueryString={this.state.signedQueryString} selectedTypeMilestones={selectedTypeMilestones} systemLanguages={systemLanguages} />}
              

              {openDeleteTrophyModal && 
                <Modal isOpen={openDeleteTrophyModal}>
                  <ModalHeader toggle={()=>{this.closeModal(false)}}>Delete Trophy</ModalHeader>
                    <ModalBody>
                      <Row>
                        <Col xs="12">
                            Are you sure you want to delete this Trophy?
                        </Col>
                      </Row>


                      {(deleteTrophyPopupError) ? <Alert color="light">
                          <p className="validation-error">{deleteTrophyPopupError}</p> 
                        </Alert>  : null
                      } 
                    </ModalBody>
                    <ModalFooter className="justify-content-left">
                      <Button type="button" onClick={(event)=>{this.deleteTrophy(event)}} size="sm" color="primary">Delete</Button>{' '}
                      <Button type="reset" size="sm" color="danger"  onClick={() => { this.closeModal() }}>Cancel</Button>
                    </ModalFooter>
                </Modal>
              } 



               {openShowAllTrophiesModal && <ShowAllTrophy  visible={openShowAllTrophiesModal}  closeModal={this.closeModal}  selectedRow={selectedRow} getSelectedTypeMilestones={(type) => this.getSelectedTypeMilestones(type)} selectedTypeMilestones={selectedTypeMilestones} updateTrophyFromShowPopup={(event, trophysObj) => {this.updateTrophyFromShowPopup(event,trophysObj)}} />}
              


              

              <div className="dataTable-wrapper pillarList_grid">
                {<DataGrid
                  columns={this.trophysColumns}
                  options={trophysOptions}
                  rows={ trophys }
                />}
              </div> 
          </Col>
        </Row>
        
      </div>

      
    );
  }
}

export default Trophy;
