import {createAction} from 'redux-act';
import httpClient from '../../services/http';

export const requestSystemEmailData = createAction();
export const responseSystemEmailData = createAction();


export const requestUpdateSystemEmailData = createAction();
export const responseUpdateSystemEmailData = createAction();


export const requestGetImagesForEmailData = createAction();
export const responseGetImagesForEmailData = createAction();



export function getSystemEmails(params){
  return (dispatch) => {
      dispatch(requestSystemEmailData([]));
	
      httpClient.call('USER','/get_system_emails', params, {method: 'GET', headers: {}}).then(function(response){
      	dispatch(responseSystemEmailData(response));
      }, function(error){
      	dispatch(responseSystemEmailData(error));
      });
  }
}


export function updatedSystemEmail(params){
  return (dispatch) => {
      dispatch(requestUpdateSystemEmailData([]));
	
      httpClient.call('USER','/update_system_email_template', params, {method: 'PUT', headers: {}}).then(function(response){
      	dispatch(responseUpdateSystemEmailData(response));
      }, function(error){
      	dispatch(responseUpdateSystemEmailData(error));
      });
  }
}

export function getImagesForEmail(){
  return (dispatch) => {
      dispatch(requestGetImagesForEmailData([]));
	
      httpClient.call('PILLAR','/get_email_template_images', {}, {method: 'POST', headers: {}}).then(function(response){
      	dispatch(responseGetImagesForEmailData(response));
      }, function(error){
      	dispatch(responseGetImagesForEmailData(error));
      });
  }
}
