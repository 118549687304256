import { createAction } from 'redux-act';
import httpClient from '../../services/http';


export const requestGetPillarCategory = createAction();
export const responseGetPillarCategory = createAction();

export const requestAddUpdatePillarCategory = createAction();
export const responseAddUpdatePillarCategory = createAction();





export function getPillarCategory(params) {
  return (dispatch) => {
    dispatch(requestGetPillarCategory([]));


    let pillerId = '';
    if (params && params['pillarFilter'] && params['pillarFilter'] !== '') {
      pillerId = params['pillarFilter'];
    }

    let payloadData = {};
    if (params && params.sortField && params.sortValue) {
      payloadData['sortField'] = params.sortField;
      payloadData['sortValue'] = params.sortValue;
    }
    if (params && params.search) {
      payloadData['search'] = params.search;
    }
    if (params && params.type) {
      payloadData['type'] = params.type;
    }

    let endPoint = "/get_pillar_categories";
    endPoint = pillerId ? endPoint + "/" + pillerId : endPoint;

    httpClient.call('PILLAR', endPoint, payloadData, { method: 'GET', headers: {} }).then(function (response) {
      dispatch(responseGetPillarCategory(response));
    }, function (error) {
      dispatch(responseGetPillarCategory(error));
    });
  }
}


export function addUpdateCategory(params) {
  return (dispatch) => {
    dispatch(requestAddUpdatePillarCategory([]));
    let endPoint = '/update_category';
    endPoint = (params.categoryId === 0) ? '/create_category' : endPoint;
    let method = (params.categoryId === 0) ? 'POST' : 'PUT';
    httpClient.call('PILLAR', endPoint, params, { method: method, headers: {} }).then(function (response) {
      dispatch(responseAddUpdatePillarCategory(response));
    }, function (error) {
      dispatch(responseAddUpdatePillarCategory(error));
    });
  }
}
