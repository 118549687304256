import { connect } from 'react-redux';

import Feedback  from './Feedback';
import { getFeedback, closeFeedback } from './action';


const mapStateToProps = (state) => {
	var stateData = {
			requestProcess: state.feedback.requestProcess
		};

		if(state.feedback.data){
			stateData['getFeedback'] = state.feedback.data;
		}


		if(state.feedback.closedFeedbackData){
			stateData['closedFeedbackData'] = state.feedback.closedFeedbackData;
		}
		return stateData;
};

const mapDispatchToProps = (dispatch) => {
	return {
		_getFeedback: (params) => {
			dispatch(getFeedback(params));
		},


		_closeFeedback: (params) => {
			dispatch(closeFeedback(params));
		}
	}
};


export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Feedback);
