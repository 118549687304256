import {createAction} from 'redux-act';
import httpClient from '../../services/http';	



export const requestGetNotification = createAction();
export const responseGetNotification = createAction();


export const requestContentList = createAction();
export const responseContentList = createAction();

export const requestAddUpdateNotification = createAction();
export const responseAddUpdateNotification = createAction();



export function getAllNotifications(params){
  return (dispatch) => {
      dispatch(requestGetNotification([]));
      httpClient.call('REMINDER','/get_default_reminders', params, {method: 'GET', headers: {}}).then(function(response){
          dispatch(responseGetNotification(response));
      }, function(error){
          dispatch(responseGetNotification(error));
      });
  }
}

export function getContentList(params){
  return (dispatch) => {
      dispatch(requestContentList([]));
      httpClient.call('PILLAR','/get_lite_content', params, {method: 'GET', headers: {}}).then(function(response){
          dispatch(responseContentList(response));
      }, function(error){
          dispatch(responseContentList(error));
      });
  }
}

export function addUpdateNotification(params){
  return (dispatch) => {
      dispatch(requestAddUpdateNotification([]));
      let endPoint = params.reminderId ? '/update_default_reminder' : '/add_default_reminder';
      let apiMethod = params.reminderId ? 'PUT' : 'POST';
      httpClient.call('REMINDER', endPoint, params, {method: apiMethod, headers: {}}).then(function(response){
          dispatch(responseAddUpdateNotification(response));
      }, function(error){
          dispatch(responseAddUpdateNotification(error));
      });
  }
}
