"use strict";

const Message = {
	"SIZE_474_564" : "For best results please upload image of minimum size of 474px (width) X 564px (height).",
	"SIZE_474_405" : "For best results please upload image of minimum size of 474px (width) X 405px (height).",
	"SIZE_252_228" : "For best results please upload image of minimum size of 252px (width) X 228px (height).",
	"SIZE_990_900" : "For best results please upload image of minimum size of 990px (width) X 900px (height).",
	"SIZE_1125_2001" : "For best results please upload image of minimum size of 1125px (width) X 2001px (height).",

	"SIZE_474_564_error" : "Image size should not be greater then 474px (width) X 564px (height).",
	"SIZE_474_405_error" : "Image size should not be greater then 474px (width) X 405px (height).",
	"SIZE_1125_2001_error" : "Image size should not be greater then 1125px (width) X 2001px (height).",


	"SIZE_1164_780" : "For best results please upload image of minimum size of 1164px (width) X 780px (height).",
	"SIZE_1164_780_error" : "Image size should not be greater then 1164px (width) X 780px (height).",


}

export default Message;
