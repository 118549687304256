import React, { Component } from 'react';
// import { Link } from 'react-router-dom';
import { LoginForm } from 'g6reactlogin';
import { Card, CardBody, CardImg, CardGroup, Col, Container, Row, Alert } from 'reactstrap';
import { Loader,  setHttpClientConfig, roleBasedSideBar } from '../../utils/common';
import Session from '../../utils/session';
import * as _ from 'underscore';

class Login extends Component {
  constructor(props) {
    super(props);
    this.session = Session.getSessionData('config');
    this.apiUrl = this.session && this.session.UTILS && this.session.UTILS.BUCKET_URL ? this.session.UTILS.BUCKET_URL : process.env.REACT_APP_CLOUDFRONT_URL;
    this.signedQueryString = this.session && this.session.signedQueryString ? this.session.signedQueryString : '';
    this.handleSubmit = this.handleSubmit.bind(this);
    this.state = {
      loginError : false,
      errorText : ""
    }
  }

  componentWillMount() {
    let sessionData = Session.getSessionData();
    if(sessionData && sessionData['jwtToken']){
      this.props.router.push('parent');
    }
  }



  componentDidMount() {

  }

  handleSubmit(user, status){
    if (status === "success") {
      this.props._login(user)

    }
  }


  componentWillReceiveProps(nextProps) {
    let self = this;
    Loader(nextProps.requestProcess);
    this.setState({loginError : false})

    if (nextProps.data && nextProps.data.statusCode && nextProps.data.statusCode == 200 ) {
      let result = {};

      this.setState({loginError : false})
      result = nextProps.data.result;
      nextProps.data.result = {};
      Session.setSessionData(result);
      setHttpClientConfig();
      setTimeout(()=> {
        if( result.roles && result.roles.length > 0 && result.roles[0] === 'MARKETING'){
          window.location.href = process.env.REACT_APP_ANALYTICS_URL+'/marketing-social';
        }else if( result.roles && result.roles.length > 0 && result.roles[0] === 'ANALYTICS'){
          window.location.href = process.env.REACT_APP_ANALYTICS_URL;
        }else{
          let rolePath = roleBasedSideBar(result.roles);
          rolePath = rolePath && rolePath.length > 0 ? rolePath[0] : '/'
          self.props.router.push(rolePath);
        }
      }, 200)
    }else if(!_.isEmpty(nextProps.data)){
      this.setState({loginError : true, errorText : (nextProps && nextProps.data && nextProps.data.data && nextProps.data.data.statusDescription) ? nextProps.data.data.statusDescription : "Something went wrong. Please try again"})
    }
  }

  render() {
    let loginError = this.state.loginError;
    let errorText = this.state.errorText;
    const config = {
      handleSubmit:this.handleSubmit,

      //Password Regex Check for at least 8 characters, including 1 uppercase and 1 number.
      // passwordPattern: /^(?=.*[A-Z])(?=.*\d)[A-Za-z\d$@$!%*#?&]{7,}\S$/,

      //Email Regex Check for Valid Email Format.
      emailPattern: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      usernameLabel: 'Email',
      passwordLabel: 'Password',
      rememberMeLabel: 'Remember Me',
      usernameErrorText: 'Please enter valid Email',
      passwordErrorText: 'Please enter valid Password',
      usernameHelpText: '',
      passwordHelpText: '',
      submitButtonLabel: 'Sign in',
      options:{
        label:true,
        placeHolder:false,
        rememberMe:false
      }
    }


    return (
      <div className="app flex-row align-items-center login-page">
        <Container>
          <Row className="justify-content-center">
            <Col md="6">
              <CardGroup>
                <Card>
                 <CardImg top  src={this.apiUrl+"admin/img/img-login.jpg"+this.signedQueryString} alt="Card image cap" />
                  <CardBody>
                    <LoginForm config={config} />
                    {loginError ? <div style={{"textAlign":"center"}}>
                      <Alert color="danger">
                        {errorText}
                      </Alert>
                    </div> : null}
                  </CardBody>
                </Card>

              </CardGroup>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default Login;
