import { connect } from 'react-redux';

import AdminUsers from './AdminUsers';
import { getAllAdminUsers, addUpdateAdminUser, deleteAdminUser } from './action';


const mapStateToProps = (state) => {

	var stateData = {
		requestProcess: state.adminUsers.requestProcess
	};
	if(state.adminUsers.data){
		stateData['adminUserData'] = state.adminUsers.data;
	}

	if(state.adminUsers.addUpdateData){
		
		stateData['addUpdateData'] = state.adminUsers.addUpdateData;
	}
	
	return stateData;
};

const mapDispatchToProps = (dispatch) => {
	return {
		_getAllAdminUsers: (params) => {
			dispatch(getAllAdminUsers(params));
		},
		_addUpdateAdminUser: (params) => {
			dispatch(addUpdateAdminUser(params));
		},
		_deleteAdminUser: (params) => {
			dispatch(deleteAdminUser(params))
		}
	};
};


export default connect(
	mapStateToProps,
	mapDispatchToProps
)(AdminUsers);
