import {createAction} from 'redux-act';
import httpClient from '../../../services/http';	



export const requestSystemLangData = createAction();
export const responseSystemLangData = createAction();


export const requestSystemLangUpdate = createAction();
export const responseSystemLangUpdate = createAction();



export const requestSystemLangAdd = createAction();
export const responseSystemLangAdd = createAction();

export function getSystemLanguages(params){
  return (dispatch) => {
    dispatch(requestSystemLangData([]));
    httpClient.call('CONFIG','/get_all_languages', params, {method: 'GET', headers: {}}).then(function(response){
      dispatch(responseSystemLangData(response));
    }, function(error){
      dispatch(responseSystemLangData(error));
    });
  }
}


export function updateLanguage(params){
  return (dispatch) => {
    dispatch(requestSystemLangUpdate([]));
    httpClient.call('CONFIG','/update_language', params, {method: 'PUT', headers: {}}).then(function(response){
      dispatch(responseSystemLangUpdate(response));
    }, function(error){
      dispatch(responseSystemLangUpdate(error));
    });
  }
}



export function addLanguage(params){
  return (dispatch) => {
    dispatch(requestSystemLangAdd([]));
    httpClient.call('CONFIG','/add_language', params, {method: 'POST', headers: {}}).then(function(response){
      dispatch(responseSystemLangAdd(response));
    }, function(error){
      dispatch(responseSystemLangAdd(error));
    });
  }
}