import React, { Component } from 'react';
import { Loader } from '../../utils/common';
import { Row, Col, Modal, Button, ModalHeader, ModalBody, ModalFooter, FormGroup, Label, Input,  Form , Alert } from "reactstrap";
import { AppSwitch } from '@coreui/react'
  
import SimpleReactValidator from 'simple-react-validator';
import Constants from '../../config/constants';  


class UpdateAppUtility extends Component {
  constructor(props) {
    super(props);
    this.validator = new SimpleReactValidator();
    this.formatTitlePosLabel = this.formatTitlePosLabel.bind(this);
    this.state = {
      utilId : this.props.selectedRow._id,
      title : this.props.selectedRow.title,
      value : this.props.selectedRow.value,
      type : this.props.selectedRow.type,
      status : this.props.selectedRow.status,
    }
  }

  componentDidMount() {
  }

  componentWillReceiveProps(nextProps) {
   // let self = this;
    Loader(nextProps.requestProcess);
  }


  

  handleChange(event) {
    let target = event.target;
    let name = target.name;
    let value;

    if (target.type === 'checkbox') {
      if (target.name === 'status') {
        value = (target.value === 'true') ? false : true;
      }     
    }else {
      value = target.value;
    }
    this.setState({[name]: value});
  }

  handleSubmit(e) {
    if( this.validator.allValid()){
      let utilsObj = {
        utilId : this.state.utilId,
        title : this.state.title,
        status : this.state.status,
        type : this.state.type,
        value : this.state.value,
      }  
      this.props.updateAppUtility(e, utilsObj);
    }else{
      this.validator.showMessages();
      this.forceUpdate();
    }
    e.preventDefault();
  }

  formatTitlePosLabel (s) {
    if (typeof s !== 'string') return ''
      s = s.replace("_", " ");
      s = s.charAt(0).toUpperCase() + s.slice(1).toLowerCase();
      return s;
  }

  



  render() {
    //let self = this;
    let { openUpdateAppUtilityModal, closeModal, updateAppUtilityPopupError } = this.props;
    

    return (
      <Modal isOpen={openUpdateAppUtilityModal}>
          <ModalHeader toggle={()=>{closeModal(false)}}>Update Utility</ModalHeader>
          <Form onSubmit={(event) => {this.handleSubmit(event)}}>  
            <ModalBody>
              <Row>
                <Col xs="12">
                    <FormGroup>
                      <Label htmlFor="title">Title*</Label>
                      <Input type="text" id="title" placeholder="Title name" name="title" value={this.state.title} onChange={(event)=>{this.handleChange(event)}} />                                              
                      <div className="validation-error">{ this.validator.message('title', this.state.title, 'required') }</div>   
                    </FormGroup>
                </Col>
              </Row>

              <Row>
                <Col xs="12">
                    <FormGroup>
                      <Label htmlFor="value">Utility Value*</Label>
                      <Input type="text" id="value" placeholder="Utility Value" name="value" value={this.state.value} onChange={(event)=>{this.handleChange(event)}} />                                              
                      <div className="validation-error">{ this.validator.message('value', this.state.value, 'required') }</div>   
                    </FormGroup>
                </Col>
              </Row>


              <Row>
                <Col xs="12">
                    <FormGroup>
                          <Label htmlFor="status">Utility Type</Label>
                          <Input type="select" name="type" id="type" value={this.state.type} onChange={($event)=>{this.handleChange($event)}}>
                            <option value={Constants.IN_HOUSE}>{this.formatTitlePosLabel(Constants.IN_HOUSE)}</option>
                            <option value={Constants.S3}>{this.formatTitlePosLabel(Constants.S3)}</option>
                          </Input>
                      </FormGroup>
                </Col>
              </Row>

              <Row>
                <Col xs="12">
                  <FormGroup>
                      <Label htmlFor="status">Status</Label>
                      <div>
                        <AppSwitch className={ 'mx-1'} variant={ 'pill'} color={ 'primary'}  id="status"  name="status"
                          onChange={($event)=>{this.handleChange($event)}}
                          checked={(this.state.status) ? true : false} value={(this.state.status) ? "true" : "false"} />
                      </div>
                  </FormGroup>
                </Col>
              </Row>

              {(updateAppUtilityPopupError) ? <Alert color="danger">{updateAppUtilityPopupError}</Alert> : null}
            </ModalBody>
            <ModalFooter className="justify-content-left">
              <Button type="submit" size="sm" color="primary">Update</Button>{' '}
              <Button type="reset" size="sm" color="danger"  onClick={() => { closeModal() }}>Cancel</Button>
            </ModalFooter>
          </Form>
        </Modal>
      
    );
  }
}

export default UpdateAppUtility;
