import React, { Component } from 'react';
import { Loader } from '../../utils/common';
import { Row, Col, Modal, Button, ModalHeader, ModalBody, ModalFooter, FormGroup, Label, Input,  Form , Alert } from "reactstrap";
import { AppSwitch } from '@coreui/react'
 
import SimpleReactValidator from 'simple-react-validator';


class UpdateAppServices extends Component {
  constructor(props) {
    super(props);
    this.validator = new SimpleReactValidator();
    this.state = {
      serviceId : this.props.selectedRow._id,
      serviceName : this.props.selectedRow.serviceName,
      apiKey : this.props.selectedRow.apiKey,
      domain : this.props.selectedRow.domain,
      serviceStatus : this.props.selectedRow.status,
      authRequired : this.props.selectedRow.authRequired,
    }
  }

  componentDidMount() {
  }

  componentWillReceiveProps(nextProps) {
    //let self = this;
    Loader(nextProps.requestProcess);
  }


  

  handleChange(event) {
    let target = event.target;
    let name = target.name;
    let value;

    if (target.type === 'checkbox') {
      if (target.name === 'serviceStatus') {
        value = (target.value === 'true') ? false : true;
      }

      if (target.name === 'authRequired') {
        value = (target.value === 'true') ? false : true;
      }      
    }else {
      value = target.value;
    }
    this.setState({[name]: value});
  }

  handleSubmit(e) {
    if( this.validator.allValid()){
      let serviceObj = {
        serviceId : this.state.serviceId,
        serviceName : this.state.serviceName,
        apiKey : this.state.apiKey,
        status : this.state.serviceStatus,
        domain : this.state.domain,
        authRequired : this.state.authRequired,
      }  
      this.props.updateAppService(e, serviceObj);
    }else{
      this.validator.showMessages();
      this.forceUpdate();
    }
    e.preventDefault();
  }

  



  render() {
    //let self = this;
    let { openUpdateAppServiceModal, closeModal, updateAppServicePopupError } = this.props;
    

    return (
      <Modal isOpen={openUpdateAppServiceModal}>
          <ModalHeader toggle={()=>{closeModal(false)}}>Add Service</ModalHeader>
          <Form onSubmit={(event) => {this.handleSubmit(event)}}>  
            <ModalBody>
              <Row>
                <Col xs="12">
                    <FormGroup>
                      <Label htmlFor="service">Service*</Label>
                      <Input type="text" id="service" placeholder="Service name" name="serviceName" value={this.state.serviceName} onChange={(event)=>{this.handleChange(event)}} />                                              
                      <div className="validation-error">{ this.validator.message('serviceName', this.state.serviceName, 'required') }</div>   
                    </FormGroup>
                </Col>
              </Row>

              <Row>
                <Col xs="12">
                    <FormGroup>
                      <Label htmlFor="apiKey">Api Key*</Label>
                      <Input type="text" id="apiKey" placeholder="apiKey" name="apiKey" value={this.state.apiKey} onChange={(event)=>{this.handleChange(event)}} />                                              
                      <div className="validation-error">{ this.validator.message('apiKey', this.state.apiKey, 'required') }</div>   
                    </FormGroup>
                </Col>
              </Row>

              <Row>
                <Col xs="12">
                    <FormGroup>
                      <Label htmlFor="domain">Service Domain*</Label>
                      <Input type="text" id="domain" placeholder="Domain" name="domain" value={this.state.domain} onChange={(event)=>{this.handleChange(event)}} />                                              
                      <div className="validation-error">{ this.validator.message('domain', this.state.domain, 'required') }</div>   
                    </FormGroup>
                </Col>
              </Row>


              <Row>
                <Col xs="12">
                  <FormGroup>
                      <Label htmlFor="status">Status</Label>
                      <div>
                        <AppSwitch className={ 'mx-1'} variant={ 'pill'} color={ 'primary'}  id="serviceStatus"  name="serviceStatus"
                          onChange={($event)=>{this.handleChange($event)}}
                          checked={(this.state.serviceStatus) ? true : false} value={(this.state.serviceStatus) ? "true" : "false"} />
                      </div>
                  </FormGroup>
                </Col>
              </Row>

              <Row>
                <Col xs="12">
                  <FormGroup>
                      <Label htmlFor="status">Authencitation Required</Label>
                      <div>
                        <AppSwitch className={ 'mx-1'} variant={ 'pill'} color={ 'primary'}  id="authRequired"  name="authRequired"
                          onChange={($event)=>{this.handleChange($event)}}
                          checked={(this.state.authRequired) ? true : false} value={(this.state.authRequired) ? "true" : "false"} />
                      </div>
                  </FormGroup>
                </Col>
              </Row>

              {(updateAppServicePopupError) ? <Alert color="danger">{updateAppServicePopupError}</Alert> : null}
            </ModalBody>
            <ModalFooter className="justify-content-left">
              <Button type="submit" size="sm" color="primary">Update</Button>{' '}
              <Button type="reset" size="sm" color="danger"  onClick={() => { closeModal() }}>Cancel</Button>
            </ModalFooter>
          </Form>
        </Modal>
      
    );
  }
}

export default UpdateAppServices;
