import React, { Component } from 'react';
import { Loader, ErrorToast, SuccessToast, FormatTitlePosLabel } from '../../utils/common';
import { Row, Col, Input, Label} from "reactstrap";
import DataGrid from "g6reactdatagrid";
import * as _ from "underscore";
import InstallVsSignup from "../../shared/graphs/installVsSignup";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye,  faCheck } from '@fortawesome/free-solid-svg-icons'

class SocialInsights extends Component {
  constructor(props) {
    super(props);
    this.state = {     
      socialInsightsData: [],
      totalRecord: 0,
      rowsPerPage: 10,
      page: 0,
      offset: 0,
      column: 'createdDate',
      direction: -1,
      selectedTags : ['ninjafocus'],
      tags : (props.location && props.location.state && props.location.state.tags && props.location.state.tags.length) ? props.location.state.tags : []
    }
    const params = this.props.params;
    console.log(params)
    if(!params || !params.socialType){
      this.back();
    }
    this.columns = [
      { "columnName": "title", "displayName": "Post" },
      { "columnName": "likeCount", "displayName": "Like", "sort": true },
      { "columnName": "commentCount", "displayName": "Comment", "sort": true },
      { "columnName": "shareCount", "displayName": "Share", "sort": true },
      { "columnName": "signupCount", "displayName": "Signup", "sort": true },
      { "columnName": "installCount", "displayName": "Install", "sort": true },
    ];

  }

 

  componentDidMount() {
    const params = this.props.params;
    const socialType = params && params.socialType ? params.socialType : '';
    this.getSocialProfileInsights()
  }

  getSocialProfileInsights(){
    const {selectedTags, page, rowsPerPage, column, direction} = this.state;
    const params = this.props.params;
    const socialType = params && params.socialType ? params.socialType : '';
    this.props._getSocialProfileInsights({socialPlatform: socialType, selectedTags, page, limit:rowsPerPage, column, direction})

  }



  componentWillReceiveProps(nextProps) {
    Loader(nextProps.requestProcess);
    if (nextProps.insightsData && nextProps.insightsData.statusCode == 200)  {
      this.setState({socialInsightsData: nextProps.insightsData.result.list, totalRecord: nextProps.insightsData.result.total});
    }
  }



  back(path){
    this.props.router.push('marketing-social');
  }

  handleTagsChange(value) {
    let {selectedTags} = this.state;
    const index = selectedTags.indexOf(value);
    if (index == -1) {
      selectedTags.push(value)
    }else{
      selectedTags.splice(index, 1);
    }
    this.setState({selectedTags, page: 0}, ()=>{
      this.getSocialProfileInsights()
    });
  }

  renderTags(tags) {
    let self = this
    let {selectedTags} = this.state;
    let tagsSection = [];
    if (tags.length) {
      _.each(tags, ((value, index)=> {
        tagsSection.push(<button  className={selectedTags.indexOf(value['name']) !== -1 ? 'btn btn-primary tags-style' : 'btn btn-outline-primary  tags-style'} onClick={(event)=>{self.handleTagsChange(value['name'])}}>
          {selectedTags.indexOf(value['name']) !== -1 && <FontAwesomeIcon icon={faCheck} /> } {value['name']}
          </button>);
      }))
    }
    return(tagsSection)
  }


  render() {
    const self = this;
    const params = this.props.params;
    const socialType = params && params.socialType ? params.socialType.replace(/\b[a-z]/g, (x) => x.toUpperCase()) : '';

    let { socialInsightsData, page, rowsPerPage, totalRecord, tags } = this.state;
    let totalRecordsLabel = '##TOTAL_RECORDS## '+(totalRecord > 1 ? 'Parents' : 'Parent')
    let options = {
      totalRecords: totalRecord,
      totalRecordsLabel: totalRecordsLabel,
      rowsPerPage: rowsPerPage,
      page: page,
      loadMore: false,
      customColumn: {
        isActive: false,
        columnTitle: '',
        columnContent: (rowData) => {}
      },
      onPageChange(pageNumber) {
        self.state.page = pageNumber;
        self.setState({page: parseInt(pageNumber)}, ()=>{
          this.getSocialProfileInsights();
        });        
      },
      onColumnSortChange: (column, direction) => {
        self.setState({column, direction: direction == 'ASC' ? 1 : -1}, ()=>{
          this.getSocialProfileInsights();
        });  
        
      }
    };

    return (
      <div className="content">
          <Row className="pb-4">
            <Col lg={12} sm={12}>
              <h3>{socialType+' Insights'}</h3>
            </Col>            
          </Row>
          <InstallVsSignup platform={socialType} showBarChart={true} showPieChart={true}></InstallVsSignup>
          {this.renderTags(tags)}

          <Row className="pb-4">

            <Col lg={12} sm={12} className="">
              <DataGrid
                  columns={this.columns}
                  options={options}
                  rows={socialInsightsData}
                />
            </Col>

          </Row>

      </div>
    );
  }
}

export default SocialInsights;
