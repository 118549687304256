import React, { Component } from 'react';
import ReactTags from 'react-tag-autocomplete'

const KeyCodes = {
  comma: 188,
  enter: 13,
  space: 32
};

const delimiters = [KeyCodes.comma, KeyCodes.enter, KeyCodes.space];

class Tags extends Component {
  constructor(props) {

    super(props);
    this.state = {     
      tags: props.tags,
      suggestions: []
    }
    this.timeout = false;
  }


  componentWillReceiveProps(nextProps) {
    
  }

  

  handleDelete(i) {
    let {tags } = this.props;
    tags.splice(i, 1)
    this.setState({tags}, ()=>{
      this.props.updateTags(tags);
    });
  }

  handleAddition(tag) {
    
    let {tags } = this.props;
    if(tag && tag.name && tag.name.length > 0){
      let tagName = tag.name;
      tag['name'] = tagName[0] !== '#' ? '#'+tag.name : tag.name;
    }
    tags.push(tag)
    this.setState(state => ({ tags }), ()=>{
      this.props.updateTags(tags);
    });
  }
//let 


  render() {
    let {tags } = this.props;
    
    return (
      <ReactTags tags={tags}
        allowNew={true}
        suggestions={[]}
        addOnBlur={true}
        handleDelete={(index)=>{this.handleDelete(index)}}
        handleAddition={(tag) => {this.handleAddition(tag)}}
        delimiters={delimiters} />
    );
  }
}

export default Tags;
