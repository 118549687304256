import React, { Component } from 'react';
import { Loader } from '../../utils/common';
import Constants from '../../config/constants';
import { Button, Card, CardHeader, Alert, CardBody, Table } from "reactstrap";
import SimpleReactValidator from 'simple-react-validator';
import moment from 'moment';
import AddSiriShortcuts from './addSiriShortcuts';  
import UpdateSiriShortcuts from './updateSiriShortcuts';  

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit } from '@fortawesome/free-solid-svg-icons'


import * as _ from 'underscore';

class SiriShortcuts extends Component {
  constructor(props) {
    super(props);
    this.validator = new SimpleReactValidator();
    this.state = {
      allCategories : [],
      allShortcuts : [],
      totalRecords : 0,
      openAddShortCutPopup : false,
      addShortcutError : "",

      openUpdateShortCutPopup : false,
      updateShortcutError : "",

      selectedRow : {},
      fireSiriShortcutsApi : props.getSiriShortcuts
    }


    this.gridHeaders = [
      { "displayName": "Title"},
      { "displayName": "Sub Title"},
      { "displayName": "Category"},
      { "displayName": "Created On"},
      { "displayName": "Update"},
    ];
  }

  componentDidMount() {
    this.getCategories()
    // this.getAllShortcuts();
  }



  getCategories() {
    this.props._getPillarCategory({})
  }

  getAllShortcuts() {
    this.props._getAllShortcuts({})
  }

  componentWillReceiveProps(nextProps) {
    let self = this;
    //let appFeatures = [];
    
    Loader(nextProps.requestProcess);
    if ((self.state.allCategories.length === 0) && nextProps.categoryData && nextProps.categoryData.statusCode === 200) {
      if (nextProps.categoryData.result && nextProps.categoryData.result.length) {
        self.setState({allCategories: nextProps.categoryData.result});
        self.getAllShortcuts();
      }
    }


    if (nextProps.shortcuts && nextProps.shortcuts.statusCode === 200) {
      if (nextProps.shortcuts.result && nextProps.shortcuts.result.siriShortcuts && nextProps.shortcuts.result.siriShortcuts.length) {
        _.each(nextProps.shortcuts.result.siriShortcuts, function(value, index){
          nextProps.shortcuts.result.siriShortcuts[index]['category'] = _.findWhere(self.state.allCategories, {_id  : value['categoryId']})
        })
      }
      self.setState({ allShortcuts: nextProps.shortcuts.result.siriShortcuts, totalRecords: nextProps.shortcuts.result.totalRecords });
    }



    if (nextProps.shortcutAdded) {
      if (nextProps.shortcutAdded.statusCode === 200) {
        self.getAllShortcuts()
        self.closeModal()
      }else{
        self.setState({addShortcutError : (nextProps.shortcutAdded && nextProps.shortcutAdded.data && nextProps.shortcutAdded.data.statusDescription) ? nextProps.shortcutAdded.data.statusDescription : Constants.SOMETHING_WRONG})
      }
    }



    if (nextProps.shortcutUpdated) {
      if (nextProps.shortcutUpdated.statusCode === 200) {
        self.getAllShortcuts()
        self.closeModal()
      }else{
        self.setState({updateShortcutError : (nextProps.shortcutUpdated && nextProps.shortcutUpdated.data && nextProps.shortcutUpdated.data.statusDescription) ? nextProps.shortcutUpdated.data.statusDescription : Constants.SOMETHING_WRONG})
      }
    }
  }



  handleChange(index, event){
    let type = event.target.type;
    // let name = event.target.name;
    let value = event.target.value;

    if(type === 'checkbox'){
      value = event.target.checked
    }
    this.setState({name: value}) 
  }


  setGridHeaders(headingData) {
    let gridHeadings = [];
    gridHeadings.push(<th key={headingData[0]['displayName']}>#</th>)
    _.each(headingData, function(value, index){
      {
        gridHeadings.push(<th key={index}>{value['displayName']}</th>)
      }

    })

    return (<tr className="table-head">{gridHeadings}</tr>)
  }

  updateSiriShortcutPopup(data) {
    console.log(data)
    this.setState({
      selectedRow : data,
      openUpdateShortCutPopup : true,
    })
  }

  setGridData(data) {
    let self = this
    let gridData = []
    _.each(data, function(value, index){
      {
        gridData.push(
          <tr key={index}>
            <th scope="row">{index + 1}</th>
            <td>{value['title']}</td> 
            <td>{value['subTitle']}</td> 
            <td>{(value['category'] && value['category']['title']) ? value['category']['title'] : ""}</td> 
            <td>{moment(value['createdDate']).format("DD-MMM-YYYY")}</td> 
            <td> <span  title="update shortcut" onClick={() => {self.updateSiriShortcutPopup(value)}}><FontAwesomeIcon icon={faEdit}  size='1x' /></span></td> 
          </tr>
       )
      }
    })
    return gridData;
  }


  addNewShortCut() {
    this.setState({
      selectedRow : {},
      openAddShortCutPopup : true
    })
  }

  closeModal() {
    this.setState({
      selectedRow : {},
      openAddShortCutPopup : false,
      openUpdateShortCutPopup : false
    })
  }

  addSiriShortcut(event ,obj) {
    this.props._addSiriShortcut(obj)
    event.preventDefault();
  }


  updateSiriShortcut(event ,obj) {
    this.props._updateSiriShortcut(obj)
    event.preventDefault();
  }

  


  render() {
     let self = this;
     let {totalRecords, allShortcuts, openAddShortCutPopup, addShortcutError, selectedRow, allCategories, openUpdateShortCutPopup, updateShortcutError} = this.state;

    return (
      <div className="content">
        <Card>
          <CardHeader>Siri shortuts  
            <Button className="add-settings" color="primary" size="sm" onClick={() => this.addNewShortCut()}>Add New Shortcut</Button>
          </CardHeader>
          <CardBody>
            <div className="dataTable-wrapper pillarList_grid">
              <Table responsive hover className="text-sm">
                <thead>
                   {this.setGridHeaders(this.gridHeaders)}
                </thead>
                <tbody>
                  {this.setGridData(allShortcuts)}
                </tbody>
              </Table>
              <hr/>
              <p className="clearfix">Total Records : {totalRecords}
              </p>  
                
            </div> 
            {(this.state.successMessage) ? <Alert color="success">{this.state.successMessage}</Alert> : null}
            {(this.state.errorMessage) ? <Alert color="danger">{this.state.errorMessage}</Alert> : null}
          </CardBody>
          {(allCategories.length) ? <AddSiriShortcuts openAddShortCutPopup={openAddShortCutPopup} addShortcutError={addShortcutError} selectedRow={selectedRow} allCategories={allCategories} closeModal={() => {self.closeModal()}} addSiriShortcut={(e, obj) => self.addSiriShortcut(e, obj)}  ></AddSiriShortcuts> : null}



          {(allCategories.length && selectedRow._id) ? <UpdateSiriShortcuts openUpdateShortCutPopup={openUpdateShortCutPopup} updateShortcutError={updateShortcutError} selectedRow={selectedRow} allCategories={allCategories} closeModal={() => {self.closeModal()}} updateSiriShortcut={(e, obj) => self.updateSiriShortcut(e, obj)}  ></UpdateSiriShortcuts> : null}

        </Card>

          

       </div>
    );
  }
}

export default SiriShortcuts;
