import {createReducer} from 'redux-act';
import { requestGetPillarCategory, responseGetPillarCategory, requestAddUpdatePillarCategory, responseAddUpdatePillarCategory } from './action';


const defaultState = {
	requestProcess: false,
};

const reducer = createReducer({

	[requestGetPillarCategory]: (state) => {
		return {
			requestProcess: true,
		};
	},

	[responseGetPillarCategory]: (state, params) => {
		return {
			requestProcess: false,
			data: params,
		};
	},

	// [requestChangeStatusPillarCategory]: (state) => {
	// 	return {
	// 		requestProcess: true
	// 	};
	// },

	// [responseChangeStatusPillarCategory]: (state, params) => {
	// 	return {
	// 		requestProcess: false,
	// 		addUpdateCategory: params,
	// 	};
	// },
	[requestAddUpdatePillarCategory]: (state) => {
		return {
			requestProcess: true
		};
	},

	[responseAddUpdatePillarCategory]: (state, params) => {
		return {
			requestProcess: false,
			addUpdateCategory: params,
		};
	}



}, defaultState);



export default reducer
