import { createReducer } from 'redux-act';
import {requestFeaturesData, responseFeaturesData, requestUpdateFeature, responseUpdateFeature, requestGetSettings, responseGetSettings, requestUpdateSettings, responseUpdateSettings, requestAddSettings, responseAddSettings, requestServicesData, responseServicesData, requestAddService, responseAddService, requestUpdateService, responseUpdateService, requestGetUtility, responseGetUtility, requestAddUtility, responseAddUtility, requestUpdateUtility, responseUpdateUtility} from './action';


const defaultState = {
	requestProcess: false,
};

const reducer = createReducer({

	[requestFeaturesData]: (state) => {
		return {
			requestProcess: true,
			data: [],
		};
	},

	[responseFeaturesData]: (state, params) => {
		return {
			requestProcess: false,
			data: params,
		};
	},


	[requestUpdateFeature]: (state) => {
		return {
			requestProcess: true,
		};
	},

	[responseUpdateFeature]: (state, params) => {
		return {
			requestProcess: false,
			updateFeature: params,
		};
	},



	[requestGetSettings]: (state) => {
		return {
			requestProcess: true,
		};
	},

	[responseGetSettings]: (state, params) => {
		return {
			requestProcess: false,
			settings: params,
		};
	},


	[requestUpdateSettings]: (state) => {
		return {
			requestProcess: true,
		};
	},

	[responseUpdateSettings]: (state, params) => {
		return {
			requestProcess: false,
			updatedSetting: params,
		};
	},

	[requestAddSettings]: (state) => {
		return {
			requestProcess: true,
		};
	},

	[responseAddSettings]: (state, params) => {
		return {
			requestProcess: false,
			addedSetting: params,
		};
	},


	[requestServicesData]: (state) => {
		return {
			requestProcess: true,
			serviceData: [],
		};
	},

	[responseServicesData]: (state, params) => {
		return {
			requestProcess: false,
			serviceData: params,
		};
	},

	[requestAddService]: (state) => {
		return {
			requestProcess: true,
			addService: {},
		};
	},

	[responseAddService]: (state, params) => {
		return {
			requestProcess: false,
			addService: params,
		};
	},


	[requestUpdateService]: (state) => {
		return {
			requestProcess: true,
			updateService: {},
		};
	},

	[responseUpdateService]: (state, params) => {
		return {
			requestProcess: false,
			updateService: params,
		};
	},



	[requestGetUtility]: (state) => {
		return {
			requestProcess: true,
			utilityData: {},
		};
	},

	[responseGetUtility]: (state, params) => {
		return {
			requestProcess: false,
			utilityData: params,
		};
	},



	[requestAddUtility]: (state) => {
		return {
			requestProcess: true,
			addUtility: {},
		};
	},

	[responseAddUtility]: (state, params) => {
		return {
			requestProcess: false,
			addUtility: params,
		};
	},


	[requestUpdateUtility]: (state) => {
		return {
			requestProcess: true,
			updateUtility: {},
		};
	},

	[responseUpdateUtility]: (state, params) => {
		return {
			requestProcess: false,
			updateUtility: params,
		};
	},


}, defaultState);



export default reducer
