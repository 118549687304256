import { connect } from 'react-redux';

import BackgroundMusic from './BackgroundMusic';
import { getBackgrroundMusic, updateBackgroundMedia} from './action';


const mapStateToProps = (state) => {
	var stateData = {
		requestProcess: state.backgroundMusic.requestProcess
	};
	if(state.backgroundMusic.data){
		stateData['backgroundMusic'] = state.backgroundMusic.data;
	}
	if(state.backgroundMusic.updateData){
		stateData['updateData'] = state.backgroundMusic.updateData;
	}

	
	return stateData;
};

const mapDispatchToProps = (dispatch) => {
	return {
		_getBackgrroundMusic: (params) => {
			dispatch(getBackgrroundMusic(params));
		},

		_updateBackgroundMedia: (params) => {
			dispatch(updateBackgroundMedia(params));	
		}

	};
};


export default connect(
	mapStateToProps,
	mapDispatchToProps
)(BackgroundMusic);
