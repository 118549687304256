import React, { Component } from 'react';
import { Loader } from '../../utils/common';
import DataGrid from "g6reactdatagrid";
import { Row, Col, Input, Nav, NavItem, NavLink, Modal, Button, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import moment from 'moment';
import AddEditContent from './AddEditContent';
import CategoryList from './CategoryList';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons'


import Constants from '../../config/constants';
import * as _ from 'underscore';

class Content extends Component {


  constructor(props) {
    super(props);
    // this.apiUrl = "http://ninjaapi.gate6.com:3002"
    this.apiUrl = process.env.REACT_APP_CLOUDFRONT_URL;
    this.state = {
      totalRecords: 0,
      contentList: [],
      ageGroups: [],
      dropdownOpen: {},
      page: 0,
      limit: 10,
      offset: 0,
      sortColumn: "",
      sortValue: "",
      openAddEditContentModal: false,
      contentObj: {},
      searchKey: '',
      categoryId: '',
      formData: {
        _id: 0,
        title: "",
        priority: 1,
        priorityText: 'High',
        status: "",
        pillarId: "",
        categoryId: "",
        contentType: "",
        titleColor: "",
        thumbTitleColor: "",
        dialColor: "",
        description: "",
        fullImage: "",
        thumb: "",
        media: "",
        mediaUrl: "",
        order: 1,
        contentTags: [],
        titlePosition: Constants.MIDDLE,
      },
      systemLanguages: [{
        title: "English",
        slug: "ENGLISH",
        code: "EN",
        status: true,
        allowForUsers: true,
        _id: "5d80a09bee87f90029fe35f2",
        __v: 0
      }]
    }
    this.intervalInstance = '';

    this.columns = [
      { "columnName": "title", "displayName": "Title", "sort": true },
      { "columnName": "contentType", "displayName": "Content Type", "sort": true },
      { "columnName": "status", "displayName": "Status", "sort": true },
      { "columnName": "priority", "displayName": "Priority" },
      { "columnName": "titleColor", "displayName": "Title Color" },
      { "columnName": "thumbTitleColor", "displayName": "Thumb Title Color" },
      { "columnName": "imageThumb", "displayName": "Image" },
      { "columnName": "createdDate", "displayName": "Created On", "sort": true }
    ];

  }

  componentDidMount() {
    this.getContentList();
    this.getAgeGroups();
  }

  getContentList() {
    let { categoryId, searchKey, sortColumn, sortValue, limit, offset } = this.state;
    let params = {
      categoryId: categoryId,
      search: searchKey,
      sortColumn: sortColumn,
      sortValue: sortValue,
      limit: limit,
      offset: offset
    }
    this.props._getContentList(params);
  }

  componentWillReceiveProps(nextProps) {
    let self = this;
    let contentList = [];
    Loader(nextProps.requestProcess);


    if (nextProps.statusChange && nextProps.statusChange.statusCode === 200) {
      this.getContentList();
    }



    if (nextProps.data && nextProps.data.statusCode === 200) {
      let listData = (nextProps.data.result.length) ? nextProps.data.result : [];
      listData.map(function (element, key) {
        let categories = [];
        let images = [];
        let categoryImages = "";
        element['apiTitleColor'] = element.titleColor
        element['apiThumbTitleColor'] = element.thumbTitleColor
        element['apiDialColor'] = element.dialColor

        // placing ,iltiple images thumb according to selected categories
        element['categoriesMap'].map(function (subElem, subKey) {
          if (images.indexOf(subElem.imageThumb) === -1) {
            images.push(subElem.imageThumb)
            categoryImages += '<img src="' + self.apiUrl + subElem.imageThumb + '" alt="' + element.title + '" />'

          }
          return subElem;
        })
        categories = [];
        element.imageThumb = '<div class="title-img" >' + categoryImages + '</div> '

        element.titleColor = '<div class="title-bg-color" style="background:' + element.titleColor + ';"></div>'
        element.thumbTitleColor = '<div class="title-bg-color" style="background:' + element.thumbTitleColor + ';"></div>'
        element['priority'] = 0;
        if (element && element.order) {
          element['priority'] = element.order === 1 ? Constants.HIGH : (element.order === 2 ? Constants.MEDIUM : (element.order === 3 ? Constants.LOW : Constants.LOW))
        }



        element.createdDate = moment(element.createdDate).format("DD-MMM-YYYY")
        contentList.push(element);
        return element;
      });
      self.setState({ contentList: contentList, openAddEditContentModal: false, contentObj: {}, totalRecords: (nextProps.data && nextProps.data['totalRecords']) ? nextProps.data['totalRecords'] : 0 });
    }
  }

  toggle(id) {
    let self = this;
    self.state.dropdownOpen[id] = !self.state.dropdownOpen[id]
    this.setState({});
  }

  appUpdatePillarCategory(rowData) {
    let self = this
    _.each(this.state.systemLanguages, function (value, index) {
      let contentObj = _.findWhere(rowData['contentLang'], { languageSlug: value['slug'] });
      self.state.systemLanguages[index]['contentObj'] = (!_.isEmpty(contentObj)) ? contentObj : { title: "", languageSlug: value['slug'] };
    })
    this.setState({ openAddEditContentModal: true, contentObj: rowData, systemLanguages: self.state.systemLanguages });



  }


  onCloseModal = (shouldRefresh) => {
    this.setState({ openAddEditContentModal: false, contentObj: {} });
    if (shouldRefresh) {
      this.getContentList();
    }
  };



  handleChange(event) {
    let value = event.target.value;
    this.setState({ pillarId: value });
  }


  categoryChange(event) {
    let value = event.target.value;
    this.setState({ categoryId: value }, () => { this.getContentList() });

  }





  searchContent() {
    let self = this
    clearTimeout(self.intervalInstance)
    self.intervalInstance = setTimeout(() => {
      self.setState({
        limit: 10,
        offset: 0,
        page: 0
      }, function () {
        self.getContentList();
      })
    }, 300)
  }

  updateContent(rowData) {
    let self = this
    _.each(this.state.systemLanguages, function (value, index) {
      let contentObj = _.findWhere(rowData['contentLang'], { languageSlug: value['slug'] });
      self.state.systemLanguages[index]['contentObj'] = (!_.isEmpty(contentObj)) ? contentObj : { title: "", description: "", media: "", languageSlug: value['slug'] };
    })

    let contentObj = {
      categoryId: rowData.categoryId,
      contentType: rowData.contentType,
      description: rowData.description,
      dialColor: rowData.apiDialColor,
      fullImage: rowData.fullImage,
      image: rowData.image,
      thumb: rowData.thumb,
      status: rowData.status,
      thumbTitleColor: rowData.apiThumbTitleColor,
      title: rowData.title,
      titleColor: rowData.apiTitleColor,
      _id: rowData._id,
      mediaUrl: rowData.mediaUrl,
      order: rowData.order,
      titlePosition: rowData.titlePosition,
      contentLang: rowData.contentLang,
      ContentAgeMap: rowData.ContentAgeMap,
      contentTags: rowData.contentTags
    }

    this.setState({
      contentObj: contentObj,
      openAddEditContentModal: true,
      systemLanguages: self.state.systemLanguages
    })
  }

  deleteContent(rowObj) {
    this.setState({ deleteRowData: rowObj, visible: true })
  }
  closeModal() {
    this.setState({ deleteRowData: {}, visible: false })
  }

  confirmDelete() {
    let { deleteRowData } = this.state;
    if (deleteRowData && Object.keys(deleteRowData).length > 0) {
      this.props._deleteContent({ contentId: deleteRowData._id });
      setTimeout(() => { this.closeModal(); }, 1000);
    }
  }
  getAgeGroups() {
    this.props._getAgeGroups().then(response => {
      if (response && response.statusCode === 200) {
        this.setState({ ageGroups: response.result });
      }
    }, error => {
      console.log('error', error)
    })
  }

  render() {
    let self = this;
    let { contentList, totalRecords, page, openAddEditContentModal, contentObj, visible, limit, systemLanguages, ageGroups } = this.state;
    let totalRecordsLabel = '##TOTAL_RECORDS## ' + (totalRecords > 1 ? 'Contents' : 'Content')
    let options = {
      totalRecords: totalRecords,
      totalRecordsLabel: totalRecordsLabel,
      rowsPerPage: limit,
      page: page,
      loadMore: false,
      customColumn: {
        isActive: true,
        columnTitle: '',
        columnContent: (rowData) => {
          return (
            <div>
              <Nav className="justify-content-right">

                {/*rowData.status == 'PENDING' &&                      
                <NavItem>
                  <NavLink href="/" onClick={(e) => { this.updateContent(rowData); e.preventDefault(); }}><FontAwesomeIcon icon={faEdit} size='lg' /> </NavLink>
                </NavItem>
              */}
                {(rowData.status === 'PENDING' || rowData.status === 'DECLINED') &&
                  <NavItem>
                    <NavLink href="/" onClick={(e) => { this.deleteContent(rowData); e.preventDefault(); }}><FontAwesomeIcon icon={faTrashAlt} size='lg' /></NavLink>
                  </NavItem>
                }

              </Nav>
            </div>
          );
        }
      },

      onPageChange(pageNumber) {
        self.setState({ page: pageNumber, offset: parseInt(pageNumber) * parseInt(self.state.limit) });
        setTimeout(() => {
          self.getContentList();
        }, 200)


      },
      onColumnSortChange: (column, direction) => {
        direction = (direction === "ASC") ? -1 : 1;
        self.setState({ sortColumn: column, sortValue: direction }, () => { this.getContentList() })
      }
    };


    return (
      <div className="content">
        <Row className="pb-4">
          <Col lg={12} sm={12}>
            <h3>Content List</h3>
          </Col>
        </Row>
        <Row className="pb-4">
          <Col lg={4}><Input type="text" id="searchKey" placeholder="Search String" name="searchKey" value={this.state.searchKey} onChange={($event) => { this.setState({ searchKey: $event.target.value }); this.searchContent() }} /></Col>
          <Col lg={4}><CategoryList selectedPillarId={this.state.pillarId} selectedCategoryId={this.state.categoryId} categoryChange={(event) => { this.categoryChange(event) }} /></Col>
          <Col lg={4}><button onClick={() => { this.appUpdatePillarCategory(this.state.formData) }} className="btn btn-primary btn-block">Add New</button></Col>
        </Row>

        <Row>
          <Col lg={12} sm={12}>
            {openAddEditContentModal && systemLanguages && <AddEditContent visible={openAddEditContentModal} closeModal={this.onCloseModal} formData={contentObj} handleChange={(event) => { this.handleChange(event) }} categoryChange={(event) => { this.categoryChange(event) }} getContent={(event) => { this.getContentList(null) }} systemLanguages={systemLanguages} ageGroups={ageGroups} />}
            <div className="dataTable-wrapper pillarList_grid">
              <DataGrid
                columns={this.columns}
                options={options}
                rows={contentList}
              />
            </div>
          </Col>
        </Row>
        <Modal isOpen={visible}>
          <ModalHeader toggle={() => { this.closeModal(false) }}>Delete Content</ModalHeader>
          <ModalBody>
            Are you sure, want to delete content?
            </ModalBody>
          <ModalFooter className="justify-content-left">
            <Button type="button" size="sm" color="primary" onClick={() => { this.confirmDelete() }}>Delete</Button>{' '}
            <Button type="reset" size="sm" color="danger" onClick={() => { this.closeModal() }}>Cancel</Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

export default Content;
