import {createAction} from 'redux-act';
import httpClient from '../../services/http';


export const requestLoginData = createAction();
export const responseLoginData = createAction();

export function loginAction(param){
  return (dispatch) => {
    dispatch(requestLoginData([]));
     httpClient.call("USER", "/admin_login", JSON.stringify(param), { method: "POST" }).then(function(response){
      dispatch(responseLoginData(response));
    }, function(error){
      dispatch(responseLoginData(error));
    });
  }

}

