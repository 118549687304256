import { connect } from 'react-redux';

import Pillar from './Pillar';
import { getPillars, changePillerStatus, getPillar, updatePillar  } from './action';


const mapStateToProps = (state) => {
		var stateData = {
			requestProcess: state.pillar.requestProcess
		};

		if(state.pillar.data){
			stateData['data'] = state.pillar.data;
		}

		if(state.pillar.changeStatus){
			stateData['changeStatus'] = state.pillar.changeStatus;
		}

		if(state.pillar.updatedData){
			stateData['updatedData'] = state.pillar.updatedData;
		}


		return stateData;
};

const mapDispatchToProps = (dispatch) => {
	return {
		_getPillars: (params) => {
			dispatch(getPillars(params));
		},

		_changePillerStatus: (params) => {
				dispatch(changePillerStatus(params))
		},
		_getPillar: (params) => {
			dispatch(getPillar(params))
		},
		_updatePillar: (params) => {
			dispatch(updatePillar(params))
		}

	}
};


export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Pillar);
