import React, { Component } from 'react';
import {Card, CardBody, Col, Row, CardTitle, CardHeader, Button, } from 'reactstrap';
import { Loader} from '../../../utils/common';
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import moment from 'moment';
import * as _ from 'underscore';



class InstallVsSignup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      installData : [],
      installCount : 0,
      signupData : [],
      signupCount : 0,
      showPieChart : props.showPieChart,
      showBarChart : props.showBarChart,
      installDates : []
    };
  }

  componentWillReceiveProps(nextProps){
    Loader(nextProps.requestProcess);   
    if (nextProps.insightsGraphData && nextProps.insightsGraphData && nextProps.insightsGraphData.statusCode ===200) {
      if (nextProps.insightsGraphData.result && nextProps.insightsGraphData.result.installData) {

        // push all install dates in an array
        let installCount = 0;
        let installData = [];

        _.each(nextProps.insightsGraphData.result.installData, ((value, index) => {
          installCount = installCount + value['installCount']
          installData.push([new Date(value._id).getTime(), value.installCount])
        }))

        this.setState({
          installCount : installCount,
          installData: installData
        })
      }

      if (nextProps.insightsGraphData.result && nextProps.insightsGraphData.result.signupData) {

        // push all install dates in an array
        let signupCount = 0;
        let signupData = []

        _.each(nextProps.insightsGraphData.result.signupData, ((value, index) => {
          signupCount = signupCount + value['signupCount']
          signupData.push([new Date(value._id).getTime(), value.signupCount])
        }))

        this.setState({
          signupCount : signupCount,
          signupData: signupData
        })
      }
    }
  }

  componentWillMount() {
    this.getSocialVsSignupGraphData(this.props.platform)  
  }

  getSocialVsSignupGraphData(platform) {
    this.props._getInsightsGraphData({socialPlatform: platform})
  }

  


  loading = () => <div className="animated fadeIn pt-1 text-center">Loading...</div>

  render() {
    let {signupCount, installCount, showPieChart, showBarChart, installDates, installData, signupData} = this.state;

    this.pieChartOptions = {
      credits: false,
      chart: { type: 'pie'},
      title: {
        text: 'Signup/Installs',
        verticalAlign: 'middle',
        floating: true,
        y: -30,
        style :{fontSize: '13px'}
      },
      xAxis: {labels: {enabled:true}},
      colors: ['#f28f43', '#1aadce'],
      tooltip: { 
        headerFormat: '<span style="font-size: 10px"><span style="color:{point.color}">\u25CF</span>{point.key}</span><br/>',
        pointFormat: '<span><b>{point.title}</b></span><br/>Frequency: <b>{point.y:,.0f}</b>'
      },
      plotOptions: {
        pie: {
          size:'100%',
          allowPointSelect: true,            
          cursor: 'pointer',
          dataLabels: {
            distance: 0,
            enabled: true,
            format: '<b>{point.name}</b>: {point.percentage:.1f} %'
          },
          showInLegend: true
        }
      },
      legend: {
        enabled: true,
        useHTML: true,
        labelFormatter: function() {
          return '<div>' + this.name+' - '+this.y+ '</div>';
        }
      },
      series: [{
        name: 'Screen',
        innerSize: '50%',
        depth: 45,
        colorByPoint: true,
        data: [{
          name: 'Signup',
          y: signupCount,
        }, {
          name: 'Installs',
          y: installCount
        }]
      }]
    }

    this.barChartOptions = {
      credits: false,
      chart: { type: 'column'},
      title: {text: ''},
      xAxis: {
        labels: {
          style: { fontSize: '13px', fontFamily: 'Verdana, sans-serif' },
          formatter: function() { 
            return moment(this.value).format('M/D'); 
          },
        }
      },

      yAxis: { title: { text: 'No.' },allowDecimals: false},
      colors: ['#f28f43', '#1aadce'],
      tooltip: { 
        formatter: function() {
          let formatedDate = moment(this.x).format('dddd, MMMM D, YYYY');
          return '<b>' + formatedDate + '</b>  <br/> <b>'+this.series.name+':</b>'+ this.y;
        },
      },
      plotOptions: {
        column: {
          allowPointSelect: true,
          cursor: 'pointer',
          dataLabels: {
            enabled: true,
            // formatter: function() {
            //   let formatedDate = moment(this.x).format('dddd, MMMM D, YYYY');
            //   return '<b>' + formatedDate + '</b>  <br/> <b>'+this.series.name+':</b>'+ this.y;
            // },
          },

          showInLegend: false,
          animation: true,
          events:{
            click: function (event) {
              var point = this;
              // self.props.onScreenClickEvent(event.point.name)
            }
          }
        }
      },
      series: [{
          name: 'Install',
          data: installData

      }, {
          name: 'Signup',
          data: signupData

      }]
    }
    
    
    return (
        <div>
          <Row className="pb-4">
           {showPieChart &&  <Col lg={(showPieChart && showBarChart) ? 6 : 12} sm={(showPieChart && showBarChart) ? 6 : 12}>
              <Card>
                 <CardHeader>Signup Vs Installs Count</CardHeader>
                <CardBody>
                  <div className="chart-wrapper">
                    <HighchartsReact
                      highcharts={Highcharts}
                      options={this.pieChartOptions}
                      containerProps={{ style: { height: 300 } }}
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>}

            {showBarChart &&  <Col lg={(showBarChart && showBarChart) ? 6 : 12} sm={(showBarChart && showBarChart) ? 6 : 12}>
              <Card>
                 <CardHeader>Signup Vs Installs Date wise</CardHeader>
                <CardBody>
                  <div className="chart-wrapper">
                    <HighchartsReact
                      highcharts={Highcharts}
                      options={this.barChartOptions}
                      containerProps={{ style: { height: 300 } }}
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>}            
          </Row>


          

        </div>
    );
  }
}

export default InstallVsSignup;
