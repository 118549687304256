import { createReducer } from 'redux-act';
import {requestFeedbackData, responseFeedbackData, requestCloseFeedback, responseCloseFeedback} from './action';


const defaultState = {
	requestProcess: false,
};

const reducer = createReducer({

	[requestFeedbackData]: (state) => {
		return {
			requestProcess: true,
			data: [],
		};
	},

	[responseFeedbackData]: (state, params) => {
		return {
			requestProcess: false,
			data: params,
		};
	},


	[requestCloseFeedback]: (state) => {
		return {
			requestProcess: true,
			closedFeedbackData: [],
		};
	},

	[responseCloseFeedback]: (state, params) => {
		return {
			requestProcess: false,
			closedFeedbackData: params,
		};
	},


}, defaultState);



export default reducer
