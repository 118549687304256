import React, { Component } from 'react';
import { Loader } from '../../utils/common';
import { Row, Col, Button, Card, CardHeader, FormGroup, Label, Input, CardBody, CardFooter, Form } from "reactstrap";
import { AppSwitch } from '@coreui/react'
import moment from 'moment';  
import SimpleReactValidator from 'simple-react-validator';
import CheckPasswordPopup from '../../shared/checkPasswordPopup';



class Versions extends Component {
  constructor(props) {
    super(props);
    this.iosValidator = new SimpleReactValidator();
    this.androidValidator = new SimpleReactValidator();
    // this.androidVersionObj = {};
    // this.iosVersionObj = {};
    this.state = {
      limit : 10,
      offset : 0,
      page:0,
      sortField : "",
      sortValue : null,
      appVersionList : [],
      totalRecords : 0,
      openmodalbox : false,

      selectedIosVersionId : "",
      iosPlatform : "",
      iosCheckVersionUpdate : false,
      iosUpdateVersionText : "",
      iosCanSkip : true,
      iosSkipBtnText : "",
      iosStoreUrl : "",
      iosCurrentVersion : "",
      iosCurrentBuildVersion : "",
      iosUpdateBtnText : "",

      selectedAndroidVersionId : "",
      androidPlatform : "",
      androidCheckVersionUpdate : false,
      androidUpdateVersionText : "",
      androidCanSkip : true,
      androidSkipBtnText : "",
      androidStoreUrl : "",
      androidCurrentVersion : "",
      androidCurrentBuildVersion : "",
      androidUpdateBtnText : "",
      openCheckPasswordModal : false


    }

    this.columns = [
      { "columnName": "platform", "displayName": "Platform", sort : true},
      { "columnName": "currentVersion", "displayName": "Current Version", sort: true},
      { "columnName": "currentBuildVersion", "displayName": "Current Build Version", sort: true},
      { "columnName": "skipBtnText", "displayName": "Skip Button Text", sort: true},
      { "columnName": "updateBtnText", "displayName": "Update Button Text", sort: true},
      { "columnName": "updateVersionText", "displayName": "Update Version Text", sort: true},
      { "columnName": "createdDate", "displayName": "Created Date", sort: true},
    ];

  }

  componentDidMount() {
    // this.getAppVersions(this.state.offset, this.state.limit, this.state.sortField, this.state.sortValue);
    // this.getLatestAppVerisons();

    if (process.env.REACT_APP_CHECK_PASSWORD === 'ON') {
      this.setState({openCheckPasswordModal : true});
    }else {
      this.getLatestAppVerisons();
      this.closeModal(false);
    }
  }

  componentWillReceiveProps(nextProps) {
    let self = this;
    let appVersionList = [];
    Loader(nextProps.requestProcess);
    if (nextProps.getAppVersion && nextProps.getAppVersion.statusCode === 200) {
      let listData = (nextProps.getAppVersion.result.length) ? nextProps.getAppVersion.result   : [];
      listData.map(function (element, key) {
        element.createdDate = moment(element.createdDate).format("DD-MMM-YYYY")
        appVersionList.push(element);
        return true;
      });

      this.setState({
        appVersionList : appVersionList,
        totalRecords : nextProps['getAppVersion']['totalRecords'],
      })
    }

    if (nextProps.getAllservices && nextProps.getAllservices.statusCode === 200 && nextProps.getAllservices.result && nextProps.getAllservices.result.BUILD_VERSION && nextProps.getAllservices.result.BUILD_VERSION.IOS) {
      self.setState({
        selectedIosVersionId : nextProps.getAllservices.result.BUILD_VERSION.IOS['_id'],
        iosPlatform : nextProps.getAllservices.result.BUILD_VERSION.IOS['platform'],
        iosCheckVersionUpdate : nextProps.getAllservices.result.BUILD_VERSION.IOS['checkVersionUpdate'],
        iosUpdateVersionText : nextProps.getAllservices.result.BUILD_VERSION.IOS['updateVersionText'],
        iosCanSkip : nextProps.getAllservices.result.BUILD_VERSION.IOS['canSkip'],
        iosSkipBtnText : nextProps.getAllservices.result.BUILD_VERSION.IOS['skipBtnText'],
        iosStoreUrl : nextProps.getAllservices.result.BUILD_VERSION.IOS['storeUrl'],
        iosCurrentVersion : nextProps.getAllservices.result.BUILD_VERSION.IOS['currentVersion'],
        iosCurrentBuildVersion : nextProps.getAllservices.result.BUILD_VERSION.IOS['currentBuildVersion'],
        iosUpdateBtnText : nextProps.getAllservices.result.BUILD_VERSION.IOS['updateBtnText'],
      })
    }


    if (nextProps.getAllservices && nextProps.getAllservices.statusCode === 200 && nextProps.getAllservices.result && nextProps.getAllservices.result.BUILD_VERSION && nextProps.getAllservices.result.BUILD_VERSION.ANDROID) {
      self.setState({
        selectedAndroidVersionId : nextProps.getAllservices.result.BUILD_VERSION.ANDROID['_id'],
        androidPlatform : nextProps.getAllservices.result.BUILD_VERSION.ANDROID['platform'],
        androidCheckVersionUpdate : nextProps.getAllservices.result.BUILD_VERSION.ANDROID['checkVersionUpdate'],
        androidUpdateVersionText : nextProps.getAllservices.result.BUILD_VERSION.ANDROID['updateVersionText'],
        androidCanSkip : nextProps.getAllservices.result.BUILD_VERSION.ANDROID['canSkip'],
        androidSkipBtnText : nextProps.getAllservices.result.BUILD_VERSION.ANDROID['skipBtnText'],
        androidStoreUrl : nextProps.getAllservices.result.BUILD_VERSION.ANDROID['storeUrl'],
        androidCurrentVersion : nextProps.getAllservices.result.BUILD_VERSION.ANDROID['currentVersion'],
        androidCurrentBuildVersion : nextProps.getAllservices.result.BUILD_VERSION.ANDROID['currentBuildVersion'],
        androidUpdateBtnText : nextProps.getAllservices.result.BUILD_VERSION.ANDROID['updateBtnText'],
      })
    }
  }



  getAppVersions(offset, limit, sortField, sortValue, search) {
    let params = {
      limit: limit,
      offset: offset,
      search: search,
      sortField: sortField,
      sortValue: sortValue
    };

    this.props._getAppVersions(params);
  }

  getLatestAppVerisons() {
    this.props._getAllservices({});
  }

  
  updateIos(event) {
    if( this.iosValidator.allValid()) {
      let iosVersionUpdObj = {
        versionId : this.state.selectedIosVersionId,
        platform : "IOS",
        checkVersionUpdate : this.state.iosCheckVersionUpdate,
        updateVersionText : this.state.iosUpdateVersionText,
        canSkip : this.state.iosCanSkip,
        skipBtnText : this.state.iosSkipBtnText,
        storeUrl : this.state.iosStoreUrl,
        currentVersion : this.state.iosCurrentVersion,
        currentBuildVersion : this.state.iosCurrentBuildVersion,
        updateBtnText : this.state.iosUpdateBtnText
      }
      this.props._updateBuildVersion(iosVersionUpdObj);
    }else{
      this.iosValidator.showMessages();
      this.forceUpdate();
    }
    event.preventDefault();
  }

  updateAndroid (event) {

    // check for password

    if( this.androidValidator.allValid()) {
      let androidVersionUpdObj = {
        versionId : this.state.selectedAndroidVersionId,
        platform : "ANDROID",
        checkVersionUpdate : this.state.androidCheckVersionUpdate,
        updateVersionText : this.state.androidUpdateVersionText,
        canSkip : this.state.androidCanSkip,
        skipBtnText : this.state.androidSkipBtnText,
        storeUrl : this.state.androidStoreUrl,
        currentVersion : this.state.androidCurrentVersion,
        currentBuildVersion : this.state.androidCurrentBuildVersion,
        updateBtnText : this.state.androidUpdateBtnText
      }
      this.props._updateBuildVersion(androidVersionUpdObj);
    }else{
      this.androidValidator.showMessages();
      this.forceUpdate();
    }
    event.preventDefault();
  }

  handleChange(event) {
    let target = event.target;
    let name = target.name;
    let value;
    if (target.type === 'checkbox') {
      value = (this.state[name]) ? false : true
    }else {
      value = target.value;
    }
    this.setState({[name]: value});
  }


  closeModal(redirect) {
    if (redirect) {
      this.props.router.push('parent');
    }
    this.setState({openCheckPasswordModal : false})
  }


  isPasswordMatched(matched) {
    if (matched) {
      this.getLatestAppVerisons();
      this.closeModal(false);
    }
  }

  

  render() {
    let self = this;
    let { appVersionList, totalRecords, limit, openmodalbox, page, openCheckPasswordModal } = this.state;
    let totalRecordsLabel = '##TOTAL_RECORDS##' + ('Feedback')
    let options = {
      totalRecords: totalRecords,
      totalRecordsLabel: totalRecordsLabel,
      rowsPerPage: limit,
      page: page,
      loadMore: false,
      /*customColumn: {
        isActive: true,
        columnTitle: '',
        columnContent: (rowData) => {
          return (
            <div>
            <Nav>
            <NavItem>
              <NavLink href="#" onClick={() => { this.issueModal(rowData); }} disabled={rowData['status'] === 'Closed'} >{(rowData['status'] === 'Open') ?  'Close Feedback' : 'Closed'}</NavLink>
            </NavItem>
            </Nav>
            </div>
            );
        }
      },*/

      onPageChange(pageNumber) {
        self.state.page = pageNumber;
        self.state.offset = parseInt(pageNumber) * parseInt(self.state.limit)
        self.getAppVersions(self.state.offset, self.state.limit, self.state.sortField, self.state.sortValue);
      },

      onColumnSortChange: (column, direction) => {
        self.state.sortField = column;
        self.state.sortValue = (direction === "ASC") ? 1 : -1;
        self.getAppVersions(self.state.offset, self.state.limit, self.state.sortField, self.state.sortValue);

      }
    };

    return (
     <div>
      { openCheckPasswordModal && <CheckPasswordPopup openCheckPasswordModal={openCheckPasswordModal}  closeModal={(resp) => {this.closeModal(resp)}} isPasswordMatched={(resp) => this.isPasswordMatched(resp)}  />} 
          
      <div className="content">
        <Row className="pb-4">
          <Col lg={12} sm={12}>
            <h3>Versions</h3>
          </Col>
        </Row>

        <Row className="pb-4">
          <Col lg={12} sm={12}>
             <div className="dataTable-wrapper pillarList_grid">
              {/*<DataGrid
                columns={this.columns}
                options={options}
                rows={ appVersionList }
              />*/}


              <Row>
                  {/*ANDROID START*/}
                  <Col sm="6">
                    <Form onSubmit={(event) => {this.updateAndroid(event)}}>
                      <Card>
                          <CardHeader>Android</CardHeader>
                          <CardBody>
                                  <Row>
                                      <Col xs="12">
                                          <FormGroup>
                                            <Label htmlFor="platform">Update Version Text</Label>
                                            <Input type="textArea" id="androidUpdateVersionText" placeholder="Update Version Text" name="androidUpdateVersionText" value={this.state.androidUpdateVersionText} onChange={($event)=>{this.handleChange($event)}} />
                                            <div className="validation-error">{ this.androidValidator.message('Update Version Text', this.state.androidUpdateVersionText, 'required') }</div>
                                          </FormGroup>
                                      </Col>
                                  </Row>


                                  <Row>
                                      <Col xs="12">
                                          <FormGroup>
                                            <Label htmlFor="platform">Skip Button Text</Label>
                                            <Input type="text" id="androidSkipBtnText" placeholder="Skip Button Text" name="androidSkipBtnText" value={this.state.androidSkipBtnText} onChange={($event)=>{this.handleChange($event)}} />
                                            <div className="validation-error">{ this.androidValidator.message('Skip Button Text', this.state.androidSkipBtnText, 'required') }</div>
                                          </FormGroup>
                                      </Col>
                                  </Row>


                                  <Row>
                                      <Col xs="12">
                                          <FormGroup>
                                            <Label htmlFor="platform">Update Button Text</Label>
                                            <Input type="text" id="androidUpdateBtnText" placeholder="Update Button Text" name="androidUpdateBtnText" value={this.state.androidUpdateBtnText} onChange={($event)=>{this.handleChange($event)}} />
                                            <div className="validation-error">{ this.androidValidator.message('Update Button Text', this.state.androidUpdateBtnText, 'required') }</div>
                                          </FormGroup>
                                      </Col>
                                  </Row>


                                  <Row>
                                      <Col xs="12">
                                          <FormGroup>
                                            <Label htmlFor="platform">App Store Url</Label>
                                            <Input type="text" id="androidStoreUrl" placeholder="Play Store Url" name="androidStoreUrl" value={this.state.androidStoreUrl} onChange={($event)=>{this.handleChange($event)}} />
                                            <div className="validation-error">{ this.androidValidator.message('Play store url', this.state.androidStoreUrl, 'required') }</div>
                                          </FormGroup>
                                      </Col>
                                  </Row>


                                  <Row>
                                      <Col xs="12">
                                          <FormGroup>
                                            <Label htmlFor="platform">Current Version</Label>
                                            <Input type="text" id="androidCurrentVersion" placeholder="Current Version" name="androidCurrentVersion" value={this.state.androidCurrentVersion} onChange={($event)=>{this.handleChange($event)}} />
                                            <div className="validation-error">{ this.androidValidator.message('Current version', this.state.androidCurrentVersion, 'required') }</div>
                                          </FormGroup>
                                      </Col>
                                  </Row>




                                  <Row>
                                      <Col xs="12">
                                          <FormGroup>
                                            <Label htmlFor="platform">Current Build Version</Label>
                                            <Input type="text" id="androidCurrentBuildVersion" placeholder="Current Build Version" name="androidCurrentBuildVersion" value={this.state.androidCurrentBuildVersion} onChange={($event)=>{this.handleChange($event)}} />
                                            <div className="validation-error">{ this.androidValidator.message('Current Build version', this.state.androidCurrentBuildVersion, 'required') }</div>
                                          </FormGroup>  
                                      </Col>
                                  </Row>

                                   <Row>
                                      <Col xs="12">
                                          <FormGroup>
                                              <Label htmlFor="iosCheckVersionUpdate">Check Version Update</Label>
                                              <div>
                                                <AppSwitch className={ 'mx-1'} variant={ 'pill'} color={ 'primary'}  id="androidCheckVersionUpdate"  name="androidCheckVersionUpdate"
                                                  onChange={($event)=>{this.handleChange($event)}}
                                                  checked={this.state.androidCheckVersionUpdate}
                                                  value="" />
                                              </div>
                                          </FormGroup>

                                      </Col>
                                  </Row>


                                  <Row>
                                      <Col xs="12">
                                          <FormGroup>
                                              <Label htmlFor="status">Can skip</Label>
                                              <div>
                                                <AppSwitch className={ 'mx-1'} variant={ 'pill'} color={ 'primary'}  id="androidCanSkip"  name="androidCanSkip"
                                                  onChange={($event)=>{this.handleChange($event)}}
                                                  checked={(this.state.androidCanSkip) ? true : false}
                                                  value="" />
                                              </div>
                                          </FormGroup>
                                      </Col>
                                  </Row>

                              </CardBody>
                          <CardFooter>
                              <Button type="submit" size="sm" color="primary">Update</Button>
                          </CardFooter>
                      </Card>
                    </Form>
                  </Col>
                  {/*ANDROID END*/}

                  
                  {/*IOS START*/}
                    <Col sm="6">
                      <Form onSubmit={(event) => {this.updateIos(event)}}>
                          <Card>
                              <CardHeader>IOS</CardHeader>
                              <CardBody>
                                  <Row>
                                      <Col xs="12">
                                          <FormGroup>
                                            <Label htmlFor="platform">Update Version Text</Label>
                                            <Input type="textArea" id="iosUpdateVersionText" placeholder="Update Version Text" name="iosUpdateVersionText" value={this.state.iosUpdateVersionText} onChange={($event)=>{this.handleChange($event)}} />
                                            <div className="validation-error">{ this.iosValidator.message('Update Version Text', this.state.iosUpdateVersionText, 'required') }</div>
                                          </FormGroup>
                                      </Col>
                                  </Row>


                                  <Row>
                                      <Col xs="12">
                                          <FormGroup>
                                            <Label htmlFor="platform">Skip Button Text</Label>
                                            <Input type="text" id="iosSkipBtnText" placeholder="Skip Button Text" name="iosSkipBtnText" value={this.state.iosSkipBtnText} onChange={($event)=>{this.handleChange($event)}} />
                                            <div className="validation-error">{ this.iosValidator.message('Skip Button Text', this.state.iosSkipBtnText, 'required') }</div>
                                          </FormGroup>
                                      </Col>
                                  </Row>


                                  <Row>
                                      <Col xs="12">
                                          <FormGroup>
                                            <Label htmlFor="platform">Update Button Text</Label>
                                            <Input type="text" id="iosUpdateBtnText" placeholder="Update Button Text" name="iosUpdateBtnText" value={this.state.iosUpdateBtnText} onChange={($event)=>{this.handleChange($event)}} />
                                            <div className="validation-error">{ this.iosValidator.message('Update Button Text', this.state.iosUpdateBtnText, 'required') }</div>
                                          </FormGroup>
                                      </Col>
                                  </Row>


                                  <Row>
                                      <Col xs="12">
                                          <FormGroup>
                                            <Label htmlFor="platform">App Store Url</Label>
                                            <Input type="text" id="iosStoreUrl" placeholder="App Store Url" name="iosStoreUrl" value={this.state.iosStoreUrl} onChange={($event)=>{this.handleChange($event)}} />
                                            <div className="validation-error">{ this.iosValidator.message('IOS app store url', this.state.iosStoreUrl, 'required') }</div>
                                          </FormGroup>
                                      </Col>
                                  </Row>


                                  <Row>
                                      <Col xs="12">
                                          <FormGroup>
                                            <Label htmlFor="platform">Current Version</Label>
                                            <Input type="text" id="iosCurrentVersion" placeholder="Current Version" name="iosCurrentVersion" value={this.state.iosCurrentVersion} onChange={($event)=>{this.handleChange($event)}} />
                                            <div className="validation-error">{ this.iosValidator.message('Current version', this.state.iosCurrentVersion, 'required') }</div>
                                          </FormGroup>
                                      </Col>
                                  </Row>




                                  <Row>
                                      <Col xs="12">
                                          <FormGroup>
                                            <Label htmlFor="platform">Current Build Version</Label>
                                            <Input type="text" id="iosCurrentBuildVersion" placeholder="Current Build Version" name="iosCurrentBuildVersion" value={this.state.iosCurrentBuildVersion} onChange={($event)=>{this.handleChange($event)}} />
                                            <div className="validation-error">{ this.iosValidator.message('Current Build version', this.state.iosCurrentBuildVersion, 'required') }</div>
                                          </FormGroup>  
                                      </Col>
                                  </Row>

                                   <Row>
                                      <Col xs="12">
                                          <FormGroup>
                                              <Label htmlFor="iosCheckVersionUpdate">Check Version Update</Label>
                                              <div>
                                                <AppSwitch className={ 'mx-1'} variant={ 'pill'} color={ 'primary'}  id="iosCheckVersionUpdate"  name="iosCheckVersionUpdate"
                                                  onChange={($event)=>{this.handleChange($event)}}
                                                  checked={this.state.iosCheckVersionUpdate}
                                                  value="" />
                                              </div>
                                          </FormGroup>

                                      </Col>
                                  </Row>


                                  <Row>
                                      <Col xs="12">
                                          <FormGroup>
                                              <Label htmlFor="status">Can skip</Label>
                                              <div>
                                                <AppSwitch className={ 'mx-1'} variant={ 'pill'} color={ 'primary'}  id="iosCanSkip"  name="iosCanSkip"
                                                  onChange={($event)=>{this.handleChange($event)}}
                                                  checked={(this.state.iosCanSkip) ? true : false}
                                                  value="" />
                                              </div>
                                          </FormGroup>
                                      </Col>
                                  </Row>

                              </CardBody>
                              <CardFooter>
                                  <Button type="submit" size="sm" color="primary">Update</Button>
                              </CardFooter>
                          </Card>
                      </Form>
                    </Col>
                  {/*IOS END*/}  
              </Row>
            </div>
          </Col>
        </Row>
        
      </div>
      </div>
      
    );
  }
}

export default Versions;
