import React, { Component } from 'react';
import { Loader } from '../../utils/common';
import { Row, Col, Modal, Button, ModalHeader, ModalBody, ModalFooter, FormGroup, Label, Input, Form , Alert } from "reactstrap";
import moment from 'moment';  
import SimpleReactValidator from 'simple-react-validator';
import Constants from '../../config/constants';  
import { createCouponCode } from '../../utils/common';  


class AddSocialDiscount extends Component {
  constructor(props) {
    super(props);
    this.validator = new SimpleReactValidator();
    this.state = {
      couponCode : createCouponCode(),
      discount : "0",
      discountType : Constants.PERCENTAGE,
      maxDiscount : Constants.MAX_DISCOUNT_PERCRNTAGE,
      description : "",
      email : "",
      firstName : "",
      lastName : "",
      activeTab : this.props.activeTab,
      startOn : new Date(moment().format("YYYY-MM-DD")),
      expiredOn : new Date(moment().add(1, 'year').format("YYYY-MM-DD")),
      currentDate : new Date(moment().format("YYYY-MM-DD")),
    }
  }

  componentDidMount() {
  }

  componentWillReceiveProps(nextProps) {
   // let self = this;
    Loader(nextProps.requestProcess);
  }


  

  handleChange(event) {
    let target = event.target;
    let name = target.name;
    let value;


    if (name === "couponCode") {
      if (!(/^\S*$/.test(target.value))) {
        return false;
      }
      target.value = target.value.toUpperCase();
    }

    if (name === "startOn") {
      this.setState({expiredOn : target.value});
    }

    if (target.name === 'discountType') {
      this.state.maxDiscount = (target.value === Constants.PERCENTAGE) ? Constants.MAX_DISCOUNT_PERCRNTAGE : Constants.MAX_DISCOUNT_OFF;
      this.state.discount = 1;
    }

    if (name === "discount") {
      target.value = (target.value > this.state.maxDiscount) ? this.state.maxDiscount : target.value;
    }

    value = target.value;
    this.setState({[name]: value});
  }

  handleSubmit(e) {
    if( this.validator.allValid()){
      let discountsObj = {
        couponCode : this.state.couponCode,
        discount : this.state.discount,
        type : this.state.discountType,
        description : this.state.description,
        email : this.state.email,
        firstName : this.state.firstName,
        lastName : this.state.lastName,
        discountType : this.state.activeTab,
        startOn : this.state.startOn,
        expiredOn : moment(this.state.expiredOn).add(1, 'day').format("YYYY-MM-DD"),
      }  
      this.props._addDiscount(discountsObj);
    }else{
      this.validator.showMessages();
      this.forceUpdate();
    }
    e.preventDefault();
  }

  
formatTitlePosLabel (s) {
    if (typeof s !== 'string') return ''
      s = s.replace("_", " ");
      s = s.charAt(0).toUpperCase() + s.slice(1).toLowerCase();
      return s;
  }

  setDate(name, value) {
    this.setState({[name] : new Date(value)})
  }


  render() {
    //let self = this;
    let { openAddSocialDiscountModal, closeModal, addSocialDiscountPopupError } = this.props;
    //let { maxDiscount } = this.state;

    return (
      <Modal isOpen={openAddSocialDiscountModal}>
          <ModalHeader toggle={()=>{closeModal(false)}}>Add Social Coupons</ModalHeader>
          <Form onSubmit={(event) => {this.handleSubmit(event)}}>  
            <ModalBody>
              <Row>
                <Col xs="12">
                    <FormGroup>
                      <Label htmlFor="couponCode">Coupon Code</Label>
                      <Input type="text" maxLength="100" id="couponCode" placeholder="Coupon code" name="couponCode" value={this.state.couponCode} onChange={(event)=>{this.handleChange(event)}} min="5" pattern="^\S*$" />                                              
                      <div className="validation-error">{ this.validator.message('Coupon code', this.state.couponCode, 'required|min:5') }</div>   
                    </FormGroup>
                </Col>
              </Row>


              <Row>
                <Col xs="12">
                    <FormGroup>
                      <Label htmlFor="couponCode">Discount value (%)</Label>

                      <Input type="text" id="discount" placeholder="Discount value" name="discount" value={this.state.discount} onChange={(event)=>{this.handleChange(event)}} readOnly={true}>
                      </Input>
                      <div className="validation-error">{ this.validator.message('Discount', this.state.discount, 'required') }</div>
                    </FormGroup>
                </Col>
              </Row>

              {/*  <Row>
                <Col xs="12">
                    <FormGroup>
                      <Label htmlFor="description">Start On</Label>                                              
                      <DatePicker value={this.state.startOn} onChange={(event)=>{this.setDate('startOn',event)}} minDate={this.state.currentDate} />
                      <div className="validation-error">{ this.validator.message('Expire Date', this.state.expiredOn, 'required') }</div>   
                    </FormGroup>
                </Col>
              </Row>


               <Row>
                <Col xs="12">
                    <FormGroup>
                      <Label htmlFor="description">Expired On</Label>

                      <DatePicker value={this.state.expiredOn} onChange={(event)=>{this.setDate('expiredOn',event)}} minDate={this.state.startOn} />
                      <div className="validation-error">{ this.validator.message('Expire Date', this.state.expiredOn, 'required') }</div>   
                    </FormGroup>
                </Col>
              </Row>*/}
              
               <Row>
                <Col xs="12">
                    <FormGroup>
                      <Label htmlFor="email">First Name</Label>
                      <Input type="text" id="firstName" placeholder="First Name" name="firstName" value={this.state.firstName} onChange={(event)=>{this.handleChange(event)}} />
                      <div className="validation-error">{ this.validator.message('First Name', this.state.firstName, 'required') }</div>   
                    </FormGroup>
                </Col>
              </Row>

              <Row>
                <Col xs="12">
                    <FormGroup>
                      <Label htmlFor="email">Last Name</Label>
                      <Input type="text" id="lastName" placeholder="Last Name" name="lastName" value={this.state.lastName} onChange={(event)=>{this.handleChange(event)}} />
                      <div className="validation-error">{ this.validator.message('Last Name', this.state.lastName, 'required') }</div>   
                    </FormGroup>
                </Col>
              </Row>

              <Row>
                <Col xs="12">
                    <FormGroup>
                      <Label htmlFor="email">Email</Label>
                      <Input type="text" id="email" placeholder="Email" name="email" value={this.state.email} onChange={(event)=>{this.handleChange(event)}} />
                      <div className="validation-error">{ this.validator.message('Email', this.state.email, 'required|email') }</div>   
                    </FormGroup>
                </Col>
              </Row>


              <Row>
                <Col xs="12">
                    <FormGroup>
                      <Label htmlFor="description">Description</Label>
                      <Input type="textarea" id="description" maxLength="500" placeholder="Description" name="description" value={this.state.description} onChange={(event)=>{this.handleChange(event)}} />                                              
                      <div className="validation-error">{ this.validator.message('Description', this.state.description, 'required') }</div>   
                    </FormGroup>
                </Col>
              </Row>


              {(addSocialDiscountPopupError) ? <Alert color="danger">{addSocialDiscountPopupError}</Alert> : null}
            </ModalBody>
            <ModalFooter className="justify-content-left">
              <Button type="submit" size="sm" color="primary">Add</Button>{' '}
              <Button type="reset" size="sm" color="danger"  onClick={() => { closeModal() }}>Cancel</Button>
            </ModalFooter>
          </Form>
        </Modal>
      
    );
  }
}

export default AddSocialDiscount;
