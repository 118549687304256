import {createAction} from 'redux-act';
import httpClient from '../../services/http';


export const requestGetAllQuestData = createAction();
export const responseGetAllQuestData = createAction();


export const requestUpdateQuest = createAction();
export const responseUpdateQuest = createAction();

export const requestAddQuest = createAction();
export const responseAddQuest = createAction();


export const requestContentList = createAction();
export const responseContentList = createAction();



export function getAllQuest(params){
  return (dispatch) => {
      dispatch(requestGetAllQuestData([]));
      httpClient.call('PILLAR','/get_all_quests_on_admin', params, {method: 'GET', headers: {}}).then(function(response){
          dispatch(responseGetAllQuestData(response));
      }, function(error){
        console.log('error', error);
          dispatch(responseGetAllQuestData(error));
      });
  }
}



export function updateQuest(params){
  return (dispatch) => {
      dispatch(requestUpdateQuest([]));
      httpClient.call('PILLAR','/update_quest', params, {method: 'PUT', headers: {}}).then(function(response){
          dispatch(responseUpdateQuest(response));
      }, function(error){
        console.log('error', error);
          dispatch(responseUpdateQuest(error));
      });
  }
}


export function addQuest(params){
  return (dispatch) => {
    dispatch(requestAddQuest([]));
    httpClient.call('PILLAR','/add_quest', params, {method: 'POST', headers: {}}).then(function(response){
      dispatch(responseAddQuest(response));
    }, function(error){
      console.log('error', error);
      dispatch(responseAddQuest(error));
    });
  }
}


export function getContentList(params){
  return (dispatch) => {
    dispatch(requestContentList([]));
    httpClient.call('PILLAR','/get_contents_for_quest', params, {method: 'GET', headers: {}}).then(function(response){
      dispatch(responseContentList(response));
    }, function(error){
      console.log('error', error);
      dispatch(responseContentList(error));
    });
  }
}
