import React, { Component } from "react";
import { Row, Col, Modal, ModalHeader, ModalBody, ModalFooter, Button, FormGroup, Form, Label, Input, Nav, NavItem, NavLink, Alert } from "reactstrap";
import { Loader } from '../../utils/common';
import httpClient from '../../services/http';
import { AppSwitch } from '@coreui/react'
import SimpleReactValidator from 'simple-react-validator';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPencilAlt, faTrash, faArrowsAlt } from '@fortawesome/free-solid-svg-icons'
import { Table } from 'antd';
import { each } from 'underscore';
import Constants from '../../config/constants';
import 'antd/dist/antd.css';


const closest = function (el, selector, rootNode) {
  rootNode = rootNode || document.body;

  const matchesSelector =
    el.matches ||
    el.webkitMatchesSelector ||
    el.mozMatchesSelector ||
    el.msMatchesSelector;
  while (el) {
    const flagRoot = el === rootNode;
    if (flagRoot || matchesSelector.call(el, selector)) {
      if (flagRoot) {
        el = null;
      }
      break;
    }
    el = el.parentElement;
  }
  el.setAttribute('style', 'border: 50px solid red;');
  return el;
};

class CustomModule extends Component {
  constructor(props) {
    super(props);
    this.validator = new SimpleReactValidator({
      validators: {
        title: {  // name the rule
          message: 'The customise module title may only contain letters, special characters and spaces.',
          rule: (val, params, validator) => {
            return validator.helpers.testRegex(val, /^[a-zA-Z !'@#$%^&*)(]{2,50}$/i) && params.indexOf(val) === -1
          },
          messageReplace: (message, params) => message.replace(':values', this.helpers.toSentence(params)),
          // optional
          required: true  // optional
        }
      }
    });

    this.state = {
      openAddModal: false,
      openUpdateModal: false,
      _id: props.formData && props.formData._id ? props.formData._id : null,
      title: props.formData && props.formData.name ? props.formData.name : '',
      status: props.formData && props.formData.status ? props.formData.status : false,
      totalRecords: 0,
      customContentList: [],
      selectedObj: null,
      dragIndex: -1,
      draggedIndex: -1,
      screenError: "",
      statusFilter: '',
    }
    this.columns = [
      { dataIndex: "title", title: "Title", key: "title", render: (text, record, index) => <span>{this.state.customContentList[index].title}</span> },
      { dataIndex: "slug", title: "Slug", key: "slug", render: (text, record, index) => <span>{this.state.customContentList[index].slug}</span> },
      { dataIndex: "type", title: "Type", key: "type", render: (text, record, index) => <span>{this.state.customContentList[index].type}</span> },
      { dataIndex: "visibility", title: "Visibility", key: "visibility", render: (text, record, index) => <>{this.renderVisibility(this.state.customContentList[index].visibility)}</> },
      {
        title: "Status", key: "status",
        render: (text, record, index) =>
          <span>{
            this.state.customContentList[index].status == true ? 'Active' : 'Inactive'
          }
          </span>
      },
      { "dataIndex": "createdDate", "title": "Created On", "key": "createdDate" },
      {
        title: '',
        key: '',
        render: (text, record, index) =>
          <span>
            {
              (this.state.dragIndex >= 0 &&
                this.state.dragIndex !== this.state.draggedIndex &&
                index === this.state.draggedIndex &&
                <span
                  className={`drag-target-line ${this.state.draggedIndex <
                    this.state.dragIndex
                    ? 'drag-target-top'
                    : ''}`}
                />) ||
              ''}
            <div>
              <Nav>
                <NavItem>
                  <NavLink href="#" className="drag-handle" draggable="false" onMouseDown={this.onMouseDown}>
                    <FontAwesomeIcon icon={faArrowsAlt} size='1x' />
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink href="#" onClick={() => { this.updateCustomModule(this.state.customContentList[index]); }}>
                    <FontAwesomeIcon icon={faPencilAlt} size='1x' />
                  </NavLink>
                </NavItem>
                <NavItem>
                  <AppSwitch className={'mx-1'} variant={'pill'} color={'primary'} label checked={this.state.customContentList[index].status === true} onChange={() => { this.changeStatus(this.state.customContentList[index], index); }} />
                </NavItem>
                {(this.state.customContentList[index].type != 'DEFAULT' && this.state.customContentList[index].slug !== 'MEDIA_SERIES') &&
                  <NavItem>

                    <NavLink href="#" onClick={() => { this.deleteCustomModuleModal(this.state.customContentList[index]); }}><FontAwesomeIcon icon={faTrash} size='1x' /></NavLink>
                  </NavItem>
                }
              </Nav>
            </div>
          </span>,
      },
    ];

    this.onMouseDown = this.onMouseDown.bind(this);
    this.onDragStart = this.onDragStart.bind(this);
    this.onDragEnter = this.onDragEnter.bind(this);
    this.onDragEnd = this.onDragEnd.bind(this);
  }


  renderVisibility(visibility){
    if(!visibility){
      return <span>All</span>
    }else{
      if(visibility===1){
        return <span>Parent</span>
      }else if(visibility===2){
        return <span>School</span>
      }
    }
  }

  onMouseDown(e) {
    const target = this.getTrNode(e.target);
    if (target) {
      target.setAttribute('draggable', true);
      target.ondragstart = this.onDragStart;
      target.ondragend = this.onDragEnd;
    }
  }

  onDragStart(e) {
    const target = this.getTrNode(e.target);
    if (target) {
      e.dataTransfer.effectAllowed = 'move';
      target.parentElement.ondragenter = this.onDragEnter;
      target.parentElement.ondragover = function (ev) {
        ev.preventDefault();
        return true;
      };
      const dragIndex = target.rowIndex - 1;
      this.setState({ dragIndex, draggedIndex: dragIndex });
    }
  }

  onDragEnter(e) {
    const target = this.getTrNode(e.target);
    this.setState({
      draggedIndex: target ? target.rowIndex - 1 : -1,
    });
  }

  onDragEnd(e) {
    const target = this.getTrNode(e.target);
    if (target) {
      target.setAttribute('draggable', false);
      target.ondragstart = null;
      target.ondragend = null;
      target.parentElement.ondragenter = null;
      target.parentElement.ondragover = null;
      this.changeRowIndex();
    }
  }

  getTrNode(target) {
    return closest(target, 'tr');
  }

  changeRowIndex() {
    const result = {};
    const currentState = this.state;
    result.dragIndex = result.draggedIndex = -1;
    if (
      currentState.dragIndex >= 0 &&
      currentState.dragIndex !== currentState.draggedIndex
    ) {
      const { dragIndex, draggedIndex, data: oldData } = currentState;
      const customContent = this.state.customContentList;
      const item = customContent.splice(dragIndex, 1)[0];
      customContent.splice(draggedIndex, 0, item);
      result.customContent = customContent;
      result.dragIndex = -1;
      result.draggedIndex = -1;
    }
    this.setState(result);
    let payload = [];
    each(currentState.customContentList, (value, index) => {
      value['order'] = index;
      payload.push(value);
    });
    Loader(true);
    httpClient.call('PILLAR', '/reorder_custom_content', { content: payload }, { method: 'PUT', headers: {} }).then((response) => {
      if (response && response.statusCode == 200) {
        this.getCustomModule();
      } else {
        console.log('Some error found')
      }
      Loader(false);
    }, function (error) {
      console.error(error);
      Loader(false);
    });
  }


  handleChange(event) {
    let target = event.target;
    let name = target.name;
    let value = target.value;
    this.setState({ [name]: value });

  }

  handleSubmit(e) {
    let self = this;
    if (this.validator.allValid()) {
      let customObj = {
        _id: this.state._id,
        title: this.state.title,
        visibility: this.state.visibility,
        status: this.state.status,
      }
      this.addUpdateCustomContent(customObj);
    } else {
      self.validator.showMessages();
      self.forceUpdate();
    }
    e.preventDefault();
  }

  componentDidMount() {
    this.getCustomModule();
  }

  componentWillReceiveProps(nextProps) {
    Loader(nextProps.requestProcess);
  }

  toggle(update) {
    if (update) {
      this.setState({ openUpdateModal: !this.state.openUpdateModal, openAddModal: false, title: '', screenError: '', _id: '' });
    } else {
      this.setState({ openAddModal: !this.state.openAddModal, openUpdateModal: false, title: '', screenError: '', _id: '' });
    }
    this.validator.hideMessages();
  }

  changeStatus(rowData, index) {
    let { customContentList } = this.state;
    let params = {
      customContentId: rowData._id,
      status: rowData.status === true ? false : true
    }
    customContentList[index].status = params.status;
    this.setState({ customContentList }, () => {
      this.customContentStatus(params);
    })
  }

  customContentStatus(params) {
    httpClient.call("PILLAR", "/change_status/", params, { method: 'post' }).then(function (response) {
      if (response && response.statusCode == 200) {
        console.log("custom module status changed successfully");
      } else {
        console.error("Error code with status code!");
      }
    }, function (error) {
      console.error(error);
    });
  }

  getCustomModule() {
    Loader(true);
    let params = { 'admin': true, status: this.state.statusFilter }
    httpClient.call('PILLAR', '/get_custom_modules', params, { method: 'GET', headers: {} }).then((response) => {
      if (response && response.statusCode == 200) {
        this.setState({
          totalRecords: response.result.length,
          customContentList: response.result && response.result.length ? response.result : "",
        })
      } else {
        console.log("Some error found");
      }
      this.setState({
        screenError: ''
      })
      Loader(false)
    }, (error) => {
      Loader(false)
      this.setState({
        screenError: error && error.data ? error.data.statusDescription : Constants.SOMETHING_WRONG
      })
    });
  }

  addUpdateCustomContent(params) {
    Loader(true);
    let endPoint = params._id ? '/update_custom_module' : '/add_custom_module';
    let apiMethod = params._id ? 'PUT' : 'POST';
    httpClient.call('PILLAR', endPoint, params, { method: apiMethod, headers: {} }).then((response) => {
      if (response && response.statusCode == 200) {
        this.setState({
          openAddModal: false,
          openUpdateModal: false,
          title: '',
          _id: ''
        }, () => {
          this.getCustomModule();
        })
      } else {
        console.log('Some error found')
      }
      this.setState({
        screenError: ''
      })
      Loader(false)
    }, (error) => {
      Loader(false)
      this.setState({
        openAddModal: false,
        openUpdateModal: false,
        screenError: error && error.data ? error.data.statusDescription : Constants.SOMETHING_WRONG
      })
    });

  }

  deleteCustomModuleModal(moduleListObj) {
    this.setState({
      openDeleteConfirmModel: true,
      selectedObj: moduleListObj
    })
  }

  deleteCustomModulelist(params) {
    httpClient.call('PILLAR', '/delete_custom_module', params, { method: 'POST', headers: {} }).then((response) => {
      if (response && response.statusCode == 200) {
        this.setState({
          openDeleteConfirmModel: false,
        }, () => {
          this.getCustomModule();
        })
        //console.log("custom module added successfully");
      } else {
        console.log("Some error found");
      }
      this.setState({
        screenError: ''
      })
    }, function (error) {
      Loader(false)
      this.setState({
        screenError: error && error.data ? error.data.statusDescription : Constants.SOMETHING_WRONG
      })
    });
  }

  updateCustomModule(customObj) {
    this.setState({
      openUpdateModal: true,
      title: customObj.title,
      _id: customObj._id,
      visibility: customObj.visibility,
      status: customObj.status,
      selectedObj: customObj
    })
  }

  closeModal() {
    this.setState({
      openAddModal: false,
      openDeleteConfirmModel: false,
      openUpdateModal: false,
      selectedObj: null,
    })
  }

  statusChange(event) {
    let value = event.target.value;
    this.setState({ statusFilter: value }, () => { this.getCustomModule() });
  }

  render() {
    let { openAddModal, totalRecords, openDeleteConfirmModel, selectedObj, openUpdateModal, screenError, statusFilter, customContentList } = this.state;

    return (
      <div>
        <div className="content">
          <Row className="pb-4">
            <Col lg={12} sm={12}>
              <h3>Custom Module</h3>
            </Col>
          </Row>
          {screenError && <Alert color="danger">
            {screenError}
          </Alert>}

          <Row className="pb-4">

            <Col lg={3}>
              <Input type="select" name="status" id="status" value={statusFilter} onChange={($event) => { this.statusChange($event) }}>
                <option value="">Select Status</option>
                <option value="true">Active</option>
                <option value="false">Inactive</option>
              </Input>
            </Col>

            <Col lg={6}></Col>
            <Col lg={3}>
              <button onClick={() => { this.toggle() }} className="btn btn-primary btn-block float-right">Add New</button>
            </Col>

          </Row>
          <Row>
            <Col lg={12} sm={12}>
              <div style={{ margin: 20 }}>
                <Table
                  className={(this.state.dragIndex >= 0 && 'dragging-container') || ''}
                  columns={this.columns}
                  pagination={false}
                  dataSource={customContentList}
                />
              </div>
              <div>Total Record: {totalRecords}</div>
            </Col>
          </Row>
        </div>

        <Modal isOpen={openAddModal} toggle={() => { this.toggle() }}>
          <ModalHeader toggle={() => { this.toggle() }}>Add Module</ModalHeader>
          <Form onSubmit={(event) => { this.handleSubmit(event) }} autoComplete="off">
            <ModalBody>
              <Row>
                <Col xs="12">

                  <FormGroup>
                    <Label htmlFor="title">Title</Label>
                    <Input type="text" maxLength="100" id="title" placeholder="Customise Module Title" name="title" value={this.state.title} onChange={(event) => { this.handleChange(event) }} />
                    <div className="validation-error">{this.validator.message('Customise Module Title', this.state.title, 'required|title')}</div>
                  </FormGroup>
                </Col>
                <Col xs="12">
                    <FormGroup>
                      <Label htmlFor="visibility">Visibility</Label>

                      <Input
                        type="select"
                        name="visibility"
                        id="visibility"
                        value={this.state.visibility}
                        onChange={(event) => { this.handleChange(event) }}
                      >
                        <option value={0}>{'All'}</option>
                        <option value={1}>{'Parents'}</option>
                        <option value={2}>{'School'}</option>
                      </Input>
                    </FormGroup>
                  </Col>
              </Row>
            </ModalBody>
            <ModalFooter className="justify-content-left">
              <Button type="submit" size="sm" color="primary">
                Add
              </Button>
              <Button type="reset" size="sm" color="danger" onClick={() => { this.toggle() }}>Cancel</Button>
            </ModalFooter>
          </Form>
        </Modal>
        {openUpdateModal ?
          <Modal isOpen={openUpdateModal} toggle={() => { this.toggle('update') }}>
            <ModalHeader toggle={() => { this.toggle('update') }}>Update Module</ModalHeader>
            <Form onSubmit={(event) => { this.handleSubmit(event) }} autoComplete="off">
              <ModalBody>
                <Row>
                  <Col xs="12">
                    <FormGroup>
                      <Label htmlFor="title">Title</Label>
                      <Input type="text" maxLength="100" id="title" placeholder="Customise Module Title" name="title" value={this.state.title} onChange={(event) => { this.handleChange(event) }} />
                      <div className="validation-error">{this.validator.message('Customise Module Title', this.state.title, 'required|title')}</div>
                    </FormGroup>
                  </Col>
                  <Col xs="12">
                    <FormGroup>
                      <Label htmlFor="visibility">Visibility</Label>

                      <Input
                        type="select"
                        name="visibility"
                        id="visibility"
                        value={this.state.visibility}
                        onChange={(event) => { this.handleChange(event) }}
                      >
                        <option value={0}>{'All'}</option>
                        <option value={1}>{'Parents'}</option>
                        <option value={2}>{'School'}</option>
                      </Input>
                    </FormGroup>
                  </Col>
                </Row>
              </ModalBody>
              <ModalFooter className="justify-content-left">
                <Button type="submit" size="sm" color="primary">
                  Update
                </Button>{' '}
                <Button type="reset" size="sm" color="danger" onClick={() => { this.toggle('update') }}>Cancel</Button>
              </ModalFooter>
            </Form>
          </Modal>
          : null}
        {selectedObj && openDeleteConfirmModel ?
          <Modal isOpen={openDeleteConfirmModel}>
            <ModalHeader toggle={() => { this.closeModal() }}>Delete Customise Module</ModalHeader>
            <ModalBody>
              Are you sure, want to delete Customise Module?
            </ModalBody>
            <ModalFooter className="justify-content-left">
              <Button type="button" size="sm" color="primary" onClick={() => { this.deleteCustomModulelist({ customContentId: selectedObj._id }) }}>Delete</Button>{' '}
              <Button type="reset" size="sm" color="danger" onClick={() => { this.closeModal() }}>Cancel</Button>
            </ModalFooter>
          </Modal>
          : null
        }
      </div >
    );
  }
}

export default CustomModule;
