import {createAction} from 'redux-act';


export const requestDashboardData = createAction();
export const responseDashboardData = createAction();




export function getListAction(param){
  return (dispatch) => {
      dispatch(requestDashboardData([]));
  }
}
