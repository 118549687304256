
import React, { Component } from 'react';
import { Row, Col, Card, CardHeader} from "reactstrap";
import VimeoService from '../../services/vimeo';
import * as _ from 'underscore'
import { conditionallyUpdateScrollbar } from 'reactstrap/lib/utils';

import eachSeries from 'async/eachSeries';
import httpClient from '../../services/http';

class Vimeo   extends Component {

  constructor(props) {
    super(props);
    
  }

  fileChange(event){
    
    let target = event.currentTarget;
    let file = event.target.files[0]; 
    console.log('ent',file)
    VimeoService.uploadVideo({file, name: file.name, description: ''}, function(percent){
        console.log('percent',percent)
    }).then( response => {
        console.log('response', response)
    }, error => {
        console.log('error', error)
    })
  }

  multiFileUplaod(event){
    
    eachSeries(event.target.files, (element, next) => {
      console.log('element', element)
      let contentLangId = element.name.replace('.mp4', '');
      VimeoService.uploadVideo({file: element, name: element.name, description: ''}, function(percent){
        console.log('percent',percent)        
      }).then( response => {
        console.log('response', response)
        this.updateContentLang(contentLangId, response);
        next()
      }, error => {
        console.log('error', error)
        next()
      })
    })
  }


  updateContentLang(contentLangId, vimeoId){
    return httpClient.call('PILLAR','/update_vimeo_id', {contentLangId, vimeoId}  , {method: 'POST', headers: {'content-type': 'application/json'}})
  }
  




  render() {
    

    return (
        <div className="content">
            <Row>
                <Col lg={8} sm={6} >
                    <Card>
                    <CardHeader>Vimeo File uplaod </CardHeader>
                    <input id="browse" type="file" onChange={(obj) => {this.multiFileUplaod(obj)}} multiple></input>
                    </Card> 
                </Col>                
                    
            </Row>
      </div>
    );
  }
}

export default Vimeo;
