import { connect } from 'react-redux';

import SystemEmail from './SystemEmail';
import {getSystemEmails, updatedSystemEmail, getImagesForEmail} from './action';


const mapStateToProps = (state) => {
		var stateData = {
			requestProcess: state.systemEmail.requestProcess
		};

		if(state.systemEmail.systemEmailData){
			stateData['systemEmailData'] = state.systemEmail.systemEmailData;
		}

		if(state.systemEmail.updateSystemEmail){
			stateData['updateSystemEmail'] = state.systemEmail.updateSystemEmail;
		}

		if(state.systemEmail.imagesForEmail){
			stateData['imagesForEmail'] = state.systemEmail.imagesForEmail;
		}

		return stateData;
};

const mapDispatchToProps = (dispatch) => {
	return {
		_getSystemEmails: (params) => {
			dispatch(getSystemEmails(params))
		},

		_updateSystemEmail: (params) => {
			dispatch(updatedSystemEmail(params))
		},

		_getImagesForEmail: () => {
			dispatch(getImagesForEmail())
		}

	};
};


export default connect(
	mapStateToProps,
	mapDispatchToProps
)(SystemEmail);
