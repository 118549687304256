import { connect } from 'react-redux';

import SystemLanguage from './SystemLanguage';
import { getSystemLanguages, updateLanguage, addLanguage } from './action';


const mapStateToProps = (state) => {
	var stateData = {
		requestProcess: state.systemLanguage.requestProcess
	};


	if(state.systemLanguage.systemLanguageList){
		stateData['systemLanguageList'] = state.systemLanguage.systemLanguageList;
	}


	if(state.systemLanguage.languageUpdated){
		stateData['languageUpdated'] = state.systemLanguage.languageUpdated;
	}


	if(state.systemLanguage.languageAdded){
		stateData['languageAdded'] = state.systemLanguage.languageAdded;
	}

	
	return stateData;
};

const mapDispatchToProps = (dispatch) => {
	return {
		_getSystemLanguages: (params) => {
			dispatch(getSystemLanguages(params));
		},

		_updateLanguage: (params) => {
			dispatch(updateLanguage(params));
		},


		_addLanguage: (params) => {
			dispatch(addLanguage(params));
		},
	};
};


export default connect(
	mapStateToProps,
	mapDispatchToProps
)(SystemLanguage);
