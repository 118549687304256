import React, { Component } from 'react';
import { Loader, FormatTitlePosLabel } from '../../utils/common';
import DataGrid from "g6reactdatagrid";
import { Row, Col, Modal, Button, ModalHeader, ModalBody, Card, FormGroup, Label, Input, CardBody, CardFooter, Form, Nav, NavItem, NavLink } from "reactstrap";
import { AppSwitch } from '@coreui/react'
import moment from 'moment';
import Constants from '../../config/constants';
import Session from '../../utils/session';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import SimpleReactValidator from 'simple-react-validator';
import { faEdit } from '@fortawesome/free-solid-svg-icons'

import * as _ from 'underscore'

class Pillar extends Component {
  constructor(props) {
    super(props);
    this.validator = new SimpleReactValidator();
    this.session = Session.getSessionData('config');
    this.getPillars = this.getPillars.bind(this);
    this.PriorityOptions = this.PriorityOptions.bind(this);
    this.OrderOptions = this.OrderOptions.bind(this);
    this.LanguagesToRender = this.LanguagesToRender.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.UpdateSection = this.UpdateSection.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.state = {
      refreshList: false,
      totalRecord: 0,
      rowsPerPage: 10,
      pillarList: [],
      page: 0,
      sortColumn: "order",
      sortValue: "1",
      dropdownOpen: [],
      pillarData: {},
      openmodalbox: false,
      title: "",
      priority: "",
      order: 1,
      status: "",
      forGuest: "",
      tagLine: "",
      pillarId: "",
      type: "ALL",
      pillarlang: [],
      languages: [],
      signedQueryString: "",
      // systemLanguages : Session.getSessionData('config')['SYSTEM_LANGUAGES']
      systemLanguages: Session.getSessionData('languages')['languages']
    }

    this.columns = [
      { "columnName": "title", "displayName": "Title", "sort": true },
      { "columnName": "thumb", "displayName": "Icon" },
      { "columnName": "priorityText", "displayName": "Priority", "sort": true },
      { "columnName": "status", "displayName": "Status", "sort": true },
      { "columnName": "createdDate", "displayName": "Created On", "sort": true }
    ];
  }

  toggle(id) {
    let self = this;
    self.state.dropdownOpen[id] = !self.state.dropdownOpen[id]
    this.setState({});
  }

  onCloseModal = () => {
    this.setState({ openmodalbox: false });
  };

  PriorityOptions(props) {
    var priorities = [{
      value: 1, displayValue: "High"
    }, {
      value: 2, displayValue: "Medium"
    }, {
      value: 3, displayValue: "Low"
    }];
    return priorities.map(function (obj) {
      return (
        <option key={obj.value} value={obj.value} >{obj.displayValue}</option>
      );
    })
  }


  OrderOptions() {
    let orders = [];
    for (let index = 1; index <= this.state.totalRecord; index++) {
      orders.push(<option key={index} value={index} >{index}</option>);
    }

    return orders;
  }

  handleChange(event) {
    let target = event.target;
    let value;
    if (target.type === 'checkbox' && target.name === 'status') {
      value = (target.value === 'Active') ? 'Inactive' : 'Active'
    } else if (target.type === 'checkbox' && target.name === 'forGuest') {
      value = (target.value === 'Active') ? 'Inactive' : 'Active'
    } else {
      value = target.value;
    }

    const name = target.name;
    this.setState({
      [name]: value
    });
  }

  handleSubmit(event) {
    if (this.validator.allValid()) {
      let languageData = [];
      _.each(this.state.systemLanguages, function (value, index) {
        languageData.push(value['selectedRow']);
      })

      let pillarData = {
        priority: parseInt(this.state.priority),
        order: parseInt(this.state.order),
        // title : this.state.title,
        // tagLine : this.state.tagLine,
        status: (this.state.status === 'Active') ? true : false,
        forGuest: (this.state.forGuest === 'Active') ? true : false,
        pillarId: this.state.pillarId,
        pillarLanguages: languageData
      }

      this.props._updatePillar(pillarData);
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
    event.preventDefault();
  }

  setTitle(event, index, languageSlug) {
    this.state.systemLanguages[index]['selectedRow'][event.target.name] = event.target.value;
    this.state.systemLanguages[index]['selectedRow']['languageSlug'] = languageSlug;
    this.setState({ systemLanguages: this.state.systemLanguages })

  }


  LanguagesToRender() {
    let self = this;
    return self.state.systemLanguages.map(function (obj, index) {
      return (
        <div key={obj.slug}>
          <Row >
            <Col xs="12">
              <FormGroup >
                <Label htmlFor={'title' + obj.title}>{obj.title} Title</Label>
                <Input type="text" id={'title' + obj.title} placeholder="Title" name="title" value={(obj.selectedRow && obj.selectedRow['title']) ? obj.selectedRow['title'] : ""} onChange={(event) => { self.setTitle(event, index, obj.slug) }} />
                {self.state.systemLanguages[index]['selectedRow'] && <div className="validation-error">{self.validator.message('title' + obj.title, self.state.systemLanguages[index]['selectedRow']['title'], 'required')}</div>}
              </FormGroup>
            </Col>
          </Row>

          <Row >
            <Col xs="12">
              <FormGroup >
                <Label htmlFor={'descriptionText' + obj.title} Title>{obj.title} Description</Label>
                <Input type="textarea" id={'descriptionText' + obj.title} placeholder="Description" name="descriptionText" value={(obj.selectedRow && obj.selectedRow['descriptionText']) ? obj.selectedRow['descriptionText'] : ""} onChange={(event) => { self.setTitle(event, index, obj.slug) }} />
                {self.state.systemLanguages[index]['selectedRow'] && <div className="validation-error">{self.validator.message('description' + obj.title, self.state.systemLanguages[index]['selectedRow']['descriptionText'], 'required')}</div>}
              </FormGroup>
            </Col>
          </Row>

          <Row>
            <Col xs="12">
              <FormGroup>
                <Label htmlFor={'tagline' + obj.title}>{obj.title} Tagline</Label>
                <Input type="text" id={'tagline' + obj.title} placeholder="Tagline" name="tagLine" value={(obj.selectedRow && obj.selectedRow['tagLine']) ? obj.selectedRow['tagLine'] : ""} onChange={(event) => { self.setTitle(event, index, obj.slug) }} />

                {self.state.systemLanguages[index]['selectedRow'] && <div className="validation-error">{self.validator.message('tagLine' + obj.title, self.state.systemLanguages[index]['selectedRow']['tagLine'], 'required')}</div>}
              </FormGroup>
            </Col>
          </Row>
        </div>
      );
    })
  }

  UpdateSection(props) {
    let PriorityOptions = props.PriorityOptions;
    let OrderOptions = props.OrderOptions;
    let LanguagesToRender = this.LanguagesToRender;
    let { pillarlang } = this.state;
    return (
      <Modal isOpen={props.visible}>
        <ModalHeader toggle={props.closeModal}>Update Pillar</ModalHeader>
        <ModalBody>
          <Form onSubmit={this.handleSubmit}>
            <Row>
              <Col sm="12">
                <Card>
                  <CardBody>

                    {pillarlang && LanguagesToRender()}

                    <Row>
                      <Col xs="12">

                        <FormGroup>
                          <Label htmlFor="priority">Priority</Label>
                          <Input type="select" name="priority" id="priority" value={this.state.priority} onChange={this.handleChange}>
                            <PriorityOptions />
                          </Input>
                        </FormGroup>

                      </Col>
                    </Row>
                    <Row>
                      <Col xs="12">

                        <FormGroup>
                          <Label htmlFor="order">Order</Label>
                          <Input type="select" name="order" id="order" value={this.state.order} onChange={this.handleChange}>
                            <OrderOptions />
                          </Input>
                        </FormGroup>

                      </Col>
                    </Row>
                    <Row>
                      <Col xs="12">
                        <FormGroup>
                          <Label htmlFor="status">Status</Label>
                          <div>
                            <AppSwitch className={'mx-1'} variant={'pill'} color={'primary'} id="status" name="status" onChange={this.handleChange} checked={(this.state.status === 'Inactive') ? false : true} value={this.state.status} />
                          </div>
                        </FormGroup>

                      </Col>
                    </Row>

                    <Row>
                      <Col xs="12">
                        <FormGroup>
                          <Label htmlFor="status">For Guest</Label>
                          <div>
                            <AppSwitch className={'mx-1'} variant={'pill'} color={'primary'} id="forGuest" name="forGuest" onChange={this.handleChange} checked={(this.state.forGuest === 'Inactive') ? false : true} value={this.state.forGuest} />
                          </div>
                        </FormGroup>

                      </Col>
                    </Row>
                  </CardBody>
                  <CardFooter>
                    <Button type="submit" size="sm" color="primary">Submit</Button>
                    <Button type="reset" size="sm" color="danger" onClick={() => { this.onCloseModal() }}>Cancel</Button>
                  </CardFooter>
                </Card>
              </Col>

            </Row>
          </Form>
        </ModalBody>
      </Modal>
    );
  }



  componentDidMount() {
    this.getPillars(this.state.offset, this.state.rowsPerPage);
  }


  getPillars(offset, limit) {
    let { search, sortColumn, sortValue, type } = this.state;
    let param = {
      // limit: limit,
      // offset: offset,
      search: search,
      sortField: (sortColumn) ? sortColumn : "",
      sortValue: (sortValue) ? sortValue : "",
      type: type
    }
    this.props._getPillars(param);
    this.setState({
      dropdownOpen: []
    })
  }

  updatePillar(pillarObj) {
    let selectedRow = JSON.parse(JSON.stringify(pillarObj))
    let self = this;
    let pillarlang = [];
    _.each(this.state.systemLanguages, function (value, index) {
      value['selectedRow'] = (_.findWhere(selectedRow.pillarlang, { languageSlug: value['slug'] })) ? _.findWhere(selectedRow.pillarlang, { languageSlug: value['slug'] }) : {};
      self.state.systemLanguages[index]['selectedRow'] = value['selectedRow'];
      pillarlang.push(value);
    })


    this.setState({
      title: selectedRow.title,
      status: selectedRow.status,
      forGuest: selectedRow.forGuest,
      tagLine: selectedRow.tagLine,
      priority: selectedRow.priority,
      order: selectedRow.order,
      pillarId: selectedRow._id,
      systemLanguages: self.state.systemLanguages,
      pillarlang: _.groupBy(pillarlang, 'languageSlug'),
      openmodalbox: true
    });
  }

  componentWillReceiveProps(nextProps) {
    let self = this;
    let pillarList = [];
    let signedQueryString = "";
    Loader(nextProps.requestProcess);
    if (nextProps.changeStatus && nextProps.changeStatus.statusCode === 200) {
      let message = nextProps.changeStatus.statusDescription;
      self.getPillars(self.state.offset, self.state.rowsPerPage);
    }
    let bucketUrl = self.session && self.session.UTILS && self.session.UTILS.BUCKET_URL ? self.session.UTILS.BUCKET_URL : process.env.REACT_APP_CLOUDFRONT_URL;


    if (nextProps.data && nextProps.data.statusCode === 200) {
      let listData = (nextProps.data.result.length) ? nextProps.data.result : [];
      let signedQueryString = (nextProps.data.signedQueryString) ? nextProps.data.signedQueryString : '?';
      let total = nextProps['data']['totalRecords'];
      this.setState(() => {

        listData.map((element, key) => {

          element.status = element.status ? 'Active' : 'Inactive';
          element.forGuest = element.forGuest ? 'Active' : 'Inactive';

          // if (element.priority === 1) {
          //   element.priority = 'High'
          // }else if(element.priority === 2){
          //   element.priority = 'Medium'
          // }else if(element.priority === 3){
          //   element.priority = 'Low'
          // }
          element.thumb = '<div class="thumbimg"><img src = ' + bucketUrl + Constants.PILLAR.toLowerCase() + '/' + Constants.IMAGE_PLATFORM + element.image + signedQueryString + '></div>'


          element.createdDate = moment(element.createdDate).format("DD-MMM-YYYY")
          pillarList.push(element);
          return true;
        });

        let page = self.state.page;
        let limit = self.state.rowsPerPage;
        //let totalRequestedRecord = parseInt(page + 1) * parseInt(limit);
        return { pillarList: pillarList, totalRecord: total, refreshList: false, signedQueryString: signedQueryString };
      })
    }

    if (nextProps.updatedData && nextProps.updatedData.statusCode === 200) {
      let message = nextProps.updatedData.statusDescription;
      self.onCloseModal();
      self.getPillars(self.state.offset, self.state.rowsPerPage);
    }
  }

  searchPillers() {
    let self = this
    if (self.intervalInstance) { clearInterval(self.intervalInstance) }

    self.intervalInstance = setInterval(() => {
      self.setState({ page: 0, offset: 0 })
      self.getPillars(self.state.offset, self.state.rowsPerPage, self.state.sortColumn, self.state.sortValue, self.state.search);
      if (self.intervalInstance) { clearInterval(self.intervalInstance) }
    }, 1000)
  }

  changeStatus(rowData) {
    let params = {
      pillarId: rowData._id,
      title: rowData.title,
      tagLine: rowData.tagLine,
      status: rowData.status === 'Active' ? false : true,
      //forGuest: rowData.forGuest === 'Active' ? false : true,
      //forGuest: rowData.forGuest,
      priority: rowData.priority,
      pillarLanguages: rowData.pillarlang
    }
    this.props._changePillerStatus(params);
  }


  changeForGuest(rowData) {
    let params = {
      pillarId: rowData._id,
      title: rowData.title,
      tagLine: rowData.tagLine,
      status: rowData.status === 'Active' ? true : false,
      forGuest: rowData.forGuest === 'Active' ? false : true,
      priority: rowData.priority,
      pillarLanguages: rowData.pillarlang
    }
    this.props._changePillerStatus(params);
  }


  getPillarsByType(event) {
    let self = this;
    let target = event.target;
    this.setState({
      [target.name]: target.value
    });
    setTimeout(() => {
      this.getPillars(self.state.offset, self.state.rowsPerPage);
    }, 300)
  }

  render() {
    const { openmodalbox } = this.state;
    let self = this;
    let { pillarList, showTrackForm, formElement, status, page, rowsPerPage, totalRecord, pillarData, signedQueryString } = this.state;
    let totalRecordsLabel = '##TOTAL_RECORDS## ' + (totalRecord > 1 ? 'Pillars' : 'Pillar')
    let options = {
      totalRecords: totalRecord,
      totalRecordsLabel: totalRecordsLabel,
      rowsPerPage: 10000,
      page: page,
      loadMore: false,
      customColumn: {
        isActive: true,
        columnTitle: '',
        columnContent: (rowData) => {
          return (

            <div>

              <Nav>
                <NavItem>
                  <NavLink href="#" onClick={() => { this.updatePillar(rowData); }}><FontAwesomeIcon icon={faEdit} size='1x' /></NavLink>
                </NavItem>

                <NavItem title="Change Pillar status">
                  <AppSwitch className={'mx-1'} variant={'pill'} color={'primary'} label checked={rowData.status === 'Active'} onChange={() => { this.changeStatus(rowData); }} />

                </NavItem>

                <NavItem title="Change Pillar status for guest user">
                  <AppSwitch className={'mx-1'} variant={'pill'} color={'primary'} label checked={rowData.forGuest === 'Active'} onChange={() => { this.changeForGuest(rowData); }} />
                </NavItem>
              </Nav>


            </div>


          );
        }
      },

      onPageChange(pageNumber) {
        self.state.page = pageNumber;
        self.state.offset = parseInt(pageNumber) * parseInt(self.state.rowsPerPage)
        self.getPillars(self.state.offset, self.state.rowsPerPage);
      },

      onColumnSortChange: (column, direction) => {
        self.state.sortColumn = column;
        self.state.sortValue = column === 'status' ? ((direction === "ASC") ? 1 : -1) : ((direction === "ASC") ? -1 : 1);
        self.getPillars(self.state.offset, self.state.rowsPerPage, self.state.sortColumn, self.state.sortValue);

      }
    };
    let UpdateSection = this.UpdateSection;
    let PriorityOptions = this.PriorityOptions;
    let OrderOptions = this.OrderOptions;
    return (
      <div className="content">
        <Row className="pb-4">
          <Col lg={12} sm={12}>
            <h3>Pillar List</h3>
          </Col>
        </Row>
        <Row className="pb-4">
          <Col lg={3}>
            <Input type="text" id="search" placeholder="Search String" name="search" value={this.state.search} onChange={($event) => { this.setState({ search: $event.target.value }); this.searchPillers() }} />
          </Col>
          <Col lg={3}>
            <Input type="select" name="type" id="type" value={this.state.type} onChange={(event) => { this.getPillarsByType(event) }}>
              <option value="ALL">All</option>
              <option value={Constants.PILLAR}>{FormatTitlePosLabel(Constants.PILLAR)}</option>
              <option value={Constants.EMOTION}>{FormatTitlePosLabel(Constants.EMOTION)}</option>
            </Input>
          </Col>
        </Row>
        <Row>
          <Col lg={12} sm={12}>
            <UpdateSection visible={openmodalbox} closeModal={this.onCloseModal} pillarData={pillarData} PriorityOptions={PriorityOptions} OrderOptions={OrderOptions} state={self.state} handleChange={self.handleChange} />

            <div className="dataTable-wrapper pillarList_grid">
              <DataGrid
                columns={this.columns}
                options={options}
                rows={pillarList}
              />
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

export default Pillar;
