import { connect } from 'react-redux';

import SocialInsights from './SocialInsights';
import { getSocialProfileInsights, getInsightsGraphData, getCurrentSocialConnectData } from './action';


const mapStateToProps = (state) => {		
	var stateData = {
		requestProcess: state.socialInsights.requestProcess,
		insightsData: state.socialInsights.data
	};

	if(state.marketing.insightsGraphData){
		stateData['insightsGraphData'] = state.marketing.insightsGraphData;
	}

	return stateData;
};

const mapDispatchToProps = (dispatch) => {
	return {
		_getSocialProfileInsights: (params) => {
			dispatch(getSocialProfileInsights(params));
		},
		_getInsightsGraphData: (params) => {
			dispatch(getInsightsGraphData(params));	
		},
		_getCurrentSocialConnectData: (params) => {
			dispatch(getCurrentSocialConnectData(params));	
		}
	};
};


export default connect(
	mapStateToProps,
	mapDispatchToProps
)(SocialInsights);
