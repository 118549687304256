import {createAction} from 'redux-act';
import httpClient from '../../services/http';	


export const requestFeedbackData = createAction();
export const responseFeedbackData = createAction();

export const requestCloseFeedback = createAction();
export const responseCloseFeedback = createAction();




export function getFeedback(params){
  return (dispatch) => {
      dispatch(requestFeedbackData([]));
      httpClient.call('USER','/get_feedback', params, {method: 'GET', headers: {}}).then(function(response){
          dispatch(responseFeedbackData(response));
      }, function(error){
          dispatch(responseFeedbackData(error));
      });
  }
}


export function closeFeedback(params){
  return (dispatch) => {
      dispatch(requestCloseFeedback([]));
      httpClient.call('USER','/close_feedback', params, {method: 'PUT', headers: {}}).then(function(response){
          dispatch(responseCloseFeedback(response));
      }, function(error){
          dispatch(responseCloseFeedback(error));
      });
  }
}
