import { createReducer } from 'redux-act';
import {requestGetAllQuestData, responseGetAllQuestData, requestUpdateQuest, responseUpdateQuest, requestAddQuest, responseAddQuest, requestContentList, responseContentList} from './action';


const defaultState = {
	requestProcess: false,
};

const reducer = createReducer({

	[requestGetAllQuestData]: (state) => {
		return {
			requestProcess: true,
		};
	},

	[responseGetAllQuestData]: (state, params) => {
		return {
			requestProcess: false,
			questData: params,
		};
	},


	[requestUpdateQuest]: (state) => {
		return {
			requestProcess: true,
		};
	},

	[responseUpdateQuest]: (state, params) => {
		return {
			requestProcess: false,
			questUpdated: params,
		};
	},



	[requestAddQuest]: (state) => {
		return {
			requestProcess: true,
		};
	},

	[responseAddQuest]: (state, params) => {
		return {
			requestProcess: false,
			questAdded: params,
		};
	},



	[requestContentList]: (state) => {
		return {
			requestProcess: true,
		};
	},

	[responseContentList]: (state, params) => {
		return {
			requestProcess: false,
			contentList: params,
		};
	},


}, defaultState);



export default reducer
