import { createReducer } from 'redux-act';
import { requestGetDiscount, responseGetDiscount, requestDeleteDiscount, responseDeleteDiscount, requestAddDiscount, responseAddDiscount, requestGetAlldifferencesDiscount, responseGetAlldifferencesDiscount, requestChangeSubscription, responseChangeSubscription, requestGetCouponUsers, responseGetCouponUsers, responseAddCommissionPayment, requestAddCommissionPayment, requestAddUserDiscount, responseAddUserDiscount } from './action';


const defaultState = {
	requestProcess: false,
};

const reducer = createReducer({

	[requestGetDiscount]: (state) => {
		return {
			requestProcess: true,
			data: [],
		};
	},

	[responseGetDiscount]: (state, params) => {
		return {
			requestProcess: false,
			data: params,
		};
	},

	[requestDeleteDiscount]: (state) => {
		return {
			requestProcess: true,
			deleteData: [],
		};
	},

	[responseDeleteDiscount]: (state, params) => {
		return {
			requestProcess: false,
			deleteData: params,
		};
	},

	[requestAddDiscount]: (state) => {
		return {
			requestProcess: true,
			addData: [],
		};
	},

	[responseAddDiscount]: (state, params) => {
		return {
			requestProcess: false,
			addData: params,
		};
	},


	[requestGetAlldifferencesDiscount]: (state) => {
		return {
			requestProcess: true,
		};
	},

	[responseGetAlldifferencesDiscount]: (state, params) => {
		return {
			requestProcess: false,
			appstoreDifferences: params,
		};
	},

	[requestChangeSubscription]: (state) => {
		return {
			requestProcess: true
		};
	},

	[responseChangeSubscription]: (state, params) => {
		return {
			requestProcess: false,
			subscriptionChanged: params
		};
	},


	[requestGetCouponUsers]: (state) => {
		return {
			requestProcess: true
		};
	},

	[responseGetCouponUsers]: (state, params) => {
		return {
			requestProcess: false,
			couponUsers: params
		};
	},



	[requestAddCommissionPayment]: (state) => {
		return {
			requestProcess: true
		};
	},

	[responseAddCommissionPayment]: (state, params) => {
		return {
			requestProcess: false,
			paymentAdded: params
		};
	},


	[requestAddUserDiscount]: (state) => {
		return {
			requestProcess: true
		};
	},

	[responseAddUserDiscount]: (state, params) => {
		return {
			requestProcess: false,
			addUserDiscount: params
		};
	},


}, defaultState);



export default reducer
