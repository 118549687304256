import { connect } from 'react-redux';

import Category from './Category';
import { getPillarCategory, addUpdateCategory } from './action';


const mapStateToProps = (state) => {
		var stateData = {
			requestProcess: state.category.requestProcess
		};
		if(state.category.data){
			stateData['data'] = state.category.data;
		}
		if(state.category.addUpdateCategory){
			stateData['statusChange'] = state.category.addUpdateCategory;
		}
		return stateData;
};

const mapDispatchToProps = (dispatch) => {
	return {
		_getPillarCategory: (params) => {
			dispatch(getPillarCategory(params));
		},

		_changeCategoryStatus: (params) => {
			dispatch(addUpdateCategory(params))
		},


	}
};


export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Category);
