import { connect } from 'react-redux';

import SocialProfiles from './SocialProfiles';
import { getSocialProfiles, addUpdateUser, resetSocialSortUrl, disconnectSocialProfile, getMySocialProfiles, applyFacebookPageChanges, getInsightsGraphData, updateSocialProfileTags, getSocialShortLinks } from './action';


const mapStateToProps = (state) => {		
		var stateData = {
			requestProcess: state.marketing.requestProcess
		};

		if(state.marketing.socialProfiles){
			stateData['socialProfiles'] = state.marketing.socialProfiles;
		}



		if(state.marketing.addUpdateUser){
			stateData['addUpdateUser'] = state.marketing.addUpdateUser;
		}


		if(state.marketing.getSocialProfiles){
			stateData['getSocialProfiles'] = state.marketing.getSocialProfiles;
		}

		if(state.marketing.statusChanged){
			stateData['statusChanged'] = state.marketing.statusChanged;
		}


		if(state.marketing.insightsGraphData){
			stateData['insightsGraphData'] = state.marketing.insightsGraphData;
		}

		return stateData;
};

const mapDispatchToProps = (dispatch) => {
	return {
		_getSocialProfiles: (params) => {
			dispatch(getSocialProfiles(params));
		},
		_addUpdateUser: (params) => {
			dispatch(addUpdateUser(params));
		},

		_resetSocialSortUrl: (params) =>{
			dispatch(resetSocialSortUrl(params));
		},

		_disconnectSocialProfile: (params) =>{
			dispatch(disconnectSocialProfile(params));
		},

		_getMySocialProfiles: (params) => {
			dispatch(getMySocialProfiles(params));
		},

		_applyFacebookPageChanges: (params) => {
			dispatch(applyFacebookPageChanges(params));	
		},

		_updateSocialProfileTags: (params) => {
			dispatch(updateSocialProfileTags(params));	
		},

		_getInsightsGraphData: (params) => {
			dispatch(getInsightsGraphData(params));	
		},

		_getSocialShortLinks: (params)=>{
			return getSocialShortLinks(params);
		}
	};
};


export default connect(
	mapStateToProps,
	mapDispatchToProps
)(SocialProfiles);
