import { createAction } from 'redux-act';
import httpClient from '../../services/http';



export const requestGetDiscount = createAction();
export const responseGetDiscount = createAction();


export const requestDeleteDiscount = createAction();
export const responseDeleteDiscount = createAction();

export const requestAddDiscount = createAction();
export const responseAddDiscount = createAction();


export const requestGetAlldifferencesDiscount = createAction();
export const responseGetAlldifferencesDiscount = createAction();

export const requestChangeSubscription = createAction();
export const responseChangeSubscription = createAction();


export const requestGetCouponUsers = createAction();
export const responseGetCouponUsers = createAction();

export const requestAddCommissionPayment = createAction();
export const responseAddCommissionPayment = createAction();

export const requestAddUserDiscount = createAction();
export const responseAddUserDiscount = createAction();



export function getAllDiscount(params) {
    return (dispatch) => {
        dispatch(requestGetDiscount([]));
        httpClient.call('USER', '/get_discount', params, { method: 'GET', headers: {} }).then(function (response) {
            dispatch(responseGetDiscount(response));
        }, function (error) {
            dispatch(responseGetDiscount(error));
        });
    }
}

export function deleteDiscount(params) {
    return (dispatch) => {
        dispatch(requestDeleteDiscount([]));
        httpClient.call('USER', '/delete_discount', params, { method: 'PUT', headers: {} }).then(function (response) {
            dispatch(responseDeleteDiscount(response));
        }, function (error) {
            dispatch(responseDeleteDiscount(error));
        });
    }
}

export function addDiscount(params) {
    return (dispatch) => {
        dispatch(requestAddDiscount([]));
        httpClient.call('USER', '/add_discount', params, { method: 'POST', headers: {} }).then(function (response) {
            dispatch(responseAddDiscount(response));
        }, function (error) {
            dispatch(responseAddDiscount(error));
        });
    }
}


export function getAlldifferences(params) {
    return (dispatch) => {
        dispatch(requestGetAlldifferencesDiscount([]));
        httpClient.call('USER', '/get_appstore_differences', params, { method: 'GET', headers: {} }).then(function (response) {
            dispatch(responseGetAlldifferencesDiscount(response));
        }, function (error) {
            dispatch(responseGetAlldifferencesDiscount(error));
        });
    }
}


export function changeUserSubscription(params) {
    return (dispatch) => {
        dispatch(requestChangeSubscription([]));
        httpClient.call("USER", "/change_subscription_type/", params, { method: 'POST' }).then(function (response) {
            dispatch(responseChangeSubscription(response));
        }, function (error) {
            dispatch(responseChangeSubscription(error));
        });
    }
}


export function getCouponUsers(params) {
    return (dispatch) => {
        dispatch(requestGetCouponUsers([]));
        httpClient.call("USER", "/get_coupon_users/", params, { method: 'GET' }).then(function (response) {
            dispatch(responseGetCouponUsers(response));
        }, function (error) {
            dispatch(responseGetCouponUsers(error));
        });
    }
}



export function addCommissionPayment(params) {
    return (dispatch) => {
        dispatch(requestAddCommissionPayment([]));
        httpClient.call("USER", "/add_commission_payment/", params, { method: 'POST' }).then(function (response) {
            dispatch(responseAddCommissionPayment(response));
        }, function (error) {
            dispatch(responseAddCommissionPayment(error));
        });
    }
}


export function getParents(params) {
    return new Promise((resolve, reject) => {
        httpClient.call('USER', '/parent_list', params, { method: 'GET', headers: {} }).then(function (response) {
            resolve(response);
        }, function (error) {
            reject(error);
        });
    })
}


export function addUserDiscount(params) {
    return (dispatch) => {
        dispatch(requestAddUserDiscount([]));
        httpClient.call("USER", "/add_user_discount/", params, { method: 'POST' }).then(function (response) {
            dispatch(responseAddUserDiscount(response));
        }, function (error) {
            dispatch(responseAddUserDiscount(error));
        });
    }
}
