import { createReducer } from 'redux-act';
import { requestGetUserList, responseGetUserList, requestGetSegmentList, responseGetSegmentList, requestSendNotification, responseSendNotification, requestScreensSlugs, responseScreensSlugs } from './action';


const defaultState = {
	requestProcess: false,
};

const reducer = createReducer({

	[requestGetUserList]: (state) => {
		return {
			requestProcess: true,
		};
	},

	[responseGetUserList]: (state, params) => {
		return {
			requestProcess: false,
			userList: params,
		};
	},

	[requestGetSegmentList]: (state) => {
		return {
			requestProcess: true,
		};
	},

	[responseGetSegmentList]: (state, params) => {
		console.log(params,'params');
		return {
			requestProcess: false,
			notificationSegmentList: params.result && params.result.data ? params.result.data : params,
		};
	},

	[requestSendNotification]: (state) => {
		return {
			requestProcess: true,
		};
	},

	[responseSendNotification]: (state, params) => {
		return {
			requestProcess: false,
			notificationStatus: params && params.data ? params.data : params,
		};
	},



	[requestScreensSlugs]: (state) => {
		return {
			requestProcess: true,
		};
	},

	[responseScreensSlugs]: (state, params) => {
		return {
			requestProcess: false,
			screens: params && params.data ? params.data : params,
		};
	},


}, defaultState);



export default reducer