import {createAction} from 'redux-act';
import httpClient from '../../services/http';


export const requestGetParents = createAction();
export const responseGetParents = createAction();

export const requestGetChildren = createAction();
export const responseGetChildren = createAction();

export const requestChangeStatus = createAction();
export const responseChangeStatus = createAction();

export const requestVerifyUser = createAction();
export const responseVerifyUser = createAction();

export const requestSendVerificationLink = createAction();
export const responseSendVerificationLink = createAction();

export const requestChangeSubscription = createAction();
export const responseChangeSubscription = createAction();

export const requestDeleteParent = createAction();
export const responseDeleteParent = createAction();



export function getParents(params){
  return (dispatch) => {
      dispatch(requestGetParents([]));
      httpClient.call('USER','/parent_list', params  , {method: 'GET', headers: {}}).then(function(response){
          dispatch(responseGetParents(response));
      }, function(error){
          dispatch(responseGetParents(error));
      });
  }
}

export function deleteParent(params){
  return (dispatch) => {
      dispatch(requestDeleteParent([]));
      httpClient.call('USER','/delete_parent', params  , {method: 'DELETE', headers: {}}).then(function(response){
          dispatch(responseDeleteParent(response));
      }, function(error){
          dispatch(responseDeleteParent(error));
      });
  }
}

export function getChildren(rowData){
  return (dispatch) => {
      dispatch(requestGetChildren([]));
      httpClient.call("USER","/get_childs/"+rowData['_id'] ,{}, {method: 'GET'}).then(function(response){
          dispatch(responseGetChildren(response));
      }, function(error){
          dispatch(responseGetChildren(error));
      });
  }
}


export function changeUserStatus(params){
    return (dispatch) => {
        dispatch(requestChangeStatus([]));
        httpClient.call("USER","/change_status/" , params, {method: 'post'}).then(function(response){
            dispatch(responseChangeStatus(response));
        }, function(error){
            dispatch(responseChangeStatus(error));
        });
    }
}

export function changeUserRecordingStatus(params){
    return (dispatch) => {
        dispatch(requestChangeStatus([]));
        httpClient.call("USER","/change_recording_status/" , params, {method: 'post'}).then(function(response){
            dispatch(responseChangeStatus(response));
        }, function(error){
            dispatch(responseChangeStatus(error));
        });
    }
}




export function verifyUser(params){
    return (dispatch) => {
        dispatch(requestVerifyUser([]));
        httpClient.call("USER","/verify_user_directly/" , params, {method: 'post'}).then(function(response){
            dispatch(responseVerifyUser(response));
        }, function(error){
            dispatch(responseVerifyUser(error));
        });
    }
}


export function sendVerifivationLink(params){
    return (dispatch) => {
        dispatch(requestSendVerificationLink([]));
        httpClient.call("USER","/resend_verificaton_link/" , params, {method: 'PUT'}).then(function(response){
            dispatch(responseSendVerificationLink(response));
        }, function(error){
            dispatch(responseSendVerificationLink(error));
        });
    }
}


export function changeUserSubscription(params){
    return (dispatch) => {
        dispatch(requestChangeSubscription([]));
        httpClient.call("USER","/change_subscription_type/" , params, {method: 'POST'}).then(function(response){
            dispatch(responseChangeSubscription(response));
        }, function(error){
            dispatch(responseChangeSubscription(error));
        });
    }
}

export function getTokenToExportUserList(params){
    return httpClient.call("USER","/get_token_to_export_userlist/" , params, {method: 'POST'});
}

