import React, { Component } from 'react';
import { Loader,createRandomStr } from '../../utils/common';
import { Row, Col, Button, Card, CardHeader, Alert, Label, Input, CardBody } from "reactstrap";
import SimpleReactValidator from 'simple-react-validator';
import S3Client from '../../services/aws-s3/S3Client';
import httpClient from '../../services/http';

class BackgroundMusic extends Component {
  constructor(props) {
    super(props);
    this.validator = new SimpleReactValidator();
    this.state = {
      backgroundData: [],
      successMessage: null,
      errorMessage: null,
      update: false
    }
    const s3Config = {
        bucketName: process.env.REACT_APP_S3_BUCKET,
        dirName: 'backgroundMedia', /* optional */
        region: process.env.REACT_APP_AWS_DEFAULT_REGION,
        accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
        s3Url: process.env.REACT_APP_AWS_BUCKET_URL, /* optional */
    }

    this.S3Client = new S3Client(s3Config);

  }

  componentDidMount() {
    this.props._getBackgrroundMusic();
  }

  componentWillReceiveProps(nextProps) {
    //let self = this;
    //let appFeatures = [];
    
    Loader(nextProps.requestProcess);  
    if (nextProps.backgroundMusic && nextProps.backgroundMusic.statusCode === 200 && nextProps.backgroundMusic.result) {
          let backgroundMedia = nextProps.backgroundMusic && nextProps.backgroundMusic.result && nextProps.backgroundMusic.result.backgroundMedia ? nextProps.backgroundMusic.result.backgroundMedia : []
          this.setState({backgroundData: backgroundMedia});
    }

    if (nextProps && nextProps.updateData && nextProps.updateData.statusCode === 200 && this.state.update) {
        let message = nextProps.updateData && nextProps.updateData && nextProps.updateData.statsDescription ? nextProps.updateData.statsDescription : '';
        this.setState({successMessage: message, update: false});
        setTimeout(()=>{
          this.setState({successMessage: null})
        }, 5000);
    }else if (nextProps.updateData && nextProps.updateData.data && this.state.update) {
        this.setState({errorMessage: nextProps.updateData.data.statsDescription, update: false});
        setTimeout(()=>{
          this.setState({errorMessage: null})
        }, 5000);
    }
  }

  updateState(index, event){
    let type = event.target.type;
    let name = event.target.name;
    let value = event.target.value;

    if(type === 'checkbox'){
      value = event.target.checked
    }
    let backgroundData = this.state.backgroundData;
    backgroundData[index][name] = value
    backgroundData[index]['update'] = true
    this.setState({backgroundData: backgroundData}) 
  }

  setMedia(event, index) {
    //let self = this;
    let file = event.target.files[0];
    if(file.type === 'audio/mp3' || file.type === 'audio/wav' || file.type === 'audio/ogg' || file.type === 'audio/aac' || file.type === 'audio/x-m4a' || file.type === 'audio/x-ms-wma'){
      let {backgroundData} = this.state;
      backgroundData[index]['media'] = file;
      backgroundData[index]['update'] = true;
      this.setState({backgroundData: backgroundData});
    }    
  }


  updateBackgroundMedia(index){
    let {backgroundData} = this.state;
    let paramObj = backgroundData[index];
    paramObj['mediaId'] = paramObj._id;
    paramObj['title'] = paramObj.slug;
    backgroundData[index]['update'] = false;

    if(backgroundData && backgroundData[index] && backgroundData[index].media && backgroundData[index].media  instanceof File){
        Loader(true);
        this.uploadFiles(backgroundData[index].media).then((response) => {
            backgroundData[index]['url'] = response.key;
            paramObj['url'] = response.key;
            this.setState({backgroundData: backgroundData, update: true, successMessage: null, errorMessage: null}, ()=>{
              this.props._updateBackgroundMedia(paramObj);
            })  
        }, (error) => {
          this.setState({errorMessage: error, update: false});
            setTimeout(()=>{
              this.setState({errorMessage: null})
            }, 5000);
            Loader(false);  
          })
    }else{
      this.setState({backgroundData: backgroundData, update: true, successMessage: null, errorMessage: null}, ()=>{
        this.props._updateBackgroundMedia(paramObj);
      })      
    }

    
  }


  uploadFiles(fileObj) {
    let self = this;
    return new Promise((resolve, reject) => {

          self.S3Client.getPolicy().then((policyRes)=>{

            httpClient.call('PILLAR', '/get_signature', {policy: policyRes}, {method: 'GET', headers: {}}).then((signatureRes)=>{
              if(signatureRes && signatureRes.statusCode === 200 && signatureRes.result && signatureRes.result.signature){
                  createRandomStr((randString)=>{
                      let signature = signatureRes.result.signature;
                      let ext = fileObj.name.split('.')[1];

                      self.S3Client.uploadFile(fileObj, randString+'.'+ext, signature).then(data => {
                          resolve(data);
                      }).catch(err => {                  
                         reject(err);
                      })  
                  });

              }else{
                reject(signatureRes.statusDescription);
              }   

            }, (error) => {
              reject(error)
            })
          }, (error) => {
            reject(error)
          });
    })
  }

  



  render() {
     
     let {backgroundData} = this.state;

    return (
      <div className="content">
        <Card>
          <CardHeader>Background Music</CardHeader>
          <CardBody>
            <div className="dataTable-wrapper pillarList_grid">
                <Row>
                  <Col lg={3} sm={3}>
                    <Label htmlFor="categoryId">Slug</Label>
                  </Col>
                  <Col lg={2} sm={2}>
                    <Label>Version</Label>
                  </Col>
                  <Col lg={3} sm={3}>
                    <Label>Media File</Label>
                  </Col>
                  <Col lg={2} sm={2}  className="text-center">
                    <Label>Status</Label>
                  </Col>
                  <Col lg={2} sm={2}  className="text-center">
                    <Label>Action</Label>
                  </Col>
                </Row> 
                {backgroundData && backgroundData.map((element, index)=>{
                  return (
                      <Row key={index}>
                        <Col lg={3} sm={3}>
                          {element.slug}
                        </Col>
                        <Col lg={2} sm={2}>
                          <Input type="number" value={backgroundData[index].version} name="version" id="version" onChange={(event)=>{ this.updateState(index, event) }}></Input>
                          <div className="validation-error"> 
                              <div className="srv-validation-message">{this.validator.message('version', this.state.version, 'required')}</div>
                          </div>
                        </Col>
                        <Col lg={3} sm={3}>
                          <Input type="file" name="mediaFile" id="mediaFile" onChange={(event) => this.setMedia(event, index)} />
                          <div className="validation-error"> 
                              <div className="srv-validation-message">{this.validator.message('mediaFile', this.state.mediaFile, 'required')}</div>
                          </div>
                        </Col>
                        <Col lg={2} sm={2} className="text-center">
                          <Input type="checkbox" defaultValue={backgroundData[index].status ? true : false} checked={backgroundData[index].status} name="status" id="status" onChange={(event)=>{ this.updateState(index, event) }}></Input>
                        </Col>
                        <Col lg={2} sm={2} className="text-center">
                          {element && element.update && <Button type="reset" size="sm" color="danger"  onClick={() => { this.updateBackgroundMedia(index) }}>Update</Button>}
                        </Col>
                      </Row> 
                    )
                })}
            </div> 
            {(this.state.successMessage) ? <Alert color="success">{this.state.successMessage}</Alert> : null}
            {(this.state.errorMessage) ? <Alert color="danger">{this.state.errorMessage}</Alert> : null}
          </CardBody>
        </Card>

          

       </div>
    );
  }
}

export default BackgroundMusic;
