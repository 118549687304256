import React, { Component } from 'react'; 


class EmailLayout extends Component {
 

  render() {

    return (
        <div style={{height: '100%', margin: 0, padding: 0, width: '100%', msTextSizeAdjust: '100%', WebkitTextSizeAdjust: '100%'}}>
          <center>
            <table align="center" border={0} cellPadding={0} cellSpacing={0} height="100%" width="100%" id="bodyTable" style={{borderCollapse: 'collapse', msoTableLspace: '0pt', msoTableRspace: '0pt', msTextSizeAdjust: '100%', WebkitTextSizeAdjust: '100%', height: '100%', margin: 0, padding: 0, width: '100%'}}>
              <tbody><tr>
                  <td align="center" valign="top" id="bodyCell" style={{msoLineHeightRule: 'exactly', msTextSizeAdjust: '100%', WebkitTextSizeAdjust: '100%', height: '100%', margin: 0, padding: 0, width: '100%'}}>
                    <table border={0} cellPadding={0} cellSpacing={0} width="100%" style={{borderCollapse: 'collapse', msoTableLspace: '0pt', msoTableRspace: '0pt', msTextSizeAdjust: '100%', WebkitTextSizeAdjust: '100%'}}>
                      <tbody><tr>
                          <td align="center" valign="top" id="templateHeader" data-template-container style={{}}>
                            <img src="https://www.ninjafocus.com/wp-content/uploads/2019/07/email-banner.jpg" width="100%" />
                            <table align="center" border={0} cellSpacing={0} cellPadding={0} width={600} style={{width: '600px'}}>
                              <tbody><tr>
                                  <td align="center" valign="top" width={600} style={{width: '600px'}}>
                                    <table align="center" border={0} cellPadding={0} cellSpacing={0} width="100%" className="templateContainer" style={{borderCollapse: 'collapse', msoTableLspace: '0pt', msoTableRspace: '0pt', msTextSizeAdjust: '100%', WebkitTextSizeAdjust: '100%', maxWidth: '600px !important'}}>
                                      <tbody>
                                        <tr>
                                          <td valign="top" className="headerContainer" style={{background: 'transparent none no-repeat center/cover', msoLineHeightRule: 'exactly', msTextSizeAdjust: '100%', WebkitTextSizeAdjust: '100%', backgroundColor: 'transparent', backgroundImage: 'none', backgroundRepeat: 'no-repeat', backgroundPosition: 'center', backgroundSize: 'cover', borderTop: 0, borderBottom: 0, paddingTop: 0, paddingBottom: 0}}></td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </td>
                                </tr>
                              </tbody></table>
                          </td>
                        </tr>
                        <tr>
                          <td align="center" valign="top" id="templateBody" data-template-container style={{background: '#FFFFFF none no-repeat center/cover', msoLineHeightRule: 'exactly', msTextSizeAdjust: '100%', WebkitTextSizeAdjust: '100%', backgroundColor: '#FFFFFF', backgroundImage: 'none', backgroundRepeat: 'no-repeat', backgroundPosition: 'center', backgroundSize: 'cover', borderTop: 0, borderBottom: 0, paddingTop: '27px', paddingBottom: '54px'}}>
                            <table align="center" border={0} cellSpacing={0} cellPadding={0} width={600} style={{width: '600px'}}>
                              <tbody><tr>
                                  <td align="center" valign="top" width={600} style={{width: '600px'}}>
                                    <table align="center" border={0} cellPadding={0} cellSpacing={0} width="100%" className="templateContainer" style={{borderCollapse: 'collapse', msoTableLspace: '0pt', msoTableRspace: '0pt', msTextSizeAdjust: '100%', WebkitTextSizeAdjust: '100%', maxWidth: '600px !important'}}>
                                      <tbody><tr>
                                          <td valign="top" className="bodyContainer" style={{background: 'transparent none no-repeat center/cover', msoLineHeightRule: 'exactly', msTextSizeAdjust: '100%', WebkitTextSizeAdjust: '100%', backgroundColor: 'transparent', backgroundImage: 'none', backgroundRepeat: 'no-repeat', backgroundPosition: 'center', backgroundSize: 'cover', borderTop: 0, borderBottom: 0, paddingTop: 0, paddingBottom: 0}}>
                                            <table border={0} cellPadding={0} cellSpacing={0} width="100%" className="mcnTextBlock" style={{minWidth: '100%', borderCollapse: 'collapse', msoTableLspace: '0pt', msoTableRspace: '0pt', msTextSizeAdjust: '100%', WebkitTextSizeAdjust: '100%'}}>
                                              <tbody className="mcnTextBlockOuter">
                                                <tr>
                                                  <td valign="top" className="mcnTextBlockInner" style={{paddingTop: '9px', msoLineHeightRule: 'exactly', msTextSizeAdjust: '100%', WebkitTextSizeAdjust: '100%'}}>
                                                    <table align="left" border={0} cellSpacing={0} cellPadding={0} width="100%" style={{width: '100%'}}>
                                                      <tbody>
                                                        <tr>
                                                          <td valign="top" width={'600'} style={{width: 600}}>
                                                            <table align="left" border={0} cellPadding={0} cellSpacing={0} style={{maxWidth: '100%', minWidth: '100%', borderCollapse: 'collapse', msoTableLspace: '0pt', msoTableRspace: '0pt', msTextSizeAdjust: '100%', WebkitTextSizeAdjust: '100%'}} width="100%" className="mcnTextContentContainer">
                                                              <tbody>
                                                                <tr>
                                                                  <td valign="top" className="mcnTextContent" style={{paddingTop: 0, paddingRight: '18px', paddingBottom: '9px', paddingLeft: '18px', msoLineHeightRule: 'exactly', msTextSizeAdjust: '100%', WebkitTextSizeAdjust: '100%', wordBreak: 'break-word', color: '#757575', fontFamily: 'Helvetica', fontSize: '16px', lineHeight: '150%', textAlign: 'left'}}>
                                                                    <p style={{textAlign: 'center !important', margin: '10px 0', padding: 0, msoLineHeightRule: 'exactly', msTextSizeAdjust: '100%', WebkitTextSizeAdjust: '100%', color: '#757575', fontFamily: 'Helvetica', fontSize: '16px', lineHeight: '150%'}}>
                                                                      <strong>
                                                                        <span style={{fontFamily: 'lato,helvetica neue,helvetica,arial,sans-serif'}}>
                                                                          <span style={{fontSize: '26px'}}>
                                                                            <span style={{color: '#53c1a9', lineHeight: '100%'}} dangerouslySetInnerHTML={{__html: this.props.headline}}></span>
                                                                          </span>
                                                                        </span>
                                                                      </strong>
                                                                      <br />
                                                                      <br />
                                                                      <span dangerouslySetInnerHTML={{__html: this.props.subHeadline}}></span>
                                                                    </p>
                                                                  </td>
                                                                </tr>
                                                              </tbody>
                                                            </table>
                                                          </td>
                                                        </tr>
                                                      </tbody>
                                                    </table>
                                                  </td>
                                                </tr>
                                              </tbody>
                                            </table>
                                            <span dangerouslySetInnerHTML={{__html: this.props.content}}></span>
                                          </td>
                                        </tr>
                                      </tbody></table>
                                  </td>
                                </tr>
                              </tbody></table>
                          </td>
                        </tr>
                        <tr>
                          <td align="center" valign="top" id="templateFooter" data-template-container style={{background: '#333333 none no-repeat center/cover', msoLineHeightRule: 'exactly', msTextSizeAdjust: '100%', WebkitTextSizeAdjust: '100%', backgroundColor: '#333333', backgroundImage: 'none', backgroundRepeat: 'no-repeat', backgroundPosition: 'center', backgroundSize: 'cover', borderTop: 0, borderBottom: 0, paddingTop: '45px', paddingBottom: '63px'}}>
                            <table align="center" border={0} cellSpacing={0} cellPadding={0} width={600} style={{width: '600px'}}>
                              <tbody><tr>
                                  <td align="center" valign="top" width={600} style={{width: '600px'}}>
                                    <table align="center" border={0} cellPadding={0} cellSpacing={0} width="100%" className="templateContainer" style={{borderCollapse: 'collapse', msoTableLspace: '0pt', msoTableRspace: '0pt', msTextSizeAdjust: '100%', WebkitTextSizeAdjust: '100%', maxWidth: '600px !important'}}>
                                      <tbody><tr>
                                          <td valign="top" className="footerContainer" style={{background: 'transparent none no-repeat center/cover', msoLineHeightRule: 'exactly', msTextSizeAdjust: '100%', WebkitTextSizeAdjust: '100%', backgroundColor: 'transparent', backgroundImage: 'none', backgroundRepeat: 'no-repeat', backgroundPosition: 'center', backgroundSize: 'cover', borderTop: 0, borderBottom: 0, paddingTop: 0, paddingBottom: 0}}>
                                            <table border={0} cellPadding={0} cellSpacing={0} width="100%" className="mcnFollowBlock" style={{minWidth: '100%', borderCollapse: 'collapse', msoTableLspace: '0pt', msoTableRspace: '0pt', msTextSizeAdjust: '100%', WebkitTextSizeAdjust: '100%'}}>
                                              <tbody className="mcnFollowBlockOuter">
                                                <tr>
                                                  <td align="center" valign="top" style={{padding: '9px', msoLineHeightRule: 'exactly', msTextSizeAdjust: '100%', WebkitTextSizeAdjust: '100%'}} className="mcnFollowBlockInner">
                                                    <table border={0} cellPadding={0} cellSpacing={0} width="100%" className="mcnFollowContentContainer" style={{minWidth: '100%', borderCollapse: 'collapse', msoTableLspace: '0pt', msoTableRspace: '0pt', msTextSizeAdjust: '100%', WebkitTextSizeAdjust: '100%'}}>
                                                      <tbody>
                                                        <tr>
                                                          <td align="center" style={{paddingLeft: '9px', paddingRight: '9px', msoLineHeightRule: 'exactly', msTextSizeAdjust: '100%', WebkitTextSizeAdjust: '100%'}}>
                                                            <table border={0} cellPadding={0} cellSpacing={0} width="100%" style={{minWidth: '100%', borderCollapse: 'collapse', msoTableLspace: '0pt', msoTableRspace: '0pt', msTextSizeAdjust: '100%', WebkitTextSizeAdjust: '100%'}} className="mcnFollowContent">
                                                              <tbody>
                                                                <tr>
                                                                  <td align="center" valign="top" style={{paddingTop: '9px', paddingRight: '9px', paddingLeft: '9px', msoLineHeightRule: 'exactly', msTextSizeAdjust: '100%', WebkitTextSizeAdjust: '100%'}}>
                                                                    <table align="center" border={0} cellPadding={0} cellSpacing={0} style={{borderCollapse: 'collapse', msoTableLspace: '0pt', msoTableRspace: '0pt', msTextSizeAdjust: '100%', WebkitTextSizeAdjust: '100%'}}>
                                                                      <tbody>
                                                                        <tr>
                                                                          <td align="center" valign="top" style={{msoLineHeightRule: 'exactly', msTextSizeAdjust: '100%', WebkitTextSizeAdjust: '100%'}}>
                                                                            <table align="center" border={0} cellSpacing={0} cellPadding={0}>
                                                                              <tbody><tr>
                                                                                  <td align="center" valign="top">
                                                                                    <table align="left" border={0} cellPadding={0} cellSpacing={0} style={{display: 'inline', borderCollapse: 'collapse', msoTableLspace: '0pt', msoTableRspace: '0pt', msTextSizeAdjust: '100%', WebkitTextSizeAdjust: '100%'}}>
                                                                                      <tbody>
                                                                                        <tr>
                                                                                          <td valign="top" style={{paddingRight: '10px', paddingBottom: '9px', msoLineHeightRule: 'exactly', msTextSizeAdjust: '100%', WebkitTextSizeAdjust: '100%'}} className="mcnFollowContentItemContainer">
                                                                                            <table border={0} cellPadding={0} cellSpacing={0} width="100%" className="mcnFollowContentItem" style={{borderCollapse: 'collapse', msoTableLspace: '0pt', msoTableRspace: '0pt', msTextSizeAdjust: '100%', WebkitTextSizeAdjust: '100%'}}>
                                                                                              <tbody>
                                                                                                <tr>
                                                                                                  <td align="left" valign="middle" style={{paddingTop: '5px', paddingRight: '10px', paddingBottom: '5px', paddingLeft: '9px', msoLineHeightRule: 'exactly', msTextSizeAdjust: '100%', WebkitTextSizeAdjust: '100%'}}>
                                                                                                    <table align="left" border={0} cellPadding={0} cellSpacing={0} style={{borderCollapse: 'collapse', msoTableLspace: '0pt', msoTableRspace: '0pt', msTextSizeAdjust: '100%', WebkitTextSizeAdjust: '100%'}}>
                                                                                                      <tbody>
                                                                                                        <tr>
                                                                                                          <td align="center" valign="middle" width={24} className="mcnFollowIconContent" style={{msoLineHeightRule: 'exactly', msTextSizeAdjust: '100%', WebkitTextSizeAdjust: '100%'}}>
                                                                                                            <a href="http://www.facebook.com/NinjaFocusInc" target="_blank" style={{msoLineHeightRule: 'exactly', msTextSizeAdjust: '100%', WebkitTextSizeAdjust: '100%'}} rel="noreferrer"><img src="https://cdn-images.mailchimp.com/icons/social-block-v2/outline-light-facebook-48.png" alt="Facebook" style={{display: 'block', border: 0, height: 'auto', outline: 'none', textDecoration: 'none', msInterpolationMode: 'bicubic'}} height={24} width={24} /></a>
                                                                                                          </td>
                                                                                                        </tr>
                                                                                                      </tbody>
                                                                                                    </table>
                                                                                                  </td>
                                                                                                </tr>
                                                                                              </tbody>
                                                                                            </table>
                                                                                          </td>
                                                                                        </tr>
                                                                                      </tbody>
                                                                                    </table>
                                                                                  </td>
                                                                                  <td align="center" valign="top">
                                                                                    <table align="left" border={0} cellPadding={0} cellSpacing={0} style={{display: 'inline', borderCollapse: 'collapse', msoTableLspace: '0pt', msoTableRspace: '0pt', msTextSizeAdjust: '100%', WebkitTextSizeAdjust: '100%'}}>
                                                                                      <tbody>
                                                                                        <tr>
                                                                                          <td valign="top" style={{paddingRight: '10px', paddingBottom: '9px', msoLineHeightRule: 'exactly', msTextSizeAdjust: '100%', WebkitTextSizeAdjust: '100%'}} className="mcnFollowContentItemContainer">
                                                                                            <table border={0} cellPadding={0} cellSpacing={0} width="100%" className="mcnFollowContentItem" style={{borderCollapse: 'collapse', msoTableLspace: '0pt', msoTableRspace: '0pt', msTextSizeAdjust: '100%', WebkitTextSizeAdjust: '100%'}}>
                                                                                              <tbody>
                                                                                                <tr>
                                                                                                  <td align="left" valign="middle" style={{paddingTop: '5px', paddingRight: '10px', paddingBottom: '5px', paddingLeft: '9px', msoLineHeightRule: 'exactly', msTextSizeAdjust: '100%', WebkitTextSizeAdjust: '100%'}}>
                                                                                                    <table align="left" border={0} cellPadding={0} cellSpacing={0} style={{borderCollapse: 'collapse', msoTableLspace: '0pt', msoTableRspace: '0pt', msTextSizeAdjust: '100%', WebkitTextSizeAdjust: '100%'}}>
                                                                                                      <tbody>
                                                                                                        <tr>
                                                                                                          <td align="center" valign="middle" width={24} className="mcnFollowIconContent" style={{msoLineHeightRule: 'exactly', msTextSizeAdjust: '100%', WebkitTextSizeAdjust: '100%'}}>
                                                                                                            <a href="http://www.twitter.com/NinjaFocusInc" target="_blank" style={{msoLineHeightRule: 'exactly', msTextSizeAdjust: '100%', WebkitTextSizeAdjust: '100%'}} rel="noreferrer"><img src="https://cdn-images.mailchimp.com/icons/social-block-v2/outline-light-twitter-48.png" alt="Twitter" style={{display: 'block', border: 0, height: 'auto', outline: 'none', textDecoration: 'none', msInterpolationMode: 'bicubic'}} height={24} width={24} /></a>
                                                                                                          </td>
                                                                                                        </tr>
                                                                                                      </tbody>
                                                                                                    </table>
                                                                                                  </td>
                                                                                                </tr>
                                                                                              </tbody>
                                                                                            </table>
                                                                                          </td>
                                                                                        </tr>
                                                                                      </tbody>
                                                                                    </table>
                                                                                  </td>
                                                                                  <td align="center" valign="top">
                                                                                    <table align="left" border={0} cellPadding={0} cellSpacing={0} style={{display: 'inline', borderCollapse: 'collapse', msoTableLspace: '0pt', msoTableRspace: '0pt', msTextSizeAdjust: '100%', WebkitTextSizeAdjust: '100%'}}>
                                                                                      <tbody>
                                                                                        <tr>
                                                                                          <td valign="top" style={{paddingRight: '10px', paddingBottom: '9px', msoLineHeightRule: 'exactly', msTextSizeAdjust: '100%', WebkitTextSizeAdjust: '100%'}} className="mcnFollowContentItemContainer">
                                                                                            <table border={0} cellPadding={0} cellSpacing={0} width="100%" className="mcnFollowContentItem" style={{borderCollapse: 'collapse', msoTableLspace: '0pt', msoTableRspace: '0pt', msTextSizeAdjust: '100%', WebkitTextSizeAdjust: '100%'}}>
                                                                                              <tbody>
                                                                                                <tr>
                                                                                                  <td align="left" valign="middle" style={{paddingTop: '5px', paddingRight: '10px', paddingBottom: '5px', paddingLeft: '9px', msoLineHeightRule: 'exactly', msTextSizeAdjust: '100%', WebkitTextSizeAdjust: '100%'}}>
                                                                                                    <table align="left" border={0} cellPadding={0} cellSpacing={0} style={{borderCollapse: 'collapse', msoTableLspace: '0pt', msoTableRspace: '0pt', msTextSizeAdjust: '100%', WebkitTextSizeAdjust: '100%'}}>
                                                                                                      <tbody>
                                                                                                        <tr>
                                                                                                          <td align="center" valign="middle" width={24} className="mcnFollowIconContent" style={{msoLineHeightRule: 'exactly', msTextSizeAdjust: '100%', WebkitTextSizeAdjust: '100%'}}>
                                                                                                            <a href="http://www.instagram.com/ninjafocus_" target="_blank" style={{msoLineHeightRule: 'exactly', msTextSizeAdjust: '100%', WebkitTextSizeAdjust: '100%'}} rel="noreferrer"><img src="https://cdn-images.mailchimp.com/icons/social-block-v2/outline-light-instagram-48.png" alt="Link" style={{display: 'block', border: 0, height: 'auto', outline: 'none', textDecoration: 'none', msInterpolationMode: 'bicubic'}} height={24} width={24}  /></a>
                                                                                                          </td>
                                                                                                        </tr>
                                                                                                      </tbody>
                                                                                                    </table>
                                                                                                  </td>
                                                                                                </tr>
                                                                                              </tbody>
                                                                                            </table>
                                                                                          </td>
                                                                                        </tr>
                                                                                      </tbody>
                                                                                    </table>
                                                                                  </td>
                                                                                  <td align="center" valign="top">
                                                                                    <table align="left" border={0} cellPadding={0} cellSpacing={0} style={{display: 'inline', borderCollapse: 'collapse', msoTableLspace: '0pt', msoTableRspace: '0pt', msTextSizeAdjust: '100%', WebkitTextSizeAdjust: '100%'}}>
                                                                                      <tbody>
                                                                                        <tr>
                                                                                          <td valign="top" style={{paddingRight: 0, paddingBottom: '9px', msoLineHeightRule: 'exactly', msTextSizeAdjust: '100%', WebkitTextSizeAdjust: '100%'}} className="mcnFollowContentItemContainer">
                                                                                            <table border={0} cellPadding={0} cellSpacing={0} width="100%" className="mcnFollowContentItem" style={{borderCollapse: 'collapse', msoTableLspace: '0pt', msoTableRspace: '0pt', msTextSizeAdjust: '100%', WebkitTextSizeAdjust: '100%'}}>
                                                                                              <tbody>
                                                                                                <tr>
                                                                                                  <td align="left" valign="middle" style={{paddingTop: '5px', paddingRight: '10px', paddingBottom: '5px', paddingLeft: '9px', msoLineHeightRule: 'exactly', msTextSizeAdjust: '100%', WebkitTextSizeAdjust: '100%'}}>
                                                                                                    <table align="left" border={0} cellPadding={0} cellSpacing={0} style={{borderCollapse: 'collapse', msoTableLspace: '0pt', msoTableRspace: '0pt', msTextSizeAdjust: '100%', WebkitTextSizeAdjust: '100%'}}>
                                                                                                      <tbody>
                                                                                                        <tr>
                                                                                                          <td align="center" valign="middle" width={24} className="mcnFollowIconContent" style={{msoLineHeightRule: 'exactly', msTextSizeAdjust: '100%', WebkitTextSizeAdjust: '100%'}}>
                                                                                                            <a href="https://www.ninjafocus.com" target="_blank" style={{msoLineHeightRule: 'exactly', msTextSizeAdjust: '100%', WebkitTextSizeAdjust: '100%'}} rel="noreferrer"><img src="https://cdn-images.mailchimp.com/icons/social-block-v2/outline-light-link-48.png" alt="Website" style={{display: 'block', border: 0, height: 'auto', outline: 'none', textDecoration: 'none', msInterpolationMode: 'bicubic'}} height={24} width={24}  /></a>
                                                                                                          </td>
                                                                                                        </tr>
                                                                                                      </tbody>
                                                                                                    </table>
                                                                                                  </td>
                                                                                                </tr>
                                                                                              </tbody>
                                                                                            </table>
                                                                                          </td>
                                                                                        </tr>
                                                                                      </tbody>
                                                                                    </table>
                                                                                  </td>
                                                                                </tr>
                                                                              </tbody></table>
                                                                          </td>
                                                                        </tr>
                                                                      </tbody>
                                                                    </table>
                                                                  </td>
                                                                </tr>
                                                              </tbody>
                                                            </table>
                                                          </td>
                                                        </tr>
                                                      </tbody>
                                                    </table>
                                                  </td>
                                                </tr>
                                              </tbody>
                                            </table>
                                            <table border={0} cellPadding={0} cellSpacing={0} width="100%" className="mcnTextBlock" style={{minWidth: '100%', borderCollapse: 'collapse', msoTableLspace: '0pt', msoTableRspace: '0pt', msTextSizeAdjust: '100%', WebkitTextSizeAdjust: '100%'}}>
                                              <tbody className="mcnTextBlockOuter">
                                                <tr>
                                                  <td valign="top" className="mcnTextBlockInner" style={{paddingTop: '9px', msoLineHeightRule: 'exactly', msTextSizeAdjust: '100%', WebkitTextSizeAdjust: '100%'}}>
                                                    <table align="left" border={0} cellSpacing={0} cellPadding={0} width="100%" style={{width: '100%'}}>
                                                      <tbody><tr>
                                                          <td valign="top" width={600} style={{width: '600px'}}>
                                                            <table align="left" border={0} cellPadding={0} cellSpacing={0} style={{maxWidth: '100%', minWidth: '100%', borderCollapse: 'collapse', msoTableLspace: '0pt', msoTableRspace: '0pt', msTextSizeAdjust: '100%', WebkitTextSizeAdjust: '100%'}} width="100%" className="mcnTextContentContainer">
                                                              <tbody>
                                                                <tr>
                                                                  <td valign="top" className="mcnTextContent" style={{paddingTop: 0, paddingRight: '18px', paddingBottom: '9px', paddingLeft: '18px', msoLineHeightRule: 'exactly', msTextSizeAdjust: '100%', WebkitTextSizeAdjust: '100%', wordBreak: 'break-word', color: '#FFFFFF', fontFamily: 'Helvetica', fontSize: '12px', lineHeight: '150%', textAlign: 'center'}}>
                                                                    <em>Copyright © 2019 NINJA FOCUS, INC., All rights reserved.</em>                                                                                
                                                                    <br />
                                                                    <strong>Our mailing address is:</strong>
                                                                    <br />16624 N. 90th Street Suite 111, Scottsdale, AZ 85260
                                                                    <br />
                                                                    <br /> Want to change how you receive these emails?
                                                                    <br /> You can <a href="https://www.ninjafocus.com/contact-us/" style={{msoLineHeightRule: 'exactly', msTextSizeAdjust: '100%', WebkitTextSizeAdjust: '100%', color: '#FFFFFF', fontWeight: 'normal', textDecoration: 'underline'}}>update your preferences</a>.
                                                                  </td>
                                                                </tr>
                                                              </tbody>
                                                            </table>
                                                          </td>
                                                        </tr>
                                                      </tbody></table>
                                                  </td>
                                                </tr>
                                              </tbody>
                                            </table>
                                            <table border={0} cellPadding={0} cellSpacing={0} width="100%" className="mcnDividerBlock" style={{minWidth: '100%', borderCollapse: 'collapse', msoTableLspace: '0pt', msoTableRspace: '0pt', msTextSizeAdjust: '100%', WebkitTextSizeAdjust: '100%', tableLayout: 'fixed !important'}}>
                                              <tbody className="mcnDividerBlockOuter">
                                                <tr>
                                                  <td className="mcnDividerBlockInner" style={{minWidth: '100%', padding: '18px', msoLineHeightRule: 'exactly', msTextSizeAdjust: '100%', WebkitTextSizeAdjust: '100%'}}>
                                                    <table className="mcnDividerContent" border={0} cellPadding={0} cellSpacing={0} width="100%" style={{minWidth: '100%', borderTop: '2px solid #505050', borderCollapse: 'collapse', msoTableLspace: '0pt', msoTableRspace: '0pt', msTextSizeAdjust: '100%', WebkitTextSizeAdjust: '100%'}}>
                                                      <tbody>
                                                        <tr>
                                                          <td style={{msoLineHeightRule: 'exactly', msTextSizeAdjust: '100%', WebkitTextSizeAdjust: '100%'}}>
                                                            <span />
                                                          </td>
                                                        </tr>
                                                      </tbody>
                                                    </table>
                                                  </td>
                                                </tr>
                                              </tbody>
                                            </table>
                                          </td>
                                        </tr>
                                      </tbody></table>
                                  </td>
                                </tr>
                              </tbody></table>
                          </td>
                        </tr>
                      </tbody></table>
                  </td>
                </tr>
              </tbody></table>
          </center>
       </div>
    );
  }
}

export default EmailLayout;
