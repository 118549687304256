import React, { Component } from 'react';
import { Loader } from '../../utils/common';
import DataGrid from "g6reactdatagrid";
import { Row, Col, Modal, Button, ModalHeader, ModalBody, ModalFooter, Input, Nav, NavItem, NavLink } from "reactstrap";
import { AppSwitch } from '@coreui/react'  
import AdminUsersForm from './AdminUsersForm';  
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faTrashAlt } from '@fortawesome/free-solid-svg-icons'


class AdminUsers extends Component {

  constructor(props) {
    super(props);
    this.timeout = null;
    this.state = {
      adminUserList : [],
      totalRows: 0,
      rowsPerPage: 10,
      page:0,
      limit : 10,
      offset : 0,      
      openAddAdminUserModal: false,
      openDeleteAdminUserModal: false,
      selectedObj: null,
      searchString: '',
      statusType:'',
      roleType:'',
      sortColumn: '',
      sortValue: '',
      deleteRowData: {},
      visible: false
    }
    this.adminUserColumns = [
      { "columnName": "name", "displayName": "Full Name", sort : true},
      { "columnName": "authorName", "displayName": "Author Name", sort : true},
      { "columnName": "email", "displayName": "Email", sort : true},
      { "columnName": "rolesLabel", "displayName": "Role", sort : true},     
      { "columnName": "statusLabel", "displayName": "Status", sort : true},
    ];
  }

  componentDidMount() {
    this.getAllAdminUsers();
  }

  

  componentWillReceiveProps(nextProps) {
    let self = this;
    //let adminUserList = [];
    Loader(nextProps.requestProcess);    
    if ((nextProps.adminUserData && nextProps.adminUserData.statusCode === 200)) {
    
      let adminUserList = [];
      let totalRows = 0;
      if (nextProps.adminUserData && nextProps.adminUserData.result) {   

        nextProps.adminUserData.result.map((item, index) => {        
          nextProps.adminUserData.result[index]['statusLabel'] = item.status ? 'Active' : 'Inactive';
          nextProps.adminUserData.result[index]['rolesLabel'] = item.roles[0];
          nextProps.adminUserData.result[index]['status'] = item.status ? true : false
      
        })
        adminUserList = nextProps.adminUserData.result;
        totalRows = nextProps.adminUserData.totalRecords;        
      }
      self.setState({adminUserList : adminUserList, totalRows: totalRows});  
    }
    
    if (nextProps.addUpdateData && nextProps.addUpdateData.data && nextProps.addUpdateData.data.statusCode === 200) {
      self.closeModal();
      self.getAllAdminUsers();
    }

    if(nextProps.addUpdateData) {
      if(nextProps.addUpdateData.statusCode === 200) {
        self.closeModal();
        self.getAllAdminUsers()
      }else {
        self.setState({addAdminUserPopupError : (nextProps.addUpdateData && nextProps.addUpdateData.data && nextProps.addUpdateData.data.statusDescription) ? nextProps.addUpdateData.data.statusDescription : ""});
      }
    }
  }



  getAllAdminUsers() {
    let {limit, offset, searchString, statusType, roleType, sortColumn, sortValue} = this.state;
    let updatedSortColumn = sortColumn;
    if(sortColumn === 'statusLabel'){
      updatedSortColumn = 'status';
    }
    if(sortColumn === 'rolesLabel'){
      updatedSortColumn = 'roles';
    }
    
    let params = {
      roleType,
      statusType,
      search: searchString,
      sortField: updatedSortColumn,
      sortValue: sortValue,
      limit : limit,
      offset : offset
    }
    this.props._getAllAdminUsers(params);
  }

  addAdminUserModal(event, rowData) {   
   
    this.setState({
      openAddAdminUserModal : true,
      selectedObj: rowData,
    })
  }

  closeModal(){
    this.setState({openDeleteAdminUserModal: false, openAddAdminUserModal: false, selectedObj : {}, deleteRowData: {}, visible: false });
  }


  

  handleChange(event) {
    let _self = this;
    let target = event.target;
    let name = target.name;
    let value;    
    if (target.type ==='checkbox') {
      value = (_self.state[name]) ? false : true
    }else {
      value = target.value;
    }
    let stateData = {[name]: value};
    if(name === 'searchString'){
      stateData['offset'] = 0;
      stateData['page'] = 0;
    }
    _self.setState(stateData);
    clearTimeout(_self.timeout);
    if(name === 'searchString'){      
      _self.timeout = setTimeout(function() {
        _self.getAllAdminUsers();
      }, 1000);
    }
  }

  addUpdateAdminUser(adminUserObj) {
    this.props._addUpdateAdminUser(adminUserObj);
    //this.setState({addDiscountPopupError : ""});
    //event.preventDefault();
  }

  changeStatus(rowData){
    rowData['status'] = rowData['status'] ? false : true;
    this.props._addUpdateAdminUser(rowData);
  }
  deleteContent(rowObj){
    this.setState({deleteRowData: rowObj, visible: true})
  }
 
  confirmDelete(){
    let {deleteRowData} = this.state;
    if(deleteRowData && Object.keys(deleteRowData).length > 0){
      this.props._deleteAdminUser({_id: deleteRowData._id});
      setTimeout(()=>{this.closeModal();}, 1000);
    }
  }

  render() {
    let self = this;
    let { adminUserList, page, rowsPerPage, contentData, addUpdateData, totalRows, selectedObj, openAddAdminUserModal, addAdminUserPopupError, systemLanguages, searchString, visible } = this.state;


    let totalRecordsLabel = '##TOTAL_RECORDS## '+(totalRows && totalRows.length > 1) ? 'Users' : 'User';

    let adminUsersOptions = {
      totalRecords: totalRows,
      totalRecordsLabel: totalRecordsLabel,
      rowsPerPage: rowsPerPage,
      page: page,
      loadMore: false,
      customColumn: {
        isActive: true,
        columnTitle: 'Action',
        columnContent: (rowData) => {
          console.log(rowData.roles.indexOf('ADMIN') === -1)
          return (
            <div>            
              <Nav>
                <NavItem>
                  <NavLink href="Javascript:void(0);" onClick={(event) => { this.addAdminUserModal(event, rowData); }}><FontAwesomeIcon icon={faEdit} size='lg' /> </NavLink>
                </NavItem>
                {rowData.roles.indexOf('ADMIN') === -1 &&               
                <NavItem>
                  <NavLink href="/" onClick={(e) => { this.deleteContent(rowData); e.preventDefault();}}><FontAwesomeIcon icon={faTrashAlt}  size='lg' /></NavLink>
                </NavItem>
                 }
                {rowData.roles.indexOf('ADMIN') === -1 &&
                <NavItem>
                  <AppSwitch className={'mx-1'} variant={'pill'} color={'primary'} label checked={rowData.status} onChange={() => { this.changeStatus(rowData); }} />
                </NavItem>
                }
              </Nav>
            </div>
          );
        },        
      },
      onPageChange(pageNumber) {
        self.setState({page : pageNumber, offset:  parseInt(pageNumber) * parseInt(self.state.limit)});
        setTimeout(() => {
          self.getAllAdminUsers();
        }, 200)  

      }, 
      onColumnSortChange: (column, direction) => {       
        direction = (direction === "ASC") ? -1 : 1;
        self.setState({sortColumn: column, sortValue: direction}, ()=>{self.getAllAdminUsers();})

      }
    };
    return (
      <div className="content">
        
        <Row className="pb-4">
          <Col lg={12} sm={12}>
            <Row className="pb-4">
              <Col lg={12} sm={12}>
                <h3>Admin Users</h3>                 
              </Col>             
            </Row>
            <Row className="mb-4">
                    <Col sm={3}>
                    <Input type="text" id="searchString" placeholder="Search by Email" name="searchString" value={searchString} 
                          onChange={(event)=>{
                            this.handleChange(event)
                          }} />
                    </Col>                   
                    <Col sm={3}>
                      <Input type="select" name="roleType" id="roleType" 
                        value={this.state.roleType} 
                        onChange={(event)=>{
                          this.setState({
                            roleType: event.target.value,
                            offset: 0,
                            page: 0
                          }, 
                          ()=>{this.getAllAdminUsers()});
                        }}>
                        <option value="">Filter By Role: All</option>
                        <option value="SUB_ADMIN">Sub Admin</option>
                        <option value="ANALYTICS">Analytics</option>
                        <option value="AUTHOR">Author</option>
                        <option value="MARKETING">Marketing</option>
                      </Input>
                    </Col>
                    <Col sm={3}>
                      <Input type="select" name="statusType" id="statusType" 
                        value={this.state.statusType} 
                        onChange={(event)=>{
                          this.setState({
                            statusType: event.target.value,
                            offset: 0,
                            page: 0
                          }, 
                          ()=>{this.getAllAdminUsers()});
                        }}>
                        <option value="">Filter By Status: All</option>
                        <option value="true">Active</option>
                        <option value="false">Inactive</option>
                      </Input>
                    </Col>
                    <Col sm={3}><button className="btn btn-primary" color="link" onClick={() => this.addAdminUserModal()}>Add New User</button></Col>
                  </Row>
              {openAddAdminUserModal && 
              
                  <AdminUsersForm 
                    contentData={contentData} 
                    systemLanguages={systemLanguages} 
                    addUpdateData={addUpdateData} 
                    {...this.props} 
                    openAddAdminUserModal={openAddAdminUserModal} 
                    formData={selectedObj} 
                    closeModal={()=>{this.closeModal()}} 
                    addUpdateAdminUser={(adminUsersObj) => {this.addUpdateAdminUser(adminUsersObj)}}  
                    addAdminUserPopupError={addAdminUserPopupError}/>
              
              }             
              

              <div className="dataTable-wrapper pillarList_grid">
                {<DataGrid
                  columns={this.adminUserColumns}
                  options={adminUsersOptions}
                  rows={ adminUserList }
                />}
              </div> 
          </Col>
        </Row>
        <Modal isOpen={visible}>
            <ModalHeader toggle={()=>{this.closeModal(false)}}>Delete User</ModalHeader>
            <ModalBody>
              Are you sure, want to delete User?
            </ModalBody>
            <ModalFooter className="justify-content-left">
              <Button type="button" size="sm" color="primary" onClick={()=>{this.confirmDelete()}}>Delete</Button>{' '}
              <Button type="reset" size="sm" color="danger"  onClick={() => { this.closeModal() }}>Cancel</Button>
            </ModalFooter>
        </Modal>
      </div>

      
    );
  }
}

export default AdminUsers;
