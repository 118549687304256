import { createReducer } from 'redux-act';
import {requestVersionData, responseVersionData, requestServicesData, responseServicesData, requestupdateBuildVersion, responserequestupdateBuildVersion} from './action';


const defaultState = {
	requestProcess: false,
};

const reducer = createReducer({

	[requestVersionData]: (state) => {
		return {
			requestProcess: true,
			data: [],
		};
	},

	[responseVersionData]: (state, params) => {
		return {
			requestProcess: false,
			data: params,
		};
	},




	[requestServicesData]: (state) => {
		return {
			requestProcess: true,
			getAllservices: [],
		};
	},

	[responseServicesData]: (state, params) => {
		return {
			requestProcess: false,
			getAllservices: params,
		};
	},



	[requestupdateBuildVersion]: (state) => {
		return {
			requestProcess: true,
			updateBuildVersion: [],
		};
	},

	[responserequestupdateBuildVersion]: (state, params) => {
		return {
			requestProcess: false,
			updateBuildVersion: params,
		};
	},


}, defaultState);



export default reducer
