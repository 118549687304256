import React, { Component } from 'react';
import { Loader } from '../../utils/common';
import { Row, Col, Modal, Button, ModalHeader, ModalBody, ModalFooter, FormGroup, Label, Input, Form, Alert } from "reactstrap";
import moment from 'moment';
import SimpleReactValidator from 'simple-react-validator';
import Constants from '../../config/constants';
import { createCouponCode } from '../../utils/common';
import { getParents } from './action';
import Autocomplete from 'react-autocomplete';

import DatePicker from 'react-date-picker';

import * as _ from 'underscore'

class AddUserSpecificDiscount extends Component {
  constructor(props) {
    super(props);
    this.timeout = null;
    this.validator = new SimpleReactValidator();
    this.state = {
      couponCode: createCouponCode(),
      discount: "15",
      lifetimeDiscount: "0",
      monthlyDiscount: "0",
      yearlyDiscount: "0",
      discountType: Constants.PERCENTAGE,
      maxDiscount: Constants.MAX_DISCOUNT_PERCRNTAGE,
      description: "",
      email: "",
      activeTab: this.props.activeTab,
      startOn: new Date(moment().format("YYYY-MM-DD")),
      expiredOn: new Date(moment().add(1, 'year').format("YYYY-MM-DD")),
      currentDate: new Date(moment().format("YYYY-MM-DD")),
      totalPercentageError: "",
      totalDiscountPercentage: "",
      users: [],
      selectedUser: {},
      typedContent: "",
    }
  }

  componentDidMount() {
    this.setDifferences(this.state.discount)
  }

  componentWillReceiveProps(nextProps) {
    //let self = this;
    Loader(nextProps.requestProcess);
  }

  setDifferences(discount) {
    let discountDifference = _.findWhere(this.props.appstoreDifferences, { couponSlug: "PERCENTAGE_" + discount });
    if (!_.isEmpty(discountDifference)) {
      this.setState({
        lifetimeDiscount: discountDifference.storeDiffLifetime,
        monthlyDiscount: discountDifference.storeDiffMonthly,
        yearlyDiscount: discountDifference.storeDiffYearly,
      })
    }
  }

  getUserByEmail(email) {
    getParents({ search: email, limit: 50, offset: 0 }).then(response => {
      if (response && response.result && response.result.length) {
        this.setState({ users: response.result })
      }
    })
  }


  handleChange(event) {
    let target = event.target;
    let name = target.name;
    let value;


    if (name === "couponCode") {
      if (!(/^\S*$/.test(target.value))) {
        return false;
      }
      target.value = target.value.toUpperCase();
    }


    if (name === "startOn") {
      this.setState({ expiredOn: target.value });
    }

    if (target.name === 'discountType') {
      this.state.maxDiscount = (target.value === Constants.PERCENTAGE) ? Constants.MAX_DISCOUNT_PERCRNTAGE : Constants.MAX_DISCOUNT_OFF;
      this.state.discount = 1;
    }

    if (name === "discount") {
      target.value = (target.value > this.state.maxDiscount) ? this.state.maxDiscount : target.value;
      if (this.props.appstoreDifferences && this.props.appstoreDifferences.length) {
        this.setDifferences(target.value)
      }
    }


    if (name === "email") {
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        this.getUserByEmail(target.value);
      }, 1000)

    }


    value = target.value;
    this.setState({ [name]: value });


  }

  handleSubmit(e) {
    if (this.validator.allValid()/* && this.state.totalPercentageError === ""*/) {
      let discountsObj = {
        couponCode: this.state.couponCode,
        discount: this.state.discount,
        monthlyDiscount: this.state.monthlyDiscount,
        yearlyDiscount: this.state.yearlyDiscount,
        lifetimeDiscount: this.state.lifetimeDiscount,
        type: this.state.discountType,
        description: this.state.description,
        email: this.state.selectedUser.email,
        discountType: this.state.activeTab,
        expiredOn: this.state.expiredOn,
        startOn: this.state.startOn,
        parentId: this.state.selectedUser._id,
      }

      this.setState({ selectedUser: {} })

      this.props._addUserDiscount(discountsObj);
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
    e.preventDefault();
  }


  formatTitlePosLabel(s) {
    if (typeof s !== 'string') return ''
    s = s.replace("_", " ");
    s = s.charAt(0).toUpperCase() + s.slice(1).toLowerCase();
    return s;
  }


  setDate(name, value) {
    this.setState({ [name]: new Date(value) })
  }

  onSelect(user) {
    this.setState({ selectedUser: user, typedContent: user.email, email: user.email })
  }

  render() {
    // let self = this;
    let { openUserSpecificDiscountModal, closeModal, userSpecificDiscountPopupError } = this.props;
    let { totalPercentageError, typedContent, users } = this.state;


    return (
      <Modal isOpen={openUserSpecificDiscountModal}>
        <ModalHeader toggle={() => { closeModal(false) }}>Add User Discount</ModalHeader>
        <Form onSubmit={(event) => { this.handleSubmit(event) }}>
          <ModalBody>

            <Row>
              <Col xs="12">
                <FormGroup>
                  <Label htmlFor="email">Email</Label>
                  {/* <Input type="text" id="email" placeholder="Email" name="email" value={this.state.email} onChange={(event) => { this.handleChange(event) }} />
                  */}



                  <Autocomplete
                    Autocomplete="off"
                    getItemValue={(user) => user.email}
                    items={users}
                    inputProps={{ id: 'autocomplete-input', className: "form-control", placeholder: "Search user by email" }}
                    wrapperStyle={{}}
                    renderItem={(user, isHighlighted) =>
                      <div key={user._id} style={{ background: isHighlighted ? 'lightgray' : 'white' }}>
                        {user.email}
                      </div>
                    }
                    value={typedContent ? typedContent : ''}
                    onChange={(event, value) => {
                      this.setState({ typedContent: value }, () => {
                        clearTimeout(this.timeout)
                        this.timeout = setTimeout(() => {
                          this.getUserByEmail(value)
                        }, 1000)
                      });
                    }}
                    onSelect={(value, user) => { this.onSelect(user) }}
                    selectOnBlur={true}
                  />
                  <div className="validation-error">{this.validator.message('Email', this.state.email, 'email')}</div>
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col xs="12">
                <FormGroup>
                  <Label htmlFor="couponCode">Discount value (%)</Label>
                  <Input type="select" id="discount" placeholder="Discount value" name="discount" value={this.state.discount} onChange={(event) => { this.handleChange(event) }}>
                    <option value="15">15</option>
                    <option value="30">30</option>
                    <option value="50">50</option>
                  </Input>
                  <div className="validation-error">{this.validator.message('Discount', this.state.discount, 'required')}</div>
                </FormGroup>
              </Col>
            </Row>




            <Row>
              <Col xs="12">
                <FormGroup>
                  <Label htmlFor="description">Start On</Label>
                  <DatePicker value={this.state.startOn} onChange={(event) => { this.setDate('startOn', event) }} minDate={this.state.currentDate} />
                  <div className="validation-error">{this.validator.message('Expire Date', this.state.expiredOn, 'required')}</div>
                </FormGroup>
              </Col>
            </Row>


            <Row>
              <Col xs="12">
                <FormGroup>
                  <Label htmlFor="description">Expired On</Label>

                  <DatePicker value={this.state.expiredOn} onChange={(event) => { this.setDate('expiredOn', event) }} minDate={this.state.startOn} />
                  <div className="validation-error">{this.validator.message('Expire Date', this.state.expiredOn, 'required')}</div>
                </FormGroup>
              </Col>
            </Row>




            <Row>
              <Col xs="12">
                <FormGroup>
                  <Label htmlFor="description">Description</Label>
                  <Input type="textarea" id="description" maxLength="500" placeholder="Description" name="description" value={this.state.description} onChange={(event) => { this.handleChange(event) }} />
                  <div className="validation-error">{this.validator.message('Description', this.state.description, 'required')}</div>
                </FormGroup>
              </Col>
            </Row>



            {(userSpecificDiscountPopupError) ? <Alert color="danger">{userSpecificDiscountPopupError}</Alert> : null}
            {(totalPercentageError) ? <Alert color="danger">{totalPercentageError}</Alert> : null}
          </ModalBody>
          <ModalFooter className="justify-content-left">
            <Button type="submit" size="sm" color="primary" >Add</Button>{' '}
            <Button type="reset" size="sm" color="danger" onClick={() => { closeModal() }}>Cancel</Button>
          </ModalFooter>
        </Form>
      </Modal>

    );
  }
}

export default AddUserSpecificDiscount;
