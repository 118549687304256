import { connect } from 'react-redux';

import SiriShortcuts from './SiriShortcuts';
import { getAllShortcuts, addSiriShortcut, updateSiriShortcut } from './action';
import { getPillarCategory} from '../category/action';


const mapStateToProps = (state) => {
	var stateData = {
		requestProcess: state.backgroundMusic.requestProcess
	};

	if(state.category.data){
		stateData['categoryData'] = state.category.data;
	}


	if(state.siriShortcuts.shortcuts){
		stateData['shortcuts'] = state.siriShortcuts.shortcuts;
	}


	if(state.siriShortcuts.shortcutAdded){
		stateData['shortcutAdded'] = state.siriShortcuts.shortcutAdded;
	}


	if(state.siriShortcuts.shortcutUpdated){
		stateData['shortcutUpdated'] = state.siriShortcuts.shortcutUpdated;
	}
	
	return stateData;
};

const mapDispatchToProps = (dispatch) => {
	return {
		_getPillarCategory: (params) => {
			dispatch(getPillarCategory(params));
		},

		_getAllShortcuts: (params) => {
			dispatch(getAllShortcuts(params));
		},

		_addSiriShortcut: (params) => {
			dispatch(addSiriShortcut(params));
		},


		_updateSiriShortcut: (params) => {
			dispatch(updateSiriShortcut(params));
		},
	};
};


export default connect(
	mapStateToProps,
	mapDispatchToProps
)(SiriShortcuts);
