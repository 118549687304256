import {combineReducers} from 'redux';
import {createReducer} from 'redux-act';
import { routerReducer } from 'react-router-redux';

import {requestLoaderVisibility, requestSuccessMessage, requestErrorMessage} from './utils/common';
import parent from './pages/parent/reducer';
import pillar from './pages/pillar/reducer';
import category from './pages/category/reducer';
import content from './pages/content/reducer';
import dashboard from './pages/dashboard/reducer';
import feedback from './pages/feedback/reducer';
import auth from './pages/auth/reducer';
import versions from './pages/versions/reducer';
import settings from './pages/settings/reducer';
import discount from './pages/discount/reducer';
import trophy from './pages/trophy/reducer';
import paymentHistory from './pages/paymentHistory/reducer';
import notifications from './pages/notifications/reducer';
import emailNotification from './pages/emailNotification/reducer';
import systemEmail from './pages/systemEmail/reducer';
import backgroundMusic from './pages/backgroundMusic/reducer';
import dailyQuest from './pages/dailyQuest/reducer';
import staticLanguage from './pages/languageManagement/StaticLanguage/reducer';
import systemLanguage from './pages/languageManagement/SystemLanguage/reducer';
import whatsAppCampaign from './pages/whatsAppCampaign/reducer';
import emailCampaign from './pages/emailCampaign/reducer';
import userNotification from './pages/userNotification/reducer';
import marketing from './marketing/socialProfiles/reducer';
import socialInsights from './marketing/socialInsights/reducer';
import siriShortcuts from './pages/siriShortcuts/reducer';
import installVsSignup from './shared/graphs/installVsSignup/reducer';
import authorContent from './pages/authorContent/reducer';

import adminUsers from './pages/adminUsers/reducer';
import printable from './pages/printable/reducer';

import whatsNew from './pages/whatsNew/reducer';




/***************************Start Reducer for loader *************/
const defaultLoaderState = {
	visiblity: false,
	processBarPercent: 0
};

const loader = createReducer({
  [requestLoaderVisibility]: (state, params) => {
			return {
				visiblity: params.isVisible,
				processBarPercent: params.processBarPercent
			};
	}
}, defaultLoaderState);
/***************************End Reducer for loader *************/

/***************************Start Reducer for Message *************/
const defaultMessageState = {
	message: '',
  type: ''
};

const messageObj = createReducer({
  [requestSuccessMessage]: (state, params) => {
      if(params){
        return {
          message: params,
          type: 'success'
        };
      }
			return {
        message: '',
        type: ''
			};
	},
  [requestErrorMessage]: (state, params) => {
      if(params){
        return {
          message: params,
          type: 'error'
        };
      }
			return {
        message: '',
        type: ''
			};
	}
}, defaultMessageState);
/***************************End Reducer for Message *************/

const reducers = combineReducers({
		routing: routerReducer,
    messageObj,
    loader,
    parent,
		dashboard,
    pillar,
		auth,
		category,
    content,
    feedback,
    versions,
    settings,
    discount,
    trophy,
    paymentHistory,
    notifications,
    emailNotification,
    systemEmail,
    backgroundMusic,
    dailyQuest,
    staticLanguage,
    systemLanguage,
    whatsAppCampaign,
    emailCampaign,
    userNotification,
    marketing,
    socialInsights,
    siriShortcuts,
    installVsSignup,
    adminUsers,
    authorContent,
    printable,
    whatsNew
});

export default reducers;
