import React, { Component } from 'react';
import { connect } from 'react-redux';
import { AppAsideToggler, AppHeaderDropdown, AppNavbarBrand, AppSidebarToggler } from '@coreui/react';
import { Badge, DropdownItem, DropdownMenu, DropdownToggle, Nav, NavItem, NavLink } from 'reactstrap';
import { ErrorToast, SuccessToast, Loader, setHttpClientConfig } from '../utils/common';
import { Link } from 'react-router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCoffee, faCog, faLock } from '@fortawesome/free-solid-svg-icons'


import logo from '../assets/img/logo-bar.png'
import sygnet from '../assets/img/brand/sygnet.png'
import userAvtar from '../assets/img/user-avtar.png'

class DefaultHeader extends Component {

  constructor(props) {
    super(props);
    this.state = {}
  }


  componentWillMount() { }

  navigate(path) {
    this.props.router.push(path);
  }


  render() {
    return (
      <React.Fragment>
        <AppSidebarToggler className="d-lg-none" display="md" mobile />

        <AppNavbarBrand
          full={{ src: logo, height: 30, alt: 'Ninja Focus' }}

        />

        <AppSidebarToggler className="d-md-down-none" display="lg" />

        {/*<Nav className="d-md-down-none" navbar>
                  <NavItem className="px-3">
                    <a href="/marketing-social" onClick={ (e) => {this.navigate("marketing-social"); e.preventDefault()}} className="nav-link" >Marketing</a>
                  </NavItem>
                </Nav>*/}
        <Nav className="ml-auto" navbar>

          <AppHeaderDropdown direction="down">
            <DropdownToggle nav>
              <img src={userAvtar} className="img-avatar" alt="Avatar" />
            </DropdownToggle>
            <DropdownMenu >
              {/*<DropdownItem header tag="div" className="text-center"><strong>Settings</strong></DropdownItem>*/}

              {/*<DropdownItem> <FontAwesomeIcon icon={faCog} /> Settings</DropdownItem>*/}

              {/* <DropdownItem><i className="fa fa-wrench"></i> Settings</DropdownItem> */}
              {/*<DropdownItem><i className="fa fa-usd"></i> Payments<Badge color="secondary">42</Badge></DropdownItem>*/}
              {/*<DropdownItem><i className="fa fa-file"></i> Projects<Badge color="primary">42</Badge></DropdownItem>*/}
              {/* <DropdownItem divider /> */}
              {/*<DropdownItem><i className="fa fa-shield"></i> Lock Account</DropdownItem>*/}

              <DropdownItem onClick={e => this.props.onLogout(e)}><FontAwesomeIcon icon={faLock} /> Logout</DropdownItem>
            </DropdownMenu>
          </AppHeaderDropdown>
        </Nav>
        {/*<AppAsideToggler className="d-md-down-none" />*/}
        {/*<AppAsideToggler className="d-lg-none" mobile />*/}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {

  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    /*_signOut: ()=>{
      return signOutAction();
    }*/
  };
};


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DefaultHeader);
