
import React, { Component } from 'react';
import { Loader, SuccessToast, createRandomStr } from '../../utils/common';
import { Row, Col, Table, Button, Input, Alert, Form, Card, CardHeader, CardBody, FormGroup, Label, CardFooter, Pagination, PaginationItem, PaginationLink, Progress} from "reactstrap";
import moment from 'moment';
import SimpleReactValidator from 'simple-react-validator';
import httpClient from '../../services/http';

import S3Client from '../../services/aws-s3/S3Client';


import * as _ from 'underscore'

class WhatsAppCampaign   extends Component {

  constructor(props) {
    super(props);
    this.state = {
        userstList: [],
        rowsPerPage: 10,
        totalRecord: 0,
        screenErrMsg: '',
        isError: false,
        fileValue: '',

        message : "",
        phNumber : "",
        uploadImageUrl : "",

        campaignName : "",
        messageToAll : "",
        error : false,
        messageSendText : "",
        messageAllSendText : "",
        limit : 10,
        offset : 0,
        campaignList : [],
        totalCampaign : 0,
        page : 1,
        allWhatsContacts: '',
        whatsContacts: '',
        importedFileName : '',
        importvalidateMsg :'',
        sendAllImageErrText : '',
        sendImageErrText : '',
        uploadPercent:0
    }
    this.intervalInstance = '';

    this.columns = [
      { "columnName": "firstName", "displayName": "First Name" },
      { "columnName": "lastName", "displayName": "Last Name", "sort": false },
      { "columnName": "phoneNumber", "displayName": "Phone Number", "sort": false },
      { "columnName": "email", "displayName": "Email", "sort": false },
      { "columnName": "createdDate", "displayName": "Created On", "sort": false }
    ];

    this.campaignListHeadings = [
      { "displayName": "Campaign Name"},
      { "displayName": "Message"},
      { "displayName": "Message Status"},
      { "displayName": "Created On"},
    ];


    this.importContacts = this.importContacts.bind(this);
    this.props._getUsers();
    this.singleMessagevalidator = new SimpleReactValidator();
    this.multiMessagevalidator = new SimpleReactValidator();

    const s3Config = {
        bucketName: process.env.REACT_APP_S3_BUCKET,
        dirName: 'campaign/message', /* optional */
        region: process.env.REACT_APP_AWS_DEFAULT_REGION,
        accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
        s3Url: process.env.REACT_APP_AWS_BUCKET_URL, /* optional */
    }
    this.S3Client = new S3Client(s3Config);
  }


  componentDidMount() {
    let self = this
    self.getCampaignList()
  }

  getCampaignList() {
    let self = this
    let params = {
      limit : self.state.limit,
      offset : self.state.offset,
    }

    this.props._getCampaignList(params)
  }



  componentWillReceiveProps(nextProps) {
    let self = this
    Loader(nextProps.requestProcess);

    if (nextProps.dataImported) {
      if (nextProps.dataImported && nextProps.dataImported.statusCode === 200) {
        self.setState({fileValue: '', importedFileName : '', error : false, importvalidateMsg : "Data Imported"});
        setTimeout(()=> {
          self.setState({fileValue: '', importedFileName : '', error : false, importvalidateMsg : "" ,uploadPercent:0});
        }, 4000)
        SuccessToast(nextProps.dataImported.result);
        self.props._getUsers();
      }else {
        self.setState({uploadPercent: 0, importedFileName: null, fileValue: null, error : true, importvalidateMsg : "Data format is not valid on the file. Please download sample csv"})
      }
    }

    if (nextProps.users && nextProps.users.statusCode === 200) {
        self.setState({userstList: nextProps.users.result.list, totalRecord: nextProps.users.result.count})
      }


    if (nextProps.singleMessageSend) {
      if (nextProps.singleMessageSend.statusCode === 200) {
        self.setState({messageSendText : nextProps.singleMessageSend.statusDescription, message : "", phNumber : "", error : false, uploadImageUrl : "", importvalidateMsg : "", sendAllImageErrText : "", sendImageErrText: "", allWhatsContacts : "", whatsContacts : ""})

        self.singleMessagevalidator = new SimpleReactValidator();
        self.multiMessagevalidator = new SimpleReactValidator();
      }else{
        self.setState({messageSendText : nextProps.singleMessageSend.data.statusDescription, error : true})
      }

      setTimeout(() => {
        self.setState({messageSendText : "", error : false})
      }, 4000)
    }

    if (nextProps.sentAllMessage) {
      if (nextProps.sentAllMessage.statusCode === 200) {
        self.setState({messageAllSendText : nextProps.sentAllMessage.statusDescription, messageToAll : "", campaignName : "", uploadImageUrl : "", error : false, offset : 0, importvalidateMsg : "", sendAllImageErrText : "", sendImageErrText: "", allWhatsContacts : "", whatsContacts : ""})
        self.getCampaignList();
        self.singleMessagevalidator = new SimpleReactValidator();
        self.multiMessagevalidator = new SimpleReactValidator();
      }else{
        self.setState({messageAllSendText : nextProps.sentAllMessage.data.statusDescription, error : true})
      }

      setTimeout(() => {
        self.setState({messageAllSendText : "", error : false})
      }, 4000)
    }


    if (nextProps.campaignList) {
      if (nextProps.campaignList.statusCode === 200) {
        self.setState({campaignList : nextProps.campaignList.result.list, totalCampaign : nextProps.campaignList.result.totalRecords})
      }else{
        // DO NOTHING
      }
    }
  }

  handleChange(event) {
    let target = event.target;
    let name = target.name;
    let value = target.value;
    this.setState({[name]: value});
  }


  importContacts(e) {

      const data = new FormData();
      const file = e.target.files[0];
      let self = this;
      let proceed = self.validateFileType(file.name, file);
      if (proceed) {
        self.setState({importedFileName : file.name, uploadPercent : 1})
        setTimeout(()=>{
          data.append('csvFile', file)
          self.props._uploadCSV(data, function(uploadPercent){
            console.log('uploadPercent', uploadPercent);
          self.setState({uploadPercent : uploadPercent})
          });
        }, 2000)
      }


  }



  validateFileType(name, file){
    let self = this;
    if(file){
      if(['text/csv', 'application/vnd.ms-excel'].indexOf(file.type) === -1){
        self.setState({importvalidateMsg: "Please select valid csv file", error : true});
        return false;
      } else {
        self.setState({importvalidateMsg: ""});
        return true;
      }
    }else {
      return true;
    }
  }





  importFileTrigger(e) {
    this.fileUploader.click();
    e.preventDefault();
  }



  importSendAllFileTrigger(e) {
    this.attachSendAllImage.click();
    e.preventDefault();
  }


  sendWhatsAppMessage(event) {
    if( this.singleMessagevalidator.allValid() && this.state.sendImageErrText === "") {
      let params = {
        "contactNumber" : this.state.phNumber,
        "message" : this.state.message,
        "mediaUrl" : this.state.uploadImageUrl
      }
      this.props._sendSingleWhatsAppMessage(params);
    }else{
      this.singleMessagevalidator.showMessages();
      this.forceUpdate();
    }
    event.preventDefault();
  }

  sendWhatsAppMessageToAll(event){
    if( this.multiMessagevalidator.allValid() && this.state.sendAllImageErrText === "") {
      let params = {
        "campaignName" : this.state.campaignName,
        "message" : this.state.messageToAll,
        "mediaUrl" : this.state.uploadImageUrl
      }
      this.props._sendMessageToAll(params);
    }else{
      this.multiMessagevalidator.showMessages();
      this.forceUpdate();
    }
    event.preventDefault();
  }


  uploadCampaignImage(e) {
    Loader(true)
    const fileObj = e.target.files[0];


    let self = this;
    if (fileObj && fileObj.name) {
      let proceed = self.validateSendAllFileType(fileObj.name, fileObj)
      if (proceed) {
        self.setState({[e.target.name]: fileObj.name});
       // let responseObj = {};
       // let err = null;
       // let progressIndex = 0;
        self.S3Client.getPolicy().then((policyRes)=>{
          httpClient.call('PILLAR', '/get_signature', {policy: policyRes}, {method: 'GET', headers: {}}).then((signatureRes)=>{
            if(signatureRes && signatureRes.statusCode === 200 && signatureRes.result && signatureRes.result.signature){
              if(fileObj instanceof File){
                createRandomStr((randString)=>{
                  let signature = signatureRes.result.signature;
                  let filename = randString;
                  let ext = fileObj.name.split('.')[1];

                  self.S3Client.uploadFile(fileObj, filename+'.'+ext, signature).then(data => {
                    Loader(false)
                    self.setState({uploadImageUrl : process.env.REACT_APP_CLOUDFRONT_URL + data.key})
                  }).catch(err => {
                    console.error(err)

                  })
                });
              }else{
                console.log('ERROR:- NOT A FILE ', signatureRes);
              }

            }else{
              console.log('ERROR:- SIGNATURE RESPONSE ', signatureRes);

            }

          }, (error) => {
            console.log('ERROR:- SIGNATURE REQUEST ', error);

          })
        }, (error) => {
          console.log('ERROR:- SIGNATURE REQUEST ', error);

        });
      }else{
        Loader(false)
      }

    }else{
      Loader(false)
    }
  }

  validateSendAllFileType(name, file){
    let self = this;
    if(file){
      console.log(file.type)
      if(['image/jpeg', 'image/png', 'text/csv', 'application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.presentationml.presentation', 'application/pdf', 'text/plain',  'application/msword'].indexOf(file.type) === -1){
        self.setState({sendAllImageErrText: "Please select valid file (e.g. csv, ppt, xls, doc)", error : true});
        return false;
      } else {
        self.setState({sendAllImageErrText: "", error : false});
        return true;
      }
    }else {
      return true;
    }
  }

  uploadCampaignImageSingle(e) {
    Loader(true)
    const fileObj = e.target.files[0];


    let self = this;
    if (fileObj && fileObj.name) {
      let proceed = self.validateSendAllFileTypeSingle(fileObj.name, fileObj)
      if (proceed) {
        self.setState({[e.target.name]: fileObj.name});
       // let responseObj = {};
       // let err = null;
      // let progressIndex = 0;
        self.S3Client.getPolicy().then((policyRes)=>{
          httpClient.call('PILLAR', '/get_signature', {policy: policyRes}, {method: 'GET', headers: {}}).then((signatureRes)=>{
            if(signatureRes && signatureRes.statusCode === 200 && signatureRes.result && signatureRes.result.signature){
              if(fileObj instanceof File){
                createRandomStr((randString)=>{
                  let signature = signatureRes.result.signature;
                  let filename = randString;
                  let ext = fileObj.name.split('.')[1];

                  self.S3Client.uploadFile(fileObj, filename+'.'+ext, signature).then(data => {
                    Loader(false)
                    self.setState({uploadImageUrl : process.env.REACT_APP_CLOUDFRONT_URL + data.key})
                  }).catch(err => {
                    console.error(err)

                  })
                });
              }else{
                console.log('ERROR:- NOT A FILE ', signatureRes);
              }

            }else{
              console.log('ERROR:- SIGNATURE RESPONSE ', signatureRes);

            }

          }, (error) => {
            console.log('ERROR:- SIGNATURE REQUEST ', error);

          })
        }, (error) => {
          console.log('ERROR:- SIGNATURE REQUEST ', error);

        });
      }else{
        Loader(false)
      }
    }else{
      Loader(false)
    }
  }

  validateSendAllFileTypeSingle(name, file){
    let self = this;
    if(file){
      if(['image/jpeg', 'image/png', 'text/csv', 'application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.presentationml.presentation', 'application/pdf', 'text/plain', 'application/msword'].indexOf(file.type) === -1){
        self.setState({sendImageErrText: "Please select valid csv file ", error : true});
        return false;
      } else {
        self.setState({sendImageErrText: "", error : false});
        return true;
      }
    }else {
      return true;
    }
  }

  setGridHeaders(headingData) {
    let gridHeadings = [];
    gridHeadings.push(<th key={'index_0'}>#</th>)
    _.each(headingData, function(value, index){
      {
        gridHeadings.push(<th key={index} style={{width:(value['displayName'] === 'Message' ? '50%' : '')}}>{value['displayName']}</th>)
      }

    })

    return (<tr className="">{gridHeadings}</tr>)
  }


   setGridData(data) {
   // let self = this;
    let gridData = [];
    let page = this.state.page;
    page = (page -1) * 10;
    page = page > 0 ? page : 0;

    if (data.length) {
      _.each(data, function(value, index){
        {
          gridData.push(
            <tr key={index}>
              <th scope="row">{page+(index + 1)}</th>
              <td>{value['campaignName']}</td>
              <td>{value['message']}</td>
              <td>{value['messageDelivered']}</td>
              <td>{moment(value['createdDate']).format("DD-MMM-YYYY")}</td>
            </tr>
         )
        }
      })
    }else{
      gridData.push(<tr key={'index-123'}><td className="no-data-found" colSpan={this.campaignListHeadings.length + 1}>No Records Found</td></tr>)
    }

    return gridData;
  }


  setPaginationLinks(totalRecords, pageLimit) {

    let linksCount = (totalRecords > 0 && pageLimit > 0) ? Math.ceil(totalRecords / pageLimit) : 0;
    let links = [];
    // links.push(<PaginationItem disabled><PaginationLink first  /></PaginationItem><PaginationItem disabled><PaginationLink previous  /></PaginationItem>)


    for (let i = 0; i < linksCount ; i++) {
      links.push(<PaginationItem key={i} active={this.state.page === i + 1} >
        <PaginationLink disabled={this.state.page === i + 1} onClick={(event) => this.getMoreCampaignListData(i)}>
          {i + 1}
        </PaginationLink>
      </PaginationItem>)
    }

    return (<Pagination aria-label="Page navigation example" className="float-right"> {links} </Pagination>)
  }

  getMoreCampaignListData(offset) {
    let self = this;
    self.setState({offset : offset * self.state.limit, page: offset + 1}, () => {
      self.getCampaignList();
    })
  }




  render() {
    const { error,  messageSendText, limit, allWhatsContacts, whatsContacts, importedFileName} = this.state;
   // let self = this;
    let { page, rowsPerPage, totalRecord, messageAllSendText, campaignList, totalCampaign, importvalidateMsg, sendAllImageErrText, sendImageErrText } = this.state;

    let totalRecordsLabel = '##TOTAL_RECORDS## '+(totalRecord > 1 ? 'Users' : 'User')
    let options = {
      totalRecords: totalRecord,
      totalRecordsLabel: totalRecordsLabel,
      rowsPerPage: rowsPerPage,
      page: page,
      loadMore: false,
      customColumn: {
        isActive: false,
        columnTitle: '',
        columnContent: (rowData) => {
          return null;
        }
      },

      onPageChange(pageNumber) {
      },

      onColumnSortChange: (column, direction) => {

      }
    };



    return (
      <div className="content">

          <Row className="pb-4">
            <Col lg={6} sm={6}>
              <h3>WhatsApp Campaign</h3>
            </Col>
          </Row>
          <Row >
            <Col lg={8} sm={6} >
              <Card>
                <CardHeader>Campaign List </CardHeader>
                <CardBody>
                  <Table responsive hover>
                    <thead>
                       {this.setGridHeaders(this.campaignListHeadings)}
                    </thead>
                    <tbody>
                      {this.setGridData(campaignList)}
                    </tbody>
                  </Table>
                </CardBody>
                <CardFooter>
                  <span>Total Campaigns : {totalCampaign}</span>
                  { this.setPaginationLinks(totalCampaign, limit)
                  }
                </CardFooter>
              </Card>
            </Col>

            <Col lg={4} sm={6}>

              <Row className="pb-4" >
                <Col lg={12} sm={12}>
                  <Form onSubmit={(event) => {this.sendWhatsAppMessageToAll(event)}}>
                  <h4>Import Contacts ({totalRecord})</h4>

              <Row>
                              <Col xs="6">
                              <Button type="button"  size="lg" color="success" onClick={(event) => this.importFileTrigger(event)}>{(importedFileName) ? importedFileName : "Choose CSV File"}</Button>
                              <input type="file" value={this.state.fileValue} name="myImage" onChange= {this.importContacts}
                               ref={(ref) => this.fileUploader = ref} style={{display: "none"}} />
                              </Col>
                              <Col xs="6" className="text-right m-t10">
                               <a href="https://cdn03.ninjafocus.com/placeholder/sample.csv" target="_blank" rel="noreferrer" ><i className="fa fa-trash"></i>Download Sample CSV</a>
                              </Col>
                              </Row>

                  {this.state.uploadPercent > 0 && <Progress  className="m-t10" value={this.state.uploadPercent}>{this.state.uploadPercent}%</Progress>}
                  {(importvalidateMsg) ? <Alert color={(error) ? "danger" : 'success'}>
                                {importvalidateMsg}
                          </Alert> : null}
                <hr />


                      <Card>
                          <CardHeader>Send Message To Imported Contacts </CardHeader>

                          <CardBody>

                              <Row>
                                  <Col xs="12">
                                      <FormGroup>
                                        <Label htmlFor="campaignName">Campaign Name</Label>
                                        <Input  id="campaignName" placeholder="Campaign name" name="campaignName" value={this.state.campaignName} onChange={($event)=>{this.handleChange($event)}} />
                                        <div className="validation-error">{ this.multiMessagevalidator.message('Campaign Name', this.state.campaignName, 'required') }</div>
                                      </FormGroup>
                                  </Col>
                              </Row>


                              <Row>
                                  <Col xs="12">
                                      <FormGroup>
                                        <Label htmlFor="messageToAll">Message</Label>
                                        <Input type="textarea" id="message" placeholder="Message" name="messageToAll" value={this.state.messageToAll} onChange={($event)=>{this.handleChange($event)}} />
                                        <div className="validation-error">{ this.multiMessagevalidator.message('Message', this.state.messageToAll, 'required') }</div>
                                      </FormGroup>
                                  </Col>
                              </Row>



                              <Row>
                                  <Col xs="12">
                                      <FormGroup>
                                        <Label htmlFor="allWhatsContacts"  className="btn btn-sm btn-primary">{(allWhatsContacts) ? allWhatsContacts : "Choose File"}</Label>
                                        <input type="file"  name="allWhatsContacts" id="allWhatsContacts"  onChange= {(event) => {this.uploadCampaignImage(event)}}
                                        ref={(ref) => this.attachSendAllImage = ref} style={{display: "none"}}  />
                                      </FormGroup>
                                      {(sendAllImageErrText) ?  <Alert color={(error) ? "danger" : 'success'}>
                                        {sendAllImageErrText}
                                      </Alert> : null}
                                  </Col>


                              </Row>



                              {(messageAllSendText) ?  <Alert color={(error) ? "danger" : 'success'}>
                                {messageAllSendText}
                              </Alert> : null}
                          </CardBody>

                          <CardFooter>
                              <Button type="submit"  size="sm" color="primary float-right">Send To All</Button>
                          </CardFooter>
                      </Card>
                  </Form>
                </Col>
              </Row>

              <Row className="pb-4">
                <Col lg={12} sm={12}>
                  <Form onSubmit={(event) => {this.sendWhatsAppMessage(event)}}>
                      <Card>
                          <CardHeader>Send Message On Whatsapp Number</CardHeader>
                          <CardBody>
                              <Row>
                                  <Col xs="12">
                                      <FormGroup>
                                        <Label htmlFor="message">Message</Label>
                                        <Input type="textarea" id="message" placeholder="Message" name="message" value={this.state.message} onChange={($event)=>{this.handleChange($event)}} />
                                        <div className="validation-error">{ this.singleMessagevalidator.message('Message', this.state.message, 'required') }</div>
                                      </FormGroup>
                                  </Col>

                                  <Col xs="12">
                                      <FormGroup>
                                        <Label htmlFor="phNumber">Phone Number</Label>
                                        <Input id="phNumber" placeholder="Enter Phone Number with Country code" name="phNumber" value={this.state.phNumber} onChange={($event)=>{this.handleChange($event)}} />
                                        <div className="validation-error">{ this.singleMessagevalidator.message('Phone Number', this.state.phNumber, 'required') }</div>
                                      </FormGroup>
                                      {(sendImageErrText) ?  <Alert color={(error) ? "danger" : 'success'}>
                                      {sendImageErrText}
                                      </Alert> : null}
                                  </Col>
                              </Row>
                               <Row>
                                  <Col xs="12">
                                      <FormGroup>
                                        <Label htmlFor="whatsContacts" className="btn btn-sm btn-primary">{(whatsContacts) ? whatsContacts : "Choose File"}</Label>
                                        <input type="file"  name="whatsContacts" id="whatsContacts" onChange= {(event) => {this.uploadCampaignImageSingle(event)}}
                                        ref={(ref) => this.attachSendAllImage = ref} style={{display: "none"}}  />
                                      </FormGroup>
                                  </Col>
                              </Row>

                              {(messageSendText) ?  <Alert color={(error) ? "danger" : 'success'}>
                                {messageSendText}
                              </Alert> : null}
                          </CardBody>

                          <CardFooter>
                              <Button type="submit"  size="sm" color="primary" className="float-right">Send</Button>

                          </CardFooter>
                      </Card>
                  </Form>
                </Col>
              </Row>

            </Col>

          </Row>

      </div>
    );
  }
}

export default WhatsAppCampaign;
