import React, { Component } from 'react';
import { getUserPaymentHistory } from './action';
import { Loader } from '../../utils/common';
import { Row, Col, Modal, Button, ModalHeader, ModalBody, Card, FormGroup, Label, Input, CardBody, CardFooter, Form, Alert } from "reactstrap";
import { AppSwitch } from '@coreui/react'
import SimpleReactValidator from 'simple-react-validator';
import Constants from '../../config/constants';
import { Table } from 'reactstrap';
import moment from 'moment';




import * as _ from 'underscore'

class UserPayments extends Component {


  constructor(props) {
    super(props);
    this.state = {
      title: "",
      status: true,
      forGuest: false,
      isFree: false,
      screenError: "",
      parentId: props.parentId,
      userPaymentList: [],
      userTotalPayment: 0
    }
  }


  componentDidMount() {
    Loader(true)
    getUserPaymentHistory({ userId: this.state.parentId }).then(getUserPaymentHistoryResponse => {
      if (getUserPaymentHistoryResponse && getUserPaymentHistoryResponse.statusCode === 200 && getUserPaymentHistoryResponse.result && getUserPaymentHistoryResponse.result.length) {

        let sum = 0
        for (let i = 0; i < getUserPaymentHistoryResponse.result.length; i++) {
          const element = getUserPaymentHistoryResponse.result[i];
          sum += parseFloat(element.amount)
        }
        sum = sum.toFixed(2)

        this.setState({ userTotalPayment: sum, userPaymentList: getUserPaymentHistoryResponse.result })
        Loader(false)

      }
    })
      .catch(error => {
        Loader(false)
        console.error('Error during service worker registration:', error);
      })
  }

  render() {

    let { visible, closeModal } = this.props;

    return (
      <Modal isOpen={visible} style={{ maxWidth: '1200px', width: '100%' }} scrollable={true}>
        <ModalHeader toggle={() => { closeModal() }}>
          <span className="">User Payment History</span>
        </ModalHeader>
        <ModalBody>
          <Row>
            {this.state.userPaymentList[0] && <>
              <Col xs="2">
                <div className=""><b className="">Total Payment: {this.state.userTotalPayment}</b></div>
              </Col>
            </>}
          </Row>

          {this.state.userPaymentList[0] && <>
            <Row>
              <Col xs="12">

                <table className='table'>
                  <tr>
                    <th>Amount</th>
                    <th>TransactionId</th>
                    <th>Status</th>
                    <th>Type</th>
                    <th>Email</th>
                    <th>IsTest</th>
                    <th>StartsOn</th>
                    <th>EndOn</th>
                    <th>CreatedDate</th>
                  </tr>
                  {this.state.userPaymentList.map((userPayment) => {
                    return (
                      <tr key={userPayment._id}>
                        <td>{userPayment.amount}</td>
                        <td>{userPayment.transactionId}</td>
                        <td>{userPayment.status ? 'True' : 'False'}</td>
                        <td>{userPayment.type}</td>
                        <td>{userPayment.email}</td>
                        <td>{userPayment.istest ? 'True' : 'False'}</td>
                        <td>{moment(userPayment.startsOn).format("DD-MMM-YYYY")}</td>
                        <td>{moment(userPayment.endOn).format("DD-MMM-YYYY")}</td>
                        <td>{moment(userPayment.createdDate).format("DD-MMM-YYYY")}</td>
                      </tr>
                    );
                  })}
                </table>
              </Col>
            </Row>
          </>}
        </ModalBody>
      </Modal>
    );
  }
}



export default UserPayments;
