import { connect } from 'react-redux';

import Notifications from './Notifications';
import { getAllNotifications, addUpdateNotification, getContentList } from './action';


const mapStateToProps = (state) => {

	var stateData = {
		requestProcess: state.notifications.requestProcess
	};
	if(state.notifications.data){
		stateData['notificationData'] = state.notifications.data;
	}

	if(state.notifications.addUpdateData){
		stateData['addUpdateData'] = state.notifications.addUpdateData;
	}
	if(state.notifications.content){
		stateData['contentList'] = state.notifications.content;
	}
	
	
	return stateData;
};

const mapDispatchToProps = (dispatch) => {
	return {
		_getAllNotifications: (params) => {
			dispatch(getAllNotifications(params));
		},
		_addUpdateNotification: (params) => {
			dispatch(addUpdateNotification(params));
		},
		_getContentList: (params) => {
			dispatch(getContentList(params));
		}
	};
};


export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Notifications);
