import React, { Component } from 'react';
import { Loader } from '../../../utils/common';
import { Row, Col, Button, Modal, ModalHeader, ModalBody, ModalFooter, Alert} from "reactstrap";
import moment from 'moment';
import FacebookLogin from 'react-facebook-login';
import { AppSwitch } from '@coreui/react'
import Session from '../../../utils/session';
import * as _ from 'underscore'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye,  faCopy } from '@fortawesome/free-solid-svg-icons'
import { faFacebookF, faInstagram } from '@fortawesome/free-brands-svg-icons'
import TwitterLogin from 'react-twitter-auth/lib/react-twitter-auth-component.js';
import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes';
import ShortLinks from './ShortLinks';
import SocialMedia from './SocialMedia';
import Facebook from './Facebook';
import Instagram from './Instagram';
import Twitter from './Twitter';

class SocialProfiles extends Component {
  constructor(props) {

    super(props);
    this.state = {     
      userConnectId: null,
      openConfirmModal: false,
      openSocialMediaModal: false,
      openShortLinkModal: false,
      openInviteCodeModal: false,
      isSocialAccountConnected: true,
      userInputInvite: '',
      socialPlatformId: null,
      randomStr: null,
      isErrorMessaeg: null
    }
    this.sessionData = Session.getSessionData();
  }


  componentDidMount() {
    this.getMySocialProfiles()
  }


  getMySocialProfiles() {
    this.props._getMySocialProfiles()
  }

  componentWillReceiveProps(nextProps) {
    Loader(nextProps.requestProcess);
    if (nextProps.addUpdateUser && nextProps.addUpdateUser.statusCode === 200) {
      this.setState({userConnectId: null, openConfirmModal: false}, ()=>{this.getMySocialProfiles()});
    }
    if(nextProps.getSocialProfiles && nextProps.getSocialProfiles.result){
        if(Object.keys(nextProps.getSocialProfiles.result) == 0){
          this.setState({isSocialAccountConnected: false});
        }else{
          this.setState({isSocialAccountConnected: true});
        }
    }
  }


  socialInsightsGlobal(path, tags){
    this.props.history.push({
      pathname: 'social-'+path+'-insights',
      state: {
        tags: tags,
      }
    })
  }

  socialInsightsByUser(path, tags){
    this.props.history.push({
      pathname: 'social-'+path+'-insights-user',
      state: {
        tags: tags,
      }
    })
  }

  socialInsightsByCode(path, shortLinkId){
    this.props.history.push({
      pathname: 'social-'+path+'-insights-'+shortLinkId,
      state: {
        tags: [],
      }
    })
  }

  

  toggle(){
    this.setState({openConfirmModal: !this.state.openConfirmModal});
  }
  toggleSocialMediaModal(){
    this.setState({openSocialMediaModal: !this.state.openSocialMediaModal});
  }
   disconnectAccount(userConnectId){
    this.setState({userConnectId: userConnectId}, ()=>{ this.toggle()}); 
    
  }

  deleteProfile(){
    const {userConnectId } = this.state;
    this.props._disconnectSocialProfile({userSocialConnectId: userConnectId});
  }


  

  socialConnectButton = (type)=> <button className={type == 'top' ? "social-media connect-button m-4 p-3" : "social-media connect-button m-4"} onClick={()=>{this.toggleSocialMediaModal()}}>Connect To social media account</button>

  render() {
    const {openConfirmModal, openSocialMediaModal, userConnectId, isSocialAccountConnected, openInviteCodeModal, socialPlatformId} = this.state;


    return (
      <div className="container-fluid card mt-4">
          <Row>
            {this.sessionData.roles.indexOf('ADMIN') != -1 &&
              <Col lg={4} sm={12} style={{borderRight: '1px solid #dadada'}}>
                <Row>
                  <Col sm={12}>
                    <h3 className="mt-5">Social Profiles</h3>
                  </Col>
                </Row>
                {isSocialAccountConnected ? 
                    <Facebook 
                      {...this.props} 
                      displayAccount={true}
                      socialInsightsGlobal={(path, tags)=>{this.socialInsightsGlobal(path, tags)}}
                      socialInsightsByUser={(path, tags)=>{this.socialInsightsByUser(path, tags)}}
                      socialInsightsByCode={(path, shortLinkId)=>{this.socialInsightsByCode(path, shortLinkId)}}
                      disconnectAccount={(userConnectId)=>{this.disconnectAccount(userConnectId)}}
                    />              
                :
                <Row className="eq-col">
                  <Col sm={12} className="text-center">
                    <div className="border-box">{this.socialConnectButton()}</div>
                  </Col>              
                </Row>
              }
              </Col>
            }

            <Col lg={this.sessionData.roles.indexOf('ADMIN') != -1 ? 8 : 12} sm={12}>
              <ShortLinks 
                resetShortUrl={()=>{this.resetShortUrl()}}
                socialInsightsByCode={(path, shortLinkId) => {this.socialInsightsByCode(path, shortLinkId)}}
                {...this.props}/>
            </Col>
          </Row>

          
        
          <Modal fade={false} isOpen={openSocialMediaModal} toggle={()=>{this.toggleSocialMediaModal()}} >
            <ModalHeader toggle={()=>{this.toggleSocialMediaModal()}}>Social Media!</ModalHeader>
            <ModalBody>
                <SocialMedia onClose={()=>{this.toggleSocialMediaModal()}} {...this.props} />
            </ModalBody>
            <ModalFooter>              
              <Button color="secondary" onClick={()=>{this.toggleSocialMediaModal()}} >Close</Button>
            </ModalFooter>
          </Modal>
          <Modal fade={false} isOpen={openConfirmModal} toggle={()=>{this.toggle()}} >
            <ModalHeader toggle={()=>{this.toggle()}}>Disconnect Confirmation!</ModalHeader>
            <ModalBody>
              Are you sure, you want to disconnect social account?
            </ModalBody>
            <ModalFooter>
              <Button color="primary" onClick={()=>{this.deleteProfile()}}>Disconnect</Button>{' '}
              <Button color="secondary" onClick={()=>{this.toggle()}} >Cancel</Button>
            </ModalFooter>
          </Modal>

      </div>
    );
  }
}

export default SocialProfiles;