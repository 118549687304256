import {createAction} from 'redux-act';
import httpClient from '../../services/http';


export const requestGetAllShortcuts = createAction();
export const responseGetAllShortcuts = createAction();

export const requestAddShortcut = createAction();
export const responseAddShortcut = createAction();


export const requestUpdateShortcut = createAction();
export const responseUpdateShortcut = createAction();



export function getAllShortcuts(params){
  return (dispatch) => {
    dispatch(requestGetAllShortcuts([]));
    httpClient.call('CONFIG','/get_all_shortcuts', params, {method: 'GET', headers: {}}).then(function(response){
      dispatch(responseGetAllShortcuts(response));
    }, function(error){
      dispatch(responseGetAllShortcuts(error));
    });
  }
}



export function addSiriShortcut(params){
  return (dispatch) => {
    dispatch(requestAddShortcut([]));
    httpClient.call('CONFIG','/add_siri_shortcut', params, {method: 'POST', headers: {}}).then(function(response){
      dispatch(responseAddShortcut(response));
    }, function(error){
      dispatch(responseAddShortcut(error));
    });
  }
}



export function updateSiriShortcut(params){
  return (dispatch) => {
    dispatch(requestUpdateShortcut([]));
    httpClient.call('CONFIG','/update_siri_shortcut', params, {method: 'PUT', headers: {}}).then(function(response){
      dispatch(responseUpdateShortcut(response));
    }, function(error){
      dispatch(responseUpdateShortcut(error));
    });
  }
}
