import React, { Component } from 'react';
import { Loader  } from '../../../utils/common';
import { Row, Col, NavDropdown, Tooltip} from "reactstrap";
import moment from 'moment';
import FacebookLogin from 'react-facebook-login';
import Constants from '../../../config/constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faUnlink, faSync,  faCopy, faQuestionCircle } from '@fortawesome/free-solid-svg-icons'
import { faFacebookF } from '@fortawesome/free-brands-svg-icons'
import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes';
import Tags from './Tags';
import ShortLinks from './ShortLinks';
import config from '../../../config';

const KeyCodes = {
  comma: 188,
  enter: 13,
};

const delimiters = [KeyCodes.comma, KeyCodes.enter];


class Facebook extends Component {
  constructor(props) {

    super(props);
    this.state = {     
      socialProfileList: [],
      fbLoggedIn : false,
      fbData : [],
      copyText: "Copy to clipboard",
      parsedSocialPages: [],
      selectedPage: [],
      tags: [],
      suggestions: [],
      toolTipOpen: false
    }
    this.timeout = false;
  }




  componentWillReceiveProps(nextProps) {
    Loader(nextProps.requestProcess);
    
    if (nextProps.getSocialProfiles && nextProps.getSocialProfiles.statusCode == 200 && nextProps.getSocialProfiles.result) {      
        this.parseProfileData(nextProps.getSocialProfiles);
    }    
  }

  parseProfileData(profileData){
      if(profileData){
        let fbData = profileData.result[Constants.FACEBOOK];
        this.setState({fbData})
      }
  }

  facebookResponse = (e) => {
    if (e && e.accessToken) {
      this.setState({fbLoggedIn : true})
      
      // calling api on backend 
      let params = {
        "socialPlatformId": e.id,
        "socialPlatform": Constants.FACEBOOK,
        "name": (e.name) ? e.name : "",
        "expires": (e.data_access_expiration_time) ? e.data_access_expiration_time.toString() : "",
        "profilePicUrl": (e.picture && e.picture.data && e.picture.data.url),
        "email": (e.email) ? e.email : "",
        "accessToken": e.accessToken,
        "refreshToken": e.signedRequest,
      }

      if(typeof this.props.onClose == 'function'){this.props.onClose();}
      this.props._addUpdateUser(params)
    }
  };



  changepageStatus(e, element, key){
    let {fbData} = this.state;
    let SocialPages = fbData.SocialPages;
    SocialPages[key].status = !SocialPages[key].status;
    this.setState({fbData: {SocialPages: SocialPages}})
  }


  applyChanges(selectedPage){
    let self = this;
    if(self.timeout){clearTimeout(self.timeout)}
    self.setState({selectedPage}, ()=>{
      self.timeout = setTimeout(()=>{      
        self.props._applyFacebookPageChanges(selectedPage);  
      }, 3000)
    })
  }


  copyLink(link){
    if(link){
      this.setState({copyText: 'Copied!'}, ()=>{
        this.props.copyLink(link);
      })
    }
  }

  setCopyTextToInitial() {
    let self = this
    setTimeout(() => {
      self.setState({copyText : "Copy to clipboard"})
    }, 100)
  }
  
  toggleToolTip(option){
    this.setState({toolTipOpen: option})
  }

  
  render() {
    let { fbData, selectedPage, parsedSocialPages, tags, toolTipOpen } = this.state;
    const {displayAccount} = this.props;
    if(fbData && fbData.length == 0){
      this.parseProfileData(this.props.getSocialProfiles);
    }
    return (
      <div className="row">
        {fbData && fbData.length > 0 && displayAccount &&    
          fbData.map((element, index)=>{
            const tags = element && element.tags ? element.tags : [];
            return (
              <div className="col-md-12"  key={index}>
                <div className="card card-content"  style={{maxWidth: "540px"}}>
                  <div className="row no-gutters">
                    <div className="col-md-2">
                      <span className="social-media-icon facebook-bg"><FontAwesomeIcon icon={faFacebookF} /></span>
                      {(element.profilePic) ? <img src={element.profilePic} className="card-img" alt="..." /> : <img src="https://image.flaticon.com/icons/svg/124/124010.svg" className="card-img" alt="..." />}
                    </div>
                    <div className="col-md-10">
                      <div className="btn-view">
                        {/*<button className="btn btn-primary mr-2" title="Disconnect Account" onClick={()=>{this.props.disconnectAccount(element._id)}}>
                              <FontAwesomeIcon icon={faUnlink} />
                        </button>*/}
                        <button className="btn btn-primary" title="View Facebook Posts Insights" onClick={()=>{this.props.socialInsightsGlobal(Constants.FACEBOOK.toLowerCase(), tags)}}>
                              <FontAwesomeIcon icon={faEye} />
                        </button>
                      </div>
                        
                        <div className="social-card">              
                          <p className="card-text mb-0"><strong>{element.name}</strong> <br />{element.socialPlatformEmail}</p>
                          {/*<p className="card-text mb-0">{element.shortUrl}</p>
                          <p>
                            <button className="btn btn-outline-primary tooltip-custom p-1"  onClick={(e) => { this.props.resetShortUrl(element.socialPlatformId); e.preventDefault();}} >
                                <FontAwesomeIcon icon={faSync} /> Generate New Link
                            </button>
                            <button className="btn btn-outline-primary tooltip-custom ml-2 p-1" 
                                onClick={(e) => { this.copyLink(element.shortUrl); e.preventDefault();}} 
                                onMouseOut={() => this.setCopyTextToInitial()}  
                                >
                                <FontAwesomeIcon icon={faCopy} /> Copy to clipboard <span className="tooltiptext" id="myTooltip">{this.state.copyText}</span>
                            </button>
                            
                          </p>*/}
                        </div>
                    </div>
                  </div>
                  {/*<Tags {...this.props} userSocialConnectId={element._id} tags={tags} />*/}
                </div>
               </div>)
            })
        }
        {!displayAccount && 
          <div className="card" style={{maxWidth: "540px"}}>
              <div className="row no-gutters">                     
                <div className="col-md-12 text-center">
                  <div className="card-body">                          
                    <FacebookLogin 
                    appId={config.REACT_APP_FACEBOOK_KEY} 
                    textButton='Connect to Facebook'
                    autoLoad={false} fields="name,email,picture" 
                    cssClass="social-media facebook-connect-button" 
                    icon="fa-facebook" 
                    callback={this.facebookResponse} />
                  </div>
                </div>
              </div>
          </div>
        }
      </div>
    );
  }
}

export default Facebook;