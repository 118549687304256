import { createReducer } from 'redux-act';
import {requestData, responseData, requestUpdateData, responseUpdateData} from './action';


const defaultState = {
	requestProcess: false,
};

const reducer = createReducer({

	[requestData]: (state) => {
		return {
			requestProcess: true,
			data: [],
		};
	},

	[responseData]: (state, params) => {
		return {
			requestProcess: false,
			data: params,
		};
	},

	[requestUpdateData]: (state) => {
		return {
			requestProcess: true,
			updateData: [],
		};
	},

	[responseUpdateData]: (state, params) => {
		return {
			requestProcess: false,
			updateData: params,
		};
	},



}, defaultState);



export default reducer
