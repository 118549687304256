import { createReducer } from 'redux-act';
import {requestSystemLangData, responseSystemLangData, responseSystemLangUpdate, requestSystemLangUpdate, responseSystemLangAdd, requestSystemLangAdd} from './action';


const defaultState = {
	requestProcess: false,
};

const reducer = createReducer({

	[requestSystemLangData]: (state) => {
		return {
			requestProcess: true,
		};
	},

	[responseSystemLangData]: (state, params) => {
		return {
			requestProcess: false,
			systemLanguageList: params,
		};
	},



	[requestSystemLangUpdate]: (state) => {
		return {
			requestProcess: true,
		};
	},

	[responseSystemLangUpdate]: (state, params) => {
		return {
			requestProcess: false,
			languageUpdated: params,
		};
	},


	[requestSystemLangAdd]: (state) => {
		return {
			requestProcess: true,
		};
	},

	[responseSystemLangAdd]: (state, params) => {
		return {
			requestProcess: false,
			languageAdded: params,
		};
	}

}, defaultState);



export default reducer
