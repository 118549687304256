import React, { Component } from 'react';
import { Loader } from '../../utils/common';
import { Row, Col, Modal, Button, ModalHeader, ModalBody, ModalFooter, FormGroup, Label, Input,  Form , Alert } from "reactstrap";  
import SimpleReactValidator from 'simple-react-validator';


class AddAppSetting extends Component {
  constructor(props) {
    super(props);
    this.validator = new SimpleReactValidator();
    this.state = {
      title :  "",
      value :  ""
    }
  }

  componentDidMount() {
  }

  componentWillReceiveProps(nextProps) {
    //let self = this;
    Loader(nextProps.requestProcess);
  }


  

  handleChange(event) {
    let target = event.target;
    let name = target.name;
    let value;
    value = target.value;
    this.setState({[name]: value});
  }

  handleSubmit(e) {
    if( this.validator.allValid()){
      let settingsObj = {
        title : this.state.title,
        value : this.state.value,
      }  
      this.props.addAppSetting(e, settingsObj);
    }else{
      this.validator.showMessages();
      this.forceUpdate();
    }
    e.preventDefault();
  }

  

  render() {
    //let self = this;
    let { openAddAppSettingModal, closeModal, AddAppSettingPopupError } = this.props;
    

    return (
      <Modal isOpen={openAddAppSettingModal}>
          <ModalHeader toggle={()=>{closeModal(false)}}>Add setting</ModalHeader>
          <Form onSubmit={(event) => {this.handleSubmit(event)}}>  
            <ModalBody>
              <Row>
                <Col xs="12">
                    <FormGroup>
                      <Label htmlFor="title">Title</Label>
                      <Input type="text" id="title" placeholder="Title" name="title" value={this.state.title} onChange={(event)=>{this.handleChange(event)}} />                                              
                      <div className="validation-error">{ this.validator.message('title', this.state.title, 'required') }</div>   
                    </FormGroup>
                </Col>
              </Row>

              <Row>
                <Col xs="12">
                    <FormGroup>
                      <Label htmlFor="title">Value</Label>
                      <Input type="text" id="value" placeholder="Value" name="value" value={this.state.value} onChange={(event)=>{this.handleChange(event)}} />                                              
                      <div className="validation-error">{ this.validator.message('value', this.state.value, 'required') }</div>   
                    </FormGroup>
                </Col>
              </Row>

              {(AddAppSettingPopupError) ? <Alert color="danger">{AddAppSettingPopupError}</Alert> : null}
            </ModalBody>
            <ModalFooter className="justify-content-left">
              <Button type="submit" size="sm" color="primary">Add</Button>{' '}
              <Button type="reset" size="sm" color="danger"  onClick={() => { closeModal() }}>Cancel</Button>
            </ModalFooter>
          </Form>
        </Modal>
      
    );
  }
}

export default AddAppSetting;
