import React, { Component } from 'react';
import { Loader  } from '../../../utils/common';
import { Row, Col, NavDropdown} from "reactstrap";
import moment from 'moment';
import Constants from '../../../config/constants';
import Session from '../../../utils/session';
import * as _ from 'underscore'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye,  faCopy } from '@fortawesome/free-solid-svg-icons'
import { faInstagram } from '@fortawesome/free-brands-svg-icons'
import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes';
import SocialButton from './socialButton';
import config from '../../../config';

class Instagram extends Component {
  constructor(props) {

    super(props);
    this.state = {
      instaData : {},
      copyText: "Copy to clipboard",
    }
  }

  componentWillReceiveProps(nextProps) {
    Loader(nextProps.requestProcess);
    if (nextProps.getSocialProfiles && nextProps.getSocialProfiles.statusCode == 200 && nextProps.getSocialProfiles.result && nextProps.getSocialProfiles.result.hasOwnProperty.call(Constants.INSTAGRAM)) {
          this.setState({instaData : nextProps.getSocialProfiles.result[Constants.INSTAGRAM]})
    }

  }

  handleSocialLogin = (user, err) => {
    if (err) {
      // do nothing
    }else{
      let params = {
        "socialPlatformId": user.profile.id,
        "socialPlatform": Constants.INSTAGRAM,
        "name": (user.profile.name) ? user.profile.name : "",
        "profilePicUrl": (user.profile && user.profile) ? user.profile.profilePicURL : "",
        "email": (user.profile.email) ? user.profile.email : "",
        "accessToken": user.token.accessToken
      }
      this.props._addUpdateUser(params)
    }
  }

  copyLink(link){
    if(link){
      this.setState({copyText: 'Copied!'}, ()=>{
        this.props.copyLink(link);
      })
    }
  }

  setCopyTextToInitial() {
    let self = this
    setTimeout(() => {
      self.setState({copyText : "Copy to clipboard"})
    }, 100)
  }



  render() {
    let {instaData} = this.state;


    if(!instaData || (instaData && Object.keys(instaData).length == 0)){
      return (
        <div className="card" style={{maxWidth: "540px"}}>
          <div className="row no-gutters">
            <div className="col-md-12 text-center">
              <div className="card-body">
                <SocialButton
                  provider='instagram'
                  appId={config.REACT_APP_INSTAGRAM_KEY}
                  onLoginSuccess={this.handleSocialLogin}
                  onLoginFailure={this.handleSocialLoginFailure}
                  redirect={window.location.origin+'/marketing-social'}
                  autoCleanUri={true}
                  autoLogin = {false}
                  className="social-media insta-connect-button"
                >
                  <FontAwesomeIcon style={{marginRight: 20}} icon={faInstagram} /> Connect to Instagram
                </SocialButton>
              </div>
            </div>
          </div>
        </div>
      )
    }


    return (
      <div className="card" style={{maxWidth: "540px"}}>
        <div className="row no-gutters">
          <div className="col-md-2">
            <span className="social-media-icon instragm-bg"><FontAwesomeIcon icon={faInstagram} /></span>
            {(instaData.profilePic) ? <img src={instaData.profilePic} className="card-img" alt="..." /> : <img src="https://image.flaticon.com/icons/svg/124/124010.svg" className="card-img" alt="..." />}
          </div>
          <div className="col-md-10">
            <button className="btn btn-primary btn-view" onClick={()=>{this.socialInsights(Constants.INSTAGRAM.toLowerCase())}}>
              <FontAwesomeIcon icon={faEye} />
            </button>
            <div className="social-card">
              <p className="card-text m-b0"><strong>{instaData.name}</strong> <br />{instaData.socialPlatformEmail}</p>
              <p className="card-text m-b0">{instaData.shortUrl}
              {/*  onClick={() => { this.copyLink(instaData.shortUrl) }} */}
                <button className="btn btn-outline-primary tooltip-custom"
                    onMouseOut={() => this.setCopyTextToInitial()}
                    onClick={(e) => { this.copyLink(instaData.shortUrl); e.preventDefault(); }}>
                    <FontAwesomeIcon icon={faCopy} />
                    <span className="tooltiptext" id="myTooltip">{this.state.copyText}</span>
                </button>
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Instagram;
