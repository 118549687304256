import {createReducer} from 'redux-act';
import { requestSocialProfiles, responseSocialProfiles, requestAddUpdateUser,responseAddUpdateUser, requestGetSocialProfile, responseGetSocialProfile, requestFacebookPageStatusChange, responseFacebookPageStatusChange, requestInsightsGraphData, responseInsightsGraphData} from './action';


const defaultState = {
	requestProcess: false,
};

const reducer = createReducer({

	[requestSocialProfiles]: (state) => {
		return {
			requestProcess: true,
			data: []
		};
	},

	[responseSocialProfiles]: (state, params) => {
		return {
			requestProcess: false,
			data: params
		};
	},


	[requestAddUpdateUser]: (state) => {
		return {
			requestProcess: true,
		};
	},

	[responseAddUpdateUser]: (state, params) => {
		return {
			requestProcess: false,
			addUpdateUser: params
		};
	},



	[requestGetSocialProfile]: (state) => {
		return {
			requestProcess: true,
		};
	},

	[responseGetSocialProfile]: (state, params) => {
		return {
			requestProcess: false,
			getSocialProfiles: params
		};
	},

	[requestInsightsGraphData]: (state) => {
		return {
			requestProcess: true,
		};
	},

	[responseInsightsGraphData]: (state, params) => {
		return {
			requestProcess: false,
			insightsGraphData: params
		};
	},


}, defaultState);



export default reducer
