import { createReducer } from 'redux-act';
import {requestSystemEmailData, responseSystemEmailData, requestUpdateSystemEmailData, responseUpdateSystemEmailData, requestGetImagesForEmailData, responseGetImagesForEmailData} from './action';


const defaultState = {
	requestProcess: false,
};

const reducer = createReducer({

	[requestSystemEmailData]: (state) => {
		return {
			requestProcess: true,
			systemEmailData: [],
		};
	},

	[responseSystemEmailData]: (state, params) => {
		return {
			requestProcess: false,
			systemEmailData: params,
		};
	},


	[requestUpdateSystemEmailData]: (state) => {
		return {
			requestProcess: true,
			updateSystemEmail: {},
		};
	},

	[responseUpdateSystemEmailData]: (state, params) => {
		return {
			requestProcess: false,
			updateSystemEmail: params,
		};
	},

	[requestGetImagesForEmailData]: (state) => {
		return {
			requestProcess: true,
			imagesForEmail: {},
		};
	},

	[responseGetImagesForEmailData]: (state, params) => {
		return {
			requestProcess: false,
			imagesForEmail: params,
		};
	},


}, defaultState);



export default reducer
