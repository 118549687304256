import {createAction} from 'redux-act';
import httpClient from '../../services/http';



export const requestGetPaymentHistory = createAction();
export const responseGetPaymentHistory = createAction();

export function getPaymentHistory(params){
  return (dispatch) => {
      dispatch(requestGetPaymentHistory([]));
      httpClient.call('USER','/get_payment_history', params, {method: 'GET', headers: {}}).then(function(response){
          dispatch(responseGetPaymentHistory(response));
      }, function(error){
          dispatch(responseGetPaymentHistory(error));
      });
  }
}

export function getTokenToExportPaymentHistory(params){
  return httpClient.call("USER","/get_token_to_export_userlist/" , params, {method: 'POST'});
}

export function getUserPaymentHistory(params){
  return httpClient.call("USER","/get_user_payment_history/" , params, {method: 'GET'});
}
