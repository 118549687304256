import React, { Component } from 'react';
import { connect } from 'react-redux';
// import { addUpdateCategory } from './action';
import { Input } from "reactstrap";
import { getPillars } from '../pillar/action';
import * as _ from 'underscore';

class PillarList extends Component {


  constructor(props) {
    super(props);
    this.state = {
      showAllOption : this.props.showAllOption
    }
    this.props._getPillars();
  }
  createSelectOption(pillarList){
    if(!pillarList){
      return null;
    }
    if(pillarList && pillarList.result && pillarList.statusCode === 200){

      let pillarData = [];
      if (pillarList && pillarList.result) {
        pillarData = _.groupBy(pillarList.result, function(obj){ return (obj['type']); });

        if(!this.state.showAllOption && pillarData && pillarData.PILLAR && pillarData.PILLAR.length >0 && !this.props.selectedPillarId){
          let event = {target: {name: 'pillarId', value: pillarData.PILLAR[0]._id } }
          this.props.handleChange(event)
        }

        return _.map(pillarData, function(obj, key){
          return (
            <optgroup key={key} label={key}>

            {_.map(obj, function(subObj, key){
              return <option key={subObj._id} value={subObj._id}>{subObj.title}</option> 
            })}
              
            </optgroup>
          );
        })
      }else{
        return (
          <option key="0" value="No Result"></option>
        );
      }
    }
    return null
  }

  render() {

    let {pillarList, selectedPillarId, handleChange} = this.props;
    return (
        <Input type="select" name="pillarId" id="pillarId" value={selectedPillarId} onChange={($event)=>{handleChange($event)}}>
            {this.state.showAllOption && <optgroup label="ALL">
              <option value="">ALL</option>
            </optgroup>}
            {this.createSelectOption(pillarList)}
        </Input>
    );
  }
}


const mapStateToProps = (state) => {
		var stateData = {
			requestProcess: state.pillar.requestProcess
		};
		if(state.pillar && state.pillar.data){
			stateData['pillarList'] = state.pillar.data;
		}
		return stateData;
};

const mapDispatchToProps = (dispatch) => {
	return {
		_getPillars: (params) => {
			dispatch(getPillars(params));
		},

	}
};


export default connect(
	mapStateToProps,
	mapDispatchToProps
)(PillarList);
