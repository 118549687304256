import React, { Component } from 'react';
import { Loader, SuccessToast, createRandomStr } from '../../utils/common';
import { Row, Col, Table, Button, Input, Alert, Form, Card, CardHeader, CardBody, FormGroup, Label, CardFooter, Pagination, PaginationItem, PaginationLink, Progress} from "reactstrap";
import moment from 'moment';
import SimpleReactValidator from 'simple-react-validator';
import DateTimePicker from 'react-datetime-picker';
import * as _ from 'underscore'
import Tags from './Tags';
import { element } from 'prop-types';
import InputEmoji from "../react-input-emoji-master";
class EmailCampaign   extends Component {

  constructor(props) {
    super(props);
    this.state = {
        page: 0,
        rowsPerPage: 10,
        emailCampaignList : [],
        successMessage: null,
        errorMessage: null,
        totalRecord: 0,
        uploadPercent:0,
        campaignName : '',
        compaignContactsFile: '',
        campaignContactFileName : '',
        subject: '',
        campaignTemplate: 'Diwali.html',
        scheduleDateTime: new Date(moment().add(1, 'days')),
        templateFile: '',
        templateFileName : '',
        templateValidateMsg : '',
        templateTitle : '',
        successTemplateMsg : '',
        errorTemplateMsg : '',
        templateList : [],
        testSubject: '',
        testCampaignTemplate: 'Diwali.html',
        testEmail: [],
        templatePage: 0,
        validationMesssge: '',
        sendMsg: false,
    }

    this.campaignListHeadings = [
      { "displayName": "Campaign Name"},
      { "displayName": "Subject"},
      { "displayName": "Template Name"},
      { "displayName": "Open"},
      { "displayName": "Click"},
      { "displayName": "Open %"},
      { "displayName": "Click %"},
      { "displayName": "Total"},
      { "displayName": "Scheduled On"},
      { "displayName": ""},
    ];

    this.templateListHeadings = [
      {"displayName": "Title"},
      {"displayName": "Created Date"},
      {"displayName": ""}
    ];

    this.validator = new SimpleReactValidator();
    this.templateValidator = new SimpleReactValidator();
    this.testValidator = new SimpleReactValidator();

    this.getCampaignList();
    this.getTemplateList();
  }


  getCampaignList() {
    let self = this
    let params = {
      limit : self.state.rowsPerPage,
      offset : (self.state.page * self.state.rowsPerPage),
    }
    this.props._getCampaignList(params)
  }

  getTemplateList() {
    let self = this
    let params = {
      limit : self.state.rowsPerPage,
      offset : (self.state.templatePage * self.state.rowsPerPage),
    }
    this.props._getTemplateList(params)
  }



  componentWillReceiveProps(nextProps) {
    Loader(nextProps.requestProcess);
    console.log(nextProps);
    if (nextProps.templateList && nextProps.templateList.statusCode === 200) {

        this.setState({ templateList : nextProps.templateList.result.list, totalTemplates : nextProps.templateList.result.count}, ()=>{

        })
    }

    if (nextProps.emailCampaignList && nextProps.emailCampaignList.statusCode === 200) {

        this.setState({ emailCampaignList : nextProps.emailCampaignList.result.list, totalCampaign : nextProps.emailCampaignList.result.count}, ()=>{

        })
    }
  }

  handleChange(event) {
    let target = event.target;
    let name = target.name;
    let value = target.value;
    this.setState({[name]: value});
  }

  updateTags(tags) {
    this.checkEmailExist(tags);
    this.setState({ testEmail: tags })
  }
  checkEmailExist(tags){
    let email =[];
    tags.some((element) => {
      email.push(element.name);
    })
    console.log(email.every(this.isEmail));
    if(!email.every(this.isEmail)){
      this.setState({ validationMesssge: "Please enter validate email", sendMsg : false })
    } else {
      this.setState({ validationMesssge: "", sendMsg : true })
    }
  }

  isEmail(email) {
    return email.match(/^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/);
  }


  importContacts(e) {
      const data = new FormData();
      const file = e.target.files[0];
      let self = this;
      let proceed = self.validateFileType(file.name, file);
      if (proceed) {

        setTimeout(()=>{
          data.append('csvFile', file)
          self.setState({compaignContactsFile: file, campaignContactFileName : file.name})

        }, 2000)
      }
  }



  validateFileType(name, file){
    let self = this;
    if(file){
      if(['text/csv', 'application/vnd.ms-excel'].indexOf(file.type) === -1){
        self.setState({importvalidateMsg: "Please select valid csv file", error : true});
        return false;
      } else {
        self.setState({importvalidateMsg: ""});
        return true;
      }
    }else {
      return true;
    }
  }

  importFileTrigger(e) {
    this.fileUploader.click();
    e.preventDefault();
  }


  sendEmailCompaign(e) {

    let _self = this;
    if( _self.validator.allValid()){
        Loader(true)
        const data = new FormData();
        let {subject, campaignName, compaignContactsFile,  campaignTemplate, scheduleDateTime} = _self.state;
        data.append('campaignContactFile', compaignContactsFile)
        data.append('campaignName', campaignName)
        data.append('campaignTemplate', campaignTemplate);
        data.append('subject', subject);
        data.append('scheduleDateTime', scheduleDateTime);
        _self.props._uploadContactCSV(data, function(uploadPercent){
            _self.setState({uploadPercent : uploadPercent})
        }).then( response => {
            if(response && response.statusCode === 200){
                try{
                    this.form.resetForm();
                }catch(error){
                    console.log('catch error', error)
                }
                Loader(false)
                _self.setState({
                  uploadPercent: 0,
                  campaignContactFileName: '',
                  compaignContactsFile: '',
                  campaignName: '',
                  subject: '',
                  scheduleDateTime: new Date(moment().add(1, 'days')),
                  campaignTemplate: 'Diwali.html',
                  successMessage: response.result
                }, ()=>{
                    _self.getCampaignList();
                    setTimeout( ()=>{
                      document.querySelector(".email-subject").innerHTML = '';
                        _self.setState({successMessage: null})
                    }, 3000);
                })
            }else{
                _self.setState({errorMessage: response.result}, ()=>{
                    setTimeout( ()=>{
                        _self.setState({errorMessage: null})
                    }, 3000);
                })
                Loader(false)
            }

        }, error => {
            console.log('error', error)
            Loader(false)
        });


    }else{
        this.validator.showMessages();
        this.forceUpdate();
    }
    e.preventDefault();
  }

  templateFileTrigger(e){
    this.templateFileUploader.click();
    e.preventDefault();

  }

  updateTemplateFile(e){
    const data = new FormData();
      const file = e.target.files[0];
      let self = this;
      let proceed = self.validateTemplateFile(file.name, file);
      if (proceed) {

        setTimeout(()=>{
          data.append('templateFile', file)
          self.setState({templateFile: file, templateFileName : file.name})

        }, 2000)
      }
  }

  validateTemplateFile(name, file){
    let self = this;
    if(file){
      if(['text/html', 'application/xhtml+xml'].indexOf(file.type) === -1){
        self.setState({templateValidateMsg: "Please select valid HTML file", error : true});
        return false;
      } else {
        self.setState({templateValidateMsg: ""});
        return true;
      }
    }else {
      return true;
    }

  }

  sendTestEmail(e){
    e.preventDefault();
    let _self = this;
    if( _self.testValidator.allValid() && _self.state.sendMsg){
        Loader(true)
        let {testEmail, testSubject, testCampaignTemplate} = _self.state;
        let email =[];
        testEmail.some((element) => {
          email.push(element.name);
        })
        let data = {email : email.join(), subject : testSubject, template : testCampaignTemplate};
        _self.props._sendTestEmail(data).then( response => {
            if(response && response.statusCode === 200){
              try{
                this.testEmailForm.resetForm();
              }catch(error){
                  console.log('catch error', error)
              }
              Loader(false)
              this.testValidator.hideMessages();
              _self.setState({
                testEmail: [],
                testSubject: '',
                testEmailMessage: response.result
              }, ()=>{
                  setTimeout( ()=>{
                    document.querySelector(".email-test-subject").innerHTML = '';
                      _self.setState({testEmailMessage: null})
                  }, 3000);
              })

            } else {
                _self.setState({testEmailMessage: response.result}, ()=>{
                    setTimeout( ()=>{
                        _self.setState({testEmailMessage: null})
                    }, 3000);
                })
                Loader(false)
            }

        }, error => {
            if(error && error.data && error.data.statusMessage){
              _self.setState({testEmailMessage: error.data.statusMessage}, ()=>{
                setTimeout( ()=>{
                    _self.setState({testEmailMessage: null})
                }, 3000);
              })
            }
            console.log('error', error)
            Loader(false)
        });

    }else{
      this.testValidator.showMessages();
      this.forceUpdate();
    }
    e.preventDefault();
  }

  updateDefaultTemplate(e){
    e.preventDefault();
    let _self = this;
    if( _self.templateValidator.allValid()){
        Loader(true)
        let {templateTitle, templateFile} = _self.state;

        const data = new FormData();
        data.append('templateFile', templateFile)
        data.append('templateTitle', templateTitle)

        _self.props._uploadDefaultTemplate(data, function(uploadPercent){
          _self.setState({templateUploadPercent : uploadPercent})
        }).then( response => {
            if(response && response.statusCode === 200){

              try{
                this.templateForm.resetForm();
              }catch(error){
                  console.log('catch error', error)
              }
              Loader(false)
              this.templateValidator.hideMessages();
              _self.setState({
                templateUploadPercent: 0,
                templateFileName: '',
                templateFile: '',
                templateTitle : '',
                templateFileData : '',
                successTemplateMsg: response.result
              }, ()=>{
                  _self.getTemplateList();
                  setTimeout( ()=>{
                      _self.setState({successTemplateMsg: null})
                  }, 3000);
              })

            }else{
                _self.setState({errorTemplateMsg: response.result}, ()=>{
                    setTimeout( ()=>{
                        _self.setState({errorTemplateMsg: null})
                    }, 3000);
                })
                Loader(false)
            }

        }, error => {
            if(error && error.data && error.data.statusMessage){
              _self.setState({errorTemplateMsg: error.data.statusMessage}, ()=>{
                setTimeout( ()=>{
                    _self.setState({errorTemplateMsg: null})
                }, 3000);
              })
            }
            console.log('error', error)
            Loader(false)
        });
    }else{
        this.templateValidator.showMessages();
        this.forceUpdate();
    }
    e.preventDefault();

  }

  downloadTemplate(){
    let _self = this;
    Loader(true);
    _self.props._downloadDefaultTemplate()
    //.then((response) => response.blob())
    .then( (blob) => {
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'default_template.html');
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);

      Loader(false);
    }, error => {
        if(error && error.data && error.data.statusMessage){
          _self.setState({errorTemplateMsg: error.data.statusMessage}, ()=>{
            setTimeout( ()=>{
                _self.setState({errorTemplateMsg: null})
            }, 3000);
          })
        }
        console.log('error', error)
        Loader(false)
    });
  }

  deleteTemplate(id){
    let _self = this;
    Loader(true);
    _self.props._deleteTemplate({tempId : id}).then( response => {
      console.log(response);
      Loader(false);
      _self.getTemplateList();
    }, error => {
      console.log(error);
    })
  }

  setGridHeaders(headingData) {
    let gridHeadings = [];
    //gridHeadings.push(<th key={'index_0'}>#</th>)
    _.each(headingData, function(value, index){
      {
        gridHeadings.push(<th key={index} style={{width:(value['displayName'] === 'Message' ? '50%' : '')}}>{value['displayName']}</th>)
      }

    })

    return (<tr className="">{gridHeadings}</tr>)
  }


   setGridData(data) {
   // let self = this;
    let gridData = [];
    let page = this.state.page;
    page = (page -1) * 10;
    page = page > 0 ? page : 0;

    if (data.length) {
      _.each(data, function(value, index){
        {
          gridData.push(
            <tr key={index}>
              {/*<th scope="row">{page+(index + 1)}</th>*/}
              <td>{value['campaignName']}</td>
              <td>{value['subject']}</td>
              <td>{value['template']}</td>
              <td>{value['openEmailContact'] ? value['openEmailContact'] : 0}</td>
              <td>{value['surveyClickContact'] ? value['surveyClickContact'] : 0}</td>
              <td>{value['openEmailContact'] ? ((parseInt(value['openEmailContact']) * 100) / parseInt(value['totalContact'])).toFixed(2) : 0.00}</td>
              <td>{value['surveyClickContact'] ? ((parseInt(value['surveyClickContact']) * 100) / parseInt(value['totalContact'])).toFixed(2) : 0.00}</td>
              <td>{value['totalContact'] ? value['totalContact'] : 0}</td>
              <td>{moment(value['scheduleDateTime']).format("DD-MMM-YYYY h:mm a")}</td>
              {/*<td><Button id="columnFilterSection" type="button">Export Csv</Button></td>*/}
            </tr>
         )
        }
      })
    }else{
      gridData.push(<tr key={'index-123'}><td className="no-data-found" colSpan={this.campaignListHeadings.length + 1}>No Records Found</td></tr>)
    }

    return gridData;
  }


  setTemplateGridHeaders(headingData) {
    let gridHeadings = [];
    //gridHeadings.push(<th key={'index_0'}>#</th>)
    _.each(headingData, function(value, index){
      {
        gridHeadings.push(<th key={index} >{value['displayName']}</th>)
      }

    })

    return (<tr className="">{gridHeadings}</tr>)
  }


   setTemplateGridData(data) {
   let self = this;
    let gridData = [];
    let page = this.state.templatePage;
    page = (page -1) * 10;
    page = page > 0 ? page : 0;

    if (data && data.length) {
      _.each(data, function(value, index){
        {
          gridData.push(
            <tr key={value['_id']}>
              <td>{value['templateName']}</td>
              <td>{moment(value['createdDate']).format("DD-MMM-YYYY h:mm a")}</td>
              <td><Button id="deleteTemplate" type="button" onClick={(event) => self.deleteTemplate(value['_id'])}>Delete</Button></td>
            </tr>
         )
        }
      })
    }else{
      gridData.push(<tr key={'index-123'}><td className="no-data-found" colSpan={this.templateListHeadings.length + 1}>No Records Found</td></tr>)
    }

    return gridData;
  }


  setPaginationLinks(totalRecords, pageLimit, dataType) {

    let linksCount = (totalRecords > 0 && pageLimit > 0) ? Math.ceil(totalRecords / pageLimit) : 0;
    let links = [];
    // links.push(<PaginationItem disabled><PaginationLink first  /></PaginationItem><PaginationItem disabled><PaginationLink previous  /></PaginationItem>)


    for (let i = 0; i < linksCount ; i++) {
      if(dataType == 'CAMPAIGN'){
      links.push(<PaginationItem key={i} active={this.state.page === i} >
          <PaginationLink disabled={this.state.page === i} onClick={(event) => this.getMoreCampaignListData(i)}>
            {i + 1}
          </PaginationLink>
        </PaginationItem>)

      }else{
        links.push(<PaginationItem key={i} active={this.state.templatePage === i} >
          <PaginationLink disabled={this.state.templatePage === i} onClick={(event) => this.getMoreTemplateListData(i)}>
            {i + 1}
          </PaginationLink>
        </PaginationItem>)

      }
    }

    return (<Pagination aria-label="Page navigation example" className="float-right"> {links} </Pagination>)
  }

  getMoreCampaignListData(offset) {
    let self = this;
    self.setState({offset : offset * self.state.rowsPerPage, page: offset}, () => {
      self.getCampaignList();
    })
  }

  getMoreTemplateListData(offset){
    let self = this;
    self.setState({offset : offset * self.state.rowsPerPage, templatePage: offset}, () => {
      self.getTemplateList();
    })
  }

  handleSubject(event){
    if((!this.state.successMessage || this.state.successMessage == null) && event != undefined){
      this.setState({
        subject : event
      })
    } else {
      this.setState({
        subject : ''
      })
      document.querySelector(".email-subject").innerHTML = '';
    }
  }

  handleTestSubject(event){
    if((!this.state.testEmailMessage || this.state.testEmailMessage == null) && event != undefined){
      this.setState({
        testSubject : event
      })
    } else {
      this.setState({
        testSubject : ''
      })
      document.querySelector(".email-test-subject").innerHTML = '';
    }

  }


  render() {
    let { rowsPerPage, emailCampaignList, totalCampaign, templateList, totalTemplates, importvalidateMsg, campaignContactFileName, successMessage, errorMessage, templateFileName, templateValidateMsg, successTemplateMsg, errorTemplateMsg, testEmailMessage, validationMesssge } = this.state;
    let optionTemplate = this.state.templateList.map((v,k) => (
      <option key={k} value={v.templateFileName}>{v.templateName}</option>
    ));
    return (
        <div className="content">
            <Row className="pb-4">
                <Col lg={6} sm={6}>
                    <h3>Email Campaign</h3>
                </Col>
            </Row>
            <Row>
                <Col lg={8} sm={6} >
                    <Card>
                    <CardHeader>Campaign List </CardHeader>
                    <CardBody>
                        <Table responsive hover>
                        <thead>
                            {this.setGridHeaders(this.campaignListHeadings)}
                        </thead>
                        <tbody>
                            {this.setGridData(emailCampaignList)}
                        </tbody>
                        </Table>
                    </CardBody>
                    <CardFooter>
                        <span>Total Campaigns : {totalCampaign}</span>
                        { this.setPaginationLinks(totalCampaign, rowsPerPage, 'CAMPAIGN')
                        }
                    </CardFooter>
                    </Card>
                </Col>
                <Col lg={4} sm={6}>
                    <Row className="pb-4" >
                        <Col lg={12} sm={12}>
                            <Form onSubmit={(event) => {event.preventDefault();}} ref={form => this.form = form}>
                            <Card>
                                <CardHeader>Import contacts and send Campaign</CardHeader>
                                <CardBody>
                                    <Row>
                                        <Col xs="12">
                                            <FormGroup>
                                            <Button type="button"  size="lg" color="success" onClick={(event) => this.importFileTrigger(event)}>{(campaignContactFileName) ? campaignContactFileName : "Choose CSV File To Import Contacts"}</Button>
                                            <input type="file" value={this.state.campaignContacts} name="myImage" onChange= {(e) =>{this.importContacts(e)}} ref={(elRef) => this.fileUploader = elRef} style={{display: "none"}} />
                                            {this.state.uploadPercent > 0 && <Progress  className="m-t10" value={this.state.uploadPercent}>{this.state.uploadPercent}%</Progress>}
                                            <div className="validation-error">{ this.validator.message('Campaign Contacts', this.state.compaignContactsFile, 'required') }</div>
                                            {importvalidateMsg && <div className="validation-error">{ importvalidateMsg }</div>}
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs="12">
                                            <FormGroup>
                                            <Label htmlFor="campaignName">Campaign Name</Label>
                                            <Input  id="campaignName" placeholder="Campaign name" name="campaignName" value={this.state.campaignName} onChange={($event)=>{this.handleChange($event)}} />
                                            <div className="validation-error">{ this.validator.message('Campaign Name', this.state.campaignName, 'required') }</div>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs="12">
                                            <FormGroup>
                                            <Label htmlFor="subject">Subject</Label>
                                            {/* <Input id="subject" placeholder="Subject" name="subject" value={this.state.subject} onChange={($event)=>{this.handleChange($event)}} /> */}
                                            <InputEmoji
                                            style={{marginLeft: 10}}
                                            inputClass={'form-control email-subject'}
                                            height={10}
                                            maxLength="20"
                                            type="input"
                                            id="notificationTitle"
                                            placeholder="Subject"
                                            name="subject"
                                            value={this.state.subject}
                                            onChange={($event)=>{this.handleSubject($event)}}
                                          />
                                            <div className="validation-error">{ this.validator.message('Subject', this.state.subject, 'required') }</div>
                                            </FormGroup>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col xs="12">
                                            <FormGroup>
                                            <Label htmlFor="campaignTemplate">Template</Label>
                                            <Input type="select" id="campaignTemplate" name="campaignTemplate" value={this.state.campaignTemplate} onChange={($event)=>{this.handleChange($event)}} >
                                              {optionTemplate}
                                                <option value="Diwali.html">Diwali</option>
                                                <option value="dr_melmed.html">Dr. Melmed</option>
                                                <option value="FreeContentForUsers.html">FreeContentForUsers</option>
                                                <option value="SurveyFree.html">SurveyFree</option>
                                                <option value="SurveyPaid.html">SurveyPaid</option>
                                                <option value="ComingSoon.html">ComingSoon</option>
                                            </Input>
                                            <div className="validation-error">{ this.validator.message('Template', this.state.campaignTemplate, 'required') }</div>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs="12">
                                            <FormGroup>
                                            <Label htmlFor="campaignTemplate">Schedule Date & Time</Label>
                                            <DateTimePicker
                                              name={'scheduleDateTime'}
                                              className={'form-control'}
                                              value={this.state.scheduleDateTime}
                                              onChange={(value)=>{
                                                this.setState({scheduleDateTime: new Date(value)})
                                              }}
                                            />
                                            <div className="validation-error">{ this.validator.message('Schedule Date & Time', this.state.scheduleDateTime, 'required') }</div>
                                            </FormGroup>
                                        </Col>
                                    </Row>

                                    {successMessage && <Alert color="success">{successMessage}</Alert>}
                                    {errorMessage && <Alert color="danger">{errorMessage}</Alert>}
                                </CardBody>
                                <CardFooter>
                                    <Button type="submit" onClick={(event)=>{this.sendEmailCompaign(event);}}  size="sm" color="primary float-right">Send</Button>
                                </CardFooter>
                                </Card>
                            </Form>
                        </Col>

                        <Col lg={12} sm={12}>
                          <Row className="pb-4" >
                              <Col lg={12} sm={12}>
                                  <Form method="POST" onSubmit={(event) => {event.preventDefault();}} ref={(elRef) => this.testEmailForm = elRef}>
                                  <Card>
                                      <CardHeader>Send Test Email</CardHeader>
                                      <CardBody>
                                          <Row>
                                              <Col xs="12">
                                                  <FormGroup>
                                                  <Label htmlFor="testSubject">Email</Label>
                                                  {/* <Input id="testEmail" placeholder="Email" name="testEmail" value={this.state.testEmail} onChange={($event)=>{this.handleChange($event)}} /> */}
                                                  <Tags tags={this.state.testEmail} updateTags={(tags) => { this.updateTags(tags) }} ></Tags>
                                                  { validationMesssge &&
                                                  <div className="validation-error"> {validationMesssge} </div>
                                                  }
                                                  </FormGroup>
                                              </Col>
                                          </Row>
                                          <Row>
                                              <Col xs="12">
                                                  <FormGroup>
                                                  <Label htmlFor="testSubject">Subject</Label>
                                                  {/* <Input id="testSubject" placeholder="Subject" name="testSubject" value={this.state.testSubject} onChange={($event)=>{this.handleChange($event)}} /> */}
                                                  <InputEmoji
                                                      style={{marginLeft: 10}}
                                                      inputClass={'form-control email-test-subject'}
                                                      height={60}
                                                      maxLength="200"
                                                      type="input"
                                                      id="testSubject"
                                                      placeholder="Subject"
                                                      name="testSubject"
                                                      value={this.state.testSubject}
                                                      onChange={($event)=>{this.handleTestSubject($event)}}
                                                    />
                                                  <div className="validation-error">{ this.testValidator.message('Subject', this.state.testSubject, 'required') }</div>
                                                  </FormGroup>
                                              </Col>
                                          </Row>

                                          <Row>
                                              <Col xs="12">
                                                  <FormGroup>
                                                  <Label htmlFor="testCampaignTemplate">Template</Label>
                                                  <Input type="select" id="testCampaignTemplate" name="testCampaignTemplate" value={this.state.testCampaignTemplate} onChange={($event)=>{this.handleChange($event)}} >
                                                    {optionTemplate}
                                                      <option value="Diwali.html">Diwali</option>
                                                      <option value="dr_melmed.html">Dr. Melmed</option>
                                                      <option value="FreeContentForUsers.html">FreeContentForUsers</option>
                                                      <option value="SurveyFree.html">SurveyFree</option>
                                                      <option value="SurveyPaid.html">SurveyPaid</option>
                                                      <option value="ComingSoon.html">ComingSoon</option>
                                                  </Input>
                                                  <div className="validation-error">{ this.testValidator.message('Template', this.state.testCampaignTemplate, 'required') }</div>
                                                  </FormGroup>
                                              </Col>
                                          </Row>
                                          {testEmailMessage && <Alert color="success">{testEmailMessage}</Alert>}

                                      </CardBody>
                                      <CardFooter>
                                          <Button type="submit" onClick={(event)=>{this.sendTestEmail(event);}}  size="sm" color="primary float-right">Send</Button>
                                      </CardFooter>
                                      </Card>
                                  </Form>
                              </Col>
                          </Row>
                      </Col>
                    </Row>
                </Col>

            </Row>
            <Row>
            <Col lg={12} sm={12}>
            <Form className="ui form" method="POST" onSubmit={(event) => {this.updateDefaultTemplate(event)}} ref={(elRef) => this.templateForm = elRef}>
                        <Row>
                        <Col xs="6">
                        <Card>
                        <CardHeader>Template List </CardHeader>
                        <CardBody>
                            <Table responsive hover>
                            <thead>
                                {this.setTemplateGridHeaders(this.templateListHeadings)}
                            </thead>
                            <tbody>
                                {this.setTemplateGridData(templateList)}
                            </tbody>
                            </Table>
                        </CardBody>
                        <CardFooter>
                            <span>Total : {totalTemplates}</span>
                            { this.setPaginationLinks(totalTemplates, rowsPerPage, 'TEMPLATE')
                            }
                        </CardFooter>
                        </Card>
                        </Col>


                        <Col xs="6">
                        <Card>
                        <CardHeader>Custom Template</CardHeader>
                          <CardBody>
                          <Row>
                            <Col xs="12">
                                <FormGroup>
                                <Button type="button"  size="lg" color="success" onClick={(event) => this.templateFileTrigger(event)}>{(templateFileName) ? templateFileName : "Choose Template File"}</Button>
                                <input type="file" key={this.state.templateFileData} onChange= {(e) =>{this.updateTemplateFile(e)}} ref={(elRef) => this.templateFileUploader = elRef} style={{display: "none"}} />
                                {this.state.templateUploadPercent > 0 && <Progress  className="m-t10" value={this.state.templateUploadPercent}>{this.state.templateUploadPercent}%</Progress>}
                                <div className="validation-error">{ this.templateValidator.message('Template file', this.state.templateFile, 'required') }</div>
                                {templateValidateMsg && <div className="validation-error">{ templateValidateMsg }</div>}
                                </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                              <Col xs="12">
                                  <FormGroup>
                                  <Label htmlFor="templateTitle">Template Title</Label>
                                  <Input id="templateTitle" placeholder="Template Title" name="templateTitle" value={this.state.templateTitle} onChange={($event)=>{this.handleChange($event)}} />
                                  <div className="validation-error">{ this.templateValidator.message('Template Title', this.state.templateTitle, 'required') }</div>
                                  </FormGroup>
                              </Col>
                          </Row>
                          {successTemplateMsg && <Alert color="success">{successTemplateMsg}</Alert>}
                          {errorTemplateMsg && <Alert color="danger">{errorTemplateMsg}</Alert>}
                          </CardBody>
                          <CardFooter>
                          <Button type="button" onClick={(event) => this.downloadTemplate()} size="sm" color="primary float-left">Download Default Template</Button>
                              <Button type="submit" size="sm" color="primary float-right">Upload New Template</Button>
                          </CardFooter>
                          </Card>
                        </Col>
                        </Row>
                </Form>
            </Col>
            </Row>
      </div>
    );
  }
}

export default EmailCampaign;
