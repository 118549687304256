import {createAction} from 'redux-act';
import httpClient from '../../../services/http';


export const requestGetStaticLanguage = createAction();
export const responseGetStaticLanguage = createAction();


export const requestAddStaticLanguage = createAction();
export const responseAddStaticLanguage = createAction();


export const requestStaticLanguageStatus = createAction();
export const responseStaticLanguageStatus = createAction();



export const requestUpdateStaticLanguage = createAction();
export const responseUpdateStaticLanguage = createAction();

export const requestTriggerNotification = createAction();
export const responseTriggerNotification = createAction();



export function getStaticLanguage(params){
    return (dispatch) => {
      dispatch(requestGetStaticLanguage([]));
      httpClient.call('CONFIG', '/get_static_languages', params, {method: 'GET', headers: {}}).then(function(response){
          dispatch(responseGetStaticLanguage(response));
      }, function(error){
          dispatch(responseGetStaticLanguage(error));
      });
  }
}




export function addStaticLanguage(params){
    return (dispatch) => {
      dispatch(requestAddStaticLanguage([]));
      httpClient.call('CONFIG', '/add_static_language', params, {method: 'POST', headers: {}}).then(function(response){
          dispatch(responseAddStaticLanguage(response));
      }, function(error){
          dispatch(responseAddStaticLanguage(error));
      });
  }
}


export function changeStaticLanguageStatus(params){
    return (dispatch) => {
      dispatch(requestStaticLanguageStatus([]));
      httpClient.call('CONFIG', '/change_static_lang_status', params, {method: 'PUT', headers: {}}).then(function(response){
          dispatch(responseStaticLanguageStatus(response));
      }, function(error){
          dispatch(responseStaticLanguageStatus(error));
      });
  }
}


export function updateStaticLanguage(params){
    return (dispatch) => {
      dispatch(requestUpdateStaticLanguage([]));
      httpClient.call('CONFIG', '/update_static_language', params, {method: 'PUT', headers: {}}).then(function(response){
          dispatch(responseUpdateStaticLanguage(response));
      }, function(error){
          dispatch(responseUpdateStaticLanguage(error));
      });
  }
}


export function triggerNotificaton(params){
    return (dispatch) => {
      dispatch(requestTriggerNotification([]));
      httpClient.call('CONFIG', '/static_lang_upd_notificaton', params, {method: 'GET', headers: {}}).then(function(response){
          dispatch(responseTriggerNotification(response));
      }, function(error){
          dispatch(responseTriggerNotification(error));
      });
  }
}
